import axios from 'axios';
import { pack } from '../../../../@gc';
import { toastr } from 'react-redux-toastr';
import { startLoading, stopLoading } from '../../../../@gc/common/actions/common.action';
import _ from 'lodash';

export const FETCH_BASIC = '[VATTEND] FETCH_BASIC';
export const FETCH_DETAILS = '[VATTEND] FETCH_DETAILS';
export const FETCH_YEARS = '[VATTEND] FETCH_YEARS';
export const FETCH_EDITS = '[VATTEND] FETCH_EDITS';
export const UPDATE_DETAIL = '[VATTEND] UPDATE_DETAIL';
export const REMOVE_DETAILS = '[VATTEND] REMOVE_DETAILS';
export const UPDATE_YEAR = '[VATTEND] UPDATE_YEAR';
export const REMOVE_YEARS = '[VATTEND] REMOVE_YEARS';
export const UPDATE_EDIT = '[VATTEND] UPDATE_EDIT';

const jtf = require('json-form-data');

export function fetchBasic() {
   return dispatch =>
      axios
         .get('/vattend/basic')
         .then(res =>
            dispatch({
               type: FETCH_BASIC,
               payload: res.data,
            }),
         )
         .catch(e => pack.showError(e));
}

export function fetchDetails() {
   return dispatch =>
      axios
         .get('/vattend/details')
         .then(res =>
            dispatch({
               type: FETCH_DETAILS,
               payload: res.data,
            }),
         )
         .catch(e => pack.showError(e));
}

export function fetchYears() {
   return dispatch =>
      axios
         .get('/vattend/years')
         .then(res =>
            dispatch({
               type: FETCH_YEARS,
               payload: res.data,
            }),
         )
         .catch(e => pack.showError(e));
}

export function fetchEdits(year) {
   return dispatch => {
      dispatch(startLoading());

      axios
         .get(`/vattend/edits/${year}`)
         .then(res => {
            dispatch({
               type: FETCH_EDITS,
               payload: res.data,
            });
            dispatch(stopLoading());
         })
         .catch(e => {
            pack.showError(e);
            dispatch(stopLoading());
         });
   };
}

export function updateBasic(data) {
   return (dispatch, getState) =>
      axios
         .post('/vattend/basic', data)
         .then(() => {
            const { strings } = getState().gc.common;
            toastr.success(strings.infoTitle, strings.successMessage);
            dispatch(fetchBasic());
            dispatch(fetchEdits(new Date().getFullYear()));
         })
         .catch(e => pack.showError(e));
}

export function updateDetail(row) {
   return dispatch =>
      axios
         .post('/vattend/detail', row)
         .then(res =>
            dispatch({
               type: UPDATE_DETAIL,
               payload: res.data,
               mode: !row.seq ? 'add' : 'edit',
            }),
         )
         .catch(e => pack.showError(e));
}

export function removeDetails(rows) {
   return dispatch =>
      axios.patch('/vattend/details/delete', jtf({ seqs: rows.join(',') })).then(res =>
         dispatch({
            type: REMOVE_DETAILS,
            rows,
         }),
      );
}

export function updateYear(row) {
   return dispatch =>
      axios.post('/vattend/year', row).then(res =>
         dispatch({
            type: UPDATE_YEAR,
            payload: res.data,
            mode: !row.seq ? 'add' : 'edit',
         }),
      );
}

export function removeYears(rows) {
   return dispatch =>
      axios.patch('/vattend/years/delete', jtf({ seqs: rows.join(',') })).then(res =>
         dispatch({
            type: REMOVE_YEARS,
            rows,
         }),
      );
}

export function updateEdit(row) {
   return dispatch =>
      axios.post('/vattend/edit', JSON.stringify(_.pick(row, ['seq', 'empno', 'amount', 'theYear', 'reason']))).then(res =>
         dispatch({
            type: UPDATE_EDIT,
            payload: res.data,
         }),
      );
}
