import { withFormsy } from 'formsy-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { pack } from '@gc';
import { Col, FormText, Input, Label } from 'reactstrap';
import InputGroup from 'reactstrap/es/InputGroup';
import InputGroupAddon from 'reactstrap/es/InputGroupAddon';
import cx from 'classnames';

class InputFormsy extends Component {
   constructor(props) {
      super(props);
      this.state = {
         touched: false,
      };
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.viewmode !== this.props.viewmode) {
         this.setState({
            touched: false,
         });
      }
   }

   changeValue = evt => {
      if (!this.props.noUpdate) this.props.setValue(evt.target.value);
      if (typeof this.props.onChange === 'function') {
         evt.persist(); // 사용부분에서 onChange={_.debounce(this.handleChange, 300)} 처럼 사용하려면 먼저 persist 를 해줘야 한다.
         this.props.onChange(evt);
      }
   };

   onBlur = evt => {
      if (this.props.viewmode) return false;
      if (!this.state.touched) this.setState({ touched: true });
      if (typeof this.props.onBlur === 'function') {
         this.props.onBlur(evt);
      }
   };

   handleInnerRef = ref => {
      if (typeof this.props.inputRef === 'function') {
         this.props.inputRef(ref);
      }
   };

   render() {
      const { touched } = this.state;
      let importedProps = _.pick(this.props, [
         'type',
         'name',
         'className',
         'autoFocus',
         'maxLength',
         'defaultValue',
         'className',
         'disabled',
         'onFocus',
         'placeholder',
         'children',
      ]);
      let error = false;
      let errorMessage = this.props.getErrorMessage() || this.props.errorTrigger;
      const value = (!this.props.noUpdate ? this.props.getValue() : this.props.value) || '';

      if (!importedProps.type) {
         importedProps.type = 'text';
      }

      if (touched && !value && !errorMessage && this.props.required) {
         errorMessage = this.props.requiredMsg || '';
         error = true;
      }

      if (this.props.matching) {
         const func = pack.getMatchingFunction(this.props.matching);
         if (func != null)
            importedProps = {
               ...importedProps,
               ...func,
            };
         else {
            console.log("No Matching Functions for '" + this.props.matching + "'");
         }
      }

      const labelWidth = this.props.labelWidth || { sm: 2 };
      const colWidth = this.props.colWidth || { sm: 4 };
      const id = this.props.id || this.props.name;
      importedProps.id = id;
      if (this.props.type === 'textarea' && this.props.size) {
         switch (this.props.size) {
            case 'sm':
               importedProps.bsSize = '1';
               break;
            case 'md':
               importedProps.bsSize = '2';
               break;
            case 'lg':
               importedProps.bsSize = '3';
               break;
            case 'xl':
               importedProps.bsSize = '4';
               break;
         }
      }

      return (
         <>
            {Boolean(this.props.label) && (
               <Label
                  {...labelWidth}
                  for={id}
                  className={cx({
                     required: !this.props.viewmode && this.props.required,
                  })}
               >
                  {this.props.label}
               </Label>
            )}
            <Col {...colWidth} className={cx('d-flex align-items-center', this.props.colClassName || '')}>
               {!this.props.viewmode ? (
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                     }}
                     className="w-100"
                  >
                     <InputGroup>
                        {this.props.icon != null && (
                           <InputGroupAddon addonType="prepend">
                              <div className="input-group-text">{this.props.icon}</div>
                           </InputGroupAddon>
                        )}
                        <Input
                           {...importedProps}
                           autoComplete="off"
                           data-lpignore="true"
                           value={value}
                           onChange={this.changeValue}
                           onBlur={this.onBlur}
                           invalid={Boolean(errorMessage) || error}
                           innerRef={this.handleInnerRef}
                        />
                     </InputGroup>
                     {Boolean(errorMessage) && <FormText color="danger">{errorMessage}</FormText>}
                  </div>
               ) : (
                  <Label className="view crlf">{value}</Label>
               )}
            </Col>
         </>
      );
   }
}

InputFormsy.propTypes = {
   type: PropTypes.string,
   name: PropTypes.string.isRequired,
   id: PropTypes.string,
   label: PropTypes.string,
   placeholder: PropTypes.string,
   onChange: PropTypes.func,
   onBlur: PropTypes.func,
   value: PropTypes.any,
   defaultValue: PropTypes.any,
   viewmode: PropTypes.bool,
   required: PropTypes.bool,
   requiredMsg: PropTypes.string,
   errorTrigger: PropTypes.string,
   matching: PropTypes.any,
   validations: PropTypes.any,
   validationErrors: PropTypes.object,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   disabled: PropTypes.bool,
   autoFocus: PropTypes.bool,
   maxLength: PropTypes.number,
   inputRef: PropTypes.func,
   icon: PropTypes.object,
   size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
   colClassName: PropTypes.string,
   className: PropTypes.string,
   noUpdate: PropTypes.bool,
};

export default withFormsy(InputFormsy);
