import axios from 'axios';
import {startLoading, stopLoading} from "@gc/common/actions/common.action";
import {pack} from "@gc";

const jtf = require('json-form-data');

export const FETCH_AUTH_LIST = '[AUTH] FETCH_AUTH_LIST';
export const FETCH_AUTH_EMP_LIST = '[AUTH] FETCH AUTH EMP LIST';
export const FETCH_AUTH_MENU_LIST = '[AUTH] FETCH_AUTH_MENU_LIST';
export const SELECT_AUTH = '[AUTH] TOGGLE AUTH';
export const REMOVE_AUTH = '[AUTH] REMOVE AUTH';
export const START_AUTH_LOADING = '[AUTH] START AUTH LOADING';
export const STOP_AUTH_LOADING = '[AUTH] STOP AUTH LOADING';

export function fetchAuths(auth) {
   return (dispatch, getState) => {
      // const entity = getState().griffin.admin.auth.auth || {};
      const request = axios.get(`/auth/list`);

      request.then(res => {
         dispatch(stopAuthLoading());
         dispatch({
            type: FETCH_AUTH_LIST,
            payload: res.data,
            seq: (auth || getState().griffin.admin.auth.auth).seq
            // seq: entity.seq
         });
         /*if (auth != null) {
            dispatch(selectAuth(auth))
         }*/
      });
   }
}

export function fetchMenuTreeList(showLoading) {
   return (dispatch, getState) => {
      if(!showLoading)
         dispatch(stopLoading());
      else
         dispatch(startLoading());

      axios.get(`/menu/auth/treelist`)
         .then(res => {
            dispatch(stopLoading());
            dispatch({
               type: FETCH_AUTH_MENU_LIST,
               tree: res.data.tree,
               list: res.data.list
            })
         });
   };
}

export function fetchEmployees(showLoading) {
   return (dispatch) => {
      if(showLoading)
         dispatch(startLoading());

      axios.get(`/employee`)
         .then(res => {
            dispatch(stopLoading());
            dispatch({
               type: FETCH_AUTH_EMP_LIST,
               payload: res.data
            });
         })
         .catch(e => {
            pack.showError(e);
            dispatch(stopLoading());
         });
   }
}

export function startAuthLoading() {
   return {
      type: START_AUTH_LOADING
   };
}
export function stopAuthLoading() {
   return {
      type: STOP_AUTH_LOADING
   };
}

export function selectAuth(auth) {
   return {
      type: SELECT_AUTH,
      payload: auth
   }
}

export function removeAuth(seq) {
   return (dispatch, getState) => {
      dispatch(startAuthLoading());
      dispatch({
         type: REMOVE_AUTH
      });
      axios.delete(`/auth/${seq}`)
         .then(res => {
            dispatch(fetchAuths())
         })
   };
}

export function createAuth(data) {
   return (dispatch) => {
      const request = axios({
         method: 'post',
         url: `/auth`,
         data: jtf(data)
      });

      dispatch(startAuthLoading());

      request.then(res =>
         dispatch(fetchAuths(res.data))
      );
   };
}

export function saveMenuChecked(data) {
   return (dispatch, getState) => {
      const auth = getState().griffin.admin.auth.auth;
      if(!auth) {
         return null;
      }

      axios({
         method: 'post',
         url: `/auth/mapping`,
         data: jtf(data)
      }).then(res =>
         dispatch(fetchMenuTreeList(auth.seq))
      )
   };
}

export function mapAllRolesByMenu(menuId, authSeq, roles) {
   return (dispatch) => {
      return axios({
         method: 'post',
         url: `/auth/maps/${authSeq}/${menuId}`,
         data: jtf({roles: roles.map(cd => cd.codeValue).join(',')})
      }).then(res => {
         dispatch(fetchAuths());
      })
   }
}

export function unmapAllRolesByMenu(menuId, authSeq) {
   return (dispatch) =>
      axios.delete(`/auth/unmaps/${authSeq}/${menuId}`)
         .then(res =>
            dispatch(fetchAuths())
         );
}

export function mapRoleByMenu(menuId, authSeq, roleCode) {
   return (dispatch) => {
      return axios.get(`/auth/map/${authSeq}/${menuId}/${roleCode}`)
         .then(res =>
            dispatch(fetchAuths())
         );
   }
}

export function unmapRoleByMenu(menuId, authSeq, roleCode) {
   return (dispatch) => {
      return axios.delete(`/auth/unmap/${authSeq}/${menuId}/${roleCode}`)
         .then(res =>
            dispatch(fetchAuths())
         );
   }
}

export function mapMenusByRole(menus, authSeq, roleCode) {
   return (dispatch) => {
      return axios({
         method: 'post',
         url: `/auth/vmap/${authSeq}/${roleCode}`,
         data: jtf({ menus: menus.map(menu => menu.id).join(',')})
      }).then(res =>
         dispatch(fetchAuths())
      );
   }
}

export function unmapMenusByRole(authSeq, roleCode) {
   return (dispatch) => {
      axios.delete(`/auth/vunmap/${authSeq}/${roleCode}`)
         .then(res =>
            dispatch(fetchAuths())
         );
   }
}

export function mapAuthEmpAll(authSeq, filteredList) {
   return (dispatch) => {
      return axios({
         method: 'post',
         url: `/auth/emaps/${authSeq}`,
         data: jtf({
            ids: filteredList.map(emp => emp.member.id).join(',')
         })
      }).then(res =>
         dispatch(fetchAuths())
      );
   };
}

export function unmapAuthEmpAll(authSeq, filteredList) {
   return (dispatch) => {
      return axios({
         method: 'post',
         url: `/auth/eunmaps/${authSeq}`,
         data: jtf({
            ids: filteredList.map(emp => emp.member.id).join(',')
         })
      }).then(res => {
         return dispatch(fetchAuths());
      });
   };
}

export function mapAuthEmp(authSeq, memberId) {
   return (dispatch) =>
      axios.get(`/auth/emap/${authSeq}/${memberId}`)
         .then(res =>
            dispatch(fetchAuths())
         )
}

export function unmapAuthEmp(authSeq, memberId) {
   return (dispatch) =>
      axios.get(`/auth/eunmap/${authSeq}/${memberId}`)
         .then(res =>
            dispatch(fetchAuths())
         )
}