export const SHOW = '[NOTIFY] SHOW';
export const CLOSE = '[NOTIFY] CLOSE';

export function notify(props) {
   return {
      ...props,
      type: SHOW
   };
}

export function closeNotify(tag) {
   return {
      type: CLOSE,
      tag
   };
}