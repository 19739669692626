import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { addEvent, addPush, addSyslog, fetchMenuTreeList, fetchServerProps, setCurrentMenuItem } from '../common/actions/common.action';
import { initUser, updateUserRoles } from './actions/user.action';
import { GEO_DENIED, useStore } from '../../store/globalStore';

class GcAuth extends Component {
   constructor(props) {
      super(props);
      props.fetchServerProps();
   }

   componentDidMount() {
      this.props.fetchMenuTreeList(true);
   }

   componentDidUpdate(prevProps) {
      const {
         location: { pathname },
      } = this.props;
      if (prevProps.lang !== this.props.lang) {
         this.setState(state => ({ ...state }));
      }
      if (prevProps.location.pathname !== pathname) {
         window.scrollTo(0, 0);
      }

      if (pathname !== prevProps.location.pathname || prevProps.user.id !== this.props.user.id || !_.isEqual(prevProps.navigation, this.props.navigation)) {
         this.checkAuth();
      }
   }

   recursiveGetCurrentItem = (item, breadcrumb) => {
      if (item.type === 'item') {
         const realTo = item.to.startsWith('#/activity/board')
            ? item.to.replace(/\/list/g, '')
            : item.to
                 .replace(/\/list/g, '/')
                 .replace(/\/view/g, '/')
                 .replace(/\/[0-9]+/g, '/');
         const test = new RegExp(`^${realTo}`);
         const isSame = test.test('#' + this.props.location.pathname);
         if (isSame) {
            breadcrumb.push(item);
         }
         return isSame;
      }
      if (item.content)
         for (let i = 0; i < item.content.length; i++) {
            if (this.recursiveGetCurrentItem(item.content[i], breadcrumb)) {
               breadcrumb.push(item);
               return true;
            }
         }
      return false;
   };

   checkAuth = () => {
      const {
         user,
         setCurrentMenuItem,
         navigation,
         strings,
         userMenuLoaded,
         location: { pathname },
      } = this.props;
      const breadcrumb = [];
      let menu = null;
      let userMenu = null;
      const token = localStorage.getItem('imhappy');

      if (user.role !== 'guest') {
         navigation.forEach(item => {
            if (this.recursiveGetCurrentItem(item, breadcrumb)) {
               return false;
            }
         });
         if (breadcrumb.length > 0) {
            setCurrentMenuItem(breadcrumb);
            menu = breadcrumb[0];
         }
      }

      if (menu) {
         userMenu = _.find(user.menus, { id: menu.id });
         this.props.updateUserRoles((userMenu && userMenu.roleCodes) || []);
      } else {
         this.props.updateUserRoles([]);
      }
      const { geoStatus } = useStore.getState();

      if (geoStatus === GEO_DENIED) {
         this.props.history.push({
            pathname: '/denied',
         });
      } else if (user.role === 'guest' || !token || (userMenuLoaded && !menu)) {
         const regex = new RegExp(user.firstUrl + '.*');
         if (user.role === 'guest') {
            this.props.history.push({
               pathname: '/login',
               state: { redirectUrl: '/' },
            });
         } else if (!regex.test(pathname)) {
            if (pathname !== '/') this.props.addSyslog(`${strings.pushMenuFail}, path='${pathname}'`, 'error');
            this.props.history.push({
               pathname: user.firstUrl,
            });
         }
      } else if (menu && menu.label) {
         this.props.addSyslog(`${strings.pushMoveMenu} -> '${menu.label}'`, 'info');
      }
   };

   render() {
      const { children } = this.props;
      return <>{children}</>;
   }
}

function mapStateToProps({ gc }) {
   return {
      user: gc.user,
      lang: gc.common.lang,
      userMenuLoaded: gc.common.userMenuLoaded,
      strings: gc.common.strings,
      navigation: gc.navigation,
      currentItem: gc.common.currentItem,
   };
}

export default withRouter(
   connect(mapStateToProps, {
      fetchServerProps,
      updateUserRoles,
      initUser,
      fetchMenuTreeList,
      addPush,
      addEvent,
      addSyslog,
      setCurrentMenuItem,
   })(GcAuth),
);
