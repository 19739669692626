import axios from 'axios';
import { pack } from '@gc';
import _ from 'lodash';
import { updateUserData } from '../../../../@gc/auth/actions/user.action';

const jtf = require('json-form-data');

export const ENTITIES_LOADING = '[MYTASK TRELLO] ENTITIES_LOADING';
export const FETCH_ENTITIES = '[MYTASK TRELLO] FETCH_ENTITIES';
export const FETCH_BOARDS = '[MYTASK TRELLO] FETCH BOARDS';
export const FETCH_BOARD = '[MYTASK TRELLO] FETCH BOARD';
export const FETCH_BOARD_LOADING = '[MYTASK TRELLO] FETCH BOARD LOADING';
export const FETCH_BOARD_SORT = '[MYTASK TRELLO] FETCH BOARD SORT';
export const TOGGLE_CARD_DETAIL = '[TRELLO] TOGGLE CARD DETAIL';
export const SELECT_CARD = '[TRELLO] SELECT CARD';
export const DESELECT_CARD = '[TRELLO] DESELECT CARD';
export const LOAD_BOARD_STT_CODES = '[TRELLO] LOAD BOARD STT CODES';
export const SEARCH_BOARD = '[TRELLO] SEARCH BOARD';
export const SEARCH_FILE = '[TRELLO] SEARCH FILE';
export const UPDATE_CARD = '[TRELLO] UPDATE CARD';
export const CHANGE_CARD_LOCATION = '[TRELLO] CHANGE_CARD_LOCATION';
export const CREATE_LIST = '[TRELLO] CREATE LIST';
export const ADD_FOLLOWER = '[TRELLO] ADD FOLLOWER';
export const REMOVE_FOLLOWER = '[TRELLO] REMOVE FOLLOWER';
export const REMOVE_TAG = '[TRELLO] REMOVE TAG';
export const FETCH_CARD_TAGS = '[TRELLO] FETCH CARD TAGS';
export const ADD_TAG_TO_CARD = '[TRELLO] ADD TAG TO CARD';
export const REMOVE_TAG_FROM_CARD = '[TRELLO] REMOVE TAG FROM CARD';
export const UPDATE_TAG = '[TRELLO] UPDATE TAG';
export const CREATE_TAG = '[TRELLO] CREATE TAG';
export const UPDATE_CHECKLIST = '[TRELLO] UPDATE CHECKLIST';
export const REMOVE_CHECKLIST = '[TRELLO] REMOVE CHECKLIST';
export const REORDER_CHECKLIST = '[TRELLO] REORDER_CHECKLIST';
export const ADD_FILES_TO_CARD = '[TRELLO] ADD FILES TO CARD';
export const REMOVE_FILE_FROM_CARD = '[TRELLO] REMOVE CARD FROM FILE';
export const ADD_COMMENT = '[TRELLO] ADD COMMENT';
export const COMMENT_ADDED = '[TRELLO] COMMENT ADDED';
export const ACCESS_BOARD = '[TRELLO] ACCESS BOARD';
export const CARD_UPDATED = '[TRELLO] CARD UPDATED';
export const CARD_NEED_TO_CLOSE = '[TRELLO] CARD NEED TO CLOSE';
export const LIST_UPDATED = '[TRELLO] LIST UPDATED';
export const REORDER_BOARDS = '[TRELLO] REORDER BOARDS';
export const TOGGLE_BOARD_HIDDEN = '[TRELLO] TOGGLE BOARD HIDDEN';
export const TOGGLE_BOARDS_LOADING = '[TRELLO] TOGGLE BOARDS LOADING';
export const UPDATE_BOARD = '[TRELLO] UPDATE BOARD';
export const UPLOADING_USER_BACKGROUND = '[TRELLO] UPLOADING USER BACKGROUND';
export const UPDATE_COMMENT_LINK = '[TRELLO] UPDATE COMMENT LINK';

export function fetchEntities() {
   return (dispatch, getState) => {
      const { user } = getState().gc;
      dispatch({
         type: ENTITIES_LOADING,
         flag: true,
      });
      axios
         .get(`/trello/entities/${user.empno}`)
         .then(res => {
            dispatch({
               type: FETCH_ENTITIES,
               payload: res.data,
            });
         })
         .catch(pack.showError)
         .finally(() =>
            dispatch({
               type: ENTITIES_LOADING,
               flag: false,
            }),
         );
   };
}

export function fetchBoards(props, newCards, cardCreator) {
   return (dispatch, getState) => {
      let myNewCards = [];
      const myEmpno = getState().gc.user.empno;
      if (Boolean(newCards) && cardCreator !== myEmpno) {
         myNewCards = newCards.filter(card => card.employee.empno === myEmpno || (card.followers || []).some(emp => emp.empno === myEmpno));
      }
      if (props) {
         dispatch({
            type: TOGGLE_BOARDS_LOADING,
            flag: true,
         });
      }
      if (!newCards || myNewCards.length > 0) {
         axios
            .get(`/trello/boards/${myEmpno}`)
            .then(res => {
               dispatch(fetchEntities());

               const { list, access, hidden } = res.data;

               dispatch({
                  type: TOGGLE_BOARDS_LOADING,
                  flag: false,
               });
               dispatch({
                  type: FETCH_BOARDS,
                  list,
                  access,
                  hidden,
                  empno: myEmpno,
                  refresh: myNewCards.length > 0,
               });

               if (Boolean(props) && typeof props !== 'boolean') {
                  const { tab, boardid, cardid } = props.match.params;
                  if (tab === 'board' && Boolean(boardid)) {
                     dispatch(fetchBoard(boardid, cardid));
                  }
               }

               if (myNewCards.length > 0) {
                  myNewCards.forEach(card => {
                     let location = '';
                     const list = card.list;
                     const brd = list.board;
                     pack.alarm(
                        card.type === '' ? pack.serverImage(card.employee.avatar) : null,
                        card.title,
                        {
                           type: card.type || 'newCard',
                           user: getState().gc.user.name,
                           updater: card.employee.desc,
                           location: `${brd.name} > ${list.title} > ${card.title}`,
                        },
                        10000,
                        `#/activity/mytask/board/view/${brd.id}/${card.id}`,
                     );
                  });
                  pack.notify(
                     myNewCards.map(card => ({
                        title: card.title,
                        body: pack.excerpt(card.content, 20),
                     })),
                  );
               }
            })
            .catch(e => {
               pack.showError(e);
               dispatch({
                  type: TOGGLE_BOARDS_LOADING,
                  flag: false,
               });
            });
      }
   };
}

export function fetchBoard(boardid, cardid) {
   return (dispatch, getState) => {
      dispatch({
         type: FETCH_BOARD_LOADING,
         flag: true,
      });

      axios
         .get(`/trello/board/${boardid}`)
         .then(res => {
            dispatch({
               type: FETCH_BOARD,
               payload: res.data,
            });
         })
         .catch(pack.showError)
         .finally(() => {
            if (Boolean(cardid)) {
               dispatch(dispatch(selectCard(cardid)));
            }
            dispatch({
               type: FETCH_BOARD_LOADING,
               flag: false,
            });
         });
   };
}

export function fetchBoardSort() {
   return (dispatch, getState) =>
      axios.get(`/trello/board/sort/${getState().gc.user.empno}`).then(res =>
         dispatch({
            type: FETCH_BOARD_SORT,
            payload: res.data,
         }),
      );
}

export function reorderBoards(sorts) {
   return (dispatch, getState) => {
      const sortStr = sorts.join(',');
      axios.put('/trello/board/sort', jtf({ sortStr, empno: getState().gc.user.empno }));
      dispatch({
         type: REORDER_BOARDS,
         sorts,
      });
   };
}

export function loadBoardSttCodes() {
   return dispatch =>
      axios.get('/code/list/BRD_STT').then(res =>
         dispatch({
            type: LOAD_BOARD_STT_CODES,
            payload: res.data,
         }),
      );
}

export function searchBoard(value) {
   return {
      type: SEARCH_BOARD,
      value,
   };
}

export function searchFile(value) {
   return {
      type: SEARCH_FILE,
      value,
   };
}

export function createBoard(data, afterRequest) {
   return (dispatch, getState) => {
      axios
         .post('/trello/board', jtf({ ...data, empno: getState().gc.user.empno }))
         .then(res => {
            dispatch({
               type: UPDATE_BOARD,
               // payload: res.data,
            });
            dispatch(fetchBoards());
            if (typeof afterRequest === 'function') afterRequest();
         })
         .catch(e => {
            pack.showError(e);
            if (typeof afterRequest === 'function') afterRequest();
         });
   };
}

export function toggleBoardHidden(boardid, ishidden) {
   return (dispatch, getState) => {
      const { empno } = getState().gc.user;
      dispatch({
         type: TOGGLE_BOARD_HIDDEN,
         boardid,
         flag: ishidden,
         // empno,
      });
      axios
         .put('/trello/board/hider', jtf({ id: boardid, empno, ishidden: ishidden ? '1' : '0' }))
         .then(() => dispatch(fetchBoards()))
         .catch(e => pack.showError(e));
   };
}

export function openBoardView(boardid, { match, history }) {
   return (dispatch, getState) => {
      dispatch(fetchBoard(boardid));
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'view',
            boardid,
         }),
      );
      axios.post('/trello/board/access', jtf({ boardid, empno: getState().gc.user.empno })).then(res => {
         /*dispatch({
            type: ACCESS_BOARD,
            payload: res.data,
         });*/
         dispatch(fetchBoards());
      });
   };
}

export function addList(boardid, callback) {
   return (dispatch, getState) => {
      axios.post('/trello/add-list', jtf({ boardid, empno: getState().gc.user.empno })).then(res => {
         callback(res.data);
      });
   };
}

export function updateListTitle(listId, title) {
   return (dispatch, getState) => {
      axios
         .put('/trello/update-list', jtf({ id: listId, title }))
         .then(res => {
            dispatch({
               type: LIST_UPDATED,
               payload: res.data,
            });
         })
         .catch(e => pack.showError(e));
   };
}

export function createList(data, onSuccess, onFail) {
   return (dispatch, getState) =>
      axios
         .post('/trello/list', jtf({ ...data, empno: getState().gc.user.empno }))
         .then(res => {
            dispatch({
               type: CREATE_LIST,
               payload: res.data,
            });
            dispatch(fetchBoards());
            onSuccess();
         })
         .catch(e => {
            pack.showError(e);
            onFail();
         });
}

export function updateListColor(listId, color, onError) {
   return dispatch => {
      axios.put('/trello/update-list', jtf({ id: listId, color })).then(res => {
         if (res.status !== 200) {
            onError(res);
         }
      });
   };
}

export function removeList(listId, onError) {
   return dispatch => {
      axios.delete(`/trello/list/${listId}`).then(res => {
         if (res.status !== 200) onError(res);
      });
   };
}

export function moveList(lists, onError) {
   return (dispatch, getState) => {
      axios.put('/trello/list/move', jtf({ lists: lists.map(list => list.id).join(',') })).then(res => {
         if (res.status !== 200) {
            onError(res);
         }
      });
   };
}

export function addCard(data, callback) {
   return (dispatch, getState) => {
      axios.post('/trello/card', jtf({ ...data, empno: getState().gc.user.empno })).then(res => {
         dispatch(fetchBoards());
         callback(res.data);
      });
   };
}

/*
export function updateCardTitle(cardId, title, onError) {
   return (dispatch, getState) => {
      axios.put('/trello/update-card', jtf({id: cardId, title}))
         .then(res => {
            if (res.status !== 200) {
               onError(res);
            }
         });
   };
}

export function updateCardContent(cardId, content, onError) {
   return (dispatch, getState) => {
      axios.put('/trello/update-card', jtf({id: cardId, content}))
         .then(res => {
            if (res.status !== 200) {
               onError(res);
            }
         });
   };
}

export function updateCardComplete(cardId, isdone, onError) {
   return (dispatch, getState) => {
      axios.put('/trello/update-card', jtf({id: cardId, isdone}))
         .then(res => {
            if (res.status !== 200) {
               onError(res);
            }
         });
   }
}
*/

export function changeCardLocation(data, afterRequest) {
   return (dispatch, getState) => {
      const { card } = getState().griffin.activity.trello;
      const boardChanged = card.board.id !== data.boardid;
      // const listChanged = true;  // 리스트가 변경되야 위치변경 버튼을 클릭할 수 있으므로 이 값은 항상 true 이다.
      axios
         .put('/trello/card/location', jtf({ ...data, empno: getState().gc.user.empno }))
         .then(res => {
            dispatch(fetchBoards());
            dispatch(fetchBoard(card.board.id, card.id));
            /*dispatch({
               type: CHANGE_CARD_LOCATION,
               card: res.data,
               bid: data.boardid,
               oldBid: card.board.id,
               lid: data.listid,
               oldLid: card.list.id,
               boardChanged,
            });*/
            afterRequest();
         })
         .catch(e => {
            pack.showError(e);
            afterRequest();
         });
   };
}

export function updateCard(data, onSuccess, onError) {
   return (dispatch, getState) =>
      axios
         .put('/trello/card', jtf({ ...data, empno: getState().gc.user.empno }))
         .then(res => {
            dispatch({
               type: UPDATE_CARD,
               data: res.data,
            });
            if (typeof onSuccess === 'function') onSuccess(res.data);
         })
         .catch(e => {
            pack.showError(e);
            if (typeof onError === 'function') {
               onError(e);
            }
         });
}

export function removeCard(cardId, onError) {
   return (dispatch, getState) => {
      axios.delete(`/trello/card/${cardId}`).then(res => {
         dispatch(fetchBoards());
         if (res.status !== 200) {
            onError(res);
         }
      });
   };
}

export function moveCard(listId, cardId, cards, onError) {
   return (dispatch, getState) => {
      axios
         .put(
            '/trello/card/move',
            jtf({
               listId,
               cardId,
               cards: cards.map(card => card.id).join(','),
            }),
         )
         .then(res => {
            if (res.status !== 200) {
               onError(res);
            }
         });
   };
}

export function copyCard(listId, dragCard, cards, onSuccess, onError) {
   return (dispatch, getState) => {
      const { task } = dragCard;
      if (Boolean(task)) {
         axios
            .post(
               '/trello/add-card',
               jtf({
                  listId,
                  taskSeq: task.taskSeq,
                  dateSeq: task.dateSeq,
                  cards: cards.map(card => card.id).join(','),
                  empno: getState().gc.user.empno,
               }),
            )
            .then(res => {
               if (res.status === 200) {
                  onSuccess(res.data);
               } else {
                  onError(res);
               }
            });
      } else {
         axios
            .put(
               '/trello/card/copy',
               jtf({
                  listId,
                  cardId: dragCard.id,
                  cards: cards.map(card => card.id).join(','),
                  empno: getState().gc.user.empno,
               }),
            )
            .then(res => {
               if (res.status === 200) {
                  onSuccess(res.data);
               } else {
                  onError(res);
               }
            });
      }
   };
}

export function openCardDetail(card, { history, match }) {
   return dispatch => {
      dispatch(selectCard(card.id));
      history.push(
         pack.getToPath(match)({
            ...match.params,
            cardid: card.id,
         }),
      );
      /*dispatch({
         type: TOGGLE_CARD_DETAIL,
         flag: true,
      });*/
   };
}

export function deselectCard() {
   return {
      type: DESELECT_CARD,
   };
}

export function selectCard(id) {
   return {
      type: SELECT_CARD,
      id,
   };
}

export function addFollower(data) {
   return (dispatch, getState) => {
      axios.post('/trello/card/follower', jtf(data)).then(res => {
         dispatch({
            type: ADD_FOLLOWER,
            payload: res.data,
         });
      });
   };
}

export function deleteFollower(data) {
   return (dispatch, getState) =>
      axios.put('/trello/card/remove/follower', jtf(data)).then(res =>
         dispatch({
            type: REMOVE_FOLLOWER,
            empno: res.data,
         }),
      );
}

export function fetchTags() {
   return (dispatch, getState) =>
      axios.get(`/trello/card/tags/${getState().gc.user.empno}`).then(res =>
         dispatch({
            type: FETCH_CARD_TAGS,
            payload: res.data,
         }),
      );
}

export function addTagToCard(data) {
   return dispatch =>
      axios
         .put('/trello/card/add-tag', jtf(data))
         .then(res =>
            dispatch({
               type: ADD_TAG_TO_CARD,
               tag: res.data,
            }),
         )
         .catch(e => pack.showError(e));
}

export function removeTagFromCard(data) {
   return dispatch =>
      axios
         .put('/trello/card/remove-tag', jtf(data))
         .then(res =>
            dispatch({
               type: REMOVE_TAG_FROM_CARD,
               seq: res.data,
            }),
         )
         .catch(e => pack.showError(e));
}

export function deleteTag(seq) {
   return dispatch =>
      axios
         .delete(`/trello/card/tag/${seq}`)
         .then(res => {
            dispatch({
               type: REMOVE_TAG_FROM_CARD,
               seq,
            });
            dispatch(fetchTags());
            dispatch(fetchBoards());
         })
         .catch(e => pack.showError(e));
}

export function updateTag(data) {
   return dispatch => {
      axios
         .put('/trello/card/tag', jtf(data))
         .then(res =>
            dispatch({
               type: UPDATE_TAG,
               payload: res.data,
            }),
         )
         .catch(e => pack.showError(e));
   };
}

export function createTag(data, afterRequest) {
   return (dispatch, getState) =>
      axios
         .post('/trello/card/tag', jtf({ ...data, empno: getState().gc.user.empno }))
         .then(res => {
            dispatch(fetchTags());
            afterRequest();
         })
         .catch(e => {
            pack.showError(e);
            afterRequest();
         });
}

export function createChecklist(data) {
   return (dispatch, getState) =>
      axios
         .post('/trello/card/checklist', jtf({ ...data, empno: getState().gc.user.empno }))
         .then(res =>
            dispatch({
               type: UPDATE_CHECKLIST,
               payload: res.data,
               listid: data.listid,
               cardid: data.cardid,
            }),
         )
         .catch(e => pack.showError(e));
}

export function updateChecklist(data) {
   return (dispatch, getState) =>
      axios
         .put('/trello/card/checklist', jtf(data))
         .then(res =>
            dispatch({
               type: UPDATE_CHECKLIST,
               payload: res.data,
               listid: data.listid,
               cardid: data.cardid,
            }),
         )
         .catch(e => pack.showError(e));
}

export function removeChecklist(seq) {
   return dispatch =>
      axios
         .delete(`/trello/card/checklist/${seq}`)
         .then(res =>
            dispatch({
               type: REMOVE_CHECKLIST,
               seq,
            }),
         )
         .catch(e => pack.showError(e));
}

export function reorderChecklist(seqs) {
   return dispatch =>
      axios
         .put('/trello/card/checklist/reorder', jtf({ seqs: seqs.join(',') }))
         .then(res =>
            dispatch({
               type: REORDER_CHECKLIST,
               seqs,
            }),
         )
         .catch(e => pack.showError(e));
}

export function addFilesToCard(data) {
   return (dispatch, getState) =>
      axios
         .post('/trello/card/files', jtf({ ...data, empno: getState().gc.user.empno }))
         .then(res =>
            dispatch({
               type: ADD_FILES_TO_CARD,
               payload: res.data,
            }),
         )
         .catch(e => pack.showError(e));
}

export function removeFileFromCard(seq) {
   return (dispatch, getState) =>
      axios
         .delete(`/trello/card/file/${seq}`)
         .then(res =>
            dispatch({
               type: REMOVE_FILE_FROM_CARD,
               seq,
            }),
         )
         .catch(e => pack.showError(e));
}

export function commentAdded({ cardid, comment } /*{ boardid, listid, cardid, comments }*/) {
   return {
      type: COMMENT_ADDED,
      cardid,
      comment,
      /*boardid,
      listid,
      cardid,
      comments,*/
   };
}

export function updateCommentLink(comment) {
   return dispatch =>
      axios.put('/trello/comment/link', JSON.stringify(comment)).then(res =>
         dispatch({
            type: UPDATE_COMMENT_LINK,
            payload: res.data,
         }),
      );
}

export function cardUpdated({ type, boardid, listid, card, location, updater }) {
   return (dispatch, getState) => {
      const { strings } = getState().gc.common;
      const { user } = getState().gc;

      if (type === 'location') {
         if (getState().griffin.activity.trello.card.id === card.id) {
            pack.alarm(pack.serverImage(updater.avatar, pack.taskIconImage), strings.cardMoved, { type: 'cardMove', updater, location, strings }, 20000);
            dispatch({
               type: CARD_NEED_TO_CLOSE,
               id: card.id,
            });
         }
         dispatch(fetchBoards());
      } else {
         if (type === 'complete') {
            if (user.empno !== updater.empno && card.followers.some(emp => emp.empno === user.empno)) {
               pack.alarm(
                  pack.serverImage(updater.avatar, pack.taskIconImage),
                  card.isdone === '1' ? strings.cardDone : strings.cardUndone,
                  { type: 'cardDone', card, updater, location, strings },
                  20000,
               );
            }
         }
         dispatch({
            type: CARD_UPDATED,
            boardid,
            listid,
            card,
         });
      }
   };
}

export function updateBackground(data) {
   return (dispatch, getState) => {
      dispatch(updateUserData(data));
      axios.put('/trello/background', jtf({ ...data, empno: getState().gc.user.empno }));
   };
}

export function uploadingUserBackground(flag) {
   return {
      type: UPLOADING_USER_BACKGROUND,
      flag,
   };
}
