import axios from 'axios';
import {pack} from "@gc";
import {startLoading, stopLoading} from "../../../../@gc/common/actions/common.action";
import {updateUserPoint} from "../../../../@gc/auth/actions/user.action";
import _ from 'lodash';
import {getEvents} from "./calendar.actions";
import {toastr} from "react-redux-toastr";

const jtf = require('json-form-data');

export const START_ALLTASK_LOADING = '[MY TASK] START ALLTASK LOADING';
export const STOP_ALLTASK_LOADING = '[MY TASK] STOP ALLTASK LOADING';
export const START_NEWTASK_LOADING = '[MY TASK] START NEWTASK LOADING';
export const STOP_NEWTASK_LOADING = '[MY TASK] STOP NEWTASK LOADING';
export const START_MYTASK_LOADING = '[MY TASK] START MYTASK LOADING';
export const STOP_MYTASK_LOADING = '[MY TASK] STOP MYTASK LOADING';
export const PLUS_YEAR = '[MY TASK] PLUS YEAR';
export const MINUS_YEAR = '[MY TASK] MINUS YEAR';
export const FETCH_NEW_TASKS = '[MY TASK] FETCH NEW TASKS';
export const FETCH_ALL_TASKS = '[MY TASK] FETCH ALL TASKS';
export const FETCH_MY_TASKS = '[MY TASK] FETCH MY TASKS';
export const FETCH_CHART_RATE_DATA = '[MY TASK] FETCH CHART RATE DATA';
export const FETCH_COMPLETE_RATE = '[MY TASK] FETCH COMPLETE RATE';
export const FETCH_TODO_RATE_DATA = '[MY TASK] FETCH TODO RATE DATA';
export const FETCH_MAIN_TASK_LABEL_CODE = '[MY TASK] FETCH MAIN TASK LABEL CODE';
export const FETCH_MAIN_TASK_IMP_CODE = '[MY TASK] FETCH MAIN TASK IMP CODE';
export const FETCH_IMP_DESC = '[MY TASK] FETCH IMP DESC';
export const OPEN_NEW_TASK_VIEW = '[MY TASK] OPEN NEW TASK VIEW';
export const TOGGLE_MYTASK_VIEW = '[MY TASK] TOGGLE MY TASK VIEW';
export const TOGGLE_MYTASK_IMPORTANT = '[MY TASK] TOGGLEL MY TASK IMPORTANT';
export const TOGGLE_MYTASK_STAR = '[MY TASK] TOGGLE MY TASK STAR';
export const TOGGLE_MYTASK_LOCKED = '[MY TASK] TOGGLE MY TASK LOCKED';
export const OPEN_TASK_TODO_DIALOG = '[MY TASK] OPEN TASK TODO DIALOG';
export const CLOSE_TASK_TODO_DIALOG = '[MY TASK] CLOSE TASK TODO DIALOG';
export const TOGGLE_TASK_DONE = '[MY TASK] TOGGLE TASK DONE';
export const REMOVE_TASK_UPLOAD_ATTACHMENT = '[MY TASK] REMOVE TASK UPLOAD ATTACHMENT';
export const TASK_UPLOAD_ATTACHMENT = '[MY TASK] TASK_UPLOAD_ATTACHMENT';
export const TASK_COMMENT_ADD = "[MY TASK] TASK COMMENT ADD";
export const TASK_LOADING = '[MY TASK] TASK LOADING';
export const TASK_LOADED = '[MY TASK] TASK LOADED';
export const SELECT_TASK_TODO = '[MY TASK] SELECT TASK TODO';
export const EXPAND_TASK_PANEL = '[MY TASK] EXPAND TASK PANEL';
export const FETCH_TASK_PANEL_EXPANDED = '[MY TASK] FETCH TASK PANEL EXPANDED';

export function fetchLabelCode() {
   return (dispatch) =>
      axios.get('/code/list/TODO_LAB')
         .then(res =>
            dispatch({
               type: FETCH_MAIN_TASK_LABEL_CODE,
               payload: res.data
            })
         );
}

export function fetchImpCode() {
   return (dispatch) =>
      axios.get('/code/list/TASK_IMP')
         .then(res =>
            dispatch({
               type: FETCH_MAIN_TASK_IMP_CODE,
               payload: res.data
            })
         );
}

export function getImpDesc() {
   return (dispatch) =>
      axios.get('/codegroup/TASK_IMP')
         .then(res =>
            dispatch({
               type: FETCH_IMP_DESC,
               payload: (res.data || {}).codeDesc
            })
         )
}

export function plusYear() {
   return (dispatch, getState) => {
      const newYear = getState().griffin.activity.mytask.year + 1;
      dispatch(fetchTaskRateChartData(newYear));
      dispatch(fetchAllTasks(newYear));
      dispatch(fetchNewTasks(true, newYear));
      // dispatch(fetchMyTasks(true, newYear));
      dispatch({
         type: PLUS_YEAR
      });
   }
}

export function minusYear() {
   return (dispatch, getState) => {
      const newYear = getState().griffin.activity.mytask.year - 1;
      dispatch(fetchTaskRateChartData(newYear));
      dispatch(fetchAllTasks(newYear));
      dispatch(fetchNewTasks(true, newYear));
      // dispatch(fetchMyTasks(true, newYear));
      dispatch({
         type: MINUS_YEAR
      });
   }
}

export function fetchAllTasks(year) {
   return (dispatch, getState) => {
      const request = axios.get(`/task/all/${getState().gc.user.empno}/${year || getState().griffin.activity.mytask.year}`);
      request.then(res => {
         dispatch({
            type: FETCH_ALL_TASKS,
            payload: res.data
         });
      })
   }
}

export function fetchNewTasks(showLoading, year) {
   return (dispatch, getState) => {
      const request = axios.get(`/task/new/${getState().gc.user.empno}/${year || getState().griffin.activity.mytask.year}`);

      if(showLoading)
         dispatch({
            type: START_NEWTASK_LOADING
         });

      request.then(res => {
         dispatch({
            type: STOP_NEWTASK_LOADING
         });
         dispatch({
            type: FETCH_NEW_TASKS,
            payload: res.data
         })
      });
   };
}

export function takeTask(dateSeq) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: `/task/take/${dateSeq}`,
         data: jtf({empno: getState().gc.user.empno})
      }).then(res => {
         dispatch(fetchTaskRateChartData());
         dispatch(fetchAllTasks());
         dispatch(fetchNewTasks());
         // dispatch(fetchMyTasks());
         dispatch(getEvents());
      });
   }
}

export function untakeTask(dateSeq) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: `/task/untake/${dateSeq}`,
         data: jtf({empno: getState().gc.user.empno})
      }).then(res => {
         dispatch(fetchTaskRateChartData());
         dispatch(fetchAllTasks());
         dispatch(fetchNewTasks());
         // dispatch(fetchMyTasks());
         dispatch(getEvents());
      });
   }
}

/*export function fetchMyTasks(showLoading, year) {
   return (dispatch, getState) => {
      if(showLoading)
         dispatch({
            type: START_MYTASK_LOADING
         });

      axios.get(`/task/my/${getState().gc.user.empno}/${year || getState().griffin.activity.mytask.year}`)
         .then(res => {
            dispatch({
               type: STOP_MYTASK_LOADING
            });
            dispatch({
               type: FETCH_MY_TASKS,
               payload: res.data
            });
         });
   };
}*/

export function fetchTaskRateChartData(year) {
   return (dispatch, getState) => {
      axios.get(`/task/chart/task/${getState().gc.user.empno}/${year || getState().griffin.activity.mytask.year}`)
         .then(res =>
            dispatch({
               type: FETCH_CHART_RATE_DATA,
               payload: res.data
            })
         );
   }
}

export function fetchTodoRateChartData() {
   return (dispatch, getState) => {
      axios.get(`/task/chart/todo/${getState().gc.user.empno}/${getState().griffin.activity.mytask.year}`)
         .then(res =>
            dispatch({
               type: FETCH_TODO_RATE_DATA,
               payload: [res.data.taken, res.data.done, res.data.star, res.data.imp, res.data.lock]
            })
         )
   }
}

export function toggleMyTaskView() {
   return {
      type: TOGGLE_MYTASK_VIEW
   };
}


export function openNewTaskView() {
   return {
      type: OPEN_NEW_TASK_VIEW
   }
}

export function toggleDone(todo) {
   return (dispatch, getState) => {
      const val = String(+(todo.isdone)^1);
      axios.get(`/task/done/${todo.dateSeq}/${todo.seq}/${getState().gc.user.empno}/${val}`)
         .then(res => {
            if (res.status === 200) {
               dispatch({
                  type: TOGGLE_TASK_DONE,
                  todo,
                  payload: val,
                  user: getState().gc.user
               });
               dispatch(fetchNewTasks());
               dispatch(fetchTaskRateChartData());
               dispatch(fetchTodoRateChartData());
               dispatch(updateUserPoint());
            }
            // dispatch(fetchMyTasks());
         });
   };
}

export function toggleImportant(todo) {
   return (dispatch, getState) => {
      const val = String(Number(todo.important)^1);
      axios({
         method: 'post',
         url: `/task/toggle/important/${todo.dateSeq}/${todo.seq}/${val}`,
         data: jtf({empno: getState().gc.user.empno})
      }).then(res => {
         if (res.status === 200) {
            dispatch({
               type: TOGGLE_MYTASK_IMPORTANT,
               todo,
               payload: val
            });
            // dispatch(fetchMyTasks())
         }
      })
   }
}

export function toggleStarred(todo) {
   return (dispatch, getState) => {
      const val = String(Number(todo.starred)^1);
      axios({
         method: 'post',
         url: `/task/toggle/star/${todo.dateSeq}/${todo.seq}/${val}`,
         data: jtf({empno: getState().gc.user.empno})
      }).then(res => {
         if (res.status === 200) {
            dispatch({
               type: TOGGLE_MYTASK_STAR,
               todo,
               payload: val
            });
            // dispatch(fetchMyTasks())
         }
      })
   }
}

export function toggleLocked(todo) {
   return (dispatch, getState) => {
      const val = String(Number(todo.islock)^1);
      axios({
         method: 'post',
         url: `/task/toggle/lock/${todo.dateSeq}/${todo.seq}/${val}`,
         data: jtf({empno: getState().gc.user.empno})
      }).then(res => {
         if (res.status === 200) {
            dispatch({
               type: TOGGLE_MYTASK_LOCKED,
               todo,
               payload: val,
               user: getState().gc.user
            });
            // dispatch(fetchMyTasks());
         }
      });
   }
}

export function openViewDialog(task, readonly, canTake) {
   return {
      type: OPEN_TASK_TODO_DIALOG,
      mode: 'view',
      readonly,
      todo: task.todoList[Object.keys(task.todoList)[0]],
      task,
      canTake
   }
}

export function openEditDialog(task, todo) {
   return (dispatch, getState) => {
      if (task.todoList != null) {
         dispatch({
            type: OPEN_TASK_TODO_DIALOG,
            mode: 'edit',
            readonly: task.iscomplete === '1',
            todo: todo || task.todoList[Object.keys(task.todoList)[0]],
            task
         });
      } else {
         axios.get(`/task/${task.taskSeq}/${task.dateSeq}/${getState().gc.user.empno}`)
            .then(res => {
               dispatch({
                  type: OPEN_TASK_TODO_DIALOG,
                  mode: 'edit',
                  readonly: false,
                  todo: res.data.todoList[0],
                  task: res.data
               })
            });
      }
   };
}

export function closeTodoDialog() {
   return {
      type: CLOSE_TASK_TODO_DIALOG
   };
}

export function removeAttachment(uploadSeq, todo) {
   return (dispatch) => {
      axios.delete(`/task/upload/${uploadSeq}/${todo.seq}/${todo.dateSeq}`)
         .then(res => {
            if (res.status === 200) {
               dispatch({
                  type: REMOVE_TASK_UPLOAD_ATTACHMENT,
                  payload: res.data,
                  todo
               });
            }
         });
   }
}

export function updateUploads(data, todo) {
   return (dispatch, getState) => {
      const request = axios({
         method: 'post',
         url: `/task/todo/upload`,
         data: jtf({...data, empno: getState().gc.user.empno})
      });

      request.then(res => {
         if (res.status === 200) {
            dispatch({
               type: TASK_UPLOAD_ATTACHMENT,
               payload: res.data,
               todo
            });
            const {strings} = getState().gc.common;
            toastr.success(strings.infoTitle, strings.successMessage);
         }
      })
   }
}

export function commentAdd(comment, todo) {
   return (dispatch, getState) => {
      const request = axios({
         method: 'post',
         url: '/task/comment',
         data: jtf(_.merge(comment, {todoSeq: todo.seq, dateSeq: todo.dateSeq}, {empno: getState().gc.user.empno}))
      });

      request.then(res => {
         if(res.status === 200) {
            dispatch({
               type: TASK_COMMENT_ADD,
               payload: res.data,
               todo
            });
         }
         // dispatch(fetchMyTasks());
         // dispatch(fetchNewTasks());
      });
   }

}

export function completeTodo(task) {
   return (dispatch) =>
      axios.get(`/task/complete/${task.dateSeq}`)
         .then(res => {
            dispatch(fetchNewTasks());
            // dispatch(fetchMyTasks());
            dispatch(updateUserPoint());
         });
}

export function selectTodo(seq) {
   return {
      type: SELECT_TASK_TODO,
      seq
   };
}

export function startTaskLoading() {
   return {
      type: TASK_LOADING
   };
}

export function stopTaskLoading() {
   return {
      type: TASK_LOADED
   };
}

export function fetchTaskPanelExpanded() {
   return (dispatch, getState) => {
      axios.get(`/task/panel-expanded/${getState().gc.user.empno}`)
         .then(res =>
            dispatch({
               type: FETCH_TASK_PANEL_EXPANDED,
               flag: res.data === 1
            })
         );
   }
}

export function onTaskPanelExpand(evt, newExpanded) {
   return (dispatch, getState) => {
      dispatch({
         type: EXPAND_TASK_PANEL,
         flag: newExpanded
      });
      axios.put('/task/expand-panel', jtf({mytaskExpand: newExpanded ? '1' : '0', empno: getState().gc.user.empno}))
         .then(res => {
            if (res.status !== 200) {
               const {strings} = getState().gc.common;
               toastr.error(strings.errTitle, strings.mytaskErrSaveExpand);
            }
         });
   }
}