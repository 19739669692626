import axios from 'axios';
import {toastr} from 'react-redux-toastr';
import {pack} from "@gc";
import {startLoading, stopLoading} from "../../../../@gc/common/actions/common.action";

const jtf = require('json-form-data');

export const FETCH_DOMAINS = '[SYSMAIL] FETCH DOMAINS';
export const FETCH_GROUPS = '[SYSMAIL] FETCH GROUPS';
export const TOGGLE_GROUP_MODAL = '[SYSMAIL] TOGGLE GROUP MODAL';
export const FETCH_TARGET_TREE = '[SYSMAIL] FETCH TARGET TREE';
export const TOGGLE_LOADING = '[SYSMAIL] TOGGLE LOADING';

export function fetchDomains() {
   return (dispatch, getState) => {
      const request = axios.get(`/sysmail/domains`);

      request.then(res => {
         return dispatch({
            type: FETCH_DOMAINS,
            payload: res.data
         })
      })
   }
}

export function fetchGroups(showLoading) {
   return (dispatch, getState) => {
      if (showLoading) {
         dispatch(startLoading());
      }
      axios.get(`/sysmail/aliases`)
         .then(res => {
            dispatch(stopLoading());
            dispatch({
               type: FETCH_GROUPS,
               payload: res.data
            })
         });
   }
}

export function fetchTargetTree() {
   return (dispatch, getState) =>
      axios.get(`/department/emp/tree?lang=${getState().gc.common.lang}`)
         .then(res =>
            dispatch({
               type: FETCH_TARGET_TREE,
               tree: res.data.tree,
               list: res.data.list
            })
         );
}

export function createAlias(data, onSuccess) {
   return (dispatch, getState) => {
      dispatch({
         type: TOGGLE_LOADING,
         flag: true
      });
      axios.post('/sysmail/alias', jtf(data))
         .then(res => {
            dispatch(fetchGroups());
            dispatch({
               type: TOGGLE_LOADING,
               flag: false
            });
            onSuccess();
         });
   }
}

export function removeGroupMail(email) {
   return (dispatch, getState) =>
      axios.delete(`/sysmail/alias/${email}`)
         .then(res => {
            dispatch(fetchGroups());
         })

}

export function addDomain(name) {
   return (dispatch, getState) => {
      axios.post('/sysmail/domain', jtf({ name }))
         .then(res =>
            dispatch(fetchDomains())
         );
   }
}

export function removeDomain(id) {
   return (dispatch, getState) => {
      axios.delete(`/sysmail/domain/${id}`)
         .then(res => {
            if (res.status === 200) {
               dispatch(fetchDomains());
               toastr.success(pack.strings.infoTitle, pack.strings.successMessage);
            } else {
               toastr.error(pack.strings.errTitle, pack.strings.errMessage);
            }
         });
   }

}
