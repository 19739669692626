import * as Actions from '../actions';
import _ from 'lodash';

const initState = {
   entities: [],
   auth: {
      seq: '',
      name: '',
      detail: '',
      // section: pack.serverprop().option.section
   },
   auths: [],
   menuTree: [],
   menuList: [],
   menuLoadCnt: 0,
   employees: [],
   empFetched: false,
   mappedEmps: [],
   empLoadCnt: 0,
   loaderActive: false,
};

const authReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_AUTH_LIST:
         const entities = action.payload;
         return {
            ...state,
            entities,
            auth: Boolean(action.seq) ? _.find(entities, { seq: +action.seq }) : state.auth,
            menuLoadCnt: state.menuLoadCnt + 1,
         };
      case Actions.FETCH_AUTH_MENU_LIST:
         return {
            ...state,
            menuTree: action.tree,
            menuList: action.list,
            menuLoadCnt: state.menuLoadCnt + 1,
         };
      case Actions.FETCH_AUTH_EMP_LIST:
         return {
            ...state,
            employees: action.payload.filter(emp => emp.isretire === '0'),
            empFetched: state.empFetched || true,
            empLoadCnt: state.empLoadCnt + 1,
         };
      case Actions.REMOVE_AUTH:
         return {
            ...state,
            auth: initState.auth,
         };
      case Actions.SELECT_AUTH:
         return {
            ...state,
            auth: action.payload,
         };
      case Actions.START_AUTH_LOADING:
         return {
            ...state,
            loaderActive: true,
         };
      case Actions.STOP_AUTH_LOADING:
         return {
            ...state,
            loaderActive: false,
         };
      default:
         return state;
   }
};

export default authReducer;
