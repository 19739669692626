import axios from 'axios';
import { startLoading, stopLoading, updateRefSignCount } from '../../../../@gc/common/actions/common.action';
import { pack } from '@gc';
import _ from 'lodash';
import { FETCH_EMPLOYEE } from '../../../admin/employee/actions';

const jtf = require('json-form-data');

export const OPEN_GROUP_MODAL = '[APPROVE] OPEN GROUP MODAL';
export const CLOSE_GROUP_MODAL = '[APPROVE] CLOSE GROUP MODAL';
export const OPEN_LIST = '[APPROVE] OPEN LIST';
export const OPEN_ADD = '[APPROVE] OPEN ADD';
export const OPEN_EDIT = '[APPROVE] OPEN EDIT';
export const REMOVE_ENTITY = '[APPROVE] REMOVE ENTITY';
export const FETCH_LIST = '[APPROVE] FETCH LIST';
export const FETCH_LINE_GROUPS = '[APPROVE] FETCH LINE GROUPS';
export const FETCH_FORMAT_LIST = '[APPROVE] FETCH FORMAT LIST';
export const FETCH_TARGET_LIST = '[APPROVE] FETCH TARGET LIST';
export const FETCH_HUGA_TYPES = '[APPROVE] FETCH HUGA TYPES';
export const TOGGLE_RIGHT_BAR = '[APPROVE] TOGGLE RIGHT BAR';
export const SELECT_LINES = '[APPROVE] SELECT LINES';
export const UPDATE_LINES = '[APPROVE] UPDATE LINES';
export const SELECT_REFS = '[APPROVE] SELECT REFS';
export const UPDATE_REFS = '[APPROVE] UPDATE REFS';
export const SELECT_FORMAT = '[APPROVE] SELECT FORMAT';
export const APPROVE_CLEAR = '[APPROVE] CLEAR';
export const SET_SUBJECT = '[APPROVE] SET SUBJECT';
export const FETCH_APPROVE = '[APPROVE] FETCH APPROVE';
export const CONFIRM = '[APPROVE] CONFIRM';
export const REJECT = '[APPROVE] REJECT';
export const RECALL_APPROVE = '[APPROVE] RECALL APPROVE';
export const RECALL_LINE = '[APPROVE] RECALL LINE';
export const START_LOADER = '[APPROVE] START LOADER';
export const STOP_LOADER = '[APPROVE] STOP LOADER';
export const UPDATE_COMMENT = '[APPROVE] UPDATE COMMENT';
export const SET_LIST_TYPE = '[APPROVE] SET LIST TYPE';
export const FETCH_ME = '[APPROVE] FETCH ME';
export const FETCH_DEPARTMENTS = '[APPROVE] FETCH DEPARTMENTS';
export const FETCH_FIRMS = '[APPROVE] FETCH FIRMS';

export const APPROVE_TYPE_GET = 'get';
export const APPROVE_TYPE_REQUEST = 'req';
export const APPROVE_TYPE_REF = 'ref';
export const APPROVE_TYPE_TEMP = 'temp';
export const APPROVE_TYPE_ALL = 'all';
export const APPROVE_TYPE_ING = 'ing';
export const APPROVE_TYPE_CANCEL = 'cancel';
export const APPROVE_TYPE_FORM = 'form';
export const APPROVE_TYPE_REQ_HUGA = 'hgs';
export const APPROVE_TYPE_REQ_JCH = 'jch';
export const APPROVE_TYPE_REQ_JJJ = 'jjj';
export const APPROVE_TYPE_REQ_JGB = 'jgb'; // 주간보고서
export const APPROVE_TYPE_REQ_JGH = 'jgh'; // 주간회의의사록
export const APPROVE_TYPE_REQ_GJG = 'gjg'; // 경조금신청서
export const APPROVE_TYPE_REQ_GBY = 'gby'; // 기본양식

export const APPROVE_STATUS_WAIT = '0';
export const APPROVE_STATUS_ING = '2';
export const APPROVE_STATUS_CANCEL = '5';
export const APPROVE_STATUS_RETURN = '5';
export const APPROVE_STATUS_REJECT = '8';
export const APPROVE_STATUS_CONFIRM = '9';

export const LINE_STATUS_WAIT = '0';
export const LINE_STATUS_RETURN = '5';
export const LINE_STATUS_REJECT = '8';
export const LINE_STATUS_CONFIRM = '9';

export const APP_SIGN_TYPE_APPROVAL = '1';
export const APP_SIGN_TYPE_HELP = '0';

export const APPROVE_LINE_TYPE_LINES = 'lines';
export const APPROVE_LINE_TYPE_REFS = 'refs';

export const APPROVE_EXCLUDE_LIST = ['hgs', 'jch'];

export function startLoader() {
   return {
      type: START_LOADER,
   };
}

export function stopLoader() {
   return {
      type: STOP_LOADER,
   };
}

export function fetchFirms() {
   return dispatch =>
      axios
         .get('/firms')
         .then(res =>
            dispatch({
               type: FETCH_FIRMS,
               payload: res.data,
            }),
         )
         .catch(pack.showError);
}

export function fetchDepartments() {
   return dispatch =>
      axios
         .get('/departments')
         .then(res =>
            dispatch({
               type: FETCH_DEPARTMENTS,
               payload: res.data,
            }),
         )
         .catch(pack.showError);
}

export function fetchList(listType, showLoading, props) {
   return (dispatch, getState) => {
      dispatch({
         type: SET_LIST_TYPE,
         payload: listType,
      });

      if (showLoading) dispatch(startLoading());

      axios
         .get(`/approve/list/${listType}/${getState().gc.user.id}`)
         .then(res => {
            dispatch({
               type: FETCH_LIST,
               payload: res.data,
            });

            if (props && Boolean(props.match.params.seq)) {
               if (props.match.params.mode === 'edit') {
                  dispatch(openEdit(props.match.params.seq, props));
               } else {
                  dispatch(fetchApprove(props.match.params.seq));
               }
            }
         })
         .finally(() => dispatch(stopLoading()));
   };
}

export function fetchRefCount() {
   return (dispatch, getState) => {
      const { user } = getState().gc;
      axios
         .get(`/approve/refcount/${user.empno}`)
         .then(res => {
            dispatch(updateRefSignCount(res.data));
         })
         .catch(pack.showError);
   };
}

export function fetchTargetList() {
   return (dispatch, getState) => {
      axios.get(`/department/emp/tree?lang=${getState().gc.common.lang}`).then(res =>
         dispatch({
            type: FETCH_TARGET_LIST,
            tree: res.data.tree,
            list: res.data.list,
         }),
      );
   };
}

export function fetchFormatList() {
   return dispatch =>
      axios.get('/approve/format-list').then(res =>
         dispatch({
            type: FETCH_FORMAT_LIST,
            payload: res.data,
         }),
      );
}

export function fetchApprove(seq) {
   return (dispatch, getState) => {
      const { entities } = getState().griffin.activity.approve;
      if (!entities[seq]) {
         axios.get(`/approve/${seq}`).then(res =>
            dispatch({
               type: FETCH_APPROVE,
               entity: res.data,
            }),
         );
      } else {
         dispatch({
            type: FETCH_APPROVE,
            entity: entities[seq],
         });
      }
   };
}

export function fetchLineGroups() {
   return (dispatch, getState) => {
      axios.get(`/approve/linegroups/${getState().gc.user.empno}`).then(res => {
         dispatch({
            type: FETCH_LINE_GROUPS,
            payload: res.data,
         });
      });
   };
}

export function openLineGroupModal(group) {
   return {
      type: OPEN_GROUP_MODAL,
      group,
   };
}

export function closeLineGroupModal() {
   return {
      type: CLOSE_GROUP_MODAL,
   };
}

export function saveGroup(data) {
   return (dispatch, getState) =>
      axios
         .post(
            '/approve/linegroup',
            jtf({
               seq: data.seq,
               name: data.name,
               lines: JSON.stringify(data.rows),
               empno: getState().gc.user.empno,
            }),
         )
         .then(res => {
            dispatch(fetchLineGroups());
         });
}

export function removeLineGroup(seq) {
   return (dispatch, getState) => axios.delete(`/approve/linegroup/${seq}`).then(res => dispatch(fetchLineGroups()));
}

export function saveForm(data) {
   return dispatch => {
      dispatch(startLoader());
      axios.post('/approve/format', jtf(data)).then(res => {
         dispatch(fetchFormatList());
         dispatch(stopLoader());
      });
   };
}

export function openEdit(seq, { match, history }) {
   return (dispatch, getState) => {
      const {
         format: { code },
      } = getState().griffin.activity.approve;
      dispatch(fetchApprove(seq));
      if (match.params.mode !== 'edit') {
         history.push(
            pack.getToPath(match)({
               ...match.params,
               mode: 'edit',
               listType: code || match.params.listType,
               seq,
            }),
         );
      }
   };
}

export function openAdd({ match, history }) {
   return dispatch => {
      dispatch({
         type: OPEN_ADD,
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            listType: APPROVE_TYPE_REQUEST,
            mode: 'add',
            seq: null,
         }),
      );
   };
}

export function openView(seq, { match, history }) {
   return dispatch => {
      dispatch(fetchApprove(seq));
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'view',
            seq,
         }),
      );
   };
}

export function fetchHugaTypes() {
   return dispatch =>
      axios.get('/vattend/details').then(res =>
         dispatch({
            type: FETCH_HUGA_TYPES,
            payload: res.data,
         }),
      );
}

export function toggleRightBar(which, open) {
   return {
      type: TOGGLE_RIGHT_BAR,
      which: which,
      open,
   };
}

export function selectLines(keys) {
   return dispatch => {
      dispatch({
         type: SELECT_LINES,
         keys,
      });
   };
}

export function updateLines(lines) {
   return {
      type: UPDATE_LINES,
      lines,
   };
}

export function selectRefs(keys) {
   return {
      type: SELECT_REFS,
      keys,
   };
}

export function updateRefs(refs) {
   return {
      type: UPDATE_REFS,
      refs,
   };
}

export function selectFormat(format) {
   return dispatch => {
      axios.get(`/approve/format/${format}`).then(res => {
         dispatch({
            type: SELECT_FORMAT,
            payload: res.data,
         });
      });
   };
}

export function clear() {
   return {
      type: APPROVE_CLEAR,
   };
}

export function setSubject(subject) {
   return {
      type: SET_SUBJECT,
      subject,
   };
}

export function createApprove({ data, lines, refs, enddate, useEnddate, hgs, rmFileSeqs }, files, { match, history }) {
   return (dispatch, getState) => {
      const { format } = getState().griffin.activity.approve;
      // let hgsData = {};
      // if (format.code === 'hgs') hgsData = { ...hgs };
      const isUpdate = Boolean(data.seq);

      axios({
         method: 'post',
         url: `/approve`,
         data: jtf({
            ..._.pick(data, ['seq', 'subject', 'content', 'currentStep']),
            // url: getUrl(),
            enddate: useEnddate ? pack.dateToString(enddate, 'yyyyMMdd') : null,
            formatCode: format.code,
            lineEmpnos: lines.map(line => (line.id.startsWith('e_') ? line.id.substring(2) : line.id)).join(','),
            lineTypes: lines.map(line => line.type).join(','),
            refEmpnos: refs.map(ref => (ref.id.startsWith('e_') ? ref.id.substring(2) : ref.id)).join(','),
            filenames: files.map(file => file.filename).join(':'),
            savenames: files.map(file => file.savename).join(':'),
            filesizes: files.map(file => file.filesize).join(':'),
            // hgsGubun: hgsData.gubun,
            // hgsAmount: hgsData.amount,
            // hgsStartdate: hgsData.startdate,
            // hgsEnddate: hgsData.enddate,
            // hgsReason: hgsData.reason,
            // hgsSpecial: hgsData.special,
            rmFileSeqs: rmFileSeqs.join(','),
            empno: getState().gc.user.empno,
         }),
      }).then(res => {
         if (res.status === 200 && format.code === 'hgs') {
            dispatch(saveLeave(res.data, hgs, isUpdate));
         }
         dispatch(fetchList(APPROVE_TYPE_REQUEST));
         history.push(
            pack.getToPath(match)({
               ...match.params,
               listType: data.currentStep === 0 ? APPROVE_TYPE_TEMP : APPROVE_TYPE_REQUEST,
               mode: 'list',
               seq: null,
            }),
         );
      });
   };
}

export function saveLeave(approve, hgs, isUpdate) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: '/vacation',
         data: jtf({
            approveSeq: approve.seq,
            empno: getState().gc.user.empno,
            startdate: pack.dateToString(hgs.startdate),
            enddate: pack.dateToString(hgs.enddate),
            amount: hgs.amount,
            reason: hgs.reason,
            special: hgs.special,
            type: hgs.gubun,
         }),
      }).then(res => {});
   };
}

export function removeApprove(data, { match, history }) {
   return (dispatch, getState) => {
      const { user } = getState().gc;
      axios.delete(`/approve/${data.seq}/${user.empno}`).then(res => {
         dispatch(fetchList(match.params.listType));
         history.goBack();
      });
   };
}

export function confirm(line, { match }, onSuccess) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: '/approve/confirm',
         data: jtf({ lineSeq: line.seq, empno: getState().gc.user.empno /*url: getUrl()*/ }),
      }).then(res => {
         dispatch({
            type: CONFIRM,
            payload: res.data,
         });
         dispatch(fetchList(match.params.listType, false, { match }));
         if (typeof onSuccess === 'function') {
            onSuccess();
         }
      });
}

export function reject(line, { match, history }, onSuccess) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: '/approve/reject',
         data: jtf({ lineSeq: line.seq, empno: getState().gc.user.empno }),
      }).then(res => {
         history.push(
            pack.getToPath(match)({
               ...match.params,
               listType: 'get',
               mode: 'list',
               seq: null,
            }),
         );
         dispatch({
            type: REJECT,
            payload: res.data,
         });
         dispatch(fetchList(match.params.listType, false, { match }));
         if (typeof onSuccess === 'function') {
            onSuccess();
         }
      });
}

export function recallApprove(approve, { match, history }, onSuccess) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: '/approve/recall/approve',
         data: jtf({ seq: approve.seq, empno: getState().gc.user.empno }),
      }).then(res => {
         setTimeout(() => dispatch(fetchList(APPROVE_TYPE_TEMP)), 1000);
         history.push(
            pack.getToPath(match)({
               ...match.params,
               listType: APPROVE_TYPE_TEMP,
               mode: 'list',
               seq: null,
            }),
         );
         if (typeof onSuccess === 'function') {
            onSuccess();
         }
      });
}

export function recallLine(line, { match, history }, onSuccess) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: '/approve/recall/line',
         data: jtf({ lineSeq: line.seq, empno: getState().gc.user.empno }),
      }).then(res => {
         dispatch(fetchList(match.params.listType));

         if (match.params.mode !== 'list') {
            history.push(
               pack.getToPath(match)({
                  ...match.params,
                  mode: 'list',
                  seq: null,
               }),
            );
         }
         if (typeof onSuccess === 'function') {
            onSuccess();
         }
      });
}

export function requestAgain(approve, { match }, onSuccess) {
   return dispatch =>
      axios.get(`/approve/resign/${approve.seq}`).then(res => {
         dispatch(fetchList(match.params.listType, false, { match }));
         if (typeof onSuccess === 'function') {
            onSuccess();
         }
      });
}

export function onReceiveNewApprove(sign, props) {
   return (dispatch, getState) => {
      const { strings } = getState().gc.common;
      if (sign.doneStatus) {
         const statusDesc = sign.doneStatus === APPROVE_STATUS_CONFIRM ? '승인' : '반려';
         pack.alarm(pack.signIconImage, `결재 ${statusDesc} 알림`, `문서명 : ${sign.subject}\n▶︎ 결재요청이 ${statusDesc}되었습니다.`, 10000);
      } else {
         pack.alarm(pack.signIconImage, strings.pushNewSign, `[${sign.regEmpnm}] ${sign.subject}`, 10000, `#/activity/approve/get/view/${sign.seq}`);
      }

      pack.notify([
         {
            title: `[${sign.regEmpnm}]`,
            body: sign.subject,
            onClick: () => {
               if (props) {
                  if (props.match.url.startsWith('/activity/approve')) {
                     props.history.push(
                        pack.getToPath(props.match)({
                           ...props.match.params,
                           listType: 'get',
                           mode: 'view',
                           seq: sign.seq,
                        }),
                     );
                  } else {
                     props.history.push(`/activity/approve/get/view/${sign.seq}`);
                  }
               }
            },
         },
      ]);
   };
}

export function addComment(content, { seq }) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: `/approve/comment/${seq}`,
         data: jtf({
            content,
            id: getState().gc.user.id,
         }),
      }).then(res => {
         dispatch({
            type: UPDATE_COMMENT,
            payload: res.data,
         });
      });
   };
}

export function editComment(content, seq) {
   return dispatch =>
      axios({
         method: 'put',
         url: `/approve/comment`,
         data: jtf({
            content,
            seq,
         }),
      }).then(res => {
         dispatch({
            type: UPDATE_COMMENT,
            payload: res.data,
         });
      });
}

export function addReply(content, seq) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: '/approve/comment/reply',
         data: jtf({ content, seq, id: getState().gc.user.id }),
      }).then(res =>
         dispatch({
            type: UPDATE_COMMENT,
            payload: res.data,
         }),
      );
}

export function removeComment(comment) {
   return dispatch =>
      axios.delete(`/approve/comment/${comment.seq}`).then(res =>
         dispatch({
            type: UPDATE_COMMENT,
            payload: res.data,
         }),
      );
}

function getUrl() {
   return `${window.location.protocol}//${window.location.host}/#/activity/approve/get/list`;
}

export function fetchMe() {
   return (dispatch, getState) => {
      const { user } = getState().gc;
      axios.get(`/emp/${user.empno}`).then(res =>
         dispatch({
            type: FETCH_ME,
            payload: res.data,
         }),
      );
   };
}
