import * as Actions from '../actions';
import _ from 'lodash';

const initState = {
   entities: [],
   listLoadCnt: 0,
   entity: {
      seq: '',
      files: []
   }
};

const testReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_TESTS:
         return {
            ...state,
            entities: _.keyBy(action.payload, 'seq')
         };
      case Actions.FETCH_TEST:
         return {
            ...state,
            entity: state.entities[+(action.seq)]
         };
      case Actions.OPEN_ADD:
         return {
            ...state,
            entity: initState.entity
         };
      case Actions.CREATE_TEST:
         return {
            ...state,
            entities: {
               ...state.entities,
               [action.seq]: action.payload
            },
            listLoadCnt: state.listLoadCnt + 1
         };
      case Actions.REMOVE_ENTITY:
         delete state.entities[+(action.seq)];
         return {
            ...state
         };
      default:
         return state;
   }
};

export default testReducer;