import * as Actions from '../actions';
import _ from 'lodash';
import { pack } from '@gc';

const initState = {
   /* 가져온 직원목록 */
   entities: [],
   emplist: [],
   empMap: {},
   /* 선택한 부서정보 */
   department: {
      deptcode: '0',
      deptname: '',
      deptdesc: '',
      pDeptcode: '',
      pDeptname: '',
   },
   newDepartment: {
      deptcode: '0',
      deptname: '',
      deptdesc: '',
      pDeptcode: '',
      pDeptname: '',
   },
   /* TreeViewComponent 에서 필요 */
   /*fields: {
      dataSource: [],
      id: 'deptcode',
      parentID: 'pDeptcode',
      text: 'deptname',
      child: 'children',
      hasChildren: 'hasChild',
      selected: 'isSelected'
   },*/
   /* SortableTree 에서 필요 */
   treeSource: [], // id, title, subtitle
   treeLoadCnt: 0,
   /* 선택한 직원 정보 */
   employee: {
      empno: '',
      name: '',
      emails: [],
      domain: '',
      address: '',
      addressZip: '',
      addressDetail: '',
      department: {
         deptcode: '',
         deptname: '',
      },
      member: {
         isAdmin: '0',
      },
      isretire: '0',
      islogin: '1',
      issuper: '0',
      mytaskExpand: '1',
      schools: [],
      langs: [],
      certs: [],
      edus: [],
      acts: [],
      awards: [],
      armys: [],
   },
   deptSelectTree: [],
   infoSaveCount: 0,
};

const employeeReducer = function (state = initState, action) {
   switch (action.type) {
      case Actions.FETCH_EMPLOYEES:
         const emplist = action.payload;
         return {
            ...state,
            entities: !action.deptcode ? emplist : emplist.filter(emp => Boolean(emp.department) && emp.department.deptcode === action.deptcode),
            emplist: action.payload,
            empMap: _.keyBy(action.payload, 'empno'),
         };
      case Actions.FETCH_EMPLOYEE:
         return {
            ...state,
            employee: action.payload,
         };
      case Actions.FETCH_DEPARTMENT_SELECT_TREE:
         return {
            ...state,
            deptSelectTree: action.payload,
         };
      case Actions.OPEN_EMPLOYEE_ADD:
         return {
            ...state,
            employee: initState.employee,
         };
      case Actions.OPEN_EMPLOYEE_EDIT:
         return {
            ...state,
            // mode: action.mode,
            // currentTab: 0,
            employee:
               action.mode === 'view'
                  ? {
                       ...action.payload,
                       member: { ..._.omit(action.payload.member, 'password') },
                    }
                  : state.employee,
            // department: action.mode === 'view' ? action.payload.department : state.department
         };
      /*case Actions.OPEN_EMPLOYEE_LIST:
         return {
            ...state,
            mode: 'list'
         };*/
      case Actions.REMOVE_EMPLOYEE:
         _.remove(state.entities, { empno: action.empno });
         _.remove(state.emplist, { empno: action.empno });
         delete state.empMap[action.empno];
         return state;
      case Actions.REMOVE_DEPARTMENT:
         return {
            ...state,
            department: initState.department,
         };
      case Actions.FETCH_DEPARTMENT_TREE:
         return {
            ...state,
            treeSource: action.deptTree,
            treeMap: _.keyBy(action.deptList, 'id'),
            treeLoadCnt: state.treeLoadCnt + 1,
         };
      case Actions.SELECT_DEPARTMENT:
         const dept = action.payload || initState.department;
         const isFirm = dept.deptcode === dept.firmcode;

         return {
            ...state,
            department: dept,
            newDepartment: {
               ...initState.department,
               deptcode: '',
               pDeptcode: dept.deptcode || '0',
               pDeptname: dept.deptname || pack.servermsg()['dept.root'],
            },
            entities: !action.payload
               ? state.emplist
               : state.emplist.filter(
                    emp => Boolean(emp.department) && (isFirm ? emp.department.firmcode === dept.firmcode : emp.department.deptcode === dept.deptcode),
                 ),
         };
      case Actions.CREATE_EMPLOYEE:
         return {
            ...state,
            employee: initState.employee,
            // mode: 'list'
         };
      case Actions.CREATE_DEPARTMENT:
         return {
            ...state,
            department: action.payload,
         };
      case Actions.OPEN_DEPARTMENT_ADD:
         return {
            ...state,
            // mode: 'add',
            newDepartment: {
               ...initState.department,
               deptcode: '',
               pDeptcode: state.department.deptcode || '0',
               pDeptname: state.department.deptname || pack.servermsg()['dept.root'],
            },
         };
      case Actions.OPEN_DEPARTMENT_EDIT:
         return {
            ...state,
            // mode: action.mode,
            // currentTab: 1,
            // employee: action.payload
         };
      case Actions.SET_EMP_DEPT_ROLES:
         return {
            ...state,
            roles: action.payload,
         };
      case Actions.UPDATE_INFOS:
         return {
            ...state,
            employee: {
               ...state.employee,
               [action.key]: action.payload,
            },
            infoSaveCount: action.save ? state.infoSaveCount + 1 : state.infoSaveCount,
         };
      default:
         return state;
   }
};

export default employeeReducer;
