import * as Actions from "../actions";
import _ from 'lodash';
import {pack} from '@gc';
import axios from 'axios';

const initState = {
   entities: [],
   firmTree: [],
   firmList: [],
   entity: {
      seq: 0,
      name: pack.servermsg()['firm.root'],
      note: ''
   },
   treeLoadCnt: 0
};

const firmReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_FIRMS:
         return {
            ...state,
            firmTree: action.tree,
            firmList: action.list,
            treeLoadCnt: state.treeLoadCnt + 1
         };
      case Actions.FETCH_FIRM:
         let entity = initState.entity;
         const firm = _.find(state.firmList, {seq: +(action.seq)});

         if (firm)
            entity = firm;

         return {
            ...state,
            entity
         };
      case Actions.SAVE_ENTITY:
         return {
            ...state,
            entity: action.payload
         };
      case Actions.REMOVE_ENTITY:
         return {
            ...state,
            entity: initState.entity
         };
      case Actions.OPEN_VIEW:
         return {
            ...state,
            entity: _.find(state.firmList, {seq: +(action.seq)})
         };
      case Actions.SELECT_FIRM:
         return {
            ...state,
            entity: action.payload || initState.entity
         };
      default:
         return state;
   }
};

export default firmReducer;