import * as Action from '../actions/common.action';
import { pack } from '@gc';

const initState = {
   serverprop: null,
   timeoutEnable: true,
   menuTree: [],
   menuList: [],
   menuLoadCnt: 0,
   mailCount: sessionStorage.getItem('mailcount') || 0,
   signCount: sessionStorage.getItem('signcount') || 0,
   signReady: false,
   mySignList: [],
   currentItem: {
      label: '',
      icon: '',
      detail: '',
   },
   strings: pack.strings,
   lang: pack.language,
   breadcrumb: [],
   pushList: [],
   eventList: [],
   sysList: [],
   userMenuLoaded: false,
   loaded: true,
   pathFullscreen: 'null',
   reloadable: false,
   email: '',
   refSignCount: 0,
   connectInfo: undefined,
   finishInfo: undefined,
};

const collectItemMenus = (menu, list) => {
   if (menu.type === 'item') {
      list.push(menu);
   } else if (Array.isArray(menu.content)) {
      menu.content.forEach(item => {
         collectItemMenus(item, list);
      });
   }
};

const getRealType = type => (type || '').replace(/error/, 'danger');

const commonReducer = (state = initState, action) => {
   switch (action.type) {
      case Action.SET_FINISH_INFO:
         sessionStorage.setItem('finn', JSON.stringify(action.payload));
         return {
            ...state,
            finishInfo: action.payload,
         };
      case Action.SET_CURRENT_EMAIL:
         return {
            ...state,
            email: action.address,
         };
      case Action.OPEN_THEME_SETTINGS:
         return {
            ...state,
            themeOpen: true,
         };
      case Action.CLOSE_THEME_SETTINGS:
         return {
            ...state,
            themeOpen: false,
         };
      case Action.USER_MENU_LOADED:
         return {
            ...state,
            userMenuLoaded: true,
         };
      case Action.ADD_PUSH:
         const pushList = JSON.parse(sessionStorage.getItem('push') || '[]');
         pushList.push({ msg: action.msg, time: pack.dateToString(new Date(), 'MM-dd HH:mm') });
         sessionStorage.setItem('push', JSON.stringify(pushList));

         return {
            ...state,
            pushList,
         };
      case Action.ADD_EVENT:
         const eventList = JSON.parse(sessionStorage.getItem('event') || '[]');
         eventList.push({ msg: action.msg, time: pack.dateToString(new Date(), 'MM-dd HH:mm'), type: getRealType(action.eventType) });
         sessionStorage.setItem('event', JSON.stringify(eventList));

         return {
            ...state,
            eventList,
         };
      case Action.ADD_SYSLOG:
         const sysList = JSON.parse(sessionStorage.getItem('syslog') || '[]');
         sysList.push({ msg: action.msg, time: pack.dateToString(new Date(), 'MM-dd HH:mm'), type: getRealType(action.logType) });
         sessionStorage.setItem('syslog', JSON.stringify(sysList));

         return {
            ...state,
            sysList,
         };
      case Action.CHANGE_LOCALE:
         return {
            ...state,
            strings: action.payload,
            lang: action.lang,
         };
      case Action.FETCH_SERVER_PROPS:
         const serverprop = action.payload;
         localStorage.setItem('serverprop', JSON.stringify(serverprop));
         return {
            ...state,
            serverprop,
         };
      case Action.LOADING:
         return {
            ...state,
            loaded: false,
         };
      case Action.LOADED:
         return {
            ...state,
            loaded: true,
         };
      case Action.OVERLAY_START:
         return {
            ...state,
            activeOverlay: true,
            activeText: action.text,
         };
      case Action.OVERLAY_STOP:
         return {
            ...state,
            activeOverlay: false,
         };
      case Action.TOGGLE_SHOW_FILTER:
         return {
            ...state,
            showFilter: action.payload,
         };
      case Action.PASSWORD_EXPIRED:
         return {
            ...state,
            passwordExpired: action.payload,
         };
      case Action.SESSION_TIMEOUT_TOGGLE:
         return {
            ...state,
            timeoutEnable: action.enable,
         };
      /* 메뉴바에 숫자 업데이트시 필요 */
      case Action.UPDATE_MAIL_COUNT:
         sessionStorage.setItem('mailcount', action.count);
         return {
            ...state,
            menuLoadCnt: state.menuLoadCnt + 1,
            mailCount: action.count,
         };
      case Action.UPDATE_SIGN_COUNT:
         sessionStorage.setItem('signcount', action.count);
         return {
            ...state,
            menuLoadCnt: state.menuLoadCnt + 1,
            signCount: action.count,
         };
      case Action.UPDATE_SIGN_LIST:
         return {
            ...state,
            mySignList: action.payload,
            signReady: true,
         };
      case Action.FETCH_AUTH_MENU_LIST:
         return {
            ...state,
            menuTree: action.tree,
            menuList: action.list,
            menuLoadCnt: state.menuLoadCnt + 1,
         };
      case Action.SET_CURRENT_MENU_ITEM:
         const breadcrumb = action.payload;
         return {
            ...state,
            currentItem: breadcrumb != null && breadcrumb.length > 0 ? breadcrumb[0] : initState.currentItem,
            breadcrumb,
         };
      case Action.UPDATE_STRINGS:
         return {
            ...state,
            strings: pack.reloadedStrings(),
         };
      case Action.CHANGE_STRINGS:
         return {
            ...state,
            strings: action.payload,
         };
      case Action.TOGGLE_FULLSCREEN:
         return {
            ...state,
            pathFullscreen: state.pathFullscreen === 'null' ? action.path : 'null',
         };
      case Action.SET_RELOADABLE:
         return {
            ...state,
            reloadable: action.flag,
         };
      case Action.UPDATE_REF_SIGN_COUNT:
         return {
            ...state,
            refSignCount: action.count,
         };
      default:
         return state;
   }
};

export default commonReducer;
