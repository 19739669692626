import axios from 'axios';
import { toastr } from 'react-redux-toastr';

export const FETCH_APPROVE_COUNT = '[ADMBOARD] FETCH_APPROVE_COUNT';
export const FETCH_EMP_COUNT = '[ADMBOARD] FETCH_EMP_COUNT';
export const FETCH_DEPT_COUNT = '[ADMBOARD] FETCH_DEPT_COUNT';
export const FETCH_AUTH_COUNT = '[ADMBOARD] FETCH_AUTH_COUNT';
export const FETCH_CODE_COUNT = '[ADMBOARD] FETCH_CODE_COUNT';
export const FETCH_POSITION_COUNT = '[ADMBOARD] FETCH_POSITION_COUNT';
export const FETCH_CLIENT_IP = '[ADMBOARD] FETCH_CLIENT_IP';

export function fetchApproveCount() {
   return (dispatch, getState) =>
      axios
         .get('/approve/totalcount')
         .then(res => {
            dispatch({
               type: FETCH_APPROVE_COUNT,
               ing: res.data.ing,
               confirm: res.data.confirm,
               reject: res.data.reject,
            });
         })
         .catch(e => handleError(e, getState()));
}

export function fetchEmpCount() {
   return (dispatch, getState) =>
      axios
         .get('/employee/totalcount')
         .then(res =>
            dispatch({
               type: FETCH_EMP_COUNT,
               payload: res.data,
            }),
         )
         .catch(e => handleError(e, getState()));
}

export function fetchDeptCount() {
   return (dispatch, getState) =>
      axios
         .get('/department/totalcount')
         .then(res =>
            dispatch({
               type: FETCH_DEPT_COUNT,
               payload: res.data,
            }),
         )
         .catch(e => handleError(e, getState()));
}

export function fetchAuthCount() {
   return (dispatch, getState) =>
      axios
         .get('/auth/totalcount')
         .then(res =>
            dispatch({
               type: FETCH_AUTH_COUNT,
               count: res.data,
            }),
         )
         .catch(e => handleError(e, getState()));
}

export function fetchPositionCount() {
   return (dispatch, getState) =>
      axios
         .get('/code/totalcount/EMP_POS')
         .then(res =>
            dispatch({
               type: FETCH_POSITION_COUNT,
               count: res.data,
            }),
         )
         .catch(e => handleError(e, getState()));
}

export function fetchCodeCount() {
   return (dispatch, getState) =>
      axios
         .get('/code/totalcount')
         .then(res =>
            dispatch({
               type: FETCH_CODE_COUNT,
               payload: res.data,
            }),
         )
         .catch(e => handleError(e, getState()));
}

export function fetchClientIp() {
   return (dispatch, getState) =>
      axios
         .get('/common/client-ip')
         .then(res =>
            dispatch({
               type: FETCH_CLIENT_IP,
               ip: res.data,
            }),
         )
         .catch(e => handleError(e, getState()));
}

function handleError(err, state) {
   console.error(err);
   const { strings } = state.gc.common;
   toastr.error(strings.errTitle, strings.errMessage);
}
