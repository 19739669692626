import * as Actions from '../actions';

const initState = {
   show: false,
   title: '',
   body: '',
   icon: '',
   tag: 'abcdef',
   timeout: '5000',
   closeTag: '',
};

const notifyReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.SHOW:
         return {
            ...state,
            ...action,
            show: true
         };
      case Actions.CLOSE:
         return {
            ...state,
            show: false,
            closeTag: action.tag
         };
      default:
         return state;
   }
};

export default notifyReducer;