import axios from 'axios';
import {pack} from "@gc";
import {startLoading, stopLoading} from "../../../../@gc/common/actions/common.action";

const jtf = require('json-form-data');

export const FETCH_TESTS = '[TEST] FETCH TESTS';
export const FETCH_TEST = '[TEST] FETCH TEST';
export const OPEN_ADD = '[TEST] OPEN ADD';
export const REMOVE_ENTITY = '[TEST] REMOVE ENTITY';
export const CREATE_TEST = '[TEST] CREATE TEST';
export const UPDATE_TEST = '[TEST] UPDATE TEST';

export function fetchTests(showLoading, props) {
   return (dispatch, getState) => {
      if (showLoading) {
         dispatch(startLoading());
      }
      axios.get(`/test/list`).then(res => {
         dispatch(stopLoading());
         dispatch({
            type: FETCH_TESTS,
            payload: res.data
         });
         if (Boolean(props) && Boolean(props.match.params.seq)) {
            dispatch(fetchTest(props.match.params.seq));
         }
      }).catch(e => {
         dispatch(stopLoading());
         pack.showError(e);
      });
   };
}

export function fetchTest(seq) {
   return {
      type: FETCH_TEST,
      seq
   };
}

export function openAdd({match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_ADD
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'add',
         seq: null
      }));
   };
}

export function openView(seq, {match, history}) {
   return (dispatch) => {
      dispatch(fetchTest(seq));
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         seq
      }));
   }
}

export function removeTest(seq, {match, history}) {
   return (dispatch) => {
      axios.delete(`/test/${seq}`).then(res => {
         dispatch({
            type: REMOVE_ENTITY,
            seq
         });
         history.push(pack.getToPath(match)({
            ...match.params,
            mode: 'list',
            seq: null
         }));
      }).catch(e => {
         pack.showError(e);
      });
   }
}

export function createTest(data, {match, history}, onError) {
   return (dispatch, getState) => {
      axios.post('/test', jtf({...data, empno: getState().gc.user.empno}))
         .then(res => {
            dispatch({
               type: CREATE_TEST,
               payload: res.data,
               seq: res.data.seq
            });
            history.push(pack.getToPath(match)({
               ...match.params,
               mode: 'list',
               seq: null
            }));
         }).catch(e => {
            pack.showError(e);
            onError();
         });
   };
}