import axios from 'axios';
import {startLoading, stopLoading} from "../../../../@gc/common/actions/common.action";
import {pack} from "@gc";
import {toastr} from "react-redux-toastr";

const jtf = require('json-form-data');

export const TOGGLE_LIST_TAB = '[PROJECT] TOGGLE LIST TAB';
export const FETCH_PROJECTS = '[PROJECT] FETCH PROJECTS';
export const FETCH_PROJECT = '[PROJECT] FETCH PROJECT';
export const FETCH_EVENTS = '[PROJECT] FETCH EVENTS';
export const TOGGLE_PMO_GRID_TYPE = '[PROJECT] TOGGLE PMO GRID TYPE';
export const TOGGLE_WBS_ZOOM = '[PROJECT] TOGGLE WBS ZOOM';
export const OPEN_ADD = '[PROJECT] OPEN ADD';
export const OPEN_EDIT = '[PROJECT] OPEN EDIT';
export const OPEN_VIEW = '[PROJECT] OPEN VIEW';
export const TOGGLE_CLIENT_RIGHT_BAR = '[PROJECT] TOGGLE CLIENT RIGHT SIDEBAR';
export const TOGGLE_SELLER_RIGHT_BAR = '[PROJECT] TOGGLE SELLER RIGHT SIDEBAR';
export const TOGGLE_MONKER_RIGHT_BAR = '[PROJECT] TOGGLE MONKER RIGHT SIDEBAR';
export const TOGGLE_EMPLOYEE_RIGHT_BAR = '[PROJECT] TOGGLE EMPLOYEE RIGHT SIDEBAR';
export const TOGGLE_STAKE_RIGHT_BAR = '[PROJECT] TOGGLE STAKE RIGHT SIDEBAR';
export const FETCH_COMPANYS = '[PROJECT] FETCH COMPANYS';
export const FETCH_CLIENTS = '[PROJECT] FETCH CLIENTS';
export const FETCH_SELLERS = '[PROJECT] FETCH SELLERS';
export const FETCH_EMPLOYEE_TOTAL_LIST = '[PROJECT] FETCH EMPLOYEE LIST';
export const FETCH_PRODUCTS = '[PROJECT] FETCH PRODUCTS';
export const SELECT_COMPANY = '[PROJECT] SELECT COMPANY';
export const SELECT_CLIENTS = '[PROJECT] SELECT CLIENTS';
export const SELECT_EMPLOYEES = '[PROJECT] SELECT EMPLOYEES';
export const SELECT_STAKES = '[PROJECT] SELECT STAKES';
export const SELECT_FIRM_AND_SELLERS = '[PROJECT] SELECT FIRM';
export const SELECT_MONK_AND_MONKERS = '[PROJECT] SELECT MONK AND MONKERS';
export const CREATE_TASK = '[PROJECT] CREATE TASK';
export const REMOVE_TASK = '[PROJECT] REMOVE TASK';
export const CREATE_LINK = '[PROJECT] CREATE LINK';
export const REMOVE_LINK = '[PROJECT] REMOVE LINK';

export const PMO_GRID_TYPE_CALENDAR = 'cal';
export const PMO_GRID_TYPE_LIST = 'list';

export const PMO_SELECT_CLIENT = 'client';
export const PMO_SELECT_SELLER = 'seller';
export const PMO_SELECT_MONKER = 'monker';
export const PMO_SELECT_EMPLOYEE = 'employee';
export const PMO_SELECT_STAKE = 'stake';

export function fetchProjects(showLoading, props) {
   return (dispatch, getState) => {
      if(showLoading)
         dispatch(startLoading());

      axios.post(`/projects`, {}).then(res => {
            dispatch({
               type: FETCH_PROJECTS,
               payload: res.data
            });
            dispatch(stopLoading());

            if (props && Boolean(props.match.params.seq)) {
               dispatch(fetchProject(props.match.params.seq));
            }
         });
   };
}

export function fetchProject(seq) {
   return {
      type: FETCH_PROJECT,
      seq
   }
}

export function fetchEvents() {
   return (dispatch) =>
      axios.get('/project/event/list')
         .then(res =>
            dispatch({
               type: FETCH_EVENTS,
               payload: res.data
            })
         );
}

export function toggleListTab(tab) {
   return {
      type: TOGGLE_LIST_TAB,
      tab
   };
}

export function openViewBySeq(seq, {match, history}) {
   return (dispatch) =>
      axios.get(`/project/${seq}`)
         .then(res => {
            dispatch({
               type: OPEN_VIEW,
               data: res.data
            });
            history.push(pack.getToPath(match)({
               ...match.params,
               mode: 'view'
            }));
         });
}

export function fetchCompanys() {
   return (dispatch) =>
      axios.get('/company')
         .then(res =>
            dispatch({
               type: FETCH_COMPANYS,
               payload: res.data
            })
         );
}

export function fetchSellerTree() {
   return (dispatch, getState) =>
      axios.get(`/firm/seller/tree?lang=${getState().gc.common.lang}`)
         .then(res =>
            dispatch({
               type: FETCH_SELLERS,
               tree: res.data.tree,
               list: res.data.list
            })
         );
}

export function fetchProducts() {
   return (dispatch) =>
      axios.get(`/product`)
         .then(res =>
            dispatch({
               type: FETCH_PRODUCTS,
               payload: res.data
            })
         );
}

export function openAdd({match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_ADD
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'add',
         seq: null
      }));
   }
}

export function togglePmoGridType(gridType) {
   return {
      type: TOGGLE_PMO_GRID_TYPE,
      gridType
   };
}

export function toggleRightBar(type, open) {
   return (dispatch) => {
      dispatch({
         type: type === PMO_SELECT_CLIENT ? TOGGLE_CLIENT_RIGHT_BAR
            : type === PMO_SELECT_SELLER ? TOGGLE_SELLER_RIGHT_BAR
               : type === PMO_SELECT_EMPLOYEE ? TOGGLE_EMPLOYEE_RIGHT_BAR
                  : type === PMO_SELECT_MONKER ? TOGGLE_MONKER_RIGHT_BAR
                     : TOGGLE_STAKE_RIGHT_BAR,
         open
      });
   }
}

export function selectCompany(value) {
   return {
      type: SELECT_COMPANY,
      value
   }
}

export function selectClients(seqs) {
   return {
      type: SELECT_CLIENTS,
      seqs
   };
}

export function initClients() {
   return {
      type: SELECT_CLIENTS,
      seqs: []
   };
}

export function selectFirmAndSellers(pid, idList) {
   return (dispatch) =>
      axios.get(`/firm/${pid}`)
         .then(res => {
            const firm = res.data;
            const sellers = (firm.sellers || []).filter(seller => idList.includes('s_' + seller.seq));
            dispatch({
               type: SELECT_FIRM_AND_SELLERS,
               firm,
               sellers
            });
         })
}

export function selectMonkAndMonkers(pid, idList) {
   return (dispatch) =>
      axios.get(`/firm/${pid}`)
         .then(res => {
            const firm = res.data;
            const sellers = (firm.sellers || []).filter(seller => idList.includes('s_' + seller.seq));
            dispatch({
               type: SELECT_MONK_AND_MONKERS,
               firm,
               sellers
            });
         })
}

export function selectEmployees(selected) {
   return (dispatch, getState) =>
      dispatch({
         type: SELECT_EMPLOYEES,
         selected
      })
}

export function selectStakes(selected) {
   return (dispatch, getState) =>
      dispatch({
         type: SELECT_STAKES,
         selected
      })
}

export function removeEntity(seq, {match, history}) {
   return (dispatch, getState) =>
      axios.delete(`/project/${seq}/${getState().gc.user.empno}`)
         .then(res => {
            dispatch(fetchProjects(true));
            history.push(pack.getToPath(match)({
               ...match.params,
               mode: 'list'
            }));
         });
}

export function createEntity(data, {match, history}, onError) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: '/project',
         data: jtf({...data, myEmpno: getState().gc.user.empno})
      }).then(res => {
         if (res.status === 200) {
            dispatch(fetchProjects(true));
            history.push(pack.getToPath(match)({
               ...match.params,
               mode: 'list'
            }));
         } else {
            onError(res);
         }
      }).catch(e => onError(e));
   }
}

export function toggleZoom(zoom) {
   return {
      type: TOGGLE_WBS_ZOOM,
      zoom
   };
}

export function openView(seq, {match, history}) {
   return (dispatch) => {
      dispatch(fetchProject(seq));
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         seq
      }));
   }
}

export function openEdit(data, {match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_EDIT,
         data
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'edit'
      }));
   };
}

export function createTask(task, isUpdate) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: `/project/wbs${isUpdate ? '/update' : ''}`,
         data: jtf({
            taskId: task.id,
            projectSeq: getState().griffin.manage.project.entity.seq,
            parentId: task.parent,
            taskText: task.text,
            empno: task.empno,
            startdate: pack.dateToString(task.start_date, 'yyyyMMdd'),
            duration: task.duration,
            progress: (task.progress || 0).toFixed(5)
         })
      }).then(res => {
         if(res.status !== 200) {
            toastr.error(pack.strings.errTitle, pack.strings.errMessage);
         } else {
            dispatch({
               type: CREATE_TASK,
               payload: res.data
            });
         }
      });
}

export function removeTask(id) {
   return (dispatch, getState) =>
      axios.delete(`/project/wbs/task/${id}/${getState().griffin.manage.project.entity.seq}`)
         .then(res =>
            dispatch({
               type: REMOVE_TASK,
               seq: res.data
            })
         );
}

export function createLink(link, isUpdate) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: `/project/wbs/link/${isUpdate ? '/update' : ''}`,
         data: jtf({
            linkId: link.id,
            source: link.source,
            target: link.target,
            projectSeq: getState().griffin.manage.project.entity.seq
         })
      }).then(res => {
         if (res.status !== 200) {
            toastr.error(pack.strings.errTitle, pack.strings.errMessage);
         } else {
            dispatch({
               type: CREATE_LINK,
               payload: res.data
            });
         }
      });
}

export function removeLink(id) {
   return (dispatch, getState) =>
      axios.delete(`/project/wbs/link/${id}/${getState().griffin.manage.project.entity.seq}`)
         .then(res =>
            dispatch({
               type: REMOVE_LINK,
               seq: res.data
            })
         );
}