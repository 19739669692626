import * as Actions from '../actions/calendar.actions';
import { pack } from '@gc';
import moment from 'moment';

const initialState = {
   entities: [],
   eventDialog: {
      type: 'new',
      props: {
         open: false,
      },
      data: null,
   },
   eventLoadCnt: 0,
};

const myTaskCalendarReducer = function (state = initialState, action) {
   switch (action.type) {
      case Actions.GET_EVENTS:
         const entities = action.payload.map(event => ({
            ...event,
            // start: pack.dateFormat(event.start),
            // end: pack.dateFormat(event.end),
            start: pack.dateFormat(event.start),
            end: pack.momentToString(moment(event.end, 'YYYYMMDD').add(1, 'days')),
         }));
         return {
            ...state,
            entities,
            eventLoadCnt: state.eventLoadCnt + 1,
         };
      case Actions.GET_GOOGLE_EVENTS:
         const arr = action.payload.map(event => ({
            // let end = event.end.date ? pack.addDays(new Date(event.end.date), 1) : new Date(event.end.dateTime);
            // const start = event.start.date ? new Date(event.start.date) : new Date(event.start.dateTime);
            title: event.summary,
            start: event.start.date, //pack.dateToString(start, 'yyyy-MM-dd'),
            end: event.end.date, //start >= end ? null : pack.dateToString(end, 'yyyy-MM-dd'),
            allDay: true,
            backgroundColor: '#f44336',
         }));
         return {
            ...state,
            entities: [...state.entities, ...arr],
            eventLoadCnt: state.eventLoadCnt + 1,
         };
      case Actions.OPEN_NEW_EVENT_DIALOG: {
         return {
            ...state,
            eventDialog: {
               type: 'new',
               props: {
                  open: true,
               },
               data: {
                  ...action.data,
               },
            },
         };
      }
      case Actions.CLOSE_NEW_EVENT_DIALOG: {
         return {
            ...state,
            eventDialog: {
               type: 'new',
               props: {
                  open: false,
               },
               data: null,
            },
         };
      }
      case Actions.OPEN_EDIT_EVENT_DIALOG: {
         return {
            ...state,
            eventDialog: {
               type: 'edit',
               props: {
                  open: true,
               },
               data: {
                  ...action.data,
                  start: new Date(action.data.start),
                  end: new Date(action.data.end),
               },
            },
         };
      }
      case Actions.CLOSE_EDIT_EVENT_DIALOG: {
         return {
            ...state,
            eventDialog: {
               type: 'edit',
               props: {
                  open: false,
               },
               data: null,
            },
         };
      }
      default: {
         return state;
      }
   }
};

export default myTaskCalendarReducer;
