import { combineReducers } from 'redux';
import sysmail from '../mailbox/reducers/sysmail.reducers';
import boards from '../boards/reducers';
import task from '../taskplan/reducers';
import mytask from '../mytask/reducers';
import trello from '../mytask/reducers/trello.reducers';
import calendar from '../mytask/reducers/calendar.reducers';
import contacts from '../contacts/reducers';
import pds from '../pds/reducers';
import mailbox from '../mailbox/reducers';
import approve from '../approve/reducers';
import mailsetting from '../mailbox/reducers/setting.reducers';
import test from '../test/reducers';
import home from '../home/reducers';
import tmsproject from '../tms/project/reducers';
import tmsactivity from '../tms/activity/reducers';
import tmstask from '../tms/task/reducers';
import tmstodo from '../tms/todo/reducers';

const activityAppReducer = combineReducers({
   sysmail,
   boards,
   task,
   mytask,
   calendar,
   contacts,
   pds,
   mailbox,
   approve,
   mailsetting,
   trello,
   home,
   test,
   tmsproject,
   tmsactivity,
   tmstask,
   tmstodo,
});

export default activityAppReducer;
