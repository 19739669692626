import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, FormText, Input, Label } from 'reactstrap';
import cx from 'classnames';
import { Controller } from 'react-hook-form';
import { pack } from '../common/global';

function CodeSelectHook(props) {
   const [options, setOptions] = useState([]);

   const labelWidth = props.labelWidth || { sm: 2 };
   const colWidth = props.colWidth || { sm: 4 };
   const id = props.id || props.name;
   const defaultObj = options.find(opt => opt.codeValue === props.defaultValue) || {};
   const defaultLabel = defaultObj.codeDesc || props.defaultValue || '';

   useEffect(() => {
      pack
         .fetchCode(props.codeGroup)
         .then(res => setOptions(res.data))
         .catch(pack.showError);
   }, []);

   useEffect(() => {
      if (options.length > 0) {
         props.setValue(props.name, props.defaultValue || '');
      }
   }, [props.defaultValue, options]);

   return (
      <>
         {props.label && (
            <Label {...labelWidth} for={id} className={cx({ required: !props.viewmode && props.rules && props.rules.required })}>
               {props.label}
            </Label>
         )}
         <Col {...colWidth} className={('d-flex align-items-center', props.colClassName || '')}>
            {!props.viewmode ? (
               <div className="d-flex flex-column w-100">
                  <Controller
                     render={({ field }) => (
                        <Input
                           type="select"
                           onChange={e => {
                              const { value } = e.target;
                              field.onChange(value);
                              if (typeof props.onChange === 'function') {
                                 props.onChange(value);
                              }
                           }}
                           value={field.value}
                           disabled={Boolean(props.disabled)}
                           readOnly={Boolean(props.readOnly)}
                        >
                           {Boolean(props.selectNoneText) && <option value="">{props.selectNoneText}</option>}
                           {options.map(opt => (
                              <option key={opt.codeValue} value={opt.codeValue}>
                                 {opt.codeDesc}
                              </option>
                           ))}
                        </Input>
                     )}
                     name={props.name}
                     control={props.control}
                     rules={props.rules}
                     defaultValue={props.defaultValue || ''}
                  />
                  {!props.hideErrorMessage && props.errors[props.name] && props.errors[props.name].message && (
                     <FormText color="danger">{props.errors[props.name].message}</FormText>
                  )}
               </div>
            ) : (
               <Label className="view">{defaultLabel}</Label>
            )}
         </Col>
      </>
   );
}

CodeSelectHook.propTypes = {
   id: PropTypes.string,
   name: PropTypes.string.isRequired,
   control: PropTypes.any.isRequired,
   codeGroup: PropTypes.string.isRequired,
   setValue: PropTypes.func.isRequired,
   errors: PropTypes.any.isRequired,
   rules: PropTypes.object,
   defaultValue: PropTypes.any,
   label: PropTypes.string,
   viewmode: PropTypes.bool,
   onChange: PropTypes.func,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   colClassName: PropTypes.string,
   selectNoneText: PropTypes.string,
   disabled: PropTypes.bool,
   readOnly: PropTypes.bool,
   hideErrorMessage: PropTypes.bool,
};

export default CodeSelectHook;
