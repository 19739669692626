import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { blue } from '@material-ui/core/colors';
import cx from 'classnames';
import { pack } from '../../@gc';
import { useSelector } from 'react-redux';
import axios from 'axios';

const ChatArea = styled.div`
   display: inline-flex;
   align-items: flex-start;
   flex-direction: row;
   & > img {
      width: 40px;
      border-radius: 4px;
      border: 1px solid #424242;
      margin-right: 8px;
   }
   & > div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & > .info {
         display: inline-flex;
         align-items: center;
         color: #757575;
         & .name {
            font-weight: 600;
            margin-right: 6px;
         }
         & .time {
            font-size: 10px;
            font-weight: lighter;
         }
      }
      & > .content {
         margin-top: 4px;
         padding: 8px 16px;
         background: ${blue[100]};
         color: #424242;
         font-size: 14px;
         white-space: pre-wrap;
         word-break: break-all;
         width: fit-content;
         max-width: 400px;
         border-radius: 4px;
         & img {
            max-width: 200px;
            height: unset !important;
         }
      }
   }
   &.reverse {
      flex-direction: row-reverse;
      & > img {
         margin-right: 0 !important;
         margin-left: 8px;
      }
      & > div {
         align-items: flex-end !important;
      }
   }
`;

const Chat = ({ chat, onLinkFound }) => {
   const { user } = useSelector(stt => stt.gc);
   const [linkinfo, setLinkinfo] = useState(null);

   useEffect(() => {
      if (chat.id > 0) {
         let url = null;
         chat.content
            .replace(/(<([^>]+)>)/gs, '')
            .split(/\s+/)
            .forEach(it => {
               if (pack.isValidUrl(it)) {
                  url = it.trim();
                  return false;
               }
            });
         if (url) {
            axios
               .get('/common/link-info', {
                  params: {
                     url,
                  },
               })
               .then(res => {
                  setLinkinfo({ ...res.data, id: chat.id });
                  onLinkFound();
               })
               .catch(e => {
                  console.log('e=', e);
               });
         }

         const anchors = document.getElementById(`chat-${chat.id}`).getElementsByTagName('a');
         if (anchors) {
            for (const anchor of anchors) {
               if (anchor instanceof HTMLAnchorElement) {
                  anchor.setAttribute('target', '_blank');
                  anchor.style.color = blue[600];
               }
            }
         }
      }
   }, [chat.content, chat.id, onLinkFound]);

   return (
      <ChatArea className={cx('mt-3', { reverse: user.empno === chat.employee.empno })} key={chat.id} id={`chat-${chat.id}`}>
         <img src={pack.serverImage(chat.employee.avatar)} alt={chat.employee.name} />
         <div>
            <div className="info">
               <span className="name">{chat.employee.name}</span>
               <span className="time">{pack.timestampToFormattedString(chat.createdAt, 'HH:mm')}</span>
            </div>
            <div className="content" dangerouslySetInnerHTML={{ __html: chat.content }} />
            {linkinfo && linkinfo.id === chat.id && (
               <a
                  style={{
                     display: 'flex',
                     flexDirection: 'column',
                     width: 250,
                     fontSize: 10,
                     border: '1px solid #bcbcbc',
                     background: '#fff',
                  }}
                  href={linkinfo.url}
                  target="_blank"
                  rel="noreferrer noopener"
                  className="mt-2"
               >
                  <span
                     className="text-black-50 p-1"
                     style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                     }}
                  >
                     {linkinfo.title || linkinfo.domain}
                  </span>
                  {linkinfo.image ? (
                     <img src={linkinfo.image} className="w-100" height={150} style={{ objectFit: 'cover' }} />
                  ) : (
                     <div
                        style={{
                           display: 'flex',
                           width: '100%',
                           height: 150,
                           alignItems: 'center',
                           justifyContent: 'center',
                        }}
                     >
                        미리보기가 없습니다
                     </div>
                  )}
                  <span className="text-dark p-1" style={{ fontWeight: 400, fontSize: 11 }}>
                     {linkinfo.desc}
                  </span>
                  <span className="text-black-50 mt-1 p-1" style={{ fontSize: 11 }}>
                     {linkinfo.domain}
                  </span>
               </a>
            )}
         </div>
      </ChatArea>
   );
};

export default Chat;
