import gc from "@gc/reducer/merge.reducers";
import griffin from '@griffin/reducer/merge.reducers';
import {reducer as toastr} from "react-redux-toastr";
import swal from "@griffin/common/components/swal/reducers";
import notify from "@griffin/common/components/notify/reducers";

export default {
    gc,
    griffin,
    toastr,
    swal,
    notify
};
