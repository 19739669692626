import {withFormsy} from 'formsy-react';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Col, FormText, Label} from "reactstrap";
import cx from 'classnames';
import {Radio} from 'antd';

class RadioGroupFormsy extends Component {

   constructor(props) {
      super(props);
      this.state = {
         touched: false,
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if(prevProps.viewmode !== this.props.viewmode) {
         this.setState({
            touched: false
         });
      }
   }

   /*changeValue = evt => {
      this.props.setValue(evt.target.value);
      if (typeof this.props.onChange === 'function') {
         this.props.onChange(evt);
      }
   };*/

   handleClick = evt => {
      const {value} = evt.target;
      this.props.setValue(value);
      if (value !== this.props.getValue() && typeof this.props.onChange === 'function') {
         this.props.onChange(value);
      }
   };

   render() {
      const {touched} = this.state;
      let importedProps = _.pick(this.props, [
         "name",
         "className",
         "children"
      ]);
      let error = false;
      let errorMessage = this.props.getErrorMessage() || this.props.errorTrigger;
      const value = this.props.getValue() || '';

      if (!importedProps.type) {
         importedProps.type = 'text';
      }

      if (touched && !value && !errorMessage && this.props.required) {
         errorMessage = this.props.requiredMsg || '';
         error = true;
      }

      const labelWidth = this.props.labelWidth || {sm: 2};
      const colWidth = this.props.colWidth || {sm: 4};
      const id = this.props.id || this.props.name;

      return (
         <>
            {Boolean(this.props.label) &&
            <Label
               {...labelWidth}
               for={id}
               className={cx({'required': !this.props.viewmode && this.props.required})}
            >
               {this.props.label}
            </Label>
            }
            <Col {...colWidth}>
            { !this.props.viewmode ? (
                  <Radio.Group>
                  {this.props.fields.map((item, idx) => (
                     <Radio
                        {...importedProps}
                        id={idx === 0 ? id : ''}
                        value={item.value}
                        disabled={item.disabled || false}
                        checked={item.value === value}
                        onClick={this.handleClick}
                     >{item.label}</Radio>
                  ))
                  }
                  </Radio.Group>
               ) : <Label className="view">{ (_.find(this.props.fields, {value: this.props.value}) || {}).label}</Label>
            }
         </Col>
      </>
      );
   }
}

RadioGroupFormsy.propTypes = {
   name: PropTypes.string.isRequired,
   fields: PropTypes.array.isRequired,    // [ { label: 'xx', value: '1', disabled: true}, { label.... } ... ]
   label: PropTypes.string,
   value: PropTypes.any,
   onChange: PropTypes.func,
   viewmode: PropTypes.bool,
   required: PropTypes.bool,
   requiredMsg: PropTypes.string,
   errorTrigger: PropTypes.string,
   validations: PropTypes.any,
   validationErrors: PropTypes.object,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   inputRef: PropTypes.func
};

export default withFormsy(RadioGroupFormsy)