import axios from 'axios';
import { pack } from '@gc';

const jtf = require('json-form-data');

export const OPEN_CODE_DIALOG = '[CODE] OPEN CODE DIALOG';
export const CLOSE_CODE_DIALOG = '[CODE] CLOSE CODE DIALOG';
export const FETCH_CODE_TREE = '[CODE] FETCH CODE TREE';
export const FETCH_CODES = '[CODE] FETCH CODES';
export const SELECT_CODE = '[CODE] SELECT CODE';
export const CREATE_CODE = '[CODE] CREATE CODE';
export const UPDATE_CODE = '[CODE] UPDATE CODE';
export const UPDATE_CODE_SORT = '[CODE] UPDATE CODE SORT';
export const REMOVE_CODE = '[CODE] REMOVE CODE';
export const FETCH_MENUSELECT_TREE = '[CODE] FETCH MENUSELECT TREE';
export const UPDATE_CODE_MENUS = '[CODE] UPDATE CODE MENUS';

export function fetchCodes(codeGroup) {
   return dispatch => {
      axios.get(`/code/list/${codeGroup}`).then(res => {
         return dispatch({
            type: FETCH_CODES,
            payload: res.data,
         });
      });
   };
}

export function fetchMenuSelectTree() {
   const request = axios.get('/code/menu/tree');
   return dispatch =>
      request.then(res =>
         dispatch({
            type: FETCH_MENUSELECT_TREE,
            payload: res.data,
         }),
      );
}

export function fetchCodeTree(idToSelect) {
   const request = axios.get('/menu/codetree');

   return dispatch =>
      request.then(res => {
         const { list, tree } = res.data;
         const arr = [
            dispatch({
               type: FETCH_CODE_TREE,
               tree,
               list,
            }),
         ];
         if (idToSelect) {
            arr.push(dispatch(selectTreeNode(idToSelect)));
         }
         // arr.push(dispatch(fetchMenuSelectTree()));
         return Promise.all(arr);
      });
}

export function selectTreeNode(nodeKey) {
   return (dispatch, getState) => {
      dispatch(fetchCodes(nodeKey));
      axios.get(`/codegroup/${nodeKey}`).then(res =>
         dispatch({
            type: SELECT_CODE,
            payload: res.data,
         }),
      );
   };
}

export function updateCodeMenu(codeSeq, menuIds) {
   const request = axios({
      method: 'post',
      url: `/code/menus/${codeSeq}`,
      data: jtf({ menuIds: menuIds.join(',') }),
   });

   return (dispatch, getState) =>
      request.then(res => {
         dispatch(fetchCodeTree(res.data.codeValue));
         // dispatch({
         //    type: SELECT_CODE,
         //    payload: res.data
         // });
      });
}

export function updateCodeSort(data) {
   const seqs = data.map(code => code.seq).join(',');
   return (dispatch, getState) =>
      axios.put(`/code/sort`, jtf({ seqs })).then(res =>
         dispatch({
            type: UPDATE_CODE_SORT,
            payload: data,
         }),
      );
}

export function createCode(data, menus) {
   const isUpdate = Boolean(data.seq);
   return (dispatch, getState) => {
      const request = axios({
         method: 'post',
         url: `/code${isUpdate ? '/update' : ''}`,
         data: jtf(data),
      });

      request.then(res => {
         if (data.isgroup === '1') {
            dispatch(fetchCodeTree(data.codeValue));
            if (menus.length > 0) {
               dispatch(updateCodeMenu(res.data.seq, menus));
            }
         } else {
            dispatch(selectTreeNode(data.codeGroup));
            dispatch(fetchCodes(data.codeGroup));
         }
      });
   };
}

/*export function updateCode(newCode, menus) {
   newCode = pack.jsonToJpa(newCode, 'menus');
   const request = axios({
      method: 'post',
      url: '/code/update',
      data: jtf(newCode)
   });

   debugger;
   return (dispatch, getState) => request.then(res => {
      debugger;
      if (newCode.isgroup === '1') {
         dispatch(fetchCodeTree(newCode.codeValue));
         if (menus.length > 0) {
            dispatch(updateCodeMenu(res.data.seq, menus));
         }
      } else {
         dispatch(fetchCodes(newCode.codeGroup));
      }
   });
}*/

export function removeCode(seq, isGroup) {
   const request = axios.delete(`/code/${seq}`);

   return dispatch =>
      request.then(res => {
         dispatch({
            type: REMOVE_CODE,
            seq,
            isGroup: isGroup,
         });
         dispatch(fetchCodeTree());
      });
}
