import axios from 'axios';
import {startLoading, stopLoading} from "../../../../@gc/common/actions/common.action";
import {pack} from "@gc";
import _ from "lodash";

const jtf = require('json-form-data');

export const FETCH_CLIENTS = '[CLIENT] FETCH CLIENTS';
export const FETCH_CLIENT = '[CLIENT] FETCH CLIENT';
export const FETCH_COMPANYS = '[CLIENT] FETCH COMPANYS';
export const OPEN_ADD = '[CLIENT] OPEN ADD';
export const OPEN_EDIT = '[CLIENT] OPEN EDIT';
export const OPEN_VIEW = '[CLIENT] OPEN VIEW';

export function fetchClients(showLoading, props) {
   return (dispatch, getState) => {
      if (showLoading)
         dispatch(startLoading());
      const request = axios.get(`/client-list`);
      request.then(res => {
         dispatch(stopLoading());
         dispatch({
            type: FETCH_CLIENTS,
            payload: res.data
         });
         if (props && Boolean(props.match.params.seq)) {
            dispatch(fetchClient(props.match.params.seq));
         }
      })
   }
}

export function fetchClient(seq) {
   return {
      type: FETCH_CLIENT,
      seq
   };
}

export function fetchCompanys() {
   return (dispatch) =>
      axios.get(`/company`)
         .then(res => {
            pack.sortArray(res.data, 'name');
            dispatch({
               type: FETCH_COMPANYS,
               payload: res.data
            });
         });
}

export function openAdd({match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_ADD
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'add',
         seq: null
      }));
   };
}

export function openEdit(data, {match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_EDIT,
         data
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'edit'
      }));
   };
}

export function openView(seq, {match, history}) {
   return (dispatch) => {
      dispatch(fetchClient(seq));
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         seq
      }));
   };
}

export function removeEntity(seq, {match, history}) {
   return (dispatch, getState) =>
      axios.delete(`/client/${seq}/${getState().gc.user.id}`)
         .then(res => {
            dispatch(fetchClients(true));
            history.push(pack.getToPath(match)({
               ...match.params,
               mode: 'list'
            }));
         });
}

export function createEntity(data, {match, history}) {
   data = pack.jsonToJpa(data, 'member');
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: `/client${match.params.mode === 'edit' ? '/update' : ''}`,
         data: jtf({...data, myId: getState().gc.user.id})
      }).then(res => {
         dispatch(fetchClients());
         history.push(pack.getToPath(match)({
            ...match.params,
            mode: 'list'
         }));
      });
}