import axios from 'axios';
import {updateStrings} from "../../../../@gc/common/actions/common.action";
import {pack} from "@gc";
import _ from 'lodash';

const jtf = require('json-form-data');

export const OPEN_ADD = '[BOARD MGR] OPEN ADD';
export const FETCH_LIST = '[BOARD MGR] FETCH LIST';
export const FETCH_ENTITY = '[BOARD MGR] FETCH ENTITY';
export const FETCH_LOCALE = '[BOARD MGR] FETCH LOCALE';
export const SELECT_ITEM = '[BOARD MGR] SELECT ITEM';
export const UPDATED_BOARD = '[BOARD MGR] CREATE BOARD';

export function fetchList(selected, props) {
   return (dispatch, getState) => {
      axios.get(`/boardmgr/list`).then(res => {
         dispatch({
            type: FETCH_LIST,
            payload: res.data
         });
         if (selected) {
            dispatch(selectItem(selected, props));
         } else if (props && props.match.params.id) {
            const entity = _.find(getState().griffin.admin.boardmgr.entities, {id: props.match.params.id});
            dispatch(selectItem(entity, props));
         }
      });
   }
}

export function fetchLocaleData() {
   return (dispatch) => {
      axios.get('/locale').then(res => {
         dispatch({
            type: FETCH_LOCALE,
            payload: res.data
         })
      })
   }
}

export function openAdd({history, match}) {
   return (dispatch, getState) => {
      dispatch({
         type: OPEN_ADD
      });
      history.push(pack.getToPath(match)({
         ..._.omit(match.params, 'id'),
         mode: 'add'
      }));
   };
}

/**
 * 여기서의 boardNo === menu id
 */
export function removeBoard(boardNo, localeData, {match, history}) {
   return (dispatch, getState) =>
      axios({
         method: 'post', url: `/boardmgr/delete/${boardNo}`, data: jtf({json: JSON.stringify(localeData)})
      }).then(res => {
         dispatch({
            type: UPDATED_BOARD
         });
         history.push(pack.getToPath(match)({
            ...match.params,
            mode: 'view',
            id: null
         }));

         dispatch(updateStrings(true));
         // const strings = pack.updateLocaleData(localeData, getState().gc.common.lang);
         // dispatch(changeStrings(strings));
         dispatch(fetchList());
      });
}

export function selectItem(data, {match, history}) {
   return (dispatch, getState) => {
      dispatch({
         type: SELECT_ITEM,
         payload: data
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         id: data.id
      }));
   }
}

export function createBoard(data, localeData, {match, history}) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: '/boardmgr',
         data: jtf({...data, json: JSON.stringify(localeData)})
      }).then(res => {
         dispatch(updateStrings(true));
         // const strings = pack.updateLocaleData(localeData, getState().gc.common.lang);
         // dispatch(changeStrings(strings));
         dispatch({
            type: UPDATED_BOARD,
            data: res.data
         });
         history.push(pack.getToPath(match)({
            ..._.omit(match.params, 'id'),
            mode: 'view'
         }));
         dispatch(fetchList(res.data, {match, history}));
      })
}
