import { pack } from '../../../../@gc';

export const colors = ['#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#00bcd4', '#009688', '#4caf50', '#ffc107', '#ff9800', '#795548'];

export const entityToEvent = entity => ({
   ...entity,
   iid: entity.id,
   pickColor: entity.color,
   start: new Date(entity.startdate),
   end: new Date(entity.enddate).setSeconds(new Date(entity.enddate).getSeconds() + 1),
   title: `${entity.codeDesc} : ${entity.name}`,
   allDay: true,
   backgroundColor: entity.color || '',
   textColor: Boolean(entity.color) ? pack.invertColor(entity.color, true) : '#ffffff',
});

export const todoToEvent = todo => ({
   ...todo,
   iid: todo.id,
   start: new Date(todo.starttime),
   end: new Date(todo.endtime),
   title: `${todo.task.codeDesc} : ${todo.task.name}`,
   backgroundColor: todo.task.color || '',
   textColor: Boolean(todo.task.color) ? pack.invertColor(todo.task.color, true) : '#ffffff',
});
