import axios from 'axios';
import { pack } from '@gc';
import { logoutUser } from '../../../../@gc/auth/actions/user.action';

const jtf = require('json-form-data');

export const FETCH_CODES = '[MENU] FETCH CODES';
export const FETCH_MENU_TREE = '[MENU] FETCH MENU TREE';
export const OPEN_MENU_ADD = '[MENU] OPEN MENU ADD';
export const OPEN_MENU_EDIT = '[MENU] OPEN_MENU_EDIT';
export const SELECT_MENU = '[MENU] SELECT MENU';
export const DELETE_MENU = '[MENU] DELETE MENU';

export function fetchMenuTree(idToSelect, props) {
   return (dispatch, getState) =>
      axios
         .get(`/menu/tree?lang=${getState().gc.common.lang}`)
         .then(res => {
            const { list, tree } = res.data;
            const arr = [
               dispatch({
                  type: FETCH_MENU_TREE,
                  tree,
                  list,
               }),
            ];
            if (idToSelect && props) {
               arr.push(dispatch(selectTreeNode(idToSelect, props)));
            }
            return Promise.all(arr);
         })
         .catch(e => {
            if (e.response?.status === 401) {
               dispatch(logoutUser());
            }
         });
}

export function openMenuAdd({ match, history }) {
   return dispatch => {
      dispatch({
         type: OPEN_MENU_ADD,
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'add',
            tab: 1,
         }),
      );
   };
}

export function openMenuEdit({ match, history }) {
   return dispatch => {
      dispatch({
         type: OPEN_MENU_EDIT,
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'edit',
            tab: 1,
         }),
      );
   };
}

export function openMenuView({ match, history }) {
   return dispatch => {
      dispatch({
         type: OPEN_MENU_EDIT,
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'view',
            tab: 1,
         }),
      );
   };
}

export function removeMenu(menuId, pid, props) {
   const request = axios.delete(`/menu/${menuId}`);

   return dispatch =>
      request.then(res => {
         dispatch(fetchMenuTree(pid, props));
      });
}

export function createMenu(data, props) {
   const request = axios({
      method: 'post',
      url: '/menu',
      data: jtf(data),
   });

   return dispatch =>
      request.then(res => {
         dispatch(fetchMenuTree(data.id, props));
      });
}

export function selectTreeNode(nodeKey, { match, history }) {
   return (dispatch, getState) => {
      if (!nodeKey) {
         dispatch({
            type: SELECT_MENU,
            payload: null,
         });
      } else if (nodeKey === '0') {
         dispatch({
            type: SELECT_MENU,
            payload: {
               id: '0',
               title: pack.servermsg()['menu.root'],
            },
         });
      } else {
         axios.get(`/menu/${nodeKey}`).then(res => {
            return dispatch({
               type: SELECT_MENU,
               payload: res.data,
            });
         });
      }
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'view',
         }),
      );
   };
}
