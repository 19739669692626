import { withFormsy } from 'formsy-react';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import _ from 'lodash';
import { pack } from '@gc';
import { Col, Label } from 'reactstrap';
import cx from 'classnames';
import axios from 'axios';
import { Typography } from '@material-ui/core';
import 'prismjs/themes/prism-coy.css';
import Prism from 'prismjs';

// require('tinymce/tinymce');
// require('tinymce/themes/silver');
// require('tinymce/plugins/advlist');
// require('tinymce/plugins/autolink');
// require('tinymce/plugins/lists');
// require('tinymce/plugins/link');
// require('tinymce/plugins/image');
// require('tinymce/plugins/charmap');
// require('tinymce/plugins/print');
// require('tinymce/plugins/paste');
// require('tinymce/plugins/preview');
// require('tinymce/plugins/anchor');
// require('tinymce/plugins/searchreplace');
// require('tinymce/plugins/visualblocks');
// require('tinymce/plugins/code');
// require('tinymce/plugins/fullscreen');
// require('tinymce/plugins/insertdatetime');
// require('tinymce/plugins/media');
// require('tinymce/plugins/table');
// require('tinymce/plugins/paste');
// require('tinymce/plugins/code');
// require('tinymce/plugins/codesample');
// require('tinymce/plugins/help');
// require('tinymce/plugins/wordcount');
// require('tinymce/icons/default');

const addImageBtnEvent = () => {
   const btns = document.getElementsByClassName('tox-tbtn');
   if (btns && btns.length > 0) {
      for (let i = 0; i < btns.length; i++) {
         const btn = btns[i];
         const title = btn.getAttribute('title');
         if (title && title.includes('image')) {
            btn.addEventListener('click', () => {
               setTimeout(() => {
                  const tabs = document.querySelectorAll('.tox-dialog__body [role=tablist] div');
                  if (tabs && tabs.length > 1) {
                     tabs[tabs.length - 1].click();
                  }
               }, 300);
            });
         }
      }
   } else {
      setTimeout(() => {
         addImageBtnEvent();
      }, 1000);
   }
};

const convertPastedImageToBlob = pasteEvent => {
   if (pasteEvent.clipboardData === false) {
      return false;
   }

   const items = pasteEvent.clipboardData.items;

   if (items === undefined) {
      return false;
   }

   for (let i = 0; i < items.length; i++) {
      // Only paste if image is only choice
      if (items[i].type.indexOf('image') === -1) {
         return false;
      }
      // Retrieve image on clipboard as blob
      const blob = items[i].getAsFile();

      // load image if there is a pasted image
      if (blob !== null) {
         const reader = new FileReader();
         reader.onload = function (e) {
            // console.log('result', e.target.result);
         };
         reader.readAsDataURL(blob);
         return blob;
      }
   }
   return false;
};

const uploadFile = (file, onSuccess) => {
   const fData = new FormData();
   fData.append('file', file);
   axios({
      method: 'post',
      url: '/upload',
      data: fData,
   })
      .then(res => {
         onSuccess(pack.serverImage(res.data.savename));
      })
      .catch(e => {
         pack.showError(e);
         console.log('upload error!!', e);
      });
   /*const xhr = new XMLHttpRequest();
   xhr.upload.onprogress = function (e) {
      const percentComplete = (e.loaded / e.total) * 100;
      console.log("Uploaded: " + percentComplete + "%");
   };

   xhr.onload = function () {
      if (xhr.status !== 200) {
         alert("Error! Upload failed " + xhr.response);
      }
      if (callback) {
         callback(JSON.parse(xhr.response));
      }
   };

   xhr.onerror = function () {
      alert("Error! Upload failed. Can not connect to server.");
   };

   xhr.open("POST", "/upload/tinymce", true);
   var data = new FormData();
   data.append('file', file);
   xhr.send(data);*/
};

class TinyMceFormsy extends Component {
   constructor(props) {
      super(props);
      this.state = {
         touched: false,
         content: props.value,
         contentError: '',
         lang: pack.language,
         strings: pack.strings,
         init: {
            height: props.height || 400,
            width: '100%',
            plugins: ['link', 'image', 'media', 'code', 'codesample', 'lists', 'table', 'emoticons', 'powerpaste', 'editimage', 'tableofcontents'], // powerpaste 추가시 import 에 paste 주석처리.
            powerpaste_allow_local_images: true,
            powerpaste_word_import: 'prompt',
            powerpaste_html_import: 'prompt',
            // statusbar: false,  // footer 숨기기.
            menubar: false,
            branding: false, // Powered by Tiny... 제거.
            skin_url: '/static/tinymce/skins/ui/oxide',
            content_style:
               "@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Gugi&family=Hi+Melody&family=Jua&family=Nanum+Gothic:wght@400;700;800&family=Nanum+Myeongjo:wght@400;700;800&family=Nanum+Pen+Script&family=Noto+Sans+KR:wght@400;500;700;900&display=swap'); body { font-family: Noto Sans KR, sans-serif; font-size: 12px; line-height: 1.4; } p { margin: 0 }",
            // content_css: '/static/tinymce/skins/content/default/content.min.css',
            toolbar:
               'undo redo | blocks fontsize fontfamily | forecolor backcolor | bold italic underline strikethrough align | image media | ' +
               'link table | numlist bullist outdent indent | template code codesample | emoticons checklist tableofcontents',
            fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 30px 36px',
            /* 글씨체 : http://ninanio3.tistory.com/entry/TinyMCE-Advanced-%ED%95%9C%EA%B8%80%EA%B8%80%EA%BC%B4-%EC%B6%94%EA%B0%80 */
            font_family_formats: `${
               props.lang === 'ko'
                  ? '노토산스=Noto Sans KR;나눔고딕=Nanum Gothic;나눔명조=Nanum Myeongjo;한스=Black Han Sans;주아=Jua;멜로디=Hi Melody;구기=Gugi;나눔펜=Nanum Pen Script;'
                  : ''
            }Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace`,
            // paste_as_text: true,
            paste_data_images: true,
            image_advtab: true,
            // images_upload_url: `${pack.serverPrefix}/upload`,
            images_upload_handler: (blobInfo, progress) =>
               new Promise(resolve => {
                  const formData = new FormData();
                  formData.append('file', blobInfo.blob());
                  axios
                     .post('/upload', formData, {
                        onUploadProgress: e => {
                           progress(Math.round((100 * e.loaded) / e.total));
                        },
                     })
                     .then(res => {
                        resolve(pack.serverImage(res.data.savename));
                     })
                     .catch(pack.showError);
               }),
            /*images_upload_handler: (blobInfo, success, failur) => {
               const fData = new FormData();
               fData.append('file', blobInfo.blob());
               axios({
                  method: 'post',
                  url: '/upload',
                  data: fData,
               })
                  .then(res => {
                     success(pack.serverImage(res.data.savename));
                  })
                  .catch(e => {
                     pack.showError(e);
                     console.log('upload error!!', e);
                  });
            },*/
            // 붙여넣은 이미지는 기본적으로 Base64 이미지가 되는데, 업로드방식으로 변경처리
            init_instance_callback: editor => {
               editor.on('paste', ev => {
                  const imageBlob = convertPastedImageToBlob(ev);
                  if (!imageBlob) {
                     return;
                  }
                  ev.preventDefault();
                  uploadFile(imageBlob, url => {
                     editor.insertContent(`<img src="${url}" />`);
                  });
               });
            },
         },
      };
   }

   componentDidMount() {
      const elem = document.getElementById(`${this.props.id || this.props.name}_content`);
      if (elem instanceof Element) elem.innerHTML = this.state.content;
      /* 이미지 버튼 클릭후 뜨는 다이얼로그에서 업로드탭을 강제로 클릭한다 */
      setTimeout(() => {
         addImageBtnEvent();
      }, 1000);

      if (this.props.viewmode) {
         Prism.highlightAll();
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.lang !== this.props.lang) {
         this.setState({
            init: {
               ...this.state.init,
               font_formats: `${
                  this.props.lang === 'ko'
                     ? '노토산스=Noto Sans KR;나눔고딕=Nanum Gothic;나눔명조=Nanum Myeongjo;한스=Black Han Sans;주아=Jua;멜로디=Hi Melody;구기=Gugi;나눔펜=Nanum Pen Script;'
                     : ''
               }Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace`,
            },
         });
      }
      if (prevProps.viewmode !== this.props.viewmode) {
         this.setState({
            touched: false,
         });

         if (this.props.viewmode) {
            setTimeout(() => Prism.highlightAll(), 100);
         }
      }

      if (this.props.viewmode && prevState.content !== this.state.content) {
         setTimeout(() => Prism.highlightAll(), 100);
      }

      const id = this.props.id || this.props.name;
      const contentElem = document.getElementById(`${id}_content`);
      if (contentElem instanceof Element && this.props.viewmode && !contentElem.innerText && this.state.content) {
         contentElem.innerHTML = this.state.content;
      }

      if (prevProps.updateContent !== this.props.updateContent && this.props.updateContent === true) {
         if (contentElem instanceof Element) contentElem.innerHTML = this.props.value;
         this.setState({
            content: this.props.value,
         });
         if (!this.props.viewmode && this.editor.editor) this.editor.editor.setContent(this.props.value);
      }

      /*if (prevProps.readonly !== this.props.readonly) {
         this.editor.editor.setMode(this.props.readonly ? 'readonly' : 'design');
      }*/
   }

   changeValue = value => {
      // const value = e.target.getContent();
      if (!this.props.noUpdate) {
         this.setState({
            content: value,
         });
         this.props.setValue(value);
      }
      if (typeof this.props.onChange === 'function') {
         this.props.onChange(value);
      }
   };

   onBlur = evt => {
      if (this.props.viewmode) return false;
      if (!this.state.touched) this.setState({ touched: true });
      if (typeof this.props.onBlur === 'function') {
         this.props.onBlur(evt);
      }
   };

   handleRef = editor => {
      this.editor = editor;
      if (typeof this.props.editorRef === 'function') {
         this.props.editorRef(editor);
      }
   };

   render() {
      const { touched } = this.state;
      let importedProps = _.pick(this.props, ['activeClass', 'config', 'isScriptLoaded', 'scriptUrl', 'disabled']);
      let error = false;
      let errorMessage = this.props.getErrorMessage() || this.props.errorTrigger;
      const value = (!this.props.noUpdate ? this.props.getValue() : this.props.value) || '';

      if (!importedProps.type) {
         importedProps.type = 'text';
      }

      if (touched && !value && !errorMessage && this.props.required) {
         errorMessage = this.props.requiredMsg || '';
         error = true;
      }

      if (this.props.matching) {
         const func = pack.getMatchingFunction(this.props.matching);
         if (func != null)
            importedProps = {
               ...importedProps,
               ...func,
            };
         else {
            console.log("No Matching Functions for '" + this.props.matching + "'");
         }
      }

      const labelWidth = this.props.labelWidth || { sm: 2 };
      const colWidth = this.props.colWidth || { sm: 4 };
      const id = this.props.id || this.props.name;
      // importedProps.id = id;

      return (
         <>
            {Boolean(this.props.label) && (
               <Label
                  {...labelWidth}
                  for={id}
                  className={cx({
                     required: !this.props.viewmode && this.props.required,
                  })}
               >
                  {this.props.label}
               </Label>
            )}
            <Col {...colWidth} className={this.props.colClassName || ''}>
               {!this.props.viewmode ? (
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                     }}
                     className="w-100"
                  >
                     <Editor
                        {...importedProps}
                        // tinymceScriptSrc="https://cdn.tiny.cloud/1/qsvx6v6ew1xaqp4m5qxcjovrmqwtvazv7q85yywn2emxeh6h/tinymce/5/tinymce.min.js"
                        apiKey={process.env.REACT_APP_TINYMCE_KEY}
                        value={this.state.content}
                        ref={this.handleRef}
                        init={{ ...this.state.init, ...this.props.init }}
                        onEditorChange={this.changeValue}
                        onBlur={this.onBlur}
                     />
                     {Boolean(errorMessage) && (
                        <Typography color="error" variant="caption">
                           {errorMessage}
                        </Typography>
                     )}
                  </div>
               ) : (
                  <Label
                     className="view w-100"
                     id={`${id}_content`}
                     style={{
                        maxHeight: this.props.maxHeight || 'inherit',
                        overflow: 'auto',
                     }}
                  />
               )}
            </Col>
         </>
      );
   }
}

function mapStateToProps({ gc }) {
   return {
      lang: gc.common.lang,
   };
}

TinyMceFormsy.propTypes = {
   name: PropTypes.string.isRequired,
   id: PropTypes.string,
   label: PropTypes.string,
   onChange: PropTypes.func,
   onBlur: PropTypes.func,
   value: PropTypes.string,
   viewmode: PropTypes.bool,
   required: PropTypes.bool,
   requiredMsg: PropTypes.string,
   errorTrigger: PropTypes.string,
   matching: PropTypes.any,
   validations: PropTypes.any,
   validationErrors: PropTypes.object,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   disabled: PropTypes.bool,
   readonly: PropTypes.bool,
   init: PropTypes.object,
   height: PropTypes.number,
   maxHeight: PropTypes.number,
   updateContent: PropTypes.bool,
   editorRef: PropTypes.func,
   colClassName: PropTypes.string,
   noUpdate: PropTypes.bool,
};

export default withFormsy(connect(mapStateToProps, null)(TinyMceFormsy));
