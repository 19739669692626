import React from 'react';
import {withRouter} from 'react-router-dom';
import {Fab, Icon} from '@material-ui/core';
import '../style/NotFound.css';

const NotFound = (props) => {
   const goBack = () => props.history.goBack();

   return (
      <div id="not-found" className="not-found w-100 h-100 d-flex align-items-center justify-content-center">
         <Fab
            variant="extended"
            className="px-5 py-2"
            onClick={goBack}
            style={{
               marginTop: 306,
               height: 60
            }}
            color="primary"
         >
            <Icon className="mr-2">arrow_back</Icon>
            <span className="go-back">Go Back</span>
         </Fab>
      </div>
   );
};

export default withRouter(NotFound);