import { FETCH_FORMAT, FETCH_FORMATS, FETCH_SIGN, FETCH_SIGN_LOADING, FETCH_SIGNS, UPDATE_CONDITIONS, UPDATE_PAGE_OPTION } from '../actions';
import _ from 'lodash';

const initState = {
   fetching: false,
   formats: [],
   curFormat: 'all',
   signs: [],
   total: 0,
   sign: {
      employee: {},
      attachs: [],
      lines: [],
      refs: [],
      comments: [],
   },
   format: {},
   viewLoading: false,
   defaultPageOption: {
      page: 0,
      size: 50,
      sorted: null,
   },
   pageOption: {
      page: 0,
      size: 50,
   },
   conditions: [],
};

const signReducer = (state = initState, action) => {
   switch (action.type) {
      case FETCH_FORMATS:
         return {
            ...state,
            formats: _.sortBy(action.payload, 'codeDesc'),
         };
      case FETCH_FORMAT:
         return {
            ...state,
            format: action.payload,
         };
      case FETCH_SIGNS:
         return {
            ...state,
            signs: action.payload.list,
            total: action.payload.total,
         };
      case FETCH_SIGN:
         const sign = action.payload;
         return {
            ...state,
            sign: action.payload,
            refs: sign.refs.map(ref => ({
               id: `e_${ref.employee.empno}`,
               avatar: ref.employee.avatar,
               title: ref.employee.name,
               position: ref.employee.positionDesc || '',
               deptname: (ref.employee.department || {}).deptname,
            })),
            lines: sign.lines.map(line => ({
               id: `e_${line.employee.empno}`,
               avatar: line.employee.avatar,
               title: `${line.employee.name} ${line.employee.positionDesc || ''}`,
               type: line.type,
            })),
         };
      case FETCH_SIGN_LOADING:
         return {
            ...state,
            viewLoading: action.flag,
         };
      case UPDATE_PAGE_OPTION:
         return {
            ...state,
            pageOption: action.payload,
         };
      case UPDATE_CONDITIONS:
         return {
            ...state,
            conditions: action.conditions,
         };
      default:
         return state;
   }
};

export default signReducer;
