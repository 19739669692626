import axios from 'axios';

export const FETCH_NOTICES = '[HOME] FETCH_NOTICES';

export function fetchNotices() {
   return dispatch => {
      axios.get('/board/notice').then(res =>
         dispatch({
            type: FETCH_NOTICES,
            payload: res.data,
         }),
      );
   };
}
