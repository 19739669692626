import axios from 'axios';
import {startLoading, stopLoading} from "@gc/common/actions/common.action";
import {pack} from "@gc";
import _ from "lodash";

const jtf = require('json-form-data');

export const FETCH_POINT_TYPES = '[POINT] FETCH POINT TYPES';
export const FETCH_POINT_TYPE = '[POINT] FETCH POINT TYPE';
export const OPEN_POINT_TYPE_ADD = '[POINT] OPEN POINT TYPE ADD';
export const OPEN_POINT_TYPE_EDIT = '[POINT] OPEN POINT TYPE EDIT';
export const OPEN_POINT_TYPE_LIST = '[POINT] OPEN POINT TYPE LIST';

export function fetchPointTypes({history, match}) {
   return (dispatch) => {
      dispatch(startLoading());
      const request = axios.get('/point/types');
      const {params: {id}} = match;
      request.then(res => {
         dispatch(stopLoading());
         dispatch({
            type: FETCH_POINT_TYPES,
            payload: res.data
         });
         if (Boolean(id)) {
            dispatch(fetchPointType(id));
         }
      });
   }
}

export function fetchPointType(id) {
   return (dispatch, getState) =>
      dispatch({
         type: FETCH_POINT_TYPE,
         payload: getState().griffin.admin.point.entities[id]
      });
}

export function openPointAdd({match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_POINT_TYPE_ADD
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'add'
      }));
   };
}

export function openPointList({match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_POINT_TYPE_LIST
      });
      history.push(pack.getToPath(match)({
         ..._.omit(match.params, 'id'),
         mode: 'list'
      }));
   };
}

export function openPointView(id, {match, history}) {
   return (dispatch) => {
      dispatch(fetchPointType(id));
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         id
      }));
   };
}

export function openPointEdit(row, {match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_POINT_TYPE_EDIT,
         payload: row
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'edit'
      }));
   };
}

export function createPointType(data, {match, history}) {
   const request = axios({
      method: 'post',
      url: '/point/type',
      data: jtf(data)
   });

   return (dispatch) => request.then(res => {
      dispatch(fetchPointTypes({history, match}));
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'list'
      }));
   });
}

export function updatePointType(data, {match, history}) {
   const request = axios({
      method: 'post',
      url: '/point/type/update',
      data: jtf(data)
   });

   return (dispatch) => request.then(res => {
      dispatch(fetchPointTypes({history, match}));
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'list'
      }));
   });
}




