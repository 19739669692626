import * as Actions from "../actions";
import _ from 'lodash';

const initState = {
   entities: [],
   entity: {
      seq: '',
      clients: [],
      securities: []
   },
   clientOpen: false,
   client: {
      company: {},
      member: {}
   },
   secuCodes: []
};

const companyReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_COMPANYS:
         return {
            ...state,
            entities: action.payload
         };
      case Actions.FETCH_COMPANY:
         return {
            ...state,
            entity: Boolean(action.seq) ? _.find(state.entities, {seq: +(action.seq)}) : state.entity
         };
      case Actions.FETCH_SECU_CODES:
         return {
            ...state,
            secuCodes: action.payload
         };
      case Actions.OPEN_ADD:
         return {
            ...state,
            entity: initState.entity
         };
      case Actions.OPEN_EDIT:
         return {
            ...state,
            entity: action.data
         };
      case Actions.OPEN_VIEW:
         return {
            ...state,
            entity: action.data
         };
      case Actions.OPEN_CLIENT:
         return {
            ...state,
            clientOpen: true,
            client: action.payload
         };
      case Actions.CLOSE_CLIENT:
         return {
            ...state,
            clientOpen: false,
            client: { ...initState.client }
         };
      default:
         return state;
   }
};

export default companyReducer;