import _ from 'lodash';
import * as Actions from "../actions";

const initState = {
   entities: [],
   companys: [],
   entity: {
      islogin: '0',
      isdelete: '0',
      isretire: '0',
      member: {},
      company: {}
   }
};

const clientReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_CLIENTS:
         return {
            ...state,
            entities: action.payload
         };
      case Actions.FETCH_CLIENT:
         return {
            ...state,
            entity: _.find(state.entities, {seq: +(action.seq)})
         };
      case Actions.FETCH_COMPANYS:
         return {
            ...state,
            companys: action.payload
         };
      case Actions.OPEN_ADD:
         return {
            ...state,
            entity: initState.entity
         };
      case Actions.OPEN_EDIT:
      case Actions.OPEN_VIEW:
         return {
            ...state,
            entity: action.data
         };
      default:
         return state;
   }
};

export default clientReducer;