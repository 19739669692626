import axios from 'axios';
import { pack } from '../../../../../@gc';

export const FETCH_SUGGESTIONS = '[EMP SELECT] FETCH SUGGESTIONS';
export const TOGGLE_TREE = '[EMP SELECT] TOGGLE TREE';
export const FETCH_TREE = '[EMP SELECT] FETCH TREE';
export const LOADING_SUGGEST = '[EMP SELECT] LOADING SUGGEST';
export const LOADING_TREE = '[EMP SELECT] LOADING TREE';

export function fetchEmpSelector() {
   return dispatch => {
      dispatch(fetchSuggestions());
      dispatch(fetchTree());
   };
}

export function fetchSuggestions() {
   return (dispatch, getState) => {
      if (getState().griffin.common.empselect.loadingSuggest) {
         console.log('이미 Suggest 로딩중');
         return;
      }
      dispatch({
         type: LOADING_SUGGEST,
         flag: true,
      });
      axios
         .get('/employee')
         .then(res => {
            dispatch({
               type: FETCH_SUGGESTIONS,
               payload: res.data,
            });
         })
         .catch(pack.showError)
         .finally(() =>
            dispatch({
               type: LOADING_SUGGEST,
               flag: false,
            }),
         );
   };
}

export function fetchTree() {
   return (dispatch, getState) => {
      if (getState().griffin.common.empselect.loadingTree) {
         console.log('이미 Tree 로딩중');
         return;
      }

      dispatch({
         type: LOADING_TREE,
         flag: true,
      });

      axios
         .get(`/department/emp/tree?lang=${getState().gc.common.lang}`)
         .then(res =>
            dispatch({
               type: FETCH_TREE,
               tree: res.data.tree,
               list: res.data.list,
            }),
         )
         .catch(pack.showError)
         .finally(() =>
            dispatch({
               type: LOADING_TREE,
               flag: false,
            }),
         );
   };
}

export function openTree(initKeys, id) {
   return {
      type: TOGGLE_TREE,
      flag: true,
      initKeys,
      id,
   };
}

export function closeTree() {
   return {
      type: TOGGLE_TREE,
      flag: false,
      id: null,
   };
}
