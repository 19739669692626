import {withFormsy} from 'formsy-react';
import React, {Component} from 'react';
import PropTypes from 'prop-types';

class HiddenFormsy extends Component {

   render() {
      let errorMessage = this.props.getErrorMessage() || this.props.errorTrigger;
      const value = this.props.getValue() || '';

      if (!value && !errorMessage && this.props.required) {
         errorMessage = this.props.requiredMsg || 'Required';
      }

      if (Boolean(errorMessage) && typeof this.props.onError === 'function') {
         this.props.onError(errorMessage);
      }
      if (this.props.errorPlacement) {
         const errorElem = document.getElementById(this.props.errorPlacement.replace(/^#/g, ''));
         if(errorElem instanceof Element) {
            if (Boolean(errorMessage)) {
               errorElem.innerHTML = errorMessage;
               errorElem.style.color = '#f44336';
            } else {
               errorElem.innerHTML = '';
            }
         }
      }

      return (
         <input
            type="hidden"
            name={this.props.name}
            value={value}
         />
      );
   }
}

HiddenFormsy.propTypes = {
   name: PropTypes.string.isRequired,
   value: PropTypes.any.isRequired,
   onError: PropTypes.func,
   required: PropTypes.bool,
   errorPlacement: PropTypes.string,
   errorTrigger: PropTypes.string
};

export default withFormsy(HiddenFormsy)