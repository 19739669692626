import axios from 'axios';
import { pack } from '@gc';
import { loadNavigation } from '../../navigation/actions';
import { notify } from '../../../@griffin/common/components/notify/actions';
import { uuid } from 'uuidv4';
import notifySignIcon from '../../../assets/utils/images/icons/notify_approve.png';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { logoutUser } from '../../auth/actions/user.action';

export const FETCH_SERVER_PROPS = '[COMMON APP] FETCH SERVER PROPS';
export const SESSION_TIMEOUT_TOGGLE = '[COMMON APP] SESSION TIMEOUT TOGGLE';
export const TOGGLE_SHOW_FILTER = '[COMMON APP] TOGGLE SHOW FILTER';
export const FETCH_AUTH_MENU_LIST = '[COMMON APP] FETCH_AUTH_MENU_LIST';
export const UPDATE_MAIL_COUNT = '[COMMON APP] UPDATE MENU MAIL COUNT';
export const UPDATE_SIGN_COUNT = '[COMMON APP] UPDATE MENU SIGN COUNT';
export const LOADING = '[COMMON APP] STOP LOADING';
export const LOADED = '[COMMON APP] STOP LOADED';
export const OVERLAY_START = '[COMMON APP] OVERLAY START';
export const OVERLAY_STOP = '[COMMON APP] OVERLAY STOP';
export const AFTER_LOGIN_SUCCESS = '[COMMON APP] AFTER LOGIN SUCCESS';
export const PASSWORD_EXPIRED = '[COMMON APP] PASSWORD EXPIRED';
export const SET_CURRENT_MENU_ITEM = '[COMMON APP] SET CURRENT MENU ITEM';
export const CHANGE_STRINGS = '[COMMON APP] CHANGE STRINGS';
export const UPDATE_STRINGS = '[COMMON APP] UPDATE STRINGS';
export const CHANGE_LOCALE = '[COMMON APP] CHANGE_LOCALE';
export const ADD_PUSH = '[COMMON APP] ADD PUSH';
export const ADD_EVENT = '[COMMON APP] ADD EVENT';
export const ADD_SYSLOG = '[COMMON APP] ADD SYSLOG';
export const USER_MENU_LOADED = '[COMMON APP] USER MENU LOADED';
export const OPEN_THEME_SETTINGS = '[COMMON APP] OPEN THEME SETTINGS';
export const CLOSE_THEME_SETTINGS = '[COMMON APP] CLOSE THEME SETTINGS';
export const UPDATE_SIGN_LIST = '[COMMON APP] UPDATE SIGN LIST';
export const TOGGLE_FULLSCREEN = '[COMMON APP] TOGGLE FULLSCREEN';
export const SET_RELOADABLE = '[COMMON APP] SET RELOADABLE';
export const SET_CURRENT_EMAIL = '[COMMON APP] SET CURRENT EMAIL';
export const UPDATE_REF_SIGN_COUNT = '[COMMON APP] UPDATE_REF_SIGN_COUNT';
export const SET_CONNECT_INFO = '[COMMON APP] SET CONNECT INFO';
export const SET_FINISH_INFO = '[COMMON APP] SET FINISH INFO';
export const GEOLOCATION_DENIED = '[COMMON APP] GEOLOCATION DENIED';

export const GEO_REASON_NOT_SET = -1;
export const GEO_REASON_GRANTED = 0;
export const GEO_REASON_DENIED = 1;
export const GEO_REASON_NOT_SUPPORTED = 2;

/*export function setCurrentEmail(address) {
   return (dispatch, getState) => {
      if (getState().gc.common.email !== address) {
         dispatch({
            type: SET_CURRENT_EMAIL,
            address,
         });
      }
   };
}*/

/*export function resetConnectInfo() {
   sessionStorage.removeItem('conn');
   sessionStorage.removeItem('finn');
   return dispatch =>
      dispatch({
         type: SET_CONNECT_INFO,
         payload: undefined,
      });
}*/

/*export function setLocationDenied(reason) {
   return dispatch =>
      dispatch({
         type: GEOLOCATION_DENIED,
         reason,
      });
}*/

/*export function setConnectInfo() {
   return dispatch => {
      sessionStorage.removeItem('conn');

      axios
         .get('https://ipapi.co/json', {
            withCredentials: false,
            baseURL: '',
         }) // ipapi 지원중단시 대체 API : https://www.abstractapi.com/api/ip-geolocation-api  (구글에서도 사용하는 유료 API 임)
         .then(res => {
            const info = _.pick(res.data, ['ip']);
            if (navigator.geolocation) {
               navigator.geolocation.getCurrentPosition(
                  position => {
                     info.latitude = position.coords.latitude;
                     info.longitude = position.coords.longitude;
                     dispatch(postConnectInfo(info));
                     dispatch(setLocationDenied(GEO_REASON_GRANTED));
                  },
                  error => {
                     if (error.code === error.PERMISSION_DENIED) {
                        window.alert('위치 권한이 허용되지 않았습니다');
                        dispatch(setLocationDenied(GEO_REASON_DENIED));
                     } else {
                        console.log('geolocation error=', error);
                        toastr.error('알림', `위치 권한에 접근중 오류 발생 (코드: ${error.code})`);
                        dispatch(logoutUser());
                        /!*info.latitude = res.data.latitude;
                        info.longitude = res.data.longitude;
                        dispatch(postConnectInfo(info));*!/
                     }
                  },
               );
            } else {
               toastr.info('알림', '지원되지 않는 브라우저입니다');
               dispatch(logoutUser());
               dispatch(setLocationDenied(GEO_REASON_NOT_SUPPORTED));
               // info.latitude = res.data.latitude;
               // info.longitude = res.data.longitude;
               // dispatch(postConnectInfo(info));
            }
         })
         .catch(pack.showError);
   };
}*/

export function setFinishInfo(info, onAddressed) {
   return dispatch =>
      getAddressFromLatLng(info.latitude, info.longitude, (result, status) => {
         // eslint-disable-next-line no-undef
         if (status === kakao.maps.services.Status.OK) {
            info.address = result[0].road_address?.address_name || result[0].address.address_name;
            onAddressed(info);
            dispatch({
               type: SET_FINISH_INFO,
               payload: info,
            });
         }
      });
}

/*function postConnectInfo(info) {
   return dispatch => {
      if (info) {
         getAddressFromLatLng(info.latitude, info.longitude, (result, status) => {
            // eslint-disable-next-line no-undef
            if (status === kakao.maps.services.Status.OK) {
               info.address = result[0].road_address?.address_name || result[0].address.address_name;
               dispatch({
                  type: SET_CONNECT_INFO,
                  payload: info,
               });
            }
         });
      }
   };
}*/

export const getAddressFromLatLng = (lat, lng, callback) => {
   // eslint-disable-next-line no-undef
   const geocoder = new kakao.maps.services.Geocoder();
   if (geocoder) {
      geocoder.coord2Address(lng, lat, callback);
   }
   return '';
};

export function fetchServerProps() {
   const request = axios.get('/properties');

   return dispatch =>
      request.then(res => {
         dispatch({
            type: FETCH_SERVER_PROPS,
            payload: res.data,
         });
      });
}

export function updateStrings(reloadNavigation) {
   return dispatch => {
      dispatch({
         type: UPDATE_STRINGS,
      });
      if (reloadNavigation) {
         dispatch(loadNavigation(true));
      }
   };
}

export function changeStrings(strings) {
   return {
      type: CHANGE_STRINGS,
      payload: strings,
   };
}

export function offTimeout() {
   return {
      type: SESSION_TIMEOUT_TOGGLE,
      enable: false,
   };
}

export function onTimeout() {
   return {
      type: SESSION_TIMEOUT_TOGGLE,
      enable: true,
   };
}

export function toggleShowFilter(event, isShow) {
   return {
      type: TOGGLE_SHOW_FILTER,
      payload: event ? event.target.checked : isShow,
   };
}

export function fetchMenuTreeList(showLoading) {
   return (dispatch, getState) => {
      // if(showLoading)
      //    dispatch(startLoading());

      axios.get(`/menu/auth/treelist`).then(res => {
         dispatch({
            type: FETCH_AUTH_MENU_LIST,
            tree: res.data.tree,
            list: res.data.list,
         });
         // dispatch(stopLoading());
      });
   };
}

export function setCurrentMenuItem(breadcrumb) {
   return {
      type: SET_CURRENT_MENU_ITEM,
      payload: breadcrumb,
   };
}

export function updateMailCnt(count) {
   return {
      type: UPDATE_MAIL_COUNT,
      count,
   };
}

export function updateSignCnt(count) {
   return {
      type: UPDATE_SIGN_COUNT,
      count,
   };
}

export function stopLoading() {
   return {
      type: LOADED,
   };
}

export function startLoading() {
   return {
      type: LOADING,
   };
}

/*export function afterLoginSuccess(data) {
   return {
      type: AFTER_LOGIN_SUCCESS,
      payload: data
   };
}*/

export function startOverlay(text) {
   return {
      type: OVERLAY_START,
      text,
   };
}

export function stopOverlay() {
   return {
      type: OVERLAY_STOP,
   };
}

export function passwordExpired(isExpired) {
   return {
      type: PASSWORD_EXPIRED,
      payload: isExpired,
   };
}

export function changeLocale(lang) {
   const newStrings = pack.changeLocale(lang);
   return dispatch => {
      dispatch({
         type: CHANGE_LOCALE,
         payload: newStrings,
         lang,
      });
      dispatch(loadNavigation(true));
   };
}

export function addPush(msg) {
   return dispatch => {
      dispatch({
         type: ADD_PUSH,
         msg,
      });
   };
}

export function addEvent(msg, eventType) {
   return dispatch => {
      dispatch({
         type: ADD_EVENT,
         msg,
         eventType,
      });
   };
}

export function addSyslog(msg, logType) {
   return dispatch => {
      dispatch({
         type: ADD_SYSLOG,
         msg,
         logType, // info, error, warning, success
      });
   };
}

export function userMenuLoaded() {
   return {
      type: USER_MENU_LOADED,
   };
}

export function openThemeSetting() {
   return {
      type: OPEN_THEME_SETTINGS,
   };
}

export function closeThemeSetting() {
   return {
      type: CLOSE_THEME_SETTINGS,
   };
}

export function updateSignList(list) {
   return (dispatch, getState) => {
      const { signReady, mySignList, strings } = getState().gc.common;
      if (signReady && mySignList.length !== list.length) {
         const newList = list.filter(sign => !mySignList.includes(sign.seq));
         newList.forEach(sign => {
            dispatch(
               notify({
                  ...pack.options.notify,
                  title: sign.regName,
                  body: `${sign.subject} (${pack.timestampToString(sign.regDate, true, false, true)})`,
                  icon: notifySignIcon,
                  tag: uuid(),
               }),
            );
            dispatch(addEvent(strings.pushNewSign, 'info'));
         });
      }

      dispatch({
         type: UPDATE_SIGN_LIST,
         payload: list.map(sign => sign.seq),
      });
   };
}

export function toggleFullscreen(path) {
   return dispatch =>
      dispatch({
         type: TOGGLE_FULLSCREEN,
         path,
      });
}

export function setReloadable(flag) {
   return {
      type: SET_RELOADABLE,
      flag,
   };
}

export function updateRefSignCount(count) {
   return {
      type: UPDATE_REF_SIGN_COUNT,
      count,
   };
}
