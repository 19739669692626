import axios from 'axios';
import {startLoading, stopLoading} from "../../../../@gc/common/actions/common.action";
import {fetchSuggestions} from "../../mailbox/actions";
import _ from 'lodash';
import {pack} from "@gc";
import {toastr} from 'react-redux-toastr';

const jtf = require('json-form-data');

export const SAVE_CONTACTS = '[CONTACTS] SAVE CONTACTS';
export const OPEN_LIST = '[CONTACTS] OPEN LIST';
export const OPEN_ADD = '[CONTACTS] OPEN ADD';
export const OPEN_EDIT = '[CONTACTS] OPEN EDIT';
export const FETCH_LIST = '[CONTACTS] FETCH LIST';
export const REMOVE_ENTITY = '[CONTACTS] REMOVE ENTITY';
export const FETCH_DEPT_TREE = '[CONTACTS] FETCH DEPT TREE';
export const FETCH_FOLDER_TREE = '[CONTACTS] FETCH FOLDER TREE';
export const FETCH_SHARED_LIST = '[CONTACTS] FETCH SHARED LIST';
export const FETCH_EMPLOYEES = '[CONTACTS] FETCH EMPLOYEES';
export const FETCH_CONTACTS = '[CONTACTS] FETCH CONTACTS';
export const FETCH_CONTACT = '[CONTACTS] FETCH CONTACT';
export const SELECT_FOLDER = '[CONTACTS] SELECT FOLDER';
export const CREATE_CONTACTS = '[CONTACTS] CREATE CONTACTS';
export const REMOVE_CONTACTS = '[CONTACTS] REMOVE CONTACTS';
export const OPEN_SHARE_DIALOG = '[CONTACTS] OPEN SHARE DIALOG';
export const CLOSE_SHARE_DIALOG = '[CONTACTS] CLOSE SHARE DIALOG';
export const FETCH_TARGET_TREE = '[CONTACTS] FETCH TARGET TREE';

export const CONTACTS_SHARE_TYPE_FOLDER = '0';
export const CONTACTS_SHARE_TYPE_CONTACT = '1';
export const OPEN_FOLDER_ID = 'OPENS';


export function fetchDeptList() {
   const request = axios.get('/contacts/depttree');

   return (dispatch) => request.then(res => {
      const {data} = res;
      dispatch({
         type: FETCH_DEPT_TREE,
         deptTree: data.tree,
         deptList: data.list
      });
   });
}

export function fetchFolders() {
   return (dispatch, getState) => {
      axios.get(`/contacts/foldertree/${getState().gc.user.empno}?lang=${getState().gc.common.lang}`).then(res => {
         dispatch({
            type: FETCH_FOLDER_TREE,
            folderTree: res.data.tree,
            folderList: res.data.list
         });
      })
   }
}

export function fetchSharedList() {
   return (dispatch, getState) => {
      axios.get(`/contacts/sharetree/${getState().gc.user.empno}?lang=${getState().gc.common.lang}`)
         .then(res => {
            dispatch({
               type: FETCH_SHARED_LIST,
               sharedTree: res.data.tree,
               sharedList: res.data.list
            });
         });
   }
}

export function fetchEmployees(deptcode, showLoading) {
   return (dispatch) => {
      if(!showLoading)
         dispatch(stopLoading());
      else
         dispatch(startLoading());

      const request = axios.get(`/contacts/employees${deptcode ? '/' + deptcode : ''}`);

      request.then(res => {
            dispatch(stopLoading());
            return dispatch({
               type: FETCH_EMPLOYEES,
               payload: res.data
            });
         }
      );
   }
}

export function fetchContacts(folderId, showLoading, isShare, props) {
   return (dispatch, getState) => {
      if(showLoading)
         dispatch(startLoading());
      // const suburl = !isShare ? 'list/' + (getState().gc.user.id + (folderId && String(folderId) !== '0' ? '/' + folderId : '')) :
      //    `shared-list/${folderId}/${getState().gc.user.id}`;
      const suburl = !isShare ? 'list/' + (getState().gc.user.empno + (folderId && String(folderId) !== '0' ? '/' + folderId : '')) :
         `opens/${getState().gc.user.empno}`;

      const request = axios.get(`/contacts/${suburl}`);

      request.then(res => {
         dispatch(stopLoading());
         dispatch({
            type: FETCH_CONTACTS,
            payload: res.data
         });

         if (props && Boolean(props.match.params.seq)) {
            // dispatch(fetchContact(props.match.params.seq));
            const {history, match} = props;
            history.push(pack.getToPath(match)({
               ...match.params,
               mode: 'list',
               seq: null
            }));
         }
      });
   }
}

export function fetchContact(seq) {
   return {
      type: FETCH_CONTACT,
      seq
   };
}

export function fetchContactList(showLoading) {
   return (dispatch, getState) => {
      if (!showLoading)
         dispatch(stopLoading());
      else
         dispatch(startLoading());

      axios.get(`/contacts/contact-list/${getState().gc.user.empno}`)
         .then(res => {
            dispatch(stopLoading());
            return dispatch({
               type: FETCH_CONTACTS,
               payload: res.data
            });
         });
   };
}

export function openAdd({match, history}) {
   return (dispatch, getState) => {
      dispatch({
         type: OPEN_ADD
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'add'
      }));
   };
}

export function openView(data, {match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_EDIT,
         entity: data
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         seq: data.seq
      }));
   };
}

export function selectFolder(id, props, openList) {
   return (dispatch, getState) => {
      if(id != null) {
         const prefix = id.substring(0, 2);
         const realId = id.substring(2);

         if (prefix === 'd_') {  // 부서[d(ept)] 선택시
            dispatch(fetchEmployees(realId && realId !== '0' ? realId : null, true));
         } else if (prefix === 'm_' || prefix === 's_') {   // 주소함[m(ine)] 혹은 공유함[s(hare)] 선택시
            dispatch(fetchContacts(realId && realId !== '0' ? realId : (prefix === 'm_' ? null : realId), true, prefix === 's_', props));
         } else {
            dispatch(fetchContactList());
         }
         dispatch({
            type: SELECT_FOLDER,
            payload: id
         });
      } else {
         dispatch(fetchContacts(null, true, true, props));
         dispatch({
            type: SELECT_FOLDER,
            payload: OPEN_FOLDER_ID
         });
      }

      if (openList && props && props.match.params.mode !== 'list') {
         const {match, history} = props;
         history.push(pack.getToPath(match)({
            ...match.params,
            mode: 'list',
            seq: null
         }));
      }
   };
}

export function updateFolder(data) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: '/contacts/folder',
         data: jtf(data)
      }).then(res => {
         dispatch(fetchFolders());
      })
   }
}

export function removeFolder(seq) {
   return (dispatch) =>
      axios.delete(`/contacts-folder/${seq}`)
         .then(res =>
            dispatch(fetchFolders())
         );
}

export function removeContacts(seq, {match, history}) {
   return (dispatch, getState) => {
      const folder = getState().griffin.activity.contacts.selectedFolder;
      let id = null;
      try {
         id = folder.id.substring(2);
      }catch(e) {}

      axios.delete(`/contacts/${seq}`)
         .then(res => {
            dispatch(fetchContacts(id));
            history.push(pack.getToPath(match)({
               ...match.params,
               mode: 'list',
               seq: null
            }));
         });
   }
}

export function updateContacts(data, props, fromMailbox) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: '/contacts',
         data: jtf({...data, userId: getState().gc.user.id})
      }).then(res => {
         if (fromMailbox) {
            dispatch(fetchSuggestions());
         } else {
            dispatch({
               type: SAVE_CONTACTS,
               payload: res.data
            });
         }

         /*if (data.ispublic === '1') {
            axios.get(`/employee/simple`).then(response => {
               dispatch(
                  shareContacts(
                     response.data.map(emp => emp.empno).filter(empno => empno !== getState().gc.user.empno)
                     , CONTACTS_SHARE_TYPE_CONTACT
                     , res.data.seq
                  )
               );
            });
         }*/

         if (props) {
            const folder = getState().griffin.activity.contacts.selectedFolder;
            dispatch(selectFolder(folder.id || String(data.folderSeq)));
            // dispatch(fetchContacts(folder && folder.id && folder.id.substring(2) !== '0' ? folder.id.substring(2) : null, true, false, {match}));
            props.history.goBack();
         }
      });
   };
}

export function openShareDialog(kind, seq) {
   if(seq && String(seq).startsWith('m_'))
      seq = String(seq).substring(2);

   return (dispatch, getState) => {
      axios.get(`/contacts/target/${kind}/${seq}/${getState().gc.user.empno}?lang=${getState().gc.common.lang}`)
         .then(res => {
            dispatch({
               type: OPEN_SHARE_DIALOG,
               list: res.data.list,
               tree: res.data.tree,
               checked: res.data.checked,
               kind,
               seq
            });
         });
   }
}

export function closeShareDialog() {
   return {
      type: CLOSE_SHARE_DIALOG
   };
}

export function shareContacts(empnos, type, seq) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: '/contacts/share',
         data: jtf({
            empnos: empnos.map(id => id.startsWith('e_') ? id.substring(2) : id).join(","),
            sourceType: type,
            sourceSeq: seq,
            sourceEmpno: getState().gc.user.empno
         })
      }).then(res => {
         if(res.status === 200) {
            toastr.success(pack.strings.infoTitle, pack.strings.successMessage);
            dispatch(closeShareDialog());
         }
      });
   };
}

export function moveTree(pid, dragid, children) {
   return (dispatch) =>
      axios.put(`/contacts/folder/drag/${pid}/${dragid}`, jtf({children}))
         .then(res =>
            dispatch(fetchFolders())
         );
}
