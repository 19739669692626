import _ from 'lodash';
import {
   ACTIVITY_EVENTS,
   ACTIVITY_REMOVED,
   ACTIVITY_SAVED,
   FETCH_SIMPLE_PROJECTS,
   LOADING_ACTIVITIES,
   REFRESH_ACTIVITY,
   SAVING_ACTIVITY,
   SET_ACTIVITY_ENTITY,
} from '../actions';

const initState = {
   projectInfos: [],
   activities: [],
   loadingActivity: false,
   savingActivity: false,
   refreshActivity: false, // Refresh Calendar Events
   activity: {
      project: {},
      startdate: null,
      enddate: null,
      tasks: [],
      refs: [],
      files: [],
   },
};

const tmsactivityReducer = (state = initState, action) => {
   switch (action.type) {
      case ACTIVITY_EVENTS:
         return {
            ...state,
            activities: action.events,
         };
      case REFRESH_ACTIVITY:
         return {
            ...state,
            refreshActivity: action.flag,
         };
      case SET_ACTIVITY_ENTITY:
         return {
            ...state,
            activity: action.entity || _.cloneDeep(initState.activity),
         };
      case LOADING_ACTIVITIES:
         return {
            ...state,
            loadingActivity: action.flag,
         };
      case FETCH_SIMPLE_PROJECTS:
         return {
            ...state,
            projectInfos: action.payload,
         };
      case SAVING_ACTIVITY:
         return {
            ...state,
            savingActivity: action.flag,
         };
      case ACTIVITY_SAVED:
         return {
            ...state,
            activities: action.isUpdate
               ? state.activities.map(act => {
                    if (act.id === action.payload.id) {
                       return action.payload;
                    }
                    return act;
                 })
               : state.activities.concat(action.payload),
            refreshActivity: true,
         };
      case ACTIVITY_REMOVED:
         return {
            ...state,
            activities: state.activities.filter(act => act.id !== action.id),
            refreshActivity: true,
         };
      default:
         return state;
   }
};

export default tmsactivityReducer;
