import { FETCH_NOTICES } from '../actions';

const initState = {
   notices: [],
};

const homeReducer = (state = initState, action) => {
   switch (action.type) {
      case FETCH_NOTICES:
         return {
            ...state,
            notices: action.payload,
         };
      default:
         return state;
   }
};

export default homeReducer;
