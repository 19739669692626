import axios from 'axios';
import { pack } from '../../../../../@gc';
import { toastr } from 'react-redux-toastr';
import { entityToEvent, todoToEvent } from '../../common';

export const FETCH_TODOS = '[TMS] FETCH TODOS';
export const LOADING_TODO = '[TMS] LOADING TODO';
export const FETCH_TASK_LIST = '[TMS] FETCH TASK LIST';
export const REFRESH_TASK_LIST = '[TMS] REFRESH TASK LIST';
export const LOADING_TASK_LIST = '[TMS] LOADING TASK LIST';
export const SET_TODO_ENTITY = '[TMS] SET TODO ENTITY';
export const SAVING_TODO = '[TMS] SAVING TODO';
export const REFRESH_TODO = '[TMS] REFRESH TODO';
export const TAKE_TODO = '[TMS] TAKE TODO';
export const UNTAKE_TODO = '[TMS] UNTAKE TODO';
export const TODO_SAVED = '[TMS] TODO SAVED';
export const TODO_REMOVED = '[TMS] TODO REMOVED';
export const SET_TASK = '[TMS] SET TASK';
export const ADD_TODO = '[TMS] ADD TODO';

export function fetchTodos(year, notLoading) {
   return (dispatch, getState) => {
      const { user } = getState().gc;

      if (!notLoading)
         dispatch({
            type: LOADING_TODO,
            flag: true,
         });

      axios
         .get(`/tms/todo/events/${user.empno}/${year}`)
         .then(response => {
            const events = response.data.map(entity => todoToEvent(entity));
            dispatch({
               type: FETCH_TODOS,
               payload: events,
            });
         })
         .catch(pack.showError)
         .finally(() =>
            dispatch({
               type: LOADING_TODO,
               flag: false,
            }),
         );
   };
}

export function fetchTaskList(year, notLoading) {
   return (dispatch, getState) => {
      const { user } = getState().gc;

      if (!notLoading)
         dispatch({
            type: LOADING_TASK_LIST,
            flag: true,
         });

      axios
         .get(`/tms/tasks/${user.empno}/${year}`)
         .then(response =>
            dispatch({
               type: FETCH_TASK_LIST,
               payload: response.data.map(entity => entityToEvent(entity)),
            }),
         )
         .catch(pack.showError)
         .finally(() =>
            dispatch({
               type: LOADING_TASK_LIST,
               flag: false,
            }),
         );
   };
}

export function setTask(entity) {
   return {
      type: SET_TASK,
      entity,
   };
}

export function setTodoEntity(entity) {
   return {
      type: SET_TODO_ENTITY,
      entity,
   };
}

export function updateTodo(model, loadingCalendar) {
   return dispatch => {
      if (loadingCalendar) {
         dispatch({
            type: LOADING_TODO,
            flag: true,
         });
      }

      dispatch({
         type: SAVING_TODO,
         flag: true,
      });

      axios
         .put(`/tms/todo/${model.id}`, JSON.stringify(model))
         .then(res => {
            dispatch({
               type: TODO_SAVED,
               payload: todoToEvent(res.data),
            });
         })
         .catch(pack.showError)
         .finally(() => {
            dispatch({
               type: LOADING_TODO,
               flag: false,
            });
            dispatch({
               type: SAVING_TODO,
               flag: false,
            });
         });
   };
}

export function takeTodo(param, task) {
   return (dispatch, getState) => {
      const { user } = getState().gc;
      const { id, starttime, endtime, ymd } = param;

      dispatch({
         type: TAKE_TODO,
         id,
         task,
      });

      dispatch({
         type: LOADING_TASK_LIST,
         flag: true,
      });

      axios
         .put(`/tms/todo/take/${id}/${user.empno}`, JSON.stringify({ starttime, endtime, ymd }))
         .then(res => {
            dispatch({
               type: ADD_TODO,
               payload: res.data,
            });
            dispatch({
               type: REFRESH_TODO,
               flag: true,
            });
         })
         .catch(e => {
            pack.showError(e);
            dispatch({
               type: UNTAKE_TODO,
               id,
               empno: user.empno,
            });
         })
         .finally(() =>
            dispatch({
               type: LOADING_TASK_LIST,
               flag: false,
            }),
         );
   };
}

export function removeTodo(id) {
   return (dispatch, getState) => {
      const { strings } = getState().gc.common;

      axios
         .delete(`/tms/todo/${id}`)
         .then(res => {
            toastr.success(strings.infoTitle, strings.successMessage);
            dispatch({
               type: TODO_REMOVED,
               id,
            });
         })
         .catch(pack.showError);
   };
}
