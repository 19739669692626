import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, FormText, Input, Label } from 'reactstrap';
import cx from 'classnames';
import { Controller } from 'react-hook-form';
import { pack } from '../common/global';
import _ from 'lodash';

function InputHook(props) {
   const labelWidth = props.labelWidth || { sm: 2 };
   const colWidth = props.colWidth || { sm: 4 };
   const id = props.id || props.name;
   let additionalProps = _.pick(props, ['disabled', 'readOnly', 'placeholder', 'maxLength', 'max']);

   if (props.matching) {
      const func = pack.getMatchingFunction(props.matching);
      if (func != null)
         additionalProps = {
            ...additionalProps,
            ...func,
         };
      else {
         console.log("No Matching Functions for '" + props.matching + "'");
      }
   }

   useEffect(() => {
      props.setValue(props.name, props.defaultValue || '');
   }, [props.defaultValue]);

   const handleRef = useCallback(
      ref => {
         if (typeof props.innerRef === 'function') {
            props.innerRef(ref);
         }
      },
      [props.innerRef],
   );

   return (
      <>
         {props.label && (
            <Label {...labelWidth} for={id} className={cx({ required: !props.viewmode && props.rules && props.rules.required })}>
               {props.label}
            </Label>
         )}
         <Col {...colWidth} className={('d-flex align-items-center', props.colClassName || '')}>
            {!props.viewmode ? (
               <div className="d-flex flex-column w-100">
                  <Controller
                     render={({ field }) => (
                        <Input
                           type={props.type || 'text'}
                           onChange={e => {
                              const { value } = e.target;
                              field.onChange(value);
                              if (typeof props.onChange === 'function') {
                                 props.onChange(value);
                              }
                           }}
                           value={field.value}
                           innerRef={handleRef}
                           {...additionalProps}
                        />
                     )}
                     name={props.name}
                     control={props.control}
                     rules={props.rules}
                     defaultValue={props.defaultValue || ''}
                  />
                  {!props.hideErrorMessage && props.errors[props.name] && props.errors[props.name].message && (
                     <FormText color="danger">{props.errors[props.name].message}</FormText>
                  )}
               </div>
            ) : (
               <Label className="view crlf">{props.defaultValue}</Label>
            )}
         </Col>
      </>
   );
}

InputHook.propTypes = {
   id: PropTypes.string,
   type: PropTypes.string,
   name: PropTypes.string.isRequired,
   control: PropTypes.any.isRequired,
   errors: PropTypes.any.isRequired,
   setValue: PropTypes.func.isRequired,
   rules: PropTypes.object,
   defaultValue: PropTypes.any,
   label: PropTypes.string,
   viewmode: PropTypes.bool,
   onChange: PropTypes.func,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   colClassName: PropTypes.string,
   disabled: PropTypes.bool,
   readOnly: PropTypes.bool,
   maxLength: PropTypes.number,
   max: PropTypes.number,
   matching: PropTypes.any,
   placeholder: PropTypes.string,
   innerRef: PropTypes.func,
   hideErrorMessage: PropTypes.bool,
};

export default InputHook;
