import { withFormsy } from 'formsy-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { pack } from '@gc';
import { Button, Col, FormText, Input, Label } from 'reactstrap';
import InputGroup from 'reactstrap/es/InputGroup';
import InputGroupAddon from 'reactstrap/es/InputGroupAddon';
import cx from 'classnames';
import { Popover } from 'antd';

class CurrencyInputFormsy extends Component {
   constructor(props) {
      super(props);
      this.state = {
         touched: false,
      };
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.viewmode !== this.props.viewmode) {
         this.setState({
            touched: false,
         });
      }
   }

   /*componentDidMount() {
      if (this.props.focusDelay) {
         const ip = this.input;
         if(ip)
            setTimeout(() => {
               ip.focus();
            }, this.props.focusDelay);
      }
   }*/

   changeValue = evt => {
      const { value } = evt.target;
      this.props.setValue(value);
      this.setState({
         currency: value,
      });
      if (typeof this.props.onChange === 'function') {
         evt.persist(); // 사용부분에서 onChange={_.debounce(this.handleChange, 300)} 처럼 사용하려면 먼저 persist 를 해줘야 한다.
         this.props.onChange(evt);
      }
   };

   onBlur = evt => {
      if (this.props.viewmode) return false;
      if (!this.state.touched) this.setState({ touched: true });
      if (typeof this.props.onBlur === 'function') {
         this.props.onBlur(evt);
      }
   };

   handleInnerRef = ref => {
      if (typeof this.props.inputRef === 'function') {
         this.props.inputRef(ref);
      }
   };

   render() {
      const { touched, currency } = this.state;
      let importedProps = _.pick(this.props, ['type', 'name', 'autoFocus', 'className', 'disabled', 'onFocus', 'placeholder', 'children']);
      let error = false;
      let errorMessage = this.props.getErrorMessage() || this.props.errorTrigger;
      const value = this.props.getValue() || '';

      if (!importedProps.type) {
         importedProps.type = 'text';
      }

      if (touched && !value && !errorMessage && this.props.required) {
         errorMessage = this.props.requiredMsg || '';
         error = true;
      }

      const func = pack.getMatchingFunction(this.props.matching);
      if (func != null)
         importedProps = {
            ...importedProps,
            ...func,
         };
      else {
         importedProps = {
            ...importedProps,
            ...pack.getMatchingFunction('numOnly'),
         };
      }

      if (!this.props.placeholder) {
         importedProps.placeholder = 'Input numbers only...';
      }

      const labelWidth = this.props.labelWidth || { sm: 2 };
      const colWidth = this.props.colWidth || { sm: 4 };
      const id = this.props.id || this.props.name;
      importedProps.id = id;

      return (
         <>
            {Boolean(this.props.label) && (
               <Label
                  {...labelWidth}
                  for={id}
                  className={cx({
                     required: !this.props.viewmode && this.props.required,
                  })}
               >
                  {this.props.label}
               </Label>
            )}
            <Col {...colWidth} className={cx('d-flex align-items-center', this.props.colClassName || '')}>
               {!this.props.viewmode ? (
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                     }}
                     className="w-100"
                  >
                     <InputGroup>
                        {this.props.icon != null && (
                           <InputGroupAddon addonType="prepend">
                              <div className="input-group-text">{this.props.icon}</div>
                           </InputGroupAddon>
                        )}
                        <Popover content={<span className="text-primary">{pack.numberToKorean(currency)}</span>} title={this.props.label} trigger="focus">
                           <Input
                              {...importedProps}
                              autoComplete="off"
                              data-lpignore="true"
                              value={value}
                              onChange={this.changeValue}
                              onBlur={this.onBlur}
                              invalid={Boolean(errorMessage) || error}
                              innerRef={this.handleInnerRef}
                           />
                        </Popover>
                        {this.props.endText && (
                           <InputGroupAddon addonType="append">
                              <Button color={this.props.endColor || 'secondary'} className="input-group-addon-button">
                                 {this.props.endText}
                              </Button>
                           </InputGroupAddon>
                        )}
                     </InputGroup>
                     {Boolean(errorMessage) && <FormText color="danger">{errorMessage}</FormText>}
                  </div>
               ) : (
                  <Label className="view">{pack.numberToKorean(value)}</Label>
               )}
            </Col>
         </>
      );
   }
}

CurrencyInputFormsy.propTypes = {
   type: PropTypes.string,
   name: PropTypes.string.isRequired,
   id: PropTypes.string,
   label: PropTypes.string,
   placeholder: PropTypes.string,
   onChange: PropTypes.func,
   onBlur: PropTypes.func,
   value: PropTypes.any,
   viewmode: PropTypes.bool,
   required: PropTypes.bool,
   errorTrigger: PropTypes.string,
   matching: PropTypes.any,
   validations: PropTypes.any,
   validationErrors: PropTypes.object,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   disabled: PropTypes.bool,
   inputRef: PropTypes.func,
   colClassName: PropTypes.string,
   icon: PropTypes.object,
   endText: PropTypes.string,
   endColor: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'link']),
};

export default withFormsy(CurrencyInputFormsy);
