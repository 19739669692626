import { withFormsy } from 'formsy-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton, Icon as AntIcon, message, Typography, Upload } from 'antd';
import { Button, Col, FormText, Label } from 'reactstrap';
import cx from 'classnames';
import { pack } from '@gc';

const { Text } = Typography;

class FileInputFormsy extends Component {
   constructor(props) {
      super(props);
      this.state = {
         fileList: [],
         exts: Array.isArray(props.exts) ? props.exts : pack.serverprop().ext.all.split(','),
      };

      props.setValue({
         savename: props.savename,
         filename: props.filename,
      });
   }

   render() {
      let errorMessage = this.props.getErrorMessage() || this.props.errorTrigger;
      const value = this.props.getValue() || '';
      let error = false;

      if (!value && !errorMessage && this.props.required) {
         errorMessage = this.props.requiredMsg || 'Required';
      }

      if (Boolean(errorMessage) && typeof this.props.onError === 'function') {
         this.props.onError(errorMessage);
      }
      if (this.props.errorPlacement) {
         const errorElem = document.getElementById(this.props.errorPlacement.replace(/^#/g, ''));
         if (errorElem instanceof Element) {
            if (Boolean(errorMessage)) {
               errorElem.innerHTML = errorMessage;
               errorElem.style.color = '#f44336';
            } else {
               errorElem.innerHTML = '';
            }
         }
      }

      const labelWidth = this.props.labelWidth || { sm: 2 };
      const colWidth = this.props.colWidth || { sm: 4 };
      const id = this.props.id || this.props.name;

      let fileObj = this.props.getValue();
      if (!fileObj || !fileObj.savename) fileObj = { savename: this.props.savename, filename: this.props.filename };
      const fileList = this.state.fileList.length > 0 ? this.state.fileList : fileObj && fileObj.filename ? [{ uid: '1', name: fileObj.filename, status: 'done' }] : [];

      if (this.props.required && !fileObj.savename) {
         errorMessage = this.props.requiredMsg || '';
         error = true;
      }

      return (
         <>
            {Boolean(this.props.label) && (
               <Label {...labelWidth} for={id} className={cx({ required: !this.props.viewmode && this.props.required })}>
                  {this.props.label}
               </Label>
            )}
            <Col {...colWidth} className={cx('d-flex align-items-center', this.props.colClassName || '')}>
               {!this.props.viewmode ? (
                  <div className="d-flex flex-column w-100">
                     <Upload
                        name="file"
                        fileList={fileList}
                        accept={this.state.exts.map(ext => `.${ext}`).join(',')}
                        action={this.props.uploadUrl}
                        onChange={info => {
                           if (info.file.status === 'done') {
                              this.props.setValue(info.fileList[0].response);
                           } else if (info.file.status === 'error') {
                              message.error(`${info.file.name} upload Failed`);
                           } else if (info.file.status === 'removed') {
                              this.props.setValue({ savename: '', filename: '' });
                           }
                           this.setState({
                              fileList: info.fileList,
                           });
                        }}
                     >
                        <AntButton className={cx({ invalid: error })}>
                           <AntIcon type="upload" /> {this.props.uploadText || 'Click to upload'}
                        </AntButton>
                     </Upload>
                     {Boolean(errorMessage) && <FormText color="danger">{errorMessage}</FormText>}
                  </div>
               ) : (
                  <div>
                     {fileObj.filename && (
                        <Button className="px-2 btn-icon" color="primary" size="sm" onClick={() => pack.downloadFile({ filename: fileObj.filename, savename: fileObj.savename })}>
                           <i className="lnr-download btn-icon-wrapper"> </i>
                           <span>{fileObj.filename}</span>
                        </Button>
                     )}
                     {!fileObj.filename && <Text>File not exists</Text>}
                  </div>
               )}
            </Col>
         </>
      );
   }
}

FileInputFormsy.propTypes = {
   name: PropTypes.string.isRequired,
   label: PropTypes.string,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   savename: PropTypes.string,
   filename: PropTypes.string,
   exts: PropTypes.array,
   uploadUrl: PropTypes.oneOf(['/api/upload', '/api/upload/email']).isRequired,
   uploadText: PropTypes.string,
   requiredMsg: PropTypes.string,
   required: PropTypes.bool,
   colClassName: PropTypes.string,
   viewmode: PropTypes.bool,
};

export default withFormsy(FileInputFormsy);
