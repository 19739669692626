import { pack } from '../../../../@gc';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';
import _ from 'lodash';
import { startLoading, stopLoading } from '../../../../@gc/common/actions/common.action';

export const FETCH_SIGN = '[SIGN] FETCH_SIGN';
export const FETCH_SIGN_LOADING = '[SIGN] FETCH_SIGN_LOADING';
export const FETCH_SIGNS = '[SIGN] FETCH_SIGNS';
export const FETCH_SIGNS_LOADING = '[SIGN] FETCH_SIGNS_LOADING';
export const FETCH_FORMATS = '[SIGN] FETCH_FORMATS';
export const FETCH_FORMAT = '[SIGN] FETCH_FORMAT';
export const UPDATE_PAGE_OPTION = '[SIGN] UPDATE_PAGE_OPTION';
export const UPDATE_CONDITIONS = '[SIGN] UPDATE_CONDITIONS';

export const SIGN_CONDITIONS = ['subject', 'formatCode', 'status', 'regName', 'finalName'];

let loadingCount = 0;

export function fetchFormats() {
   return (dispatch, getState) =>
      pack
         .fetchCode('SIGN_FORM')
         .then(res =>
            dispatch({
               type: FETCH_FORMATS,
               payload: res.data,
            }),
         )
         .catch(e => handleError(e, getState()));
}

export function fetchFormat(code) {
   return (dispatch, getState) =>
      axios
         .get(`/approve/format/${code}`)
         .then(res =>
            dispatch({
               type: FETCH_FORMAT,
               payload: res.data,
            }),
         )
         .catch(e => handleError(e, getState()));
}

export function fetchSign(seq) {
   return (dispatch, getState) => {
      dispatch({
         type: FETCH_SIGN_LOADING,
         flag: true,
      });

      axios
         .get(`/approve/${seq}`)
         .then(res => {
            dispatch({
               type: FETCH_SIGN,
               payload: res.data,
            });
            dispatch(fetchFormat(res.data.formatCode));
         })
         .catch(e => handleError(e, getState()))
         .finally(() =>
            dispatch({
               type: FETCH_SIGN_LOADING,
               flag: false,
            }),
         );
   };
}

export function fetchSigns(param) {
   return (dispatch, getState) => {
      let option;

      if (!param) {
         option = { ...getState().griffin.admin.sign.defaultPageOption };
      } else {
         option = { ..._.omit(param, ['reset']) };
         dispatch(updatePageOption(param));
      }
      loadingCount++;
      dispatch(startLoading());

      axios
         .post('/approve/list', JSON.stringify(option))
         .then(res =>
            dispatch({
               type: FETCH_SIGNS,
               payload: res.data,
            }),
         )
         .catch(e => handleError(e, getState()))
         .finally(() => {
            loadingCount--;
            if (loadingCount <= 0) {
               dispatch(stopLoading());
               loadingCount = 0;
            }
         });
   };
}

export function updatePageOption(payload) {
   return {
      type: UPDATE_PAGE_OPTION,
      payload,
   };
}

export function updateConditions(conditions) {
   return {
      type: UPDATE_CONDITIONS,
      conditions,
   };
}

function handleError(e, state) {
   console.error(e);
   const { strings } = state.gc.common;
   toastr.error(strings.errTitle, strings.errorMessage);
}
