import axios from 'axios';
import { startLoading, stopLoading } from '../../../../@gc/common/actions/common.action';
import { FETCH_FOLDERS, fetchJustList } from './index';
import { fetchQuota } from './index';
import { toastr } from 'react-redux-toastr';
import { pack } from '@gc';
import { uuid } from 'uuidv4';

const jtf = require('json-form-data');

export const FETCH_FILTERS = '[MAILSETTING] FETCH FILTERS';
export const FETCH_FOLDER_LIST = '[MAILSETTING] FETCH FOLDER LIST';
export const TOGGLE_LOADING = '[MAILSETTING] TOGGLE LOADING';
export const FETCH_BLOCK_LIST = '[MAILSETTING] FETCH BLOCK LIST';
export const BACKUP_IS_ING = '[MAILSETTING] BACKUP IS ING';
export const FETCH_BACKUPS = '[MAILSETTING] FETCH BACKUPS';
export const TOGGLE_BACKUP_ING = '[MAILSETTING] TOGGLE BACKUP ING';
export const SET_UPLOAD_PROGRESS_RATE = '[MAILSETTING] SET UPLOAD PROGRESS RATE';
export const TOGGLE_UPLOAD_ACTIVE = '[MAILSETTING] TOGGLE UPLOAD ACTIVE';

export function fetchFolderList(email) {
   return dispatch => {
      axios.get(`/mail/folder/list/${email}`).then(res =>
         dispatch({
            type: FETCH_FOLDER_LIST,
            payload: res.data,
         }),
      );
   };
}

/* MailboxFilter ############################################################### */
export function fetchFilters(showLoading, email) {
   return (dispatch, getState) => {
      if (showLoading) dispatch(startLoading());
      axios.get(`/mail/filters/${email || getState().gc.user.currentEmail}`).then(res => {
         dispatch(stopLoading());
         dispatch({
            type: FETCH_FILTERS,
            payload: res.data,
         });
      });
   };
}

export function toggleLoading(flag) {
   return {
      type: TOGGLE_LOADING,
      flag,
   };
}

export function createFilter(data) {
   return (dispatch, getState) => {
      dispatch(toggleLoading(true));
      axios.post(`/mail/filter`, jtf(data)).then(res => {
         dispatch(toggleLoading(false));
         dispatch(fetchFilters());
      });
   };
}

export function removeFilter(seq) {
   return (dispatch, getState) => {
      axios.delete(`/mail/filter/${seq}`).then(res => {
         dispatch(fetchFilters());
      });
   };
}

export function reorderFilters(data) {
   return (dispatch, getState) =>
      axios.post(`/mail/filter/reorder`, jtf({ seqs: data.map(filter => filter.seq).join(',') })).then(res => dispatch(fetchFilters()));
}

/* MailboxFilter ###############################################################33 */

/* MailboxBlockList ###############################################################33 */
export function fetchBlockList() {
   return (dispatch, getState) => {
      axios.get(`/mail/block-list/${getState().gc.user.empno}`).then(res => {
         dispatch({
            type: FETCH_BLOCK_LIST,
            payload: res.data,
         });
      });
   };
}

export function createBlock({ email, note }) {
   return (dispatch, getState) => {
      axios
         .post(
            '/mail/block-add',
            jtf({
               from: email,
               note,
               empno: getState().gc.user.empno,
            }),
         )
         .then(res => {
            dispatch(fetchBlockList());
         });
   };
}

export function removeBlock(email) {
   return (dispatch, getState) => {
      axios
         .put(
            '/mail/block-delete',
            jtf({
               from: email,
               address: getState().gc.user.email,
               empno: getState().gc.user.empno,
            }),
         )
         .then(res => {
            dispatch(fetchBlockList());
            dispatch(fetchJustList());
         });
   };
}

/* MailboxBlockList ###############################################################33 */

/* MailboxSetting ################################################################# */
export function setUploadMessageRate(rate, total, msg) {
   return {
      type: SET_UPLOAD_PROGRESS_RATE,
      rate,
      total,
      msg,
   };
}

export function toggleUploadActive(flag) {
   return {
      type: TOGGLE_UPLOAD_ACTIVE,
      flag,
   };
}

export function requestUpload(data, onSuccess, onFail) {
   return (dispatch, getState) => {
      const { strings } = getState().gc.common;
      const randomId = uuid();
      let timer = null;

      dispatch(toggleUploadActive(true));

      axios
         .post(`/mail/restore?lang=${getState().gc.common.lang}`, jtf({ ...data, randomId }))
         .then(res => {
            const {
               data: { code, msg },
            } = res;
            if (timer != null) {
               clearInterval(timer);
            }
            if (res.status === 200) {
               if (code === '0001') {
                  onSuccess('Another uploading process is in progress');
               } else if (code === '0000') {
                  dispatch(fetchJustList(null, () => onSuccess(msg)));
                  dispatch(fetchQuota());
               } else {
                  onFail(msg);
               }
            } else {
               onFail(msg || strings.errMessage);
            }
            dispatch(toggleUploadActive(false));
         })
         .catch(e => {
            if (timer != null) {
               clearInterval(timer);
            }
            dispatch(toggleUploadActive(false));
            pack.showError(e);
            onFail(strings.errMessage);
         });

      timer = setInterval(() => {
         axios.post('/progress', jtf({ randomId })).then(res => {
            const { data } = res;
            if (data.finished) {
               clearInterval(timer);
            }
            dispatch(setUploadMessageRate(data.progressRate, data.total, data.message));
         });
      }, 300);
   };
}

export function requestBackup(email) {
   return (dispatch, getState) => {
      axios
         .post('/mail/backup', jtf({ email }))
         .then(res => {
            if (res.status === 200) {
               dispatch(fetchBackups());
               dispatch(toggleBackupIng(true));
            } else {
               const { strings } = getState().gc.common;
               toastr.error(strings.errTitle, strings.mailBackupFail);
            }
         })
         .catch(e => {
            pack.showError(e);
         });
   };
}

export function fetchBackups() {
   return (dispatch, getState) =>
      axios.get(`/mail/backups/${getState().gc.user.empno}`).then(res =>
         dispatch({
            type: FETCH_BACKUPS,
            payload: res.data,
         }),
      );
}

export function toggleBackupIng(flag) {
   return {
      type: TOGGLE_BACKUP_ING,
      flag,
   };
}

export function removeBackup(seq) {
   return (dispatch, getState) => axios.delete(`/mail/backup/${seq}`).then(res => dispatch(fetchBackups()));
}

/* MailboxSetting ################################################################# */
