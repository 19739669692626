import axios from 'axios';

export const FETCH_SUGGESTIONS = '[OUT SELECT] FETCH SUGGESTIONS';
export const TOGGLE_TREE = '[OUT SELECT] TOGGLE TREE';

export function fetchSuggestions() {
   return (dispatch) => {
      axios.get('/outsource/list').then(res => {
         dispatch({
            type: FETCH_SUGGESTIONS,
            payload: res.data
         });
      });
   };
}

export function openTree() {
   return {
      type: TOGGLE_TREE,
      flag: true
   };
}

export function closeTree() {
   return {
      type: TOGGLE_TREE,
      flag: false
   };
}
