import axios from 'axios';
import { startLoading, stopLoading } from '@gc/common/actions/common.action';
import { pack } from '@gc';
import _ from 'lodash';
import { updateUserData } from '../../../../@gc/auth/actions/user.action';
import { updateFolder } from '../../../activity/contacts/actions';
import { toastr } from 'react-redux-toastr';

const jtf = require('json-form-data');

export const OPEN_EMPLOYEE_ADD = '[EMP] OPEN EMPLOYEE ADD';
export const OPEN_EMPLOYEE_LIST = '[EMP] OPEN EMPLOYEE LIST';
export const OPEN_EMPLOYEE_EDIT = '[EMP] OPEN EMPLOYEE EDIT';
export const FETCH_EMPLOYEES = 'FETCH EMPLOYEES';
export const FETCH_EMPLOYEE = '[EMP] FETCH EMPLOYEE';
export const FETCH_DEPARTMENT_TREE = '[EMP] FETCH DEPARTMENT TREE';
export const FETCH_DEPARTMENT_SELECT_TREE = '[EMP] FETCH_DEPARTMENT_SELECT_TREE';
export const SELECT_DEPARTMENT = '[EMP] SELECT DEPARTMENT';
export const EMPLOYEE_TOGGLE_VIEW = '[EMP] EMPLOYEE TOGGLE TO ADD';
export const CREATE_EMPLOYEE = '[EMP] CREATE EMPLOYEE';
export const REMOVE_DEPARTMENT = '[EMP] REMOVE DEPARTMENT';
export const CREATE_DEPARTMENT = '[EMP] CREATE DEPARTMENT';
export const REMOVE_EMPLOYEE = '[EMP] REMOVE EMPLOYEE';
export const SET_EMP_DEPT_ROLES = '[EMP] SET EMP DEPT ROLES';
export const UPDATE_INFOS = '[EMP] UPDATE INFOS';

export const OPEN_DEPARTMENT_ADD = 'OPEN DEPARTMENT ADD';
export const OPEN_DEPARTMENT_EDIT = 'OPEN DEPARTMENT EDIT';

export const EMP_INFOS_KEY_SCHOOL = 'schools';
export const EMP_INFOS_KEY_LANG = 'langs';
export const EMP_INFOS_KEY_CERT = 'certs';
export const EMP_INFOS_KEY_EDU = 'edus';
export const EMP_INFOS_KEY_ACT = 'acts';
export const EMP_INFOS_KEY_AWARD = 'awards';
export const EMP_INFOS_KEY_ARMY = 'armys';
export const EMP_INFOS = [
   EMP_INFOS_KEY_SCHOOL,
   EMP_INFOS_KEY_LANG,
   EMP_INFOS_KEY_CERT,
   EMP_INFOS_KEY_EDU,
   EMP_INFOS_KEY_ACT,
   EMP_INFOS_KEY_AWARD,
   EMP_INFOS_KEY_ARMY,
];

export function fetchDepartmentTree(deptcodeToSelect, { history, match }) {
   return (dispatch, getState) => {
      axios.get(`/department/tree?isSuper=${getState().gc.user.isSuper}&lang=${getState().gc.common.lang}`).then(res => {
         const { data } = res;
         const arr = [
            dispatch({
               type: FETCH_DEPARTMENT_TREE,
               deptTree: data.tree,
               deptList: data.list,
            }),
         ];
         if (deptcodeToSelect) {
            arr.push(dispatch(selectDepartment(deptcodeToSelect, null, { history, match })));
         }
         return Promise.all(arr);
      });
   };
}

export function reorderDepartment(source, target, props) {
   return (dispatch, getState) => {
      if (target.targetType === 'between-items') {
         axios
            .put(
               `/department/reorder/${source.index}`,
               JSON.stringify({
                  orderType: 'between',
                  pid: target.parentItem,
                  orderIndex: target.childIndex,
                  orderPosition: target.linePosition,
               }),
            )
            .then(res => dispatch(fetchDepartmentTree(null, props)));
      } else if (target.targetType === 'item') {
         axios
            .put(
               `/department/reorder/${source.index}`,
               JSON.stringify({
                  orderType: 'drop',
                  id: target.targetItem,
               }),
            )
            .then(res => dispatch(fetchDepartmentTree(null, props)));
      }
   };
}

export function fetchDepartmentSelectTree() {
   return (dispatch, getState) =>
      axios.get(`/department/selecttree?isSuper=${getState().gc.user.isSuper}`).then(res =>
         dispatch({
            type: FETCH_DEPARTMENT_SELECT_TREE,
            payload: res.data,
         }),
      );
}

export function fetchEmployee(empno) {
   return (dispatch, getState) => {
      axios.get(`/emp/${empno}`).then(res =>
         dispatch({
            type: FETCH_EMPLOYEE,
            payload: res.data,
         }),
      );
   };
}

export function fetchEmployees(showLoading, props, deptcode) {
   return (dispatch, getState) => {
      if (showLoading) {
         dispatch(startLoading());
      }
      /* ★★★ return 빼지 말것 ★★★ */
      axios.get(`/employee`).then(res => {
         dispatch({
            type: FETCH_EMPLOYEES,
            payload: res.data,
            deptcode,
         });
         dispatch(stopLoading());
         if (props && Boolean(props.match.params.empno)) {
            dispatch(fetchEmployee(props.match.params.empno));
         }
      });
   };
}

export function openEmployeeAdd({ history, match }) {
   return dispatch => {
      dispatch({
         type: OPEN_EMPLOYEE_ADD,
      });
      history.push(
         pack.getToPath(match)({
            ..._.omit(match.params, 'empno'),
            mode: 'add',
         }),
      );
   };
}

export function openEmployeeView(mode, empno, { history, match }) {
   return dispatch => {
      dispatch(fetchEmployee(empno));
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode,
            empno,
         }),
      );
   };
}

export function openEmployeeEdit({ history, match }) {
   return dispatch => {
      dispatch({
         type: OPEN_EMPLOYEE_EDIT,
         mode: 'edit',
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'edit',
            tab: 0,
         }),
      );
   };
}

export function openEmployeeList() {
   return {
      type: OPEN_EMPLOYEE_LIST,
   };
}

export function selectDepartment(deptcode, treeRow, { history, match }, modeKeep) {
   return (dispatch, getState) => {
      if (!modeKeep) {
         history.push(
            pack.getToPath(match)({
               ..._.omit(match.params, 'empno'),
               mode: 'list',
            }),
         );
      }

      if (!deptcode || deptcode === '0') {
         dispatch({
            type: SELECT_DEPARTMENT,
            payload: null,
         });
      } else if (!treeRow) {
         axios.get(`/department/${deptcode}?lang=${getState().gc.common.lang}`).then(res =>
            dispatch({
               type: SELECT_DEPARTMENT,
               payload: res.data,
            }),
         );
      } else {
         dispatch({
            type: SELECT_DEPARTMENT,
            payload: {
               deptcode: treeRow.id,
               deptname: treeRow.data,
               pDeptcode: treeRow.pid,
               pDeptname: treeRow.pdata,
               deptdesc: treeRow.subtitle,
               firmcode: treeRow.firmcode,
            },
         });
      }
   };
}

export function createEmployee(data, { history, match }, isSavingFromOutside, onSuccess) {
   const {
      params: { mode },
   } = match;
   const isUpdate = isSavingFromOutside || mode === 'edit';

   data = pack.jsonToJpa(data, 'member');
   data = pack.jsonToJpa(data, 'department');

   const request = axios({
      method: 'post',
      url: `/employee${isUpdate ? '/update' : ''}`,
      data: jtf(data),
   });

   return (dispatch, getState) =>
      request
         .then(res => {
            toastr.success(getState().gc.common.strings.savedMessage);

            if (getState().gc.user.empno === res.data.empno && res.data.member.token) {
               localStorage.setItem('imhappy', res.data.member.token);
            }

            if (typeof onSuccess === 'function') {
               onSuccess();
            }

            if (isSavingFromOutside) {
               dispatch(
                  updateUserData({
                     name: res.data.name,
                     avatar: res.data.avatar,
                  }),
               );
               return;
            }

            history.push(
               pack.getToPath(match)({
                  ..._.omit(match.params, 'empno'),
                  mode: 'list',
               }),
            );

            dispatch(fetchEmployees(true, { history, match }));
            dispatch(selectDepartment(null, null, { history, match }));
            if (getState().gc.user.id === res.data.member.id) {
               dispatch(
                  updateUserData({
                     name: res.data.name,
                     avatar: res.data.avatar,
                  }),
               );
            }
            if (!isUpdate) {
               const contacts = {
                  seq: '',
                  parentSeq: '0',
                  name: pack.strings.baseFolder,
                  userId: getState().gc.user.id,
               };
               dispatch(updateFolder(contacts));
            }
         })
         .catch(pack.showError);
}

export const viewEmployee = (data, { history, match }) => {
   return dispatch => {
      dispatch(selectDepartment(data.department.deptcode, null, { history, match }));
      return dispatch({
         type: OPEN_EMPLOYEE_EDIT,
         mode: 'view',
         tab: 0,
         payload: data,
      });
   };
};

export const editEmployee = (data, { history, match }) => {
   return dispatch => {
      dispatch(selectDepartment(data.department.deptcode, null, { history, match }));
      return dispatch({
         type: OPEN_EMPLOYEE_EDIT,
         mode: 'edit',
         tab: 0,
         payload: data,
      });
   };
};

export const removeEmployee = (empno, { history, match }) => {
   const request = axios.delete(`/employee/${empno}`);
   return dispatch => {
      request.then(res => {
         dispatch({
            type: REMOVE_EMPLOYEE,
            empno,
         });
         history.push(
            pack.getToPath(match)({
               ..._.omit(match.params, 'empno'),
               mode: 'list',
            }),
         );
      });
   };
};

export function openDepartmentAdd({ history, match }) {
   return dispatch => {
      dispatch({
         type: OPEN_DEPARTMENT_ADD,
         // payload: 'add'
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'add',
         }),
      );
   };
}

export function openDepartmentView({ history, match }) {
   return dispatch => {
      dispatch({
         type: OPEN_DEPARTMENT_EDIT,
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'view',
            tab: 1,
         }),
      );
   };
}

export function openDepartmentEdit({ history, match }) {
   return dispatch => {
      dispatch({
         type: OPEN_DEPARTMENT_EDIT,
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'edit',
            tab: 1,
         }),
      );
   };
}

export function createDepartment(data, isUpdate, { history, match }) {
   const request = axios({
      method: 'post',
      url: '/department',
      data: jtf(data),
   });

   return dispatch =>
      request.then(res => {
         /*dispatch({
         type: CREATE_DEPARTMENT,
         payload: res.data
      });*/
         dispatch(fetchDepartmentTree(null, { history, match }));
         dispatch(fetchDepartmentSelectTree());
         if (isUpdate) {
            dispatch(fetchEmployees());
         }
      });
}

export function removeDepartment(deptcode, { history, match }) {
   return (dispatch, getState) =>
      axios.delete(`/department/${deptcode}?lang=${getState().gc.common.lang}`).then(res => {
         Promise.all([
            dispatch(fetchDepartmentTree(null, { history, match })),
            dispatch({
               type: REMOVE_DEPARTMENT,
            }),
         ]);
      });
}

export function setRoles(roles) {
   return (dispatch, getState) => {
      const user = getState().auth.user;
      const menus = user.menus.length > 0 ? user.menus : pack.getMenus();
      const curTab = getState().adminApp.employee.currentTab;
      const menu = curTab === 0 ? _.find(menus, { id: 'employee' }) : _.find(menus, { id: 'department' });
      return dispatch({
         type: SET_EMP_DEPT_ROLES,
         payload: menu ? menu.roles : [],
      });
   };
}

export function updateInfos(key, infos, save) {
   return dispatch => {
      dispatch({
         type: UPDATE_INFOS,
         key,
         save,
         payload: infos.map(info => {
            if (typeof info.seq === 'string') {
               info.seq = null;
            }
            return info;
         }),
      });
   };
}
