import axios from 'axios';
import { startLoading, stopLoading } from '../../../../@gc/common/actions/common.action';
import { APPROVE_STATUS_CONFIRM, APPROVE_STATUS_ING } from '../../../activity/approve/actions';

export const FETCH_LIST = '[VACATION] FETCH_LIST';
export const FETCH_TARGET_LIST = '[VACATION] FETCH_TARGET_LIST';
export const FETCH_EMPLOYEE = '[VACATION] FETCH EMPLOYEE';
export const FETCH_AMOUNTS = '[VACATION] FETCH AMOUNTS';
export const FETCH_ING_AMOUNTS = '[VACATION] FETCH ING AMOUNTS';
export const FETCH_TOTAL = '[VACATION] FETCH TOTAL';
export const FETCH_HUGA_TYPES = '[VACATION] FETCH_HUGA_TYPES';
export const FETCH_AMOUNT_PER_TYPE = '[VACATION] FETCH_AMOUNT_PER_TYPE';
export const TOGGLE_TREE = '[VACATION] TOGGLE TREE';
export const SELECT_ENTIITY = '[VACATION] SELECT ENTITY';
export const UPDATE_YEAR = '[VACATION] UPDATE YEAR';
export const TOGGLE_YUNCHA_LOADED = '[VACATION] TOGGLE YUNCHA LOADED';

export function fetchHugaTypes() {
   return dispatch =>
      axios.get('/code/list/HUGA_TYPE').then(res =>
         dispatch({
            type: FETCH_HUGA_TYPES,
            payload: res.data,
         }),
      );
}

export function fetchTargetList() {
   return (dispatch, getState) => {
      axios.get(`/department/emp/tree?lang=${getState().gc.common.lang}`).then(res =>
         dispatch({
            type: FETCH_TARGET_LIST,
            tree: res.data.tree,
            list: res.data.list,
         }),
      );
   };
}

export function fetchEmployee(empno) {
   return (dispatch, getState) => {
      axios.get(`/emp/${empno}`).then(res => {
         dispatch({
            type: FETCH_EMPLOYEE,
            payload: res.data,
         });
         const year = getState().griffin.manage.vacation.year;
         dispatch(fetchList(year, empno, true));
         dispatch(fetchAmounts(year, empno));
         dispatch(fetchAmountPerType(year, empno));
         dispatch(fetchTotal(year, empno));
      });
   };
}

export function fetchList(year, empno, showLoading) {
   return (dispatch, getState) => {
      if (!showLoading) {
         dispatch(stopLoading());
      } else {
         dispatch(startLoading());
      }
      axios.get(`/vacation/list/${year}/${empno}`).then(res => {
         dispatch(stopLoading());
         dispatch({
            type: FETCH_LIST,
            payload: res.data,
         });
      });
   };
}

export function fetchAmounts(year, empno) {
   return dispatch => {
      axios.get(`/vacation/amounts/${year}/${empno}/${APPROVE_STATUS_CONFIRM}`).then(res => {
         dispatch({
            type: FETCH_AMOUNTS,
            payload: res.data,
         });
      });
      axios.get(`/vacation/amounts/${year}/${empno}/${APPROVE_STATUS_CONFIRM}_${APPROVE_STATUS_ING}`).then(res => {
         dispatch({
            type: FETCH_ING_AMOUNTS,
            payload: res.data,
         });
      });
   };
}

export function fetchTotal(year, empno) {
   return dispatch =>
      axios.get(`/vattend/total/${year}/${empno}`).then(res =>
         dispatch({
            type: FETCH_TOTAL,
            payload: res.data,
         }),
      );
}

export function fetchAmountPerType(year, empno) {
   return dispatch =>
      axios.get(`/vacation/amount-per-type/${year}/${empno}`).then(res =>
         dispatch({
            type: FETCH_AMOUNT_PER_TYPE,
            payload: res.data,
         }),
      );
}

export function toggleTree(open) {
   return {
      type: TOGGLE_TREE,
      open,
   };
}

export function selectEntity(entity) {
   return {
      type: SELECT_ENTIITY,
      entity,
   };
}

export function navigatePrev() {
   return (dispatch, getState) => {
      const year = getState().griffin.manage.vacation.year - 1;
      const empno = getState().griffin.manage.vacation.employee.empno;
      dispatch(fetchList(year, empno, true));
      dispatch(fetchAmounts(year, empno));
      dispatch(fetchAmountPerType(year, empno));
      dispatch({
         type: UPDATE_YEAR,
         year,
      });
   };
}

export function navigateNext() {
   return (dispatch, getState) => {
      const year = getState().griffin.manage.vacation.year + 1;
      const empno = getState().griffin.manage.vacation.employee.empno;
      dispatch(fetchList(year, empno, true));
      dispatch(fetchAmounts(year, empno));
      dispatch(fetchAmountPerType(year, empno));
      dispatch({
         type: UPDATE_YEAR,
         year,
      });
   };
}

export function toggleYunchaLoaded(flag) {
   return {
      type: TOGGLE_YUNCHA_LOADED,
      flag,
   };
}
