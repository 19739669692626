import _ from 'lodash';
import * as Actions from "../actions";
import {pack} from "@gc";

const initState = {
   entities: [],
   task: {
      seq: '',
      mgrSeq: '',
      name: '',
      instruction: '',
      isprivate: '0',
      isauto: '1',
      isorder: '0',
      taskDateList: {},
      taskUsers: [],
      taskTodos: [],
      taskTypes: [],
      employee: {
         empno: '',
         name: '',
         member: {
            id: ''
         },
         department: {
            deptcode:'',
            deptname: ''
         }
      },
      islast: '1'
   },
   newTask: {},
   year: new Date().getFullYear(),
   views: [1,2,3],
   viewsCnt: 0,
   targetTree: [],
   targetList: [],
   liadLoadCnt: 0
};

const taskReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_TASKPLAN:
         return {
            ...state,
            entities: _.keyBy(action.payload, 'seq'),
            listLoadCnt: state.listLoadCnt + 1
         };
      case Actions.FETCH_TASK:
         return {
            ...state,
            task: state.entities[action.seq]
         };
      case Actions.FETCH_TARGET_LIST:
         return {
            ...state,
            targetTree: action.tree,
            targetList: action.list
         };
      case Actions.UPDATE_TASK:
         const tt = action.payload;

         if (Boolean(action.oldSeq)) {
            delete state.entities[action.oldSeq]
         }
         state.entities[+(tt.seq)] = tt;
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1
         };
      case Actions.TASK_PLUS_YEAR:
         return {
            ...state,
            year: state.year + 1
         };
      case Actions.TASK_MINUS_YEAR:
         return {
            ...state,
            year: state.year - 1
         };
      case Actions.TASK_TOGGLE_VIEW:
         return {
            ...state,
            views: action.views,
            viewsCnt: state.viewsCnt + 1
         };
      case Actions.OPEN_TASK_ADD:
         return {
            ...state,
            task: initState.task
            /*newTask: {
               ...initState.task,
               taskDateList: {1: {seq: '', taskSeq: '', startdate: pack.dateToString(new Date()), enddate: pack.dateToString(new Date()), ispast: '0' }},
               taskTodos: [{seq: '', taskSeq: '', content: '', sort: 1}],
            }*/
         };
      case Actions.OPEN_TASK_EDIT:
         return {
            ...state,
            task: {
               ...action.payload,
               taskDateList: _.keyBy(action.payload.taskDateList, 'seq')
            }
         };
      case Actions.REMOVE_TASKPLAN:
         delete state.entities[action.seq];
         return {
            ...state
         };
      default:
         return state;
   }
};

export default taskReducer;