import * as Actions from "../actions";
import {pack} from '@gc';

const initState = {
   show: false,
   type: '', // ['success' | 'error' | 'warning' | 'info' | 'question']
   title: '',
   text: '',
   showCancelButton: false,
   showConfirmButton: true,
   confirmButtonText: '',
   cancelButtonText: '',
   allowEscapeKey: true,
};

const swalReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.SWAL_OPEN:
         return {
            ...state,
            ...action,
            show: true,
            type: action.swalType
         };
      case Actions.SWAL_CLOSE:
         return {
            ...state,
            show: false
         };
      default:
         return state;
   }
};

export default swalReducer;