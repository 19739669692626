import axios from 'axios';
import {startLoading, stopLoading} from "../../../../@gc/common/actions/common.action";
import {pack} from "@gc";
import _ from "lodash";

const jtf = require('json-form-data');

export const FETCH_PRODUCTS = '[PRODUCT] FETCH PRODUCTS';
export const FETCH_PRODUCT = '[PRODUCT] FETCH PRODUCT';
export const OPEN_ADD = '[COMPANY] OPEN ADD';
export const OPEN_EDIT = '[COMPANY] OPEN EDIT';
export const OPEN_VIEW = '[COMPANY] OPEN VIEW';

export function fetchProducts(showLoading, props) {
   return (dispatch, getState) => {
      if (showLoading)
         dispatch(startLoading());

      axios.get(`/product/`)
         .then(res => {
            dispatch(stopLoading());
            dispatch({
               type: FETCH_PRODUCTS,
               payload: res.data
            });

            if(props && Boolean(props.match.params.seq)) {
               dispatch(fetchProduct(props.match.params.seq));
            }
         });
   };
}

export function fetchProduct(seq) {
   return {
      type: FETCH_PRODUCT,
      seq
   };
}

export function openAdd({match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_ADD
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'add',
         seq: null
      }));
   };
}

export function openView(seq, {match, history}) {
   return (dispatch) => {
      dispatch(fetchProduct(seq));
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         seq
      }));
   };
}

export function removeEntity(seq, {match, history}) {
   return (dispatch, getState) =>
      axios.delete(`/product/${seq}/${getState().gc.user.id}`)
         .then(res => {
            dispatch(fetchProducts());
            history.push(pack.getToPath(match)({
               ...match.params,
               mode: 'list',
               seq: null
            }));
         });
}

export function createEntity(data, {match, history}) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: '/product',
         data: jtf({...data, id: getState().gc.user.id})
      }).then(res => {
         dispatch(fetchProducts());
         history.push(pack.getToPath(match)({
            ...match.params,
            mode: 'list',
            seq: null
         }));
      });
   };
}
