import {
   ADD_TODO,
   FETCH_TASK_LIST,
   FETCH_TODOS,
   LOADING_TASK_LIST,
   LOADING_TODO,
   REFRESH_TASK_LIST,
   REFRESH_TODO,
   SAVING_TODO,
   SET_TASK,
   SET_TODO_ENTITY,
   TAKE_TODO,
   TODO_REMOVED,
   TODO_SAVED,
   UNTAKE_TODO,
} from '../actions';
import _ from 'lodash';

const initState = {
   loadingTask: false,
   refreshTask: false,
   loadingTodo: false,
   savingTodo: false,
   refreshTodo: false, // Refresh Calendar Events
   tasks: [],
   todos: [],
   task: {
      activity: {
         project: {},
      },
      files: [],
   },
   todo: {
      task: {
         activity: {
            project: {},
         },
         files: [],
      },
      files: [],
   },
};

const tmstodoReducer = (state = initState, action) => {
   switch (action.type) {
      case LOADING_TASK_LIST:
         return {
            ...state,
            loadingTask: action.flag,
         };
      case REFRESH_TASK_LIST:
         return {
            ...state,
            refreshTask: action.flag,
         };
      case FETCH_TASK_LIST:
         return {
            ...state,
            tasks: action.payload,
         };
      case LOADING_TODO:
         return {
            ...state,
            loadingTodo: action.flag,
         };
      case REFRESH_TODO:
         return {
            ...state,
            refreshTodo: action.flag,
         };
      case FETCH_TODOS:
         return {
            ...state,
            todos: action.payload,
         };
      case SET_TASK:
         return {
            ...state,
            task: action.entity || _.cloneDeep(initState.task),
         };
      case SET_TODO_ENTITY:
         return {
            ...state,
            todo: action.entity || _.cloneDeep(initState.todo),
         };
      case ADD_TODO:
         return {
            ...state,
            todos: state.todos.concat(action.payload),
         };
      case TAKE_TODO:
         return {
            ...state,
            todos: state.todos.concat([{ id: 0, ymd: action.ymd, task: action.task }]),
         };
      case UNTAKE_TODO:
         return {
            ...state,
            tasks: state.tasks.map(task => {
               if (task.id === action.id) {
                  _.remove(task.todos, todo => !todo.id);
               }
               return task;
            }),
         };
      case TODO_SAVED:
         return {
            ...state,
            todos: state.todos.map(todo => {
               if (todo.id === action.payload.id) {
                  return action.payload;
               }
               return todo;
            }),
         };
      case SAVING_TODO:
         return {
            ...state,
            savingTodo: action.flag,
            refreshTodo: true,
         };
      case TODO_REMOVED:
         return {
            ...state,
            todos: state.todos.filter(todo => todo.id !== action.id),
            refreshTodo: true,
         };
      default:
         return state;
   }
};

export default tmstodoReducer;
