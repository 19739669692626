import _ from 'lodash';
import * as Actions from "../actions";


const initState = {
   entities: [],
   pointType: {
      id: '',
      score: 0,
      weight: 1,
      name: '',
      detail: '',
      isuse: '0'
   },
   point: {
      seq: 0,
      memberId: '',
      signStatus: '0',
      score: 0
   },
};

const pointReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_POINT_TYPES:
         return {
            ...state,
            entities: _.keyBy(action.payload, 'id')
         };
      case Actions.OPEN_POINT_TYPE_ADD:
         return {
            ...state,
            pointType: initState.pointType
         };
      case Actions.FETCH_POINT_TYPE:
         return {
            ...state,
            pointType: action.payload
         };
      case Actions.OPEN_POINT_TYPE_LIST:
         return {
            ...state,
            pointType: initState.pointType
         };
      default:
         return state;
   }
};

export default pointReducer;