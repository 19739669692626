import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import ReactNotifications from "react-browser-notifications";
import {closeNotify} from './actions';

class GcNotify extends Component {
   constructor(props) {
      super(props);
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.show !== this.props.show && this.props.show) {
         this.show();
      }

      if (prevProps.closeTag !== this.props.closeTag) {
         this.n.close(this.props.closeTag);
      }
   }

   show = () => {
      if (this.n.supported()) {
         this.n.show();
      }
   };

   handleClick = (e) => {
      if (typeof this.props.onClick === 'function') {
         this.props.onClick(e);
      }
      this.props.closeNotify(e.target.tag);
   };

   render() {
      const importedProps = _.pick(this.props, ['title', 'body', 'icon', 'timeout']);
      return (
         <ReactNotifications
            {...importedProps}
            onRef={ref => (this.n = ref)}
            onClick={this.handleClick}
         />
      );
   }
}

function mapStateToProps({notify}) {
   return {
      ...notify
   };
}

export default connect(mapStateToProps, {closeNotify})(GcNotify);