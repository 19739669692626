import { withFormsy } from 'formsy-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { pack } from '@gc';
import { Col, FormText, Label } from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';
import cx from 'classnames';

class AutosizeInputFormsy extends Component {
   constructor(props) {
      super(props);
      this.state = {
         touched: false,
      };
   }

   componentDidMount() {
      if (typeof this.props.setter === 'function') {
         this.props.setter(this.props.setValue);
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.viewmode !== this.props.viewmode) {
         this.setState({
            touched: false,
         });
      }
   }

   changeValue = evt => {
      if (!this.props.noUpdate) this.props.setValue(evt.target.value);
      if (typeof this.props.onChange === 'function') {
         evt.persist(); // 사용부분에서 onChange={_.debounce(this.handleChange, 300)} 처럼 사용하려면 먼저 persist 를 해줘야 한다.
         this.props.onChange(evt);
      }
   };

   onBlur = evt => {
      if (this.props.viewmode) return false;
      if (!this.state.touched) this.setState({ touched: true });
      if (typeof this.props.onBlur === 'function') {
         this.props.onBlur(evt);
      }
   };

   handleInnerRef = ref => {
      if (typeof this.props.inputRef === 'function') {
         this.props.inputRef(ref);
      }
   };

   reset = () => {
      this.props.setValue('');
   };

   render() {
      const { touched } = this.state;
      let importedProps = _.pick(this.props, ['name', 'onKeyDown', 'autoFocus', 'className', 'disabled', 'placeholder', 'maxRows', 'minRows', 'children']);
      let error = false;
      let errorMessage = this.props.getErrorMessage() || this.props.errorTrigger;
      const value = (!this.props.noUpdate ? this.props.getValue() : this.props.value) || '';

      if (touched && !value && !errorMessage && this.props.required) {
         errorMessage = this.props.requiredMsg || '';
         error = true;
      }

      if (this.props.matching) {
         const func = pack.getMatchingFunction(this.props.matching);
         if (func != null)
            importedProps = {
               ...importedProps,
               ...func,
            };
         else {
            console.log("No Matching Functions for '" + this.props.matching + "'");
         }
      }

      const labelWidth = this.props.labelWidth || { sm: 2 };
      const colWidth = this.props.colWidth || { sm: 4 };
      const id = this.props.id || this.props.name;
      importedProps.id = id;

      return (
         <>
            {Boolean(this.props.label) && (
               <Label {...labelWidth} for={id} className={cx({ required: !this.props.viewmode && this.props.required })}>
                  {this.props.label}
               </Label>
            )}
            <Col {...colWidth} className={cx('d-flex align-items-center', this.props.colClassName || '')}>
               {!this.props.viewmode ? (
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                     }}
                     className="w-100"
                  >
                     <TextareaAutosize
                        {...importedProps}
                        className={cx('autosize-textarea', importedProps.className || '')}
                        value={value}
                        onChange={this.changeValue}
                        onBlur={this.onBlur}
                        aria-invalid={Boolean(errorMessage) || error}
                        inputRef={this.handleInnerRef}
                     />
                     {Boolean(errorMessage) && <FormText color="danger">{errorMessage}</FormText>}
                  </div>
               ) : (
                  <Label className="view crlf">{value}</Label>
               )}
            </Col>
         </>
      );
   }
}

AutosizeInputFormsy.propTypes = {
   name: PropTypes.string.isRequired,
   id: PropTypes.string,
   label: PropTypes.string,
   placeholder: PropTypes.string,
   maxRows: PropTypes.number,
   minRows: PropTypes.number,
   onChange: PropTypes.func,
   onBlur: PropTypes.func,
   value: PropTypes.any,
   viewmode: PropTypes.bool,
   required: PropTypes.bool,
   requiredMsg: PropTypes.string,
   errorTrigger: PropTypes.string,
   matching: PropTypes.any,
   validations: PropTypes.any,
   validationErrors: PropTypes.object,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   disabled: PropTypes.bool,
   autoFocus: PropTypes.bool,
   inputRef: PropTypes.func,
   colClassName: PropTypes.string,
   noUpdate: PropTypes.bool,
   setter: PropTypes.func,
};

export default withFormsy(AutosizeInputFormsy);
