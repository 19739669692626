import * as Actions from '../actions/trello.actions';
import _ from 'lodash';

import thum1 from '../../../../assets/utils/images/trello/background/thum1.jpg';
import thum2 from '../../../../assets/utils/images/trello/background/thum2.jpg';
import thum3 from '../../../../assets/utils/images/trello/background/thum3.jpg';
import thum4 from '../../../../assets/utils/images/trello/background/thum4.jpg';
import thum5 from '../../../../assets/utils/images/trello/background/thum5.jpg';

import bg1 from '../../../../assets/utils/images/trello/background/bg1.jpg';
import bg2 from '../../../../assets/utils/images/trello/background/bg2.jpg';
import bg3 from '../../../../assets/utils/images/trello/background/bg3.jpg';
import bg4 from '../../../../assets/utils/images/trello/background/bg4.jpg';
import bg5 from '../../../../assets/utils/images/trello/background/bg5.jpg';

const initState = {
   boards: [], // 보드 목록화면에서 사용 : 최소한의 데이터만 포함하는 보드 데이터
   entities: [], // 보드 상세화면에서 사용 : 연관관계 데이터를 포함한 보드 데이터
   accessList: [],
   hiddenList: [],
   board: {
      lists: [],
      accessList: [],
      employee: {},
      hiders: [],
   },
   card: {
      employee: {},
      followers: [],
      tags: [],
      checklists: [],
      comments: [],
      files: [],
      board: {},
      list: {},
   },
   boardSttCodes: [],
   boardSearchValue: '',
   loadCnt: 0,
   newCommentCnt: 0,
   tags: [],
   cardCloseId: 0,
   sorts: [],
   fileSearchValue: '',
   boardsLoading: false,
   thums: [thum1, thum2, thum3, thum4, thum5],
   bgs: [bg1, bg2, bg3, bg4, bg5],
   colors: [
      '#e6e8ec',
      '#dbebf1',
      '#e1f3dd',
      '#cee2f0',
      '#d5ecea',
      '#d4e4fa',
      '#dbdcf5',
      '#f9e8f7',
      '#ffedc2',
      '#ffdcc1',
      '#eae1d5',
      '#ccd9d4',
      '#dfd4d4',
      '#e5e5d7',
      'linear-gradient(to top, rgb(215, 209, 201), rgb(212, 222, 231))',
      'linear-gradient(to top, rgb(213, 188, 156), rgb(240, 236, 226))',
      'linear-gradient(to top, rgb(204, 217, 247), rgb(231, 245, 248))',
      'linear-gradient(to top, rgb(249, 240, 229), rgb(177, 213, 244))',
      'linear-gradient(to top, rgb(250, 223, 190), rgb(249, 221, 251))',
      'linear-gradient(to top, rgb(189, 224, 245), rgb(255, 225, 238))',
   ],
   uploadingUserImage: false,
};

const myTaskTrelloReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.ENTITIES_LOADING:
         return {
            ...state,
            entitiesLoading: action.flag,
         };
      case Actions.FETCH_ENTITIES:
         return {
            ...state,
            entities: action.payload,
         };
      case Actions.FETCH_BOARDS:
         /*let boardList = action.list;
         const empno = action.empno;

         boardList.forEach((board, i) => {
            if (board.employee.empno !== empno) {
               let boardIn = false;
               board.lists.forEach(list => {
                  let listIn = list.cards.some(card => card.followers.some(f => f.empno === empno));
                  if (listIn) {
                     boardIn = true;
                     list.cards.forEach(card => {
                        card.show = card.followers.some(f => f.empno === empno);
                     });
                     list.cards = list.cards.filter(card => card.show);
                  }
                  list.show = listIn;
               });
               board.lists = board.lists.filter(list => list.show);
               board.show = boardIn;
            } else {
               board.show = true;
            }
         });

         boardList = boardList.filter(board => board.show);

         const boards = _.keyBy(
            boardList.map(board => ({
               ...board,
               hiders: board.hiders.map(emp => emp.empno),
            })),
            'id',
         );
*/
         return {
            ...state,
            boards: action.list,
            accessList: action.access,
            hiddenList: action.hidden,
            // board: Boolean(action.refresh) && Boolean(state.board.id) ? boards[state.board.id] : state.board,
            loadCnt: Boolean(action.refresh) ? state.loadCnt + 1 : state.loadCnt,
         };
      case Actions.TOGGLE_BOARDS_LOADING:
         return {
            ...state,
            boardsLoading: action.flag,
         };
      case Actions.FETCH_BOARD:
         // const board = state.boards[+action.boardid] || initState.board;
         return {
            ...state,
            board: action.payload,
         };
      case Actions.FETCH_BOARD_LOADING:
         // const board = state.boards[+action.boardid] || initState.board;
         return {
            ...state,
            boardLoading: action.flag,
         };
      case Actions.FETCH_BOARD_SORT:
         return {
            ...state,
            sorts: action.payload,
            loadCnt: state.loadCnt + 1,
         };
      case Actions.REORDER_BOARDS:
         return {
            ...state,
            sorts: action.sorts,
            loadCnt: state.loadCnt + 1,
         };
      case Actions.TOGGLE_BOARD_HIDDEN:
         /*const nowBoard = state.boards[action.boardid];
         try {
            if (action.flag) {
               const idx = _.findIndex(state.boards, o => o.id === action.boardid);
               state.hiddenList.push(state.boards)
               nowBoard.hiders = nowBoard.hiders.concat([action.empno]);
            } else {
               nowBoard.hiders = nowBoard.hiders.filter(eno => eno !== action.empno);
            }
         } catch (e) {
            console.log('board 숨김 토글중 오류가 발생했습니다\n해당 보드를 찾지 못했습니다. board id = ', action.boardid);
         }*/
         return {
            ...state,
            boards: action.flag
               ? state.boards.filter(it => it.id === action.boardid)
               : state.boards.concat(state.hiddenList.find(it => it.id === action.boardid)),
            hiddenList: action.flag
               ? state.hiddenList.concat(state.boards.find(it => it.id === action.boardid))
               : state.hiddenList.filter(it => it.id === action.boardid),
            loadCnt: state.loadCnt + 1,
         };
      case Actions.LOAD_BOARD_STT_CODES:
         return {
            ...state,
            boardSttCodes: _.keyBy(action.payload, 'codeValue'),
         };
      case Actions.SEARCH_BOARD:
         return {
            ...state,
            boardSearchValue: action.value,
         };
      case Actions.SEARCH_FILE:
         return {
            ...state,
            fileSearchValue: action.value,
         };
      /*case Actions.UPDATE_BOARD:
         state.boards[action.payload.id] = action.payload;
         return {
            ...state,
            loadCnt: state.loadCnt + 1,
         };*/
      case Actions.UPDATE_CARD:
         const updatedCard = action.data;
         const { id } = updatedCard;
         state.board.lists.forEach(list => {
            const idx = _.findIndex(list.cards, { id: +id });
            if (idx > -1) {
               list.cards.splice(idx, 1, updatedCard);
               return false;
            }
         });
         updatedCard.board = state.board;
         return {
            ...state,
            card: updatedCard,
            loadCnt: state.loadCnt + 1,
         };
      case Actions.SELECT_CARD:
         let curCard = undefined;
         state.board.lists.forEach(list => {
            const c = _.find(list.cards, { id: +action.id });
            if (Boolean(c)) {
               curCard = c;
               curCard['list'] = list;
               curCard['board'] = state.board;
               return false;
            }
         });
         return {
            ...state,
            card: curCard || state.card,
         };
      case Actions.DESELECT_CARD:
         return {
            ...state,
            card: initState.card,
         };
      /*case Actions.CHANGE_CARD_LOCATION:
         let card = action.card;
         let oldList = {},
            newList = {};

         if (action.boardChanged) {
            const oldB = state.boards[action.oldBid];
            const newB = state.boards[action.bid];
            oldList = _.find(oldB.lists, { id: action.oldLid });
            newList = _.find(newB.lists, { id: action.lid });
            card.board = newB;
         } else {
            const board = state.boards[action.bid];
            oldList = _.find(board.lists, { id: action.oldLid });
            newList = _.find(board.lists, { id: action.lid });
            card.board = board;
         }
         _.remove(oldList.cards, { id: card.id });
         newList.cards = newList.cards || [];
         newList.cards.push(card);
         card.list = newList;

         return {
            ...state,
            card,
            // loadCnt: state.loadCnt + 1
         };*/
      case Actions.CREATE_LIST:
         const list = action.payload;
         // const boardOfList = state.boards[list.board.id];
         const boardOfList = state.entities.find(it => it.id === list.board.id);
         boardOfList.lists = boardOfList.lists || [];
         boardOfList.lists.push(list);
         return {
            ...state,
            // loadCnt: state.loadCnt + 1
         };
      case Actions.ADD_FOLLOWER:
         const emp = action.payload;
         _.remove(state.card.followers, { empno: emp.empno });
         state.card.followers.push(emp);
         return state;
      case Actions.REMOVE_FOLLOWER:
         _.remove(state.card.followers, { empno: action.empno });
         return {
            ...state,
            loadCnt: state.loadCnt + 1,
         };
      case Actions.FETCH_CARD_TAGS:
         return {
            ...state,
            tags: action.payload,
         };
      case Actions.ADD_TAG_TO_CARD:
         const { tag } = action;
         const idx = _.findIndex(state.card.tags, { seq: tag.seq });
         if (idx > -1) {
            state.card.tags.splice(idx, 1, tag);
         }
         return state;
      case Actions.REMOVE_TAG_FROM_CARD:
         _.remove(state.card.tags, { seq: action.seq });
         return {
            ...state,
            loadCnt: state.loadCnt + 1,
         };
      case Actions.UPDATE_TAG:
         const updatedTag = action.payload;
         const tagIdx = _.findIndex(state.card.tags, { seq: updatedTag.seq });
         if (tagIdx > -1) {
            state.card.tags.splice(tagIdx, 1, updatedTag);
         }
         return state;
      case Actions.UPDATE_CHECKLIST:
         const checklist = action.payload;
         const toList = _.find(state.board.lists, { id: +action.listid });
         const toCard = _.find(toList.cards, { id: +action.cardid });
         const checkIdx = _.findIndex(toCard.checklists, { seq: checklist.seq });
         if (checkIdx > -1) {
            toCard.checklists.splice(checkIdx, 1, checklist);
         } else {
            toCard.checklists.push(checklist);
         }
         return {
            ...state,
            loadCnt: state.loadCnt + 1,
         };
      case Actions.REMOVE_CHECKLIST:
         _.remove(state.card.checklists, { seq: action.seq });
         return state;
      case Actions.ADD_FILES_TO_CARD:
         return {
            ...state,
            board: {
               ...state.board,
               lists: state.board.lists.map(list => {
                  const curCard = _.find(list.cards, { id: action.payload.id });
                  if (curCard) {
                     return {
                        ...list,
                        cards: list.cards.map(cd => {
                           if (cd.id === curCard.id) return action.payload;
                           else return cd;
                        }),
                     };
                  } else {
                     return list;
                  }
               }),
            },
            card: {
               ...state.card,
               ...action.payload,
            },
            loadCnt: state.loadCnt + 1,
         };
      case Actions.REMOVE_FILE_FROM_CARD:
         _.remove(state.card.files, { seq: action.seq });
         return {
            ...state,
            loadCnt: state.loadCnt + 1,
         };
      case Actions.COMMENT_ADDED:
         // const cBoard = state.boards[+action.boardid];
         // const cList = _.find(cBoard.lists, { id: +action.listid });
         // const cCard = _.find(cList.cards, { id: +action.cardid });
         // cCard.comments = action.comments;
         return {
            ...state,
            card: {
               ...state.card,
               comments: state.card.id === action.cardid ? state.card.comments.concat(action.comment) : state.card.comments,
               loadCnt: state.loadCnt + 1,
            },
            board: {
               ...state.board,
               lists: state.board.lists.map(list => {
                  const curCard = _.find(list.cards, { id: action.cardid });
                  if (curCard) {
                     return {
                        ...list,
                        cards: list.cards.map(cd => {
                           if (cd.id === curCard.id)
                              return {
                                 ...cd,
                                 comments: cd.comments.concat(action.comment),
                              };
                           else return cd;
                        }),
                     };
                  } else {
                     return list;
                  }
               }),
            },
            loadCnt: state.loadCnt + 1,
            newCommentCnt: state.newCommentCnt + 1,
            // card: state.card.id === cCard.seq ? cCard : state.card,
         };
      case Actions.UPDATE_COMMENT_LINK:
         return {
            ...state,
            card: {
               ...state.card,
               comments: state.card.comments.map(cmt => {
                  if (cmt.seq === action.payload.seq) {
                     return action.payload;
                  }
                  return cmt;
               }),
            },
         };
      case Actions.CARD_UPDATED:
         const cuBoard = state.board.id === action.boardid ? state.board : null;
         if (Boolean(cuBoard)) {
            const cuList = _.find(cuBoard.lists, { id: action.listid });
            if (Boolean(cuList)) {
               const cuCardIdx = _.findIndex(cuList.cards, { id: action.card.id });
               let nCard = action.card;
               if (state.card.id === action.card.id) {
                  nCard = {
                     ...state.card,
                     ...action.card,
                  };
               }
               cuList.cards.splice(cuCardIdx, 1, nCard);
            }
         }
         return {
            ...state,
            loadCnt: state.loadCnt + 1,
         };
      case Actions.CARD_NEED_TO_CLOSE:
         return {
            ...state,
            cardCloseId: action.id,
         };
      case Actions.LIST_UPDATED:
         const lix = _.findIndex(state.board.lists, { id: action.payload.id });
         if (lix > -1) {
            state.board.lists.splice(lix, 1, action.payload);
         } else {
            state.board.lists.push(action.payload);
         }
         return {
            ...state,
            loadCnt: state.loadCnt + 1,
         };
      case Actions.UPLOADING_USER_BACKGROUND:
         return {
            ...state,
            uploadingUserImage: action.flag,
         };
      /*case Actions.ADD_EMPTY_LIST:
         const ix = _.findIndex(state.board.lists, {id: action.tempid});
         if (ix > -1)
            state.board.lists.splice(ix, 1, action.list);
         return {
            ...state,
            loadCnt: state.loadCnt + 1
         };*/
      default:
         return state;
   }
};

export default myTaskTrelloReducer;
