import axios from 'axios';
import {startLoading, stopLoading} from "../../../../@gc/common/actions/common.action";
import {pack} from "@gc";

const jtf = require('json-form-data');

export const FETCH_COMPANYS = '[COMPANY] FETCH COMPANYS';
export const FETCH_COMPANY = '[COMPANY] FETCH COMPANY';
export const FETCH_SECU_CODES = '[COMPANY] FETCH SECU CODES';
export const OPEN_ADD = '[COMPANY] OPEN ADD';
export const OPEN_EDIT = '[COMPANY] OPEN EDIT';
export const OPEN_VIEW = '[COMPANY] OPEN VIEW';
export const OPEN_CLIENT = '[COMPANY] OPEN CLIENT';
export const CLOSE_CLIENT = '[COMPANY] CLOSE CLIENT';

export function fetchCompanys(showLoading, props) {
   return (dispatch, getState) => {
      if(showLoading)
         dispatch(startLoading());

      axios.get(`/company`).then(res => {
         dispatch(stopLoading());
         dispatch({
            type: FETCH_COMPANYS,
            payload: res.data
         });
         if(props && Boolean(props.match.params.seq)) {
            dispatch(fetchCompany(props.match.params.seq));
         }
      })
   }
}

export function fetchCompany(seq) {
   return (dispatch) =>
      dispatch({
         type: FETCH_COMPANY,
         seq
      });
}

export function fetchSecuCodes() {
   return (dispatch) =>
      axios.get(`/code/list/CP_SECU`)
         .then(res =>
            dispatch({
               type: FETCH_SECU_CODES,
               payload: res.data
            })
         );
}

export function openAdd({match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_ADD
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'add',
         seq: null
      }));
   };
}

export function openView(seq, {match, history}) {
   return (dispatch) => {
      dispatch(fetchCompany(seq));
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         seq: seq
      }));
   };
}

export function removeEntity(seq, {match, history}) {
   return (dispatch) =>
      axios.delete(`/company/${seq}`)
         .then(res => {
            dispatch(fetchCompanys(true));
            history.push(pack.getToPath(match)({
               ...match.params,
               mode: 'list',
               seq: null
            }));
         });
}

export function createEntity(data, {match, history}) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: '/company',
         data: jtf({...data, id: getState().gc.user.id})
      }).then(res => {
         dispatch(fetchCompanys());
         history.push(pack.getToPath(match)({
            ...match.params,
            mode: 'list',
            seq: null
         }));
      });
}

export function openClient(seq) {
   return (dispatch) => {
      axios.get(`/client/${seq}`)
         .then(res =>
            dispatch({
               type: OPEN_CLIENT,
               payload: res.data
            })
         )
   }
}

export function closeClient() {
   return {
      type: CLOSE_CLIENT
   };
}
