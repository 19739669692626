import axios from 'axios';
import { pack } from '../../../../../@gc';
import { toastr } from 'react-redux-toastr';
import { PROJECT_SAVED, SAVING_PROJECT } from '../../project/actions';
import { entityToEvent } from '../../common';

export const SET_ACTIVITY_ENTITY = '[TMS] SET ACTIVITY ENTITY';
export const LOADING_ACTIVITIES = '[TMS] LOADING ACTIVITIES';
export const SAVING_ACTIVITY = '[TMS] SAVING ACTIVITY';
export const REFRESH_ACTIVITY = '[TMS] REFRESH ACTIVITY';
export const FETCH_SIMPLE_PROJECTS = '[TMS] FETCH SIMPLE PROJECTS';
export const ACTIVITY_SAVED = '[TMS] ACTIVITY SAVED';
export const ACTIVITY_REMOVED = '[TMS] ACTIVITY REMOVED';
export const ACTIVITY_EVENTS = '[TMS] ACTIVITY EVENTS';

export function fetchSimpleProjects() {
   return (dispatch, getState) => {
      const { user } = getState().gc;

      axios
         .get(`/tms/simple/projects/${user.empno}`)
         .then(res =>
            dispatch({
               type: FETCH_SIMPLE_PROJECTS,
               payload: res.data,
            }),
         )
         .catch(pack.showError);
   };
}

export function fetchActivities(year, notLoading) {
   return (dispatch, getState) => {
      const { user } = getState().gc;

      if (!notLoading)
         dispatch({
            type: LOADING_ACTIVITIES,
            flag: true,
         });

      axios
         .get(`/tms/activity/events/${user.empno}/${year}`)
         .then(response => {
            const events = response.data.map(entity => entityToEvent(entity));

            dispatch({
               type: ACTIVITY_EVENTS,
               events,
            });
         })
         .catch(pack.showError)
         .finally(() =>
            dispatch({
               type: LOADING_ACTIVITIES,
               flag: false,
            }),
         );
   };
}

export function setActivityEntity(entity) {
   return {
      type: SET_ACTIVITY_ENTITY,
      entity,
   };
}

export function saveActivity(model) {
   return (dispatch, getState) => {
      const isUpdate = Boolean(model.id);
      const {
         user,
         common: { strings },
      } = getState().gc;

      dispatch({
         type: SAVING_ACTIVITY,
         flag: true,
      });

      axios
         .post(`/tms/activity/${user.empno}`, JSON.stringify(model))
         .then(res => {
            toastr.success(strings.infoTitle, strings.successMessage);
            dispatch({
               type: ACTIVITY_SAVED,
               payload: entityToEvent(res.data),
               isUpdate,
            });
         })
         .catch(pack.showError)
         .finally(() =>
            dispatch({
               type: SAVING_ACTIVITY,
               flag: false,
            }),
         );
   };
}

export function removeActivity(id) {
   return (dispatch, getState) => {
      const { strings } = getState().gc.common;

      axios
         .delete(`/tms/activity/${id}`)
         .then(res => {
            toastr.success(strings.infoTitle, strings.successMessage);
            dispatch({
               type: ACTIVITY_REMOVED,
               id,
            });
         })
         .catch(pack.showError);
   };
}
