import {pack} from "@gc";
import * as Actions from "../actions";

const initState = {
   entities: [],
   entity: {
      id: '',
      pid: 'boards',
      title: 'menu_activity_boardN',
      detail: '',
      icon: '',
      sort: null,
      isuse: '1'
   },
   newEntity: null,
   mode: 'view',   // list, add, edit, view
   listLoadCnt: 0,
   localeLoadCnt: 0,
   data: {}    // locale.json data
};

const boardmgrReducer = function(state = initState, action) {
   switch(action.type) {
      case Actions.FETCH_LIST:
         return {
            ...state,
            entities: action.payload,
            listLoadCnt: state.listLoadCnt + 1
         };
      case Actions.FETCH_ENTITY:
         return {
            ...state,
            entity: action.entity || state.entity
         };
      case Actions.FETCH_LOCALE:
         return {
            ...state,
            data: action.payload,
            localeLoadCnt: state.localeLoadCnt + 1
         };
      case Actions.OPEN_ADD:
         return {
            ...state,
            entity: initState.entity
         };
      case Actions.UPDATED_BOARD:
         return {
            ...state,
            entity: initState.entity,
            data: action.data || state.data,
            localeLoadCnt: state.localeLoadCnt + 1
         };
      case Actions.SELECT_ITEM:
         return {
            ...state,
            entity: action.payload
         };
      default:
         return state;
   }
};

export default boardmgrReducer;