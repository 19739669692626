import * as Actions from '../actions';
import _ from 'lodash';

const initState = {
   entities: [],
   entity: {}
};

const outsourceReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_OUTSOURCES:
         return {
            ...state,
            entities: action.payload
         };
      case Actions.FETCH_OUTSOURCE:
         return {
            ...state,
            entity: _.find(state.entities, {seq: +(action.seq)})
         };
      case Actions.OPEN_ADD:
         return {
            ...state,
            entity: initState.entity
         };
      case Actions.REMOVE_ENTITY:
         _.remove(state.entities, {seq: +(action.seq)});
         return state;
      default:
         return state;
   }
};

export default outsourceReducer;