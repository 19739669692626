import {combineReducers} from "redux";
import vacation from "../vacation/reducers";
import commute from "../commute/reducers";
import company from "../company/reducers";
import client from "../client/reducers";
import firm from "../firm/reducers";
import seller from "../seller/reducers";
import product from "../product/reducers";
import project from "../project/reducers";
import outsource from "../outsource/reducers";

const manageAppReducer = combineReducers({
   vacation,
   commute,
   company,
   client,
   firm,
   seller,
   product,
   project,
   outsource,
});

export default manageAppReducer;