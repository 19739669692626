import axios from 'axios';
import { startLoading, stopLoading } from '@gc/common/actions/common.action';
import { updateUserPoint } from '@gc/auth/actions/user.action';
import { pack } from '@gc';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';

const jtf = require('json-form-data');

export const OPEN_BOARDS_ADD = '[BOARDS APP] OPEN BOARDS ADD';
export const OPEN_BOARDS_LIST = '[BOARDS APP] OPEN BOARDS LIST';
export const OPEN_BOARDS_EDIT = '[BOARDS APP] OPEN_BOARDS_EDIT';
export const OPEN_BOARDS_VIEW = '[BOARDS APP] OPEN_BOARDS_VIEW';
export const UPDATE_PAGING_PARAM = '[BOARDS APP] UPDATE PAGING PARAM';
export const REMOVE_BOARDS = '[BOARDS APP] REMOVE_BOARDS';
export const FETCH_BOARDS = '[BOARDS APP] FETCH BOARDS';
export const FETCH_BOARD = '[BOARDS APP] FETCH BOARD';
export const FETCH_BOARDS_COMMENTS = '[BOARDS APP] FETCH BOARDS COMMENTS';
export const BOARDS_UPDATE_VIEW = '[BOARDS APP] BOARDS UPDATE VIEW';
export const SELECT_ALL_POSTS = '[BOARDS APP] SELECT ALL POSTS';
export const DESELECT_ALL_POSTS = '[BOARDS APP] DESELECT ALL POSTS';
export const TOGGLE_IN_SELECTED_POSTS = '[BOARDS APP] TOGGLE IN SELECTED POSTS';
export const CONFIRM_BOARDS_POINT = '[BOARDS APP] CONFIRM BOARDS POINT';
export const UPDATE_CATEGORY = '[BOARDS APP] CREATE CATEGORY';
export const FETCH_CATEGORY_TREE = '[BOARDS APP] FETCH CATEGORY TREE';
export const FETCH_CATEGORY_SELECT_TREE = '[BOARDS APP] FETCH CATEGORY SELECT TREE';
export const SELECT_CATEGORY = '[BOARDS APP] SELECT CATEGORY';
export const TOGGLE_BOARDS_STARRED = '[BOARDS APP] TOGGLE BOARDS STARRED';
export const TOGGLE_BOARDS_STARRED_LIST = '[BOARDS APP] TOGGLE BOARDS STARRED LIST';
export const ADD_BOARDS_COMMENT = '[BOARDS APP] ADD COMMENT';
export const EDIT_BOARDS_COMMENT = '[BOARDS APP] EDIT COMMENT';
export const ADD_COMMENT_REPLY = '[BOARDS APP] ADD REPLY';
export const UPDATE_COMMENT = '[BOARDS APP] UPDATE COMMENT';

export const TOGGLE_BOARDS_LIST_ALL = 'all';
export const TOGGLE_BOARDS_LIST = 'list';
export const TOGGLE_BOARDS_LIST_FREQ = 'frequent';
export const TOGGLE_BOARDS_LIST_STAR = 'starred';

export function fetchBoards({ no, seq }, initParams, onSuccess) {
   return (dispatch, getState) => {
      const { listType, category, pagingParam, defaultPagingParam } = getState().griffin.activity.boards;
      const param = initParams ? defaultPagingParam : { ...pagingParam, sorted: [{ id: 'isnotice', desc: true }].concat(pagingParam.sorted || []) };

      let data = { ...param, categorySeq: category.id, userId: getState().gc.user.id };

      axios
         .post(`/board/${no}/${listType}`, JSON.stringify(data))
         .then(response => {
            dispatch({
               type: FETCH_BOARDS,
               payload: response.data,
            });
            /*if (Boolean(seq)) {
               dispatch(fetchBoard(no, seq));
            }*/
         })
         .catch(pack.showError)
         .finally(() => {
            if (typeof onSuccess === 'function') {
               onSuccess();
            }
         });
   };
}

export function fetchBoard(no, seq, onSuccess) {
   return (dispatch, getState) => {
      axios.get(`/board/entity/${no}/${seq}`).then(res => {
         const { data } = res;
         dispatch({
            type: FETCH_BOARD,
            payload: data,
         });
         dispatch(updateView(data));

         const request = axios.get(`/board-category/${data.categorySeq}`);
         request.then(res => {
            dispatch({
               type: OPEN_BOARDS_VIEW,
               payload: data,
               category: res.data,
            });
         });

         if (typeof onSuccess === 'function') {
            onSuccess();
         }
      });
   };
}

export function updatePagingParam(param, onSuccess) {
   return dispatch => {
      dispatch({
         type: UPDATE_PAGING_PARAM,
         param,
      });
      if (typeof onSuccess === 'function') {
         onSuccess();
      }
   };
}

export function toggleList(listType) {
   return {
      type: TOGGLE_BOARDS_LIST,
      listType,
   };
}

export function openBoardsAdd({ history, match }) {
   return dispatch => {
      dispatch({
         type: OPEN_BOARDS_ADD,
      });
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'add',
            seq: null,
         }),
      );
   };
}

export function openBoardsList({ history, match }) {
   return dispatch => {
      dispatch(updateUserPoint());
      history.push(
         pack.getToPath(match)({
            ...match.params,
            mode: 'list',
            seq: null,
         }),
      );
   };
}

function updateView(data) {
   return (dispatch, getState) =>
      axios({
         url: '/board/read',
         method: 'POST',
         data: jtf({ boardNo: `${data.boardsId.boardNo}`, boardsSeq: `${data.boardsId.seq}`, readId: `${getState().gc.user.id}` }),
      }).then(res => {
         if (res.status === 200) {
            dispatch({
               type: BOARDS_UPDATE_VIEW,
               payload: res.data,
            });
         } else {
            toastr.error(pack.strings.errTitle, pack.strings[res.error]);
         }
      });
}

export function openBoardsView({ history, match }, seq) {
   const { no: boardNo, seq: viewSeq } = match.params;
   const finalSeq = seq || viewSeq;
   return (dispatch, getState) => {
      dispatch(
         fetchBoard(boardNo, finalSeq, () =>
            history.push(
               pack.getToPath(match)({
                  ...match.params,
                  mode: 'view',
                  seq: finalSeq,
               }),
            ),
         ),
      );
   };
}

export function updatePost({ history, match }, param) {
   const {
      boardsId: { no, seq },
   } = param;
   param = pack.jsonToJpa(param, 'boardsId');
   return (dispatch, getState) => {
      axios({ method: 'post', url: `/board/update/${no}/${seq}`, data: jtf(param) })
         .then(res => {
            dispatch(fetchBoards(match.params));
            // dispatch(openBoardsView({history, match}, res.data));
            // dispatch(fetchBoards(match.params, getState().griffin.activity.boards.category.id));
            history.push(
               pack.getToPath(match)({
                  ...match.params,
                  mode: 'view',
                  seq,
               }),
            );
         })
         .catch(e => pack.showError(e));
   };
}

export function removeBoards({ history, match }, seq) {
   const request = axios.delete(`/board/delete/${match.params.no}/${seq}`);

   return (dispatch, getState) =>
      request.then(res => {
         dispatch({
            type: REMOVE_BOARDS,
            seq,
         });
         // dispatch(fetchBoards(match.params, getState().griffin.activity.boards.category.id)),
         history.push(
            pack.getToPath(match)({
               ...match.params,
               mode: 'list',
               seq: null,
            }),
         );
      });
}

export function selectAllPosts() {
   return {
      type: SELECT_ALL_POSTS,
   };
}

export function deSelectAllPosts() {
   return {
      type: DESELECT_ALL_POSTS,
   };
}

export function toggleInSelectedPosts({ no }, row) {
   // const star = row.starred === '1' ? 'unstarred' : 'starred';
   // const request = axios({ method: 'post', url: `/board/${no}/${star}`, data: jtf({seqs: [row.boardsId.seq], ...pack.getSessionData()}) });
   return {
      type: TOGGLE_IN_SELECTED_POSTS,
      postSeq: row.boardsId.seq,
   };
}

export function setPostsStarred(routeParams, postSeqs) {
   return (dispatch, getState) => {
      return axios({
         method: 'post',
         url: `/board/star/${routeParams.no}/starred`,
         data: jtf({ seqs: postSeqs.join(','), id: getState().gc.user.id }),
      }).then(() => {
         dispatch({
            type: TOGGLE_BOARDS_STARRED_LIST,
            starred: '1',
            seqs: postSeqs || [],
         });
         dispatch(fetchBoards(routeParams, getState().griffin.activity.boards.category.id));
      });
   };
}

export function setPostsUnstarred(routeParams, postSeqs) {
   return (dispatch, getState) => {
      const request = axios({
         method: 'post',
         url: `/board/star/${routeParams.no}/unstarred`,
         data: jtf({ seqs: postSeqs.join(','), id: getState().gc.user.id }),
      });

      return request.then(() => {
         dispatch({
            type: TOGGLE_BOARDS_STARRED_LIST,
            starred: '0',
            seqs: postSeqs || [],
         });
         dispatch(fetchBoards(routeParams, getState().griffin.activity.boards.category.id));
      });
   };
}

export function toggleStarredPost(routeParams, row, onSuccess) {
   return (dispatch, getState) => {
      const star = row.starred === '1' ? 'unstarred' : 'starred';
      axios({ method: 'post', url: `/board/star/${routeParams.no}/${star}`, data: jtf({ seq: row.boardsId.seq, id: getState().gc.user.id }) }).then(() => {
         if (typeof onSuccess === 'function') {
            onSuccess();
         }
      });

      dispatch({
         type: TOGGLE_BOARDS_STARRED,
         seq: row.boardsId.seq,
         newStarred: row.starred === '1' ? '0' : '1',
      });

      // request.then(res => dispatch(fetchBoards(routeParams, getState().griffin.activity.boards.category.id)));
   };
}

export function confirmBoardsPoint(routeParams, data) {
   data = pack.jsonToJpa(data, 'boardsId');

   const request = axios({
      method: 'post',
      url: '/boards/point/update',
      data: jtf(data),
   });

   return dispatch =>
      request.then(res => {
         dispatch({
            type: CONFIRM_BOARDS_POINT,
         });
         // dispatch(fetchBoards(routeParams));
         dispatch(updateUserPoint());
      });
}

/* 카테고리 */
export function fetchCategoryTree({ no }) {
   return (dispatch, getState) => {
      axios.get(`/board-tree/${no}?lang=${getState().gc.common.lang}`).then(res => {
         const { tree, list, select } = res.data;
         return dispatch({
            type: FETCH_CATEGORY_TREE,
            tree,
            list,
            select,
         });
      });
   };
}

export function updateCategory(routeParams, data) {
   return dispatch => {
      const request = axios({
         method: 'post',
         url: `/board-category`,
         data: jtf({
            seq: data.id,
            title: data.title,
            parentSeq: data.pid,
            boardNo: routeParams.no,
         }),
      });

      request.then(res => {
         const cate = res.data;
         dispatch({
            type: UPDATE_CATEGORY,
            payload: {
               id: cate.seq,
               pid: cate.parentSeq,
               title: cate.title,
               ptitle: cate.ptitle,
            },
         });
         dispatch(fetchCategoryTree(routeParams));
      });
   };
}

export function removeCategory(props, categoryId) {
   const request = axios.delete(`/board-category/${categoryId}`);
   return dispatch => {
      request.then(res => {
         dispatch(fetchCategoryTree(props.match.params));
         dispatch(selectTreeNode(props));
      });
   };
}

export function selectTreeNode(props, categoryId) {
   return (dispatch, getState) => {
      if (!categoryId) {
         dispatch({
            type: SELECT_CATEGORY,
            payload: null,
         });
      } else if (categoryId === '0') {
         dispatch({
            type: SELECT_CATEGORY,
            payload: {
               id: '0',
               title: pack.servermsg()['board.category.root'],
            },
         });
      } else {
         axios.get(`/board-category/${categoryId}`).then(res => {
            dispatch({
               type: SELECT_CATEGORY,
               payload: res.data,
            });
         });
      }
      const { history, match } = props;
      if (match.params.mode !== 'list') {
         history.push(
            pack.getToPath(match)({
               ...match.params,
               mode: 'list',
               seq: null,
            }),
         );
      }
      // if (Boolean(loadList)) dispatch(fetchBoards(props.match.params, categoryId && categoryId !== '0' ? categoryId : null, true));
   };
}

export function addComment(content, { boardsId }) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: `/board/comment`,
         data: jtf({
            content,
            boardNo: boardsId.boardNo,
            boardsSeq: boardsId.seq,
            id: getState().gc.user.id,
         }),
      }).then(res => {
         dispatch({
            type: UPDATE_COMMENT,
            payload: res.data,
         });
      });
   };
}

export function editComment(content, seq) {
   return dispatch =>
      axios({
         method: 'post',
         url: `/board/comment/update`,
         data: jtf({
            content,
            seq,
         }),
      }).then(res => {
         dispatch({
            type: UPDATE_COMMENT,
            payload: res.data,
         });
      });
}

export function addReply(content, seq) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: '/board/comment/reply',
         data: jtf({ content, seq, id: getState().gc.user.id }),
      }).then(res =>
         dispatch({
            type: UPDATE_COMMENT,
            payload: res.data,
         }),
      );
}

export function removeComment(comment) {
   return dispatch =>
      axios.delete(`/board/comment/${comment.seq}`).then(res =>
         dispatch({
            type: UPDATE_COMMENT,
            payload: res.data,
         }),
      );
}
