import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';
import { pack } from '../global';

export default function useTinymce({
   withoutForm,
   editorId,
   name,
   defaultValue,
   mode = 'default',
   height = 400,
   maxHeight = 1200,
   control,
   rules,
   onKeyDown,
   onKeyPress,
   autoFocus,
   inline,
}) {
   const [isReady, setIsReady] = useState(false);
   const editorRef = useRef(null);

   const onInit = useCallback(() => {
      const buttons = document.querySelectorAll(`textarea#${editorId} ~ .tox-tinymce .tox-tbtn`);
      for (let i = 0; i < buttons.length; i++) {
         const btn = buttons[i];
         if (btn.getAttribute('title') === 'Insert/edit image') {
            btn.addEventListener('click', e => {
               setTimeout(() => {
                  const uploadBtn = document.querySelector('.tox-dialog__body div[role="tablist"] .tox-tab:last-child');
                  if (uploadBtn instanceof Element) uploadBtn.click();
               }, 200);
            });
            break;
         }
      }
      setIsReady(true);
   }, [editorId]);

   const setEditorValue = useCallback(
      value => {
         editorRef.current.editor.setContent(value);
      },
      [editorRef],
   );

   const getEditorValue = useCallback(() => editorRef.current.editor.getContent(), []);

   const TinyEditor = useMemo(
      () =>
         withoutForm ? (
            <Editor
               apiKey={process.env.REACT_APP_TINYMCE_KEY}
               id={editorId}
               initialValue={defaultValue}
               init={pack.tinyOptions.init({
                  editorId,
                  autoFocus,
                  height,
                  maxHeight,
                  inline,
                  mode,
               })}
               onInit={onInit}
               ref={editorRef}
               onKeyDown={onKeyDown}
               onKeyPress={onKeyPress}
            />
         ) : (
            <Controller
               render={props => (
                  <Editor
                     apiKey={process.env.REACT_APP_TINYMCE_KEY}
                     id={editorId}
                     initialValue={defaultValue}
                     init={pack.tinyOptions.init({
                        editorId,
                        autoFocus,
                        height,
                        maxHeight,
                        inline,
                        mode,
                     })}
                     onInit={onInit}
                     ref={editorRef}
                     onEditorChange={props.field.onChange}
                     value={props.field.value}
                     onKeyDown={onKeyDown}
                     onKeyPress={onKeyPress}
                  />
               )}
               name={name}
               control={control}
               rules={rules}
            />
         ),
      [editorId, autoFocus, height, maxHeight, inline, mode, defaultValue, editorRef, name, rules],
   );

   return { TinyEditor, editorRef, setEditorValue, getEditorValue, isReady };
}
