import { FETCH_APPROVE_COUNT, FETCH_AUTH_COUNT, FETCH_CLIENT_IP, FETCH_CODE_COUNT, FETCH_DEPT_COUNT, FETCH_EMP_COUNT, FETCH_POSITION_COUNT } from '../actions';

const initState = {
   countIng: 0,
   countConfirm: 0,
   countReject: 0,
   countEmp: {
      total: 0,
      retire: 0,
   },
   countDept: {
      total: 0,
      hidden: 0,
   },
   countAuth: 0,
   countPosition: 0,
   countCode: {
      total: 0,
      mapped: 0,
   },
   ip: '0.0.0.0',
};

const adminboardReducer = (state = initState, action) => {
   switch (action.type) {
      case FETCH_APPROVE_COUNT:
         return {
            ...state,
            countIng: action.ing,
            countConfirm: action.confirm,
            countReject: action.reject,
         };
      case FETCH_EMP_COUNT:
         return {
            ...state,
            countEmp: action.payload,
         };
      case FETCH_DEPT_COUNT:
         return {
            ...state,
            countDept: action.payload,
         };
      case FETCH_AUTH_COUNT:
         return {
            ...state,
            countAuth: action.count,
         };
      case FETCH_POSITION_COUNT:
         return {
            ...state,
            countPosition: action.count,
         };
      case FETCH_CODE_COUNT:
         return {
            ...state,
            countCode: action.payload,
         };
      case FETCH_CLIENT_IP:
         return {
            ...state,
            ip: action.ip,
         };
      default:
         return state;
   }
};

export default adminboardReducer;
