import { withFormsy } from 'formsy-react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { pack } from '@gc';
import { Button, Col, FormText, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import cx from 'classnames';
import zxcvbn from 'zxcvbn';

class PasswordCheckFormsy extends Component {
   constructor(props) {
      super(props);
      this.state = {
         touched: false,
      };
   }

   componentDidMount() {
      this.rules = pack.passwordRules();
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.viewmode !== this.props.viewmode) {
         this.setState({
            touched: false,
            score: -1,
         });
      }
   }

   /*componentDidMount() {
      if (this.props.focusDelay) {
         const ip = this.input;
         if(ip)
            setTimeout(() => {
               ip.focus();
            }, this.props.focusDelay);
      }
   }*/

   changeValue = evt => {
      const val = evt.target.value;
      this.props.setValue(val);
      this.setState({
         score: zxcvbn(val).score,
      });
      if (typeof this.props.onChange === 'function') {
         this.props.onChange(evt);
      }
   };

   onBlur = evt => {
      if (this.props.viewmode) return false;
      if (!this.state.touched) this.setState({ touched: true });
      if (typeof this.props.onBlur === 'function') {
         this.props.onBlur(evt);
      }
   };

   handleInnerRef = ref => {
      if (typeof this.props.inputRef === 'function') {
         this.props.inputRef(ref);
      }
   };

   render() {
      const { touched } = this.state;
      const { strings } = this.props;
      let importedProps = _.pick(this.props, ['name', 'autoFocus', 'className', 'onFocus', 'placeholder', 'innerRef', 'children', 'disabled', 'data-lpignore']);
      let error = false;
      let errorMessage = this.props.getErrorMessage() || this.props.errorTrigger;
      const value = this.props.getValue() || '';

      if (!touched && Boolean(value) && this.state.score === -1) {
         const zx = zxcvbn(value);
         this.setState({
            score: zx.score,
         });
      }

      if (!errorMessage && Boolean(value)) {
         errorMessage = pack.passwordRuleCheck(value, strings);
      }

      if (touched && !value && !errorMessage && this.props.required) {
         errorMessage = this.props.requiredMsg || '';
         error = true;
      }
      if (Boolean(errorMessage)) {
         error = true;
      }

      if (this.props.matching) {
         const func = pack.getMatchingFunction(this.props.matching);
         if (func != null) {
            importedProps = {
               ...importedProps,
               ...func,
            };
         } else {
            console.log("No Matching Functions for '" + this.props.matching + "'");
         }
      }

      const labelWidth = this.props.labelWidth || { sm: 2 };
      const colWidth = this.props.colWidth || { sm: 4 };
      const id = this.props.id || this.props.name;
      const strength = pack.getPasswordStrengthMsg2(value, strings);
      importedProps.id = id;
      importedProps.type = 'password';

      return (
         <>
            {Boolean(this.props.label) && (
               <Label {...labelWidth} for={id} className={cx({ required: !this.props.viewmode && this.props.required })}>
                  {this.props.label}
               </Label>
            )}
            <Col {...colWidth} className={cx('d-flex align-items-center', this.props.colClassName || '')}>
               {!this.props.viewmode ? (
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                     }}
                     className="w-100"
                  >
                     <InputGroup>
                        <Input
                           {...importedProps}
                           value={value}
                           onChange={this.changeValue}
                           onBlur={this.onBlur}
                           invalid={Boolean(errorMessage) || error}
                           innerRef={this.handleInnerRef}
                        />
                        {
                           <InputGroupAddon addonType="append">
                              <Button color="light" className={cx('input-group-addon-button', `${strength.className}`)}>
                                 {strength.msg}
                              </Button>
                           </InputGroupAddon>
                        }
                     </InputGroup>
                     {Boolean(errorMessage) && <FormText color="danger">{errorMessage}</FormText>}
                  </div>
               ) : (
                  <Label className="view">{value}</Label>
               )}
            </Col>
         </>
      );
   }
}

PasswordCheckFormsy.propTypes = {
   name: PropTypes.string.isRequired,
   type: PropTypes.string,
   id: PropTypes.string,
   label: PropTypes.string,
   placeholder: PropTypes.string,
   onChange: PropTypes.func,
   onBlur: PropTypes.func,
   value: PropTypes.any,
   viewmode: PropTypes.bool,
   required: PropTypes.bool,
   errorTrigger: PropTypes.string,
   matching: PropTypes.any,
   validations: PropTypes.any,
   validationErrors: PropTypes.object,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   inputRef: PropTypes.func,
   disabled: PropTypes.bool,
   colClassName: PropTypes.string,
};

function mapStateToProps({ gc }) {
   return {
      strings: gc.common.strings,
   };
}

export default withFormsy(connect(mapStateToProps, null)(PasswordCheckFormsy));
