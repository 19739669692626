import _ from 'lodash';
import * as Actions from '../actions';
import { pack } from '@gc';
import { yellow, purple, green, blue, red } from '@material-ui/core/colors';
import { EXPAND_TASK_PANEL } from '../actions';

const initState = {
   entities: [], // to-do, 업무타입 등이 모두 매핑되어있는 리스트 (내가 담당자인 업무)
   alltasks: [], // 차트 데이터용 리스트 (전체업무)
   teamtasks: [],
   mytasks: {},
   task: {
      todoList: [],
   },
   todo: {
      uploads: [],
      comments: [],
   },
   dialog: {
      type: 'view',
   },
   mode: 'list', // 리스트, 칼렌다 토글 (구버전용)
   labels: [],
   imps: [],
   impDesc: pack.strings.importance,
   taskLoaded: true,
   currentTab: 0,
   givenCnt: 0,
   givenRate: 0,
   takenCnt: 0,
   takenRate: 0,
   doneCnt: 0,
   doneRate: 0,
   pastCnt: 0,
   pastRate: 0,
   totalDoneCnt: 0,
   givenDoneCnt: 0,
   takenDoneCnt: 0,
   todoRateData: {
      labels: ['taskRateTaken', 'todoRateDone', 'todoRateStar', 'todoRateImp', 'todoRateLockAndUndone'],
      datasets: [
         {
            data: [1, 1, 1, 1, 1],
            backgroundColor: [blue[500], green[500], yellow[500], purple[500], red[500]],
            hoverBackgroundColor: [blue[600], green[600], yellow[600], purple[600], red[600]],
         },
      ],
   },
   year: new Date().getFullYear(),
   newTaskLoading: false,
   myTaskLoading: false,
   uploadCnt: 0,
   taskLoadCnt: 0,
   mytaskExpand: true,
};

let task = null,
   todo = null,
   taskEntity = null,
   todoEntity = null;

const myTaskReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_MAIN_TASK_LABEL_CODE:
         return {
            ...state,
            labels: action.payload,
         };
      case Actions.FETCH_MAIN_TASK_IMP_CODE:
         return {
            ...state,
            imps: action.payload,
         };
      case Actions.FETCH_IMP_DESC:
         return {
            ...state,
            impDesc: action.payload,
         };
      case Actions.FETCH_ALL_TASKS:
         return {
            ...state,
            alltasks: action.payload,
         };
      case Actions.FETCH_NEW_TASKS:
         const marr = pack.deepClone(action.payload.filter(task => task.istaken === '1'));
         marr.forEach(item => {
            item['todoList'] = _.keyBy(item.todoList, 'seq');
         });
         const newTask = _.find(marr, o => o.taskSeq === state.task.taskSeq && o.dateSeq === state.task.dateSeq);
         return {
            ...state,
            entities: action.payload,
            mytasks: _.keyBy(marr, o => `${o.taskSeq}.${o.dateSeq}`),
            task: newTask || state.task,
         };
      case Actions.FETCH_CHART_RATE_DATA:
         const cntData = action.payload;
         return {
            ...state,
            givenCnt: cntData.given,
            givenRate: ((cntData.given / (cntData.total || 1)) * 100).toFixed(2),
            takenCnt: cntData.taken,
            takenRate: ((cntData.taken / (cntData.given || 1)) * 100).toFixed(2),
            doneCnt: cntData.done,
            doneRate: ((cntData.done / (cntData.taken || 1)) * 100).toFixed(2),
            pastCnt: cntData.past,
            pastRate: ((cntData.past / (cntData.given || 1)) * 100).toFixed(2),
            totalCnt: cntData.total,
            totalDoneCnt: ((cntData.totalDone / (cntData.total || 1)) * 100).toFixed(2),
            givenDoneCnt: ((cntData.givenDone / (cntData.given || 1)) * 100).toFixed(2),
            takenDoneCnt: ((cntData.takenDone / (cntData.taken || 1)) * 100).toFixed(2),
         };
      case Actions.FETCH_TODO_RATE_DATA:
         return {
            ...state,
            todoRateData: {
               ...state.todoRateData,
               datasets: [
                  {
                     ...state.todoRateData.datasets[0],
                     data: action.payload,
                  },
               ],
            },
         };
      case Actions.START_MYTASK_LOADING:
         return {
            ...state,
            myTaskLoading: true,
         };
      case Actions.STOP_MYTASK_LOADING:
         return {
            ...state,
            myTaskLoading: false,
         };
      case Actions.START_NEWTASK_LOADING:
         return {
            ...state,
            newTaskLoading: true,
         };
      case Actions.STOP_NEWTASK_LOADING:
         return {
            ...state,
            newTaskLoading: false,
         };
      case Actions.PLUS_YEAR:
         return {
            ...state,
            year: state.year + 1,
         };
      case Actions.MINUS_YEAR:
         return {
            ...state,
            year: state.year - 1,
         };
      case Actions.TOGGLE_MYTASK_VIEW:
         return {
            ...state,
            mode: state.mode === 'list' ? 'calendar' : 'list',
         };
      case Actions.TOGGLE_MYTASK_IMPORTANT:
         task = state.mytasks[`${action.todo.taskSeq}.${action.todo.dateSeq}`];
         todo = task.todoList[action.todo.seq];
         todo['important'] = action.payload;

         return {
            ...state,
            task,
            todo,
            taskLoadCnt: state.taskLoadCnt + 1,
         };
      case Actions.TOGGLE_MYTASK_STAR:
         task = state.mytasks[`${action.todo.taskSeq}.${action.todo.dateSeq}`];
         todo = task.todoList[action.todo.seq];
         todo['starred'] = action.payload;

         return {
            ...state,
            task,
            todo,
            taskLoadCnt: state.taskLoadCnt + 1,
         };
      case Actions.TOGGLE_MYTASK_LOCKED:
         task = state.mytasks[`${action.todo.taskSeq}.${action.todo.dateSeq}`];
         todo = task.todoList[action.todo.seq];
         todo.islock = action.payload;
         todo.lockUser =
            action.payload === '1'
               ? {
                    member: { id: action.user.id },
                 }
               : null;

         return {
            ...state,
            task,
            todo,
            taskLoadCnt: state.taskLoadCnt + 1,
         };
      case Actions.TOGGLE_TASK_DONE:
         task = state.mytasks[`${action.todo.taskSeq}.${action.todo.dateSeq}`];
         todo = task.todoList[action.todo.seq];
         todo.isdone = action.payload;
         todo.doneUser = action.payload === '1' ? { member: { id: action.user.id } } : null;

         return {
            ...state,
            task,
            todo,
            taskLoadCnt: state.taskLoadCnt + 1,
         };
      case Actions.TASK_UPLOAD_ATTACHMENT:
         task = state.mytasks[`${action.todo.taskSeq}.${action.todo.dateSeq}`];
         todo = task.todoList[action.todo.seq];
         todo.uploads = action.payload;
         return {
            ...state,
            task,
            todo,
            uploadCnt: state.uploadCnt + 1,
         };
      case Actions.REMOVE_TASK_UPLOAD_ATTACHMENT:
         task = state.mytasks[`${action.todo.taskSeq}.${action.todo.dateSeq}`];
         todo = task.todoList[action.todo.seq];
         todo.uploads = action.payload;
         return {
            ...state,
            task,
            todo,
            uploadCnt: state.uploadCnt + 1,
         };
      case Actions.OPEN_TASK_TODO_DIALOG:
         return {
            ...state,
            dialog: {
               type: action.mode,
               readonly: action.readonly,
               canTake: Boolean(action.canTake),
            },
            task: action.task,
            todo: action.todo,
         };
      case Actions.CLOSE_TASK_TODO_DIALOG:
         return {
            ...state,
            todo: { uploads: [], comments: [] },
            task: { todoList: [] },
            taskLoadCnt: state.taskLoadCnt + 1,
         };
      case Actions.TASK_COMMENT_ADD:
         task = state.mytasks[`${action.todo.taskSeq}.${action.todo.dateSeq}`];
         todo = task.todoList[action.todo.seq];
         todo.comments = action.payload;
         return {
            ...state,
            todo,
            taskLoadCnt: state.taskLoadCnt + 1,
         };
      case Actions.SELECT_TASK_TODO:
         return {
            ...state,
            todo: Array.isArray(state.task.todoList) ? _.find(state.task.todoList, { seq: action.seq }) : state.task.todoList[action.seq],
            taskLoadCnt: state.taskLoadCnt + 1,
         };
      case Actions.TASK_LOADING:
         return {
            ...state,
            taskLoaded: false,
         };
      case Actions.TASK_LOADED:
         return {
            ...state,
            taskLoaded: true,
         };
      case Actions.FETCH_TASK_PANEL_EXPANDED:
         return {
            ...state,
            mytaskExpand: action.flag,
         };
      case Actions.EXPAND_TASK_PANEL:
         return {
            ...state,
            mytaskExpand: action.flag,
         };
      default:
         return state;
   }
};

export default myTaskReducer;
