import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, FormText, Label } from 'reactstrap';
import cx from 'classnames';
import { Controller } from 'react-hook-form';
import { pack } from '../common/global';
import _ from 'lodash';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Editor } from '@tinymce/tinymce-react';
import 'prismjs/themes/prism-coy.css';
import Prism from 'prismjs';

// require('tinymce/tinymce');
// require('tinymce/themes/silver');
// require('tinymce/plugins/advlist');
// require('tinymce/plugins/autolink');
// require('tinymce/plugins/lists');
// require('tinymce/plugins/link');
// require('tinymce/plugins/image');
// require('tinymce/plugins/charmap');
// require('tinymce/plugins/print');
// require('tinymce/plugins/paste');
// require('tinymce/plugins/preview');
// require('tinymce/plugins/anchor');
// require('tinymce/plugins/searchreplace');
// require('tinymce/plugins/visualblocks');
// require('tinymce/plugins/code');
// require('tinymce/plugins/fullscreen');
// require('tinymce/plugins/insertdatetime');
// require('tinymce/plugins/media');
// require('tinymce/plugins/table');
// require('tinymce/plugins/paste');
// require('tinymce/plugins/code');
// require('tinymce/plugins/codesample');
// require('tinymce/plugins/help');
// require('tinymce/plugins/wordcount');
// require('tinymce/icons/default');

function TinyMceHook(props) {
   const [editor, setEditor] = useState(null);
   const labelWidth = props.labelWidth || { sm: 2 };
   const colWidth = props.colWidth || { sm: 4 };
   const id = props.id || props.name;
   const { lang } = useSelector(state => state.gc.common);
   let additionalProps = _.pick(props, ['activeClass', 'config', 'isScriptLoaded', 'scriptUrl', 'disabled']);

   const initOption = useMemo(
      () => ({
         height: props.height || 400,
         width: '100%',
         plugins: ['link image code codesample lists table print'], // powerpaste 추가시 import 에 paste 주석처리.
         powerpaste_allow_local_images: true,
         powerpaste_word_import: 'prompt',
         powerpaste_html_import: 'prompt',
         // statusbar: false,  // footer 숨기기.
         branding: false, // Powered by Tiny... 제거.
         skin_url: '/static/tinymce/skins/ui/oxide',
         content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Gugi&family=Hi+Melody&family=Jua&family=Nanum+Gothic:wght@400;700;800&family=Nanum+Myeongjo:wght@400;700;800&family=Nanum+Pen+Script&family=Noto+Sans+KR:wght@400;500;700;900&display=swap'); body { font-family: Noto Sans KR, sans-serif; font-size: 12px; line-height: 1.4; } p { margin: 0 }",
         content_css: '/static/tinymce/skins/content/default/content.min.css',
         toolbar:
            'fontsizeselect fontselect | forecolor backcolor | image paste | undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | link table | numlist bullist outdent indent | template codesample | code',
         fontsize_formats: '8px 10px 12px 14px 16px 18px 24px 30px 36px',
         /* 글씨체 : http://ninanio3.tistory.com/entry/TinyMCE-Advanced-%ED%95%9C%EA%B8%80%EA%B8%80%EA%BC%B4-%EC%B6%94%EA%B0%80 */
         font_formats: `${
            lang === 'ko'
               ? '노토산스=Noto Sans KR;나눔고딕=Nanum Gothic;나눔명조=Nanum Myeongjo;한스=Black Han Sans;주아=Jua;멜로디=Hi Melody;구기=Gugi;나눔펜=Nanum Pen Script;'
               : ''
         }Arial=arial,helvetica,sans-serif;Courier New=courier new,courier,monospace`,
         // paste_as_text: true,
         paste_data_images: true,
         image_advtab: false,
         // images_upload_url: `${process.env.REACT_APP_API_URL}${pack.serverPrefix}/upload`,
         images_upload_handler: (blobInfo, success, failur) => {
            const fData = new FormData();
            fData.append('file', blobInfo.blob());
            axios({
               method: 'post',
               url: '/upload',
               data: fData,
            })
               .then(res => {
                  success(pack.serverImage(res.data.savename));
               })
               .catch(e => {
                  pack.showError(e);
                  console.log('upload error!!', e);
               });
         },
      }),
      [props.height],
   );

   if (props.matching) {
      const func = pack.getMatchingFunction(props.matching);
      if (func != null)
         additionalProps = {
            ...additionalProps,
            ...func,
         };
      else {
         console.log("No Matching Functions for '" + props.matching + "'");
      }
   }

   useEffect(() => {
      if (props.viewmode) Prism.highlightAll();
   }, [props.viewmode]);

   useEffect(() => {
      if (editor) editor.editor.setMode(props.readonly ? 'readonly' : 'design');
   }, [editor, props.readonly]);

   useEffect(() => {
      props.setValue(props.name, props.defaultValue || '');
      const elem = document.getElementById(`${props.id}_content`);
      if (elem instanceof Element) elem.innerHTML = props.defaultValue || '';
      updatePrism();
   }, [props.id, props.defaultValue]);

   useEffect(() => {
      if (props.updateContent) {
         const contentElem = document.getElementById(`${props.id}_content`);
         const newValue = props.defaultValue; // props.getValues(props.name);
         if (contentElem instanceof Element) contentElem.innerHTML = newValue;
         if (!props.viewmode && editor) editor.editor.setContent(newValue);
         updatePrism();
      }
   }, [props.updateContent, props.id, props.viewmode, props.defaultValue, editor]);

   const updatePrism = useCallback(() => {
      if (props.viewmode) setTimeout(() => Prism.highlightAll(), 100);
   }, [props.viewmode]);

   const handleRef = useCallback(
      editor => {
         setEditor(editor);
         if (typeof props.editorRef === 'function') {
            props.editorRef(editor);
         }
      },
      [props.editorRef],
   );

   const changeValue = useCallback(
      (value, onChange) => {
         if (!props.noUpdate) {
            onChange(value);
            // props.setValue(props.name, value);
         }
         if (typeof props.onChange === 'function') {
            props.onChange(value);
         }
      },
      [props.noUpdate],
   );

   const onBlur = useCallback(
      e => {
         if (props.viewmode) return false;
         if (typeof props.onBlur === 'function') {
            props.onBlur(e);
         }
      },
      [props.viewmode],
   );

   return (
      <>
         {props.label && (
            <Label {...labelWidth} for={id} className={cx({ required: !props.viewmode && props.rules && props.rules.required })}>
               {props.label}
            </Label>
         )}
         <Col {...colWidth} className={('d-flex align-items-center', props.colClassName || '')}>
            {!props.viewmode ? (
               <div className="d-flex flex-column w-100">
                  <Controller
                     render={({ field }) => (
                        <Editor
                           {...additionalProps}
                           // tinymceScriptSrc="https://cdn.tiny.cloud/1/qsvx6v6ew1xaqp4m5qxcjovrmqwtvazv7q85yywn2emxeh6h/tinymce/5/tinymce.min.js"
                           apiKey={process.env.REACT_APP_TINYMCE_KEY}
                           value={field.value}
                           ref={handleRef}
                           init={{ ...initOption, ...props.init }}
                           onEditorChange={value => changeValue(value, field.onChange)}
                           onBlur={onBlur}
                        />
                     )}
                     name={props.name}
                     control={props.control}
                     rules={props.rules}
                     defaultValue={props.defaultValue || ''}
                  />
                  {!props.hideErrorMessage && props.errors[props.name] && props.errors[props.name].message && (
                     <FormText color="danger">{props.errors[props.name].message}</FormText>
                  )}
               </div>
            ) : (
               <Label
                  className={cx('view html crlf w-100', { border: props.viewmodeBorder })}
                  id={`${props.id}_content`}
                  dangerouslySetInnerHTML={{ __html: props.defaultValue }}
               />
            )}
         </Col>
      </>
   );
}

TinyMceHook.propTypes = {
   id: PropTypes.string.isRequired,
   name: PropTypes.string.isRequired,
   control: PropTypes.any.isRequired,
   errors: PropTypes.any.isRequired,
   setValue: PropTypes.func.isRequired,
   rules: PropTypes.object,
   defaultValue: PropTypes.any,
   maxLength: PropTypes.number,
   label: PropTypes.string,
   onChange: PropTypes.func,
   onBlur: PropTypes.func,
   value: PropTypes.string,
   viewmode: PropTypes.bool,
   required: PropTypes.bool,
   requiredMsg: PropTypes.string,
   errorTrigger: PropTypes.string,
   matching: PropTypes.any,
   validations: PropTypes.any,
   validationErrors: PropTypes.object,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   disabled: PropTypes.bool,
   readonly: PropTypes.bool,
   init: PropTypes.object,
   height: PropTypes.number,
   maxHeight: PropTypes.number,
   updateContent: PropTypes.bool,
   editorRef: PropTypes.func,
   colClassName: PropTypes.string,
   noUpdate: PropTypes.bool,
   hideErrorMessage: PropTypes.bool,
   viewmodeBorder: PropTypes.bool,
};

export default TinyMceHook;
