import React from 'react';
import '../style/NotSupported.css';
import backImg from '../../../assets/utils/images/error/support.jpg';
import chromeIcon from '../../../assets/utils/images/icons/ico_chrome.png';
import firefoxIcon from '../../../assets/utils/images/icons/ico_firefox.png';
import edgeIcon from '../../../assets/utils/images/icons/ico_edge.png';

const ListItem = (props) => (
   <div className="not-support-list d-flex align-items-center mt-2">
      <img height={40} width={40} src={props.icon} alt={props.name}/>
      <a href={props.url} target="_blank" className="not-support-href">
         <span className="ml-2 not-support-list-text">{props.name}</span>
      </a>
   </div>
);

const NotSupported = (props) => {
   return (
      <>
         <div className="not-support-back position-relative">
            <img src={backImg} className="not-support-img cover" />
            <div className="not-support-wrap">
               <div className="not-support-title">
                  This browser is not supported
               </div>
               <div className="not-support-subtitle">
                  Please upgrade to Edge or download other browser!
               </div>
               <div className="pl-3 d-flex flex-column">
                  <ListItem url="https://www.microsoftedgeinsider.com/ko-kr/download/" name="Microsoft Edge" icon={edgeIcon} />
                  <ListItem url="https://www.google.com/intl/ko/chrome" name="Chrome" icon={chromeIcon} />
                  <ListItem url="https://www.mozilla.org/ko/firefox/new" name="Firefox" icon={firefoxIcon} />
               </div>
            </div>
         </div>
      </>
   );
};

export default NotSupported;