import _ from 'lodash';
import * as Actions from '../actions';

const initState = {
   entities: {},
   approves: [], // home 화면에서 사용.
   entity: {
      subject: '',
      content: '',
      lines: [],
      refs: [],
      attachs: [],
      employee: {
         member: {},
         department: {},
      },
   },
   entityLoadCnt: 0,
   listLoadCnt: 0,
   deptEmpTree: [],
   targetTree: [],
   targetList: [],
   linesOpen: false,
   refsOpen: false,
   lines: [],
   refs: [],
   viewers: [],
   lineLoadCnt: 0,
   refLoadCnt: 0,
   format: {
      code: 'gby',
      content: '',
   },
   subject: '',
   formatList: [],
   leaveTypes: [], // 휴가타입(코드)
   group: {
      rows: [],
   },
   groups: [],
   groupOpen: false,
   loaderActive: false,
   hugaTypesLoaded: false,
   listType: Actions.APPROVE_TYPE_GET,
   me: null,
   deptlist: [],
   firmlist: [],
};

const approveReducer = function (state = initState, action) {
   switch (action.type) {
      case Actions.FETCH_FIRMS:
         return {
            ...state,
            firmlist: action.payload,
         };
      case Actions.FETCH_DEPARTMENTS:
         return {
            ...state,
            deptlist: action.payload,
         };
      case Actions.FETCH_ME:
         return {
            ...state,
            me: action.payload,
         };
      case Actions.SET_LIST_TYPE:
         return {
            ...state,
            listType: action.payload,
         };
      case Actions.START_LOADER:
         return {
            ...state,
            loaderActive: true,
         };
      case Actions.STOP_LOADER:
         return {
            ...state,
            loaderActive: false,
         };
      case Actions.FETCH_LIST:
         return {
            ...state,
            approves: action.payload,
            entities: _.keyBy(action.payload, 'seq'),
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.FETCH_TARGET_LIST:
         return {
            ...state,
            targetTree: action.tree,
            targetList: action.list,
         };
      case Actions.FETCH_FORMAT_LIST:
         return {
            ...state,
            formatList: action.payload,
         };
      case Actions.FETCH_HUGA_TYPES:
         return {
            ...state,
            leaveTypes: action.payload.filter(it => it.isuse === '1'),
            hugaTypesLoaded: true,
            // wolchaTypes: action.payload.filter(it => it.isuse === '1' && it.iswolcha === '1'),
         };
      case Actions.OPEN_GROUP_MODAL:
         return {
            ...state,
            groupOpen: true,
            group: action.group || initState.group,
         };
      case Actions.CLOSE_GROUP_MODAL:
         return {
            ...state,
            groupOpen: false,
         };
      case Actions.OPEN_ADD:
         return {
            ...state,
            entity: { ...initState.entity },
            format: { ...initState.format },
         };
      case Actions.TOGGLE_RIGHT_BAR:
         return {
            ...state,
            linesOpen: action.which === Actions.APPROVE_LINE_TYPE_LINES ? action.open : state.linesOpen,
            refsOpen: action.which === Actions.APPROVE_LINE_TYPE_REFS ? action.open : state.refsOpen,
         };
      case Actions.SELECT_FORMAT:
         return {
            ...state,
            format: !action.payload ? state.format : action.payload,
         };
      case Actions.FETCH_APPROVE:
         const approve = action.entity || { ...initState.entity };
         return {
            ...state,
            entity: approve,
            entityLoadCnt: state.entityLoadCnt + 1,
            format: _.find(state.formatList, { code: approve.formatCode }),
            refs: approve.refs.map(ref => ({
               id: `e_${ref.employee.empno}`,
               avatar: ref.employee.avatar,
               title: ref.employee.name,
               position: ref.employee.positionDesc || '',
               deptname: (ref.employee.department || {}).deptname,
            })),
            lines: approve.lines.map(line => ({
               id: `e_${line.employee.empno}`,
               avatar: line.employee.avatar,
               title: `${line.employee.name} ${line.employee.positionDesc || ''}`,
               type: line.type,
            })),
            refLoadCnt: state.refLoadCnt + 1,
            lineLoadCnt: state.lineLoadCnt + 1,
         };
      case Actions.FETCH_LINE_GROUPS:
         return {
            ...state,
            groups: action.payload,
         };
      case Actions.OPEN_EDIT:
         const entity = state.entities[action.seq] || state.entity;
         return {
            ...state,
            entity,
            lines: entity.lines.map(line => ({
               id: `e_${line.employee.empno}`,
               avatar: line.employee.avatar,
               title: `${line.employee.name} ${line.employee.positionDesc || ''}`,
               type: line.type,
            })),
            refs: entity.refs.map(ref => ({
               id: `e_${ref.employee.empno}`,
               avatar: ref.employee.avatar,
               title: ref.employee.name,
               position: ref.employee.positionDesc || '',
               deptname: (ref.employee.department || {}).deptname,
            })),
            attachs: entity.attachs,
            format: _.find(state.formatList, { code: entity.formatCode }),
            lineLoadCnt: state.lineLoadCnt + 1,
            refLoadCnt: state.refLoadCnt + 1,
         };
      case Actions.SELECT_LINES:
         const lines = action.keys.map(id => _.find(state.targetList, { id }));
         return {
            ...state,
            lines,
            lineLoadCnt: state.lineLoadCnt + 1,
         };
      case Actions.UPDATE_LINES:
         return {
            ...state,
            lines: action.lines,
            lineLoadCnt: state.lineLoadCnt + 1,
         };
      case Actions.SELECT_REFS:
         const refs = action.keys.map(id => _.find(state.targetList, { id }));
         return {
            ...state,
            refs,
            refLoadCnt: state.refLoadCnt + 1,
         };
      case Actions.UPDATE_REFS:
         return {
            ...state,
            refs: action.refs,
            refLoadCnt: state.refLoadCnt + 1,
         };
      case Actions.SET_SUBJECT:
         return {
            ...state,
            subject: action.subject,
         };
      case Actions.APPROVE_CLEAR:
         return {
            ...state,
            lines: [],
            refs: [],
            viewers: [],
            entity: { ...initState.entity },
            format: { ...initState.format },
         };
      case Actions.CONFIRM:
         return {
            ...state,
            entity: action.payload,
            entityLoadCnt: state.entityLoadCnt + 1,
         };
      case Actions.REJECT:
         return {
            ...state,
            entity: action.payload,
            entityLoadCnt: state.entityLoadCnt + 1,
         };
      case Actions.UPDATE_COMMENT:
         const ent = action.payload;
         return {
            ...state,
            entity: ent,
            entities: {
               ...state.entities,
               [ent.seq]: ent,
            },
         };
      default:
         return state;
   }
};

export default approveReducer;
