import * as Actions from '../actions';

const initState = {
   suggestions: [],
   open: false,
   newChecked: [],
   entities: []
};

const outselectReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_SUGGESTIONS:
         return {
            ...state,
            suggestions: action.payload.map(out => ({
               id: String(out.seq),
               text: `${out.name}${Boolean(out.position) ? ` (${out.position})` : ''}`,
               data: out
            })),
            entities: action.payload
         };
      case Actions.TOGGLE_TREE:
         return {
            ...state,
            open: action.flag
         };
      default:
         return state;
   }
};

export default outselectReducer;