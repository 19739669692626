import * as Actions from "../actions";
import _ from 'lodash';
import {pack} from "@gc";


const initState = {
   tab: 1,
   entities: {},
   treeSource:[],
   treeMap: null,
   treeList: [],
   menu: {
      id: '',
      pid: '',
      title: '',
      ptitle: '',
      detail: '',
      icon: '',
      type: '',
      url: '',
      isitem: '0',
      ispublic: '0',
      isuse: '1',
      section: ''
   },
   newMenu: {},
   // mode: 'view',
   // currentTab: 0,
   treeLoadCnt: 0,
   listLoadCnt: 0,
   // menuSelect: {},
   // menuSelectTree: [],

   expanded: {},
   codeFetched: false,
   roles: []
};


const menuReducer = function(state = initState, action) {
   let code = null;
   switch (action.type) {
      case Actions.FETCH_CODES:
         const codes = action.payload;
         const menus = {};
         codes.filter(c => c.menus != null && c.menus.length > 0).forEach(c => {
            menus[c.seq] = c.menus.map(menu => menu.id);
         });
         return {
            ...state,
            codes,
            menuSelect: menus,
         };
      /*case Actions.FETCH_MENUSELECT_TREE:
         return {
            ...state,
            menuSelectTree: action.payload
         };*/
      case Actions.FETCH_MENU_TREE:
         return {
            ...state,
            treeSource: action.tree,
            treeMap: _.keyBy(action.list, 'id'),
            treeList: action.list,
            treeLoadCnt: state.treeLoadCnt+1,
            menu: initState.menu
         };
      case Actions.OPEN_MENU_ADD:
         return {
            ...state,
            // mode: 'add',
            newMenu: {
               ...initState.menu,
               pid: state.menu.id || '0',
               ptitle: state.menu.title || pack.servermsg()['menu.root']
            }
         };
      case Actions.DELETE_MENU:
         return {
            ...state,
            menu: initState.menu,
            // mode: state.currentTab === 0 ? 'view' : 'list'
         };
      case Actions.OPEN_MENU_EDIT:
         return {
            ...state,
            // currentTab: 0,
            // mode: action.mode
         };
      case Actions.SELECT_MENU:
         const item = action.payload;
         return {
            ...state,
            menu: {
               ...item,
               pid: item.pid || '0',
               ptitle: item.ptitle || pack.servermsg()['menu.root']
            },
            newMenu: {
               ...state.newMenu,
               pid: item.id,
               ptitle: item.title
            }
         };
      default:
         return state;
   }
};

export default menuReducer;