import { pack } from '@gc';
import * as Actions from '../actions';
import { MAILBOX_TYPE_ALL, MAILBOX_TYPE_INBOX, MAILBOX_TYPE_TOTAL } from '../actions';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';

const initState = {
   reloadCnt: 0,
   fetchable: true,
   entities: [],
   mails: [],
   counts: {},
   unread: 0,
   newMailCnt: 0,
   checkall: false,
   loadInfos: [],
   infoLoadCnt: 0,
   pageInfos: {
      [MAILBOX_TYPE_INBOX]: 0,
   },
   searchText: '',
   totalSize: 0,
   whole: 0,
   entity: {
      seq: '',
      id: '',
      subject: '',
      text: '',
      from: '',
      fromName: '',
      fromAddress: '',
      date: '',
      isRead: false,
      starred: '0',
      important: '0',
      tos: [],
      ccs: [],
      bccs: [],
      files: [],
      tags: [],
   },
   entityLoadCnt: 0,
   tags: [],
   tag: {},
   tagLoadCnt: 0,
   searchs: [],
   search: {},
   sideExpanded: false,
   contacts: {},
   contactDialog: {
      open: false,
      data: {},
      type: 'add',
   },
   dialog: {
      seq: '',
      name: '',
   },
   newEntity: {
      seq: '',
      id: '',
      subject: '',
      text: '',
      from: '',
      fromName: '',
      fromAddress: '',
      date: '',
      isRead: false,
      starred: '0',
      important: '0',
      tos: [],
      ccs: [],
      bccs: [],
      files: [],
   },
   // mode: 'list',   // list, add, edit, view
   listLoadCnt: 0,
   foldersLoadCnt: 0,
   boxType: Actions.MAILBOX_TYPE_INBOX,
   selectType: Actions.MAILBOX_SELTYPE_ALL,
   inboxViewType: Actions.MAILBOX_IVTYPE_ALL,
   folders: [],
   folderName: 'inbox',
   checkedList: [],
   actCnt: 0, // 메일 이동 등 Act 발생후 화면업데이트가 필요할때 사용하는 카운터
   sendNow: false,
   tempSaveNow: false,
   templateSaveNow: false,
   templateSavable: false,
   mailSavable: false,
   templateOpen: false,
   sendEnable: false,
   selectedEmail: '',
   groupmails: [],
   suggestions: [],
   refreshActive: false,
   signLoadCnt: 0,
   moveActive: false,
   fetched: false,
   openPreview: false,
   condition: {
      // 상세검색 조건
      search: false,
      sender: '',
      startdate: null,
      enddate: null,
      to: {
         type: 'to', // to, cc, tocc
         value: '',
      },
      content: {
         type: 'subtext', // subject, text, subtext, filename, subfile
         value: '',
      },
   },
   conditionCnt: 0,
   templates: [],
   openEmailFrom: false,
   mailFromTos: [],
   mailFromCcs: [],
   mailFromBccs: [],
   targetTree: [],
   targetList: [],
   contactsTree: [],
   contactsList: [],
   checkedMailFromEmp: [],
   checkedMailFromContacts: [],
   removeActive: false,
   showClearRate: false,
   clearRate: 0,
   clearMessage: '',
   quotaWarnLevel: 0,
   usage: 0,
   limit: 5242880,
   /* 환경설정 관련 */
   signs: [
      {
         seq: '',
         content: '',
      },
   ],
   defaultSign: {},
   outers: [],
   outerLoading: [],
   loading: false,
   commonLoading: false,
   /* 환경설정 관련 */
};

const mailboxReducer = function (state = initState, action) {
   switch (action.type) {
      case Actions.RELOAD_FOLDER:
         return {
            ...state,
            condition: _.cloneDeep(initState.condition),
            reloadCnt: state.reloadCnt + 1,
         };
      case Actions.MAILBOX_ENABLE_FETCH:
         return {
            ...state,
            fetchable: action.payload,
         };
      case Actions.FETCH_TEMPLATES:
         return {
            ...state,
            templates: action.payload,
         };
      case Actions.FETCH_GROUP_EMAILS:
         return {
            ...state,
            groupmails: action.payload,
         };
      case Actions.FETCH_LIST:
         return {
            ...state,
            entities: action.payload,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.APPEND_LIST:
         return {
            ...state,
            totalSize: state.totalSize + action.payload.length,
            whole: state.whole + action.payload.length,
            entities: _.uniqBy(state.entities.concat(action.payload), 'seq'),
         };
      case Actions.REMOVE_FROM_LIST:
         _.remove(state.entities, o => action.payload.includes(o.seq));
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.NEW_SEEN_LIST:
         action.payload.forEach(seq => {
            const mail = _.find(state.entities, { seq });
            if (Boolean(mail)) {
               mail.isread = '1';
            }
         });
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.CONFIRM_MAIL:
         const cdata = action.data;
         const cmail = _.find(state.entities, { seq: +cdata.seq });
         if (Boolean(cmail)) {
            cmail.confirmDate = cdata.date;
            cmail.isreceived = '1';
         }
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.FETCH_EMAIL:
         const sentMails = action.payload;
         if (sentMails.length > 0 && sentMails[0].isReply === '1' && Boolean(sentMails[0].replySeq)) {
            const oriMail = _.find(state.entities, { seq: +sentMails[0].replySeq });
            if (Boolean(oriMail)) {
               oriMail.replied = '1';
            }
         }
         return {
            ...state,
            entities: _.uniqBy(state.entities.concat(action.payload), 'seq'),
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.COMMON_LOADING:
         return {
            ...state,
            commonLoading: action.flag,
         };
      case Actions.FETCH_EMAILS_LOADING:
         return {
            ...state,
            loading: action.flag,
         };
      case Actions.FETCH_COUNTS:
         return {
            ...state,
            counts: action.payload.body,
         };
      case Actions.FETCH_EMAILS_BY_FOLDER:
         return {
            ...state,
            loading: false,
            mails: action.list,
            entities: action.list,
            totalSize: action.total,
            whole: action.whole,
            listLoadCnt: state.listLoadCnt + 1,
            unread: action.unread,
         };
      case Actions.NEW_MAIL_RECEIVED:
         // const cnt = state.boxType === MAILBOX_TYPE_INBOX ? action.payload.length : 0;
         let cnt = 0;
         if (state.boxType === MAILBOX_TYPE_ALL) cnt += action.payload.length;
         else
            action.payload.forEach(mail => {
               if (mail.folderName === state.folderName) {
                  cnt++;
               }
            });
         return {
            ...state,
            mails: action.payload.concat(state.mails),
            newMailCnt: state.newMailCnt + 1,
            totalSize: state.totalSize + cnt,
            whole: state.whole + cnt,
            unread: state.unread + cnt,
         };
      /*case Actions.FETCH_ENTITIES_BY_FOLDER_FIRST:
         const f = _.find(action.folders, { name: action.folder });
         if (Boolean(f)) {
            f.page = action.page;
         }
         return {
            ...state,
            entities: action.list,
            loadInfos: action.folders,
            infoLoadCnt: state.infoLoadCnt + 1,
            listLoadCnt: state.listLoadCnt + 1,
            totalSize: action.total,
         };
      case Actions.FETCH_ENTITIES_BY_FOLDER:
         const fo = _.find(state.loadInfos, { name: action.folder });
         if (Boolean(fo)) {
            fo.page = fo.page + 1;
         }
         return {
            ...state,
            entities: _.uniqBy(state.entities.concat(action.list), 'seq'),
            infoLoadCnt: state.infoLoadCnt + 1,
            listLoadCnt: state.listLoadCnt + 1,
         };*/
      case Actions.FETCH_ENTITY:
         return {
            ...state,
            entity: action.entity,
            contacts: action.contacts,
         };
      case Actions.FETCH_FOLDERS:
         return {
            ...state,
            folders: action.payload,
            foldersLoadCnt: state.foldersLoadCnt + 1,
         };
      case Actions.FETCH_OUTERS:
         return {
            ...state,
            outers: action.payload,
         };
      case Actions.FETCH_SUGGESTIONS:
         return {
            ...state,
            suggestions: action.payload.map(addr => ({
               id: addr.replace(/^[^[]*\[(.*?)]/g, '$1'),
               text: addr,
               name: addr.replace(/^([^[]*)\[(.*?)]/g, '$1').trim(),
            })),
         };
      case Actions.REFRESH_LOADING:
         return {
            ...state,
            refreshActive: true,
         };
      case Actions.REFRESH_LOADED:
         return {
            ...state,
            refreshActive: false,
         };
      case Actions.SELECT_EMAIL:
         return {
            ...state,
            email: action.email,
         };
      case Actions.STOP_OUTER_LOADING:
         return {
            ...state,
            outerLoading: state.outerLoading.filter(seq => seq !== action.seq),
         };
      case Actions.START_OUTER_LOADING:
         return {
            ...state,
            outerLoading: [...state.outerLoading, action.seq],
         };
      case Actions.TOGGLE_ALERT:
         return {
            ...state,
            openAlert: action.flag,
            alertMsg: action.msg,
         };
      case Actions.FETCH_CONTACTS_FOR_ENTITY:
         return {
            ...state,
            contacts: action.payload,
         };
      case Actions.OPEN_EDIT:
         return {
            ...state,
            entity: action.entity,
         };
      case Actions.OPEN_ADD:
         return {
            ...state,
            newEntity: {
               ..._.cloneDeep(initState.entity),
               ...action.data,
            },
            entityLoadCnt: state.entityLoadCnt + 1,
         };
      case Actions.REMOVE_ENTITY:
         _.find(state.entities, { seq: action.seq }).folderName = 'recycle';
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.SET_SEARCH_TEXT:
         return {
            ...state,
            searchText: action.text,
         };
      case Actions.TOGGLE_BOX_TYPE:
         return {
            ...state,
            checkedList: [],
            boxType: action.payload,
            folderName: action.folderName || '',
            condition: _.cloneDeep(initState.condition),
         };
      case Actions.TOGGLE_SELECT_TYPE:
         return {
            ...state,
            selectType: action.seltype,
         };
      case Actions.TOGGLE_IV_TYPE:
         return {
            ...state,
            inboxViewType: action.ivtype,
         };
      case Actions.TOGGLE_STARRED:
         const smail = _.find(state.entities, { seq: action.seq });
         if (smail) smail.starred = action.starred;
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.TOGGLE_IMPORTANT:
         const imail = _.find(state.entities, { seq: action.seq });
         if (imail) imail.important = action.important;
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.SEND_EMAIL:
         state.entities.push(action.payload);
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.ADD_FOLDER:
         return {
            ...state,
            dialog: {
               open: true,
               name: '',
               enable: false,
            },
         };
      case Actions.SELECT_ALL_CHECKS:
         return {
            ...state,
            checkedList: action.list,
         };
      case Actions.ADD_SELECT_ALL_CHECKS:
         return {
            ...state,
            checkedList: _.uniq(state.checkedList.concat(action.list)),
         };
      case Actions.DESELECT_ALL_CHECKS:
         return {
            ...state,
            checkedList: [],
            checkall: false,
         };
      case Actions.CHECK_ALL:
         return {
            ...state,
            checkedList: action.payload,
         };
      /*case Actions.UPDATE_CHECKED_LIST:
         return {
            ...state,
            checkedList: action.list
         };*/
      case Actions.CHECK_ROW:
         return {
            ...state,
            checkedList: [...state.checkedList, action.seq],
         };
      case Actions.UNCHECK_ROW:
         const list = _.remove(state.checkedList, seq => seq !== action.seq);
         //==> 설명 : id == action.id 를 지운 나머지 목록을 list 로 받는다. (★조건을 반대로 준것에 주의)

         return {
            ...state,
            checkedList: list,
         };
      case Actions.REMOVE_CHECKED_LIST:
         (action.list || state.checkedList).forEach(seq => {
            const mail = _.find(state.entities, { seq });
            if (mail) {
               mail.folderName = 'recycle';
            }
         });

         if (state.folderName === 'send' && Boolean(action.payload)) {
            action.payload.forEach(mail => {
               const entity = _.find(state.entities, { seq: mail.seq });
               if (Boolean(entity)) {
                  entity.id = mail.id;
               }
            });
         }

         return {
            ...state,
            checkedList: [],
            actCnt: state.actCnt + 1,
         };
      case Actions.CLEAR_CHECKED_LIST:
         (action.list || state.checkedList).forEach(seq => {
            _.remove(state.entities, { seq });
         });
         return {
            ...state,
            checkedList: [],
            actCnt: state.actCnt + 1,
         };
      case Actions.SET_AS_READ_WHEN_OPEN:
         const r = _.find(state.entities, { seq: action.seq });
         if (r) r.isread = '1';
         return {
            ...state,
         };
      case Actions.MARK_AS_READ:
         state.checkedList.forEach(seq => {
            const mail = _.find(state.entities, { seq });
            if (mail) {
               mail.isread = '1';
            }
         });
         return {
            ...state,
            checkedList: [...state.checkedList],
         };
      case Actions.MARK_AS_UNREAD:
         state.checkedList.forEach(seq => {
            const mail = _.find(state.entities, { seq });
            if (mail) {
               mail.isread = '0';
            }
         });
         return {
            ...state,
            checkedList: [...state.checkedList],
         };
      case Actions.MOVE_MAIL:
         const fromFolder = state.folderName;
         state.checkedList.forEach(seq => {
            const ett = _.find(state.entities, { seq });
            ett.folderName = action.folderName;
            if (fromFolder === 'send') {
               ett.isreal = '1';
               const send = _.find(action.sends, { seq: ett.seq });
               if (Boolean(send)) {
                  ett.id = send.id;
               }
            }
         });
         if (Boolean(action.togethers)) {
            state.entities
               .filter(mail => action.togethers.includes(mail.seq))
               .forEach(mail => {
                  mail.folderName = action.folderName;
               });
         }

         return {
            ...state,
            checkedList: [],
            actCnt: state.actCnt + 1,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.MOVE_LOADER_ACTIVE:
         return {
            ...state,
            moveActive: action.flag,
         };
      case Actions.BLOCK_USER:
         action.blocked.forEach(seq => {
            const mail = _.find(state.entities, { seq });
            if (mail) {
               mail.folderName = 'spam';
            }
         });
         return {
            ...state,
            checkedList: [],
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.TOGGLE_CAN_BLOCK:
         return {
            ...state,
            contacts: {
               ...state.contacts,
               [action.email]: {
                  ...state.contacts[action.email],
                  canBlock: action.canBlock,
               },
            },
         };
      case Actions.UPDATE_FOLDER:
         state.entities.forEach(mail => {
            if (mail.folderName === action.oldName) {
               mail.folderName = action.name;
            }
         });

         return {
            ...state,
            actCnt: state.actCnt + 1,
         };
      case Actions.REMOVE_FOLDER:
         const newFolders = _.remove(state.folders, folder => folder.seq !== action.seq);

         state.entities.forEach(mail => {
            if (mail.folderName === action.name) {
               mail.folderName = 'recycle';
            }
         });

         return {
            ...state,
            folders: newFolders,
            actCnt: state.actCnt + 1,
         };
      case Actions.PERMIT_USER_IMAGE:
         state.entities.forEach(ml => {
            if (ml.fromAddress === action.address) {
               ml['istrust'] = '1';
            }
         });

         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.SEND_REQUEST:
         return {
            ...state,
            sendNow: true,
         };
      case Actions.TEMPSAVE_REQUEST:
         return {
            ...state,
            tempSaveNow: action.flag,
         };
      case Actions.TEMPLATE_SAVE_REQUEST:
         return {
            ...state,
            templateSaveNow: action.flag,
         };
      case Actions.TEMPLATE_LOAD_REQUEST:
         return {
            ...state,
            templateOpen: action.flag,
         };
      case Actions.REMOVE_TEMPLATE:
         _.remove(state.templates, { seq: action.seq });
         return {
            ...state,
         };
      case Actions.MAIL_SENDING:
         return {
            ...state,
            sendEnable: false,
         };
      case Actions.TOGGLE_SEND_ENABLE:
         return {
            ...state,
            sendEnable: action.enable,
         };
      case Actions.TOGGLE_SEND_NOW:
         return {
            ...state,
            sendNow: action.flag,
         };
      case Actions.TOGGLE_TEMPLATE_SAVABLE:
         return {
            ...state,
            templateSavable: action.flag,
         };
      case Actions.ACT_REPLY:
         const text = action.data.text.replace(/cid:(.*?)>/g, `${pack.serverUrl}/image/email/$1>`);
         return {
            ...state,
            newEntity: { ..._.cloneDeep(initState.entity), ...action.data, text },
            // mode: 'add'
         };
      case Actions.ACT_REPLY_ALL:
         const texts = action.data.text.replace(/cid:(.*?)>/g, `${pack.serverUrl}/image/email/$1>`);
         return {
            ...state,
            newEntity: { ..._.cloneDeep(initState.entity), ...action.data, text: texts },
            // mode: 'add'
         };
      case Actions.ACT_FORWARD:
         const textz = action.data.text.replace(/cid:(.*?)>/g, `${pack.serverUrl}/image/email/$1>`);
         return {
            ...state,
            newEntity: { ..._.cloneDeep(initState.entity), ...action.data, text: textz },
            // mode: 'add'
         };
      case Actions.OPEN_CONTACTS_DIALOG:
         const data = action.payload || {};
         if (!data.name) data.name = action.name;
         if (!data.email) data.email = action.address;
         return {
            ...state,
            contactDialog: {
               ...state.contactDialog,
               open: true,
               data: data,
               type: !action.payload ? 'add' : 'edit',
            },
         };
      case Actions.CLOSE_CONTACTS_DIALOG:
         return {
            ...state,
            contactDialog: _.cloneDeep(initState.contactDialog),
         };
      case Actions.FETCH_SIGNS:
         const defSign = action.payload.find(it => it.seq === state.defaultSign.seq);
         return {
            ...state,
            signs: action.payload,
            signLoadCnt: state.signLoadCnt + 1,
            defaultSign: !defSign ? state.defaultSign : defSign,
         };
      case Actions.FETCH_DEFAULT_SIGN:
         return {
            ...state,
            defaultSign: action.payload,
         };
      case Actions.REMOVE_SIGN:
         return {
            ...state,
            signs: action.payload,
         };
      case Actions.TOGGLE_SIDE_EXPANDED:
         return {
            ...state,
            sideExpanded: action.flag,
         };
      case Actions.FETCH_TAGS:
         return {
            ...state,
            tags: action.payload,
            tagLoadCnt: state.tagLoadCnt + 1,
         };
      case Actions.SELECT_TAG:
         return {
            ...state,
            tag: action.tag || {},
            tagLoadCnt: state.tagLoadCnt + 1,
         };
      case Actions.APPEND_TAG:
         return {
            ...state,
            tags: [...state.tags, action.tag],
            tagLoadCnt: state.tagLoadCnt + 1,
         };
      case Actions.UPDATE_TAG:
         const idx = _.findIndex(state.tags, { seq: action.tag.seq });
         state.tags.splice(idx, 1);
         state.tags.splice(idx, 0, action.tag);
         return {
            ...state,
            tagLoadCnt: state.tagLoadCnt + 1,
         };
      case Actions.REMOVE_TAG:
         _.remove(state.tags, { seq: action.seq });
         return {
            ...state,
            tagLoadCnt: state.tagLoadCnt + 1,
         };
      case Actions.MAP_TAG:
         const mappedEntity = {
            ...state.entity,
            tags: [...state.entity.tags, action.tag],
         };
         const mapIdx = _.findIndex(state.entities, { seq: mappedEntity.seq });
         state.entities.splice(mapIdx, 1, mappedEntity);
         return {
            ...state,
            entity: mappedEntity,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.UNMAP_TAG:
         _.remove(state.entity.tags, { seq: action.tag.seq });
         const unmapped = {
            ...state.entity,
            tags: [...state.entity.tags],
         };
         const unmapIdx = _.findIndex(state.entities, { seq: unmapped.seq });
         state.entities.splice(unmapIdx, 1, unmapped);
         return {
            ...state,
            entity: unmapped,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.FETCH_LOADING:
         return {
            ...state,
            fetched: action.flag,
         };
      case Actions.TOGGLE_PREVIEW:
         return {
            ...state,
            openPreview: action.flag,
         };
      case Actions.INIT_CHECKED_LIST:
         return {
            ...state,
            checkedList: [],
         };
      case Actions.FETCH_SEARCHS:
         return {
            ...state,
            searchs: action.payload.map(item => ({
               ...item,
               condition: JSON.parse(item.cond),
            })),
         };
      case Actions.SEARCH_DETAIL:
         return {
            ...state,
            condition: action.condition,
            search: Boolean(action.seq) ? _.find(state.searchs, { seq: +action.seq }) : {},
            conditionCnt: state.conditionCnt + 1,
         };
      case Actions.SEARCH_DETAIL_INIT:
         return {
            ...state,
            condition: _.cloneDeep(initState.condition),
            search: {},
         };
      case Actions.FETCH_EMP_TREE:
         return {
            ...state,
            targetTree: action.tree,
            targetList: action.list,
         };
      case Actions.FETCH_CONTACTS_TREE:
         return {
            ...state,
            contactsTree: action.tree,
            contactsList: action.list,
         };
      case Actions.TOGGLE_EMAIL_FROM_DIALOG:
         return {
            ...state,
            openEmailFrom: action.flag,
         };
      case Actions.MAIL_FROM_ADD:
         return {
            ...state,
            mailFromTos: action.which === 'to' ? state.mailFromTos.concat(action.emails) : state.mailFromTos,
            mailFromCcs: action.which === 'cc' ? state.mailFromCcs.concat(action.emails) : state.mailFromCcs,
            mailFromBccs: action.which === 'bcc' ? state.mailFromBccs.concat(action.emails) : state.mailFromBccs,
            checkedMailFromEmp: [],
            checkedMailFromContacts: [],
         };
      case Actions.MAIL_FROM_REMOVE:
         return {
            ...state,
            mailFromTos: action.which === 'to' ? state.mailFromTos.filter(item => !action.ids.includes(item.id)) : state.mailFromTos,
            mailFromCcs: action.which === 'cc' ? state.mailFromCcs.filter(item => !action.ids.includes(item.id)) : state.mailFromCcs,
            mailFromBccs: action.which === 'bcc' ? state.mailFromBccs.filter(item => !action.ids.includes(item.id)) : state.mailFromBccs,
         };
      case Actions.MAIL_FROM_REMOVE_ALL:
         return {
            ...state,
            mailFromTos: action.which === 'to' ? [] : state.mailFromTos,
            mailFromCcs: action.which === 'cc' ? [] : state.mailFromCcs,
            mailFromBccs: action.which === 'bcc' ? [] : state.mailFromBccs,
         };
      case Actions.SET_CHECKED_MAILFROM_EMP:
         return {
            ...state,
            checkedMailFromEmp: action.checked,
         };
      case Actions.SET_CHECKED_MAILFROM_CONTACTS:
         return {
            ...state,
            checkedMailFromContacts: action.checked,
         };
      case Actions.INIT_MAILFROM:
         return {
            ...state,
            mailFromTos: [],
            mailFromCcs: [],
            mailFromBccs: [],
            checkedMailFromEmp: [],
            checkedMailFromContacts: [],
         };
      case Actions.TOGGLE_REMOVE_ACTIVE:
         return {
            ...state,
            removeActive: action.flag,
         };
      case Actions.CLEAR_FOLDER:
         action.payload.forEach(seq => {
            _.remove(state.entities, { seq });
         });
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.FETCH_QUOTA:
         const { strings, usage, limit } = action;
         let quotaWarnLevel = state.quotaWarnLevel;
         if (quotaWarnLevel < 2 && action.usage >= action.limit) {
            toastr.error(strings.errTitle, strings.mailLimitOver);
            quotaWarnLevel = 2;
         } else if (quotaWarnLevel === 0 && (usage / limit) * 100 >= 95) {
            toastr.warning(strings.warnTitle, strings.mailLimitWarn);
            quotaWarnLevel = 1;
         }
         return {
            ...state,
            usage,
            limit,
            quotaWarnLevel,
         };
      case Actions.SET_CLEAR_RATE:
         return {
            ...state,
            showClearRate: Boolean(action.total) && +action.total > 0,
            clearRate: action.rate,
            clearMessage: action.message,
         };
      case Actions.SET_PAGE_INFO:
         return {
            ...state,
            pageInfos: {
               ...state.pageInfos,
               [action.boxType]: action.page,
            },
         };
      default:
         return state;
   }
};

export default mailboxReducer;
