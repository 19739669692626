import { combineReducers } from "redux";
import user from "../auth/reducers/user.reducer";
import common from "../common/reducers/common.reducer";
import navigation from "../navigation/reducers";
import { USER_LOGGED_OUT } from "../auth/actions/user.action";

const gcTempReducers = combineReducers({
  user,
  common,
  navigation,
});

// 로그아웃 시 "gc" reducer 데이터 초기화 처리
const gcReducers = (state, action) => {
  if (action.type === USER_LOGGED_OUT) {
    return gcTempReducers(undefined, action);
  }
  return gcTempReducers(state, action);
};

export default gcReducers;
