import { pack } from '@gc';
import { loadNavigation } from '../../../@gc/navigation/actions';
import { setUserData } from '../../../@gc/auth/actions/user.action';
import { refreshList } from '../../activity/mailbox/actions';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { GEO_NOT_READY, useStore } from '../../../store/globalStore';

const jtf = require('json-form-data');

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export function submitLogin(formData, onSuccess, onFail, onFinally) {
   const request = axios({
      method: 'post',
      url: `/login`,
      // url: `/login/${pack.serverprop().option.section}`,
      data: formData,
   });

   return (dispatch, getState) => {
      const { geoStatus, setConnectInfo } = useStore.getState();
      if (geoStatus === GEO_NOT_READY) {
         setConnectInfo();
         setTimeout(() => dispatch(submitLogin(formData, onSuccess, onFail, onFinally)), 500);
      } else {
         request
            .then(res => {
               const { data } = res;
               if (data.code === '0000') {
                  if (data.token) {
                     localStorage.setItem('imhappy', data.token);
                  }

                  axios
                     .get(`/me/${data.isAdmin}/${data.isSuper}`)
                     .then(resp => {
                        const { data: sd } = resp;
                        // sessionStorage.setItem('mycookie', sign.encrypt(sd.isAdmin ? 'true' : 'false'));
                        // sessionStorage.setItem('yourcookie', sign.encrypt(sd.isSuper ? 'true' : 'false'));
                        sd['shortcuts'] = data.shortcuts || [];
                        if (data.passwordExpired !== '1' && data.needChangePassword !== '1') {
                           dispatch(setUserData(sd));
                           dispatch(loadNavigation(true));
                           dispatch(refreshList(true, sd.primaryEmail)); // 이메일 갱신
                        }
                        onSuccess(data.passwordExpired === '1', data.needChangePassword === '1', sd);
                     })
                     .catch(pack.showError);
               } else {
                  onFail(data.msg);
               }
            })
            .catch(e => {
               // pack.showError(e);
               onFail(e.response.data.message || '처리 중 오류가 발생했습니다');
            })
            .finally(onFinally);
      }
   };
}

export function submitChange(userData, password, history) {
   return (dispatch, getState) => {
      axios
         .put('/member/password', jtf({ id: userData.id, password }))
         .then(res => {
            if (res.status === 200) {
               if (res.data.token) {
                  localStorage.setItem('imhappy', res.data.token);
               }
               dispatch(setUserData(userData));
               dispatch(loadNavigation(true));
               history.push('/');
            } else {
               const { strings } = getState().gc.common;
               toastr.error(strings.errTitle, strings.errMessage);
               console.log(res);
            }
         })
         .catch(e => pack.showError(e));
   };
}

export function submitNext(userData, history) {
   return dispatch => {
      axios.put('/member/password/delay', jtf({ id: userData.id }));

      dispatch(setUserData(userData));
      dispatch(loadNavigation(true));
      history.push('/');
   };
}
