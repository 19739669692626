import * as Actions from '../actions/profile.actions';

const initState = {
   open: false,
   entity: {
      empno: '',
      name: '',
      email: '',
      domain: '',
      address: '',
      addressZip: '',
      addressDetail: '',
      department: {
         deptcode: '',
         deptname: '',
      },
      member: {
         isAdmin: '0',
      },
      isretire: '0',
      islogin: '1',
      issuper: '0',
      mytaskExpand: '1',
      schools: [],
      langs: [],
      certs: [],
      edus: [],
      acts: [],
      awards: [],
      armys: [],
      emails: [],
   },
   infoSaveCount: 0,
};

const profileReducer = function (state = initState, action) {
   switch (action.type) {
      case Actions.OPEN_PROFILE_DIALOG:
         return {
            ...state,
            open: true,
            // entity: action.payload,
         };
      case Actions.CLOSE_PROFILE_DIALOG:
         return {
            ...state,
            open: false,
            // entity: initState.entity,
         };
      case Actions.FETCH_ENTITY:
         return {
            ...state,
            entity: action.payload,
         };
      case Actions.UPDATE_PROFILE_INFOS:
         return {
            ...state,
            entity: {
               ...state.entity,
               [action.key]: action.payload,
            },
            infoSaveCount: action.save ? state.infoSaveCount + 1 : state.infoSaveCount,
         };
      default:
         return state;
   }
};

export default profileReducer;
