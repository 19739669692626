export const SWAL_OPEN = '[SWAL] OPEN';
export const SWAL_CLOSE = '[SWAL] CLOSE';

export function swal(props) {
   return {
      ...props,
      swalType: props.type,
      type: SWAL_OPEN
   };
}

export function closeSwal() {
   return {
      type: SWAL_CLOSE,
   };
}