import * as Actions from "../actions";
import _ from 'lodash';

const initState = {
   entities: [],
   entity: {}
};

const productReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_PRODUCTS:
         return {
            ...state,
            entities: action.payload
         };
      case Actions.FETCH_PRODUCT:
         return {
            ...state,
            entity: _.find(state.entities, {seq: +(action.seq)})
         };
      case Actions.OPEN_ADD:
         return {
            ...state,
            entity: initState.entity
         };
      default:
         return state;
   }
};

export default productReducer;