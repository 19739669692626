import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import '../style/GoogleLoading.css';

const GoogleLoading = ({type, modal}) => {
   return (
      // modal 효과를 위한 wrapper
      <div className={cx("loading", modal)}>
         {/* 넣고 싶은 애니메이션을 여기에 삽입 : http://cfoucht.com/loadlab/ */}
         {type === 'triple' ?
            <div className="triple-spinner"/>
         : type === 'mesh' ?
            <div className="mesh-loader">
               <div className="set-one">
                  <div className="circle circle-1"></div>
                  <div className="circle circle-2"></div>
               </div>
               <div className="set-two">
                  <div className="circle circle-3"></div>
                  <div className="circle circle-4"></div>
               </div>
            </div>
         : null
         }
      </div>
   )
};

GoogleLoading.propTypes = {
   type: PropTypes.oneOf(['triple', 'mesh']).isRequired,
   modal: PropTypes.oneOf(['black', 'white']).isRequired
};

export default GoogleLoading;