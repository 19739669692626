import * as Actions from '../actions';

const initState = {
   entity: {
      seq: null,
      workType: '0', // 0: 고정, 1: 유연
      saturday: '',

      workTimeStart: null,
      workTimeEnd: null,
      exWorkTimeStart: null,
      exWorkTimeEnd: null,
      nightWorkTimeStart: null,
      nightWorkTimeEnd: null,
      restTimeStart: null,
      restTimeEnd: null,
      exRestTimeStart: null,
      exRestTimeEnd: null,
      nightRestTimeStart: null,
      nightRestTimeEnd: null,
      amTimeStart: null,
      amTimeEnd: null,
      pmTimeStart: null,
      pmTimeEnd: null,

      yunchaStartType: '1', // 0:입사일, 1:연차기준월
      yunchaStartMonth: 1,
      useYunchaExceed: '0',
   },
   hugaDetails: [],
   hugaYears: [],
   detailLoadCnt: 0,
   yearsLoadCnt: 0,
   editLoadCnt: 0,
   entities: [],
   editLoading: false,
};

const vattendReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_BASIC:
         return {
            ...state,
            entity: action.payload,
         };
      case Actions.FETCH_DETAILS:
         return {
            ...state,
            hugaDetails: action.payload,
            detailLoadCnt: state.detailLoadCnt + 1,
         };
      case Actions.FETCH_YEARS:
         return {
            ...state,
            hugaYears: action.payload,
            yearsLoadCnt: state.yearsLoadCnt + 1,
         };
      case Actions.FETCH_EDITS:
         return {
            ...state,
            entities: action.payload,
            editLoadCnt: state.editLoadCnt + 1,
            editLoading: false,
         };
      case Actions.UPDATE_DETAIL:
         return {
            ...state,
            detailLoadCnt: state.detailLoadCnt + 1,
            hugaDetails:
               action.mode === 'edit'
                  ? state.hugaDetails.map(detail => {
                       if (detail.seq === action.payload.seq) {
                          return action.payload;
                       }
                       return detail;
                    })
                  : state.hugaDetails.concat(action.payload),
         };
      case Actions.REMOVE_DETAILS:
         return {
            ...state,
            hugaDetails: state.hugaDetails.filter(it => !action.rows.includes(it.seq)),
            detailLoadCnt: state.detailLoadCnt + 1,
         };
      case Actions.UPDATE_YEAR:
         return {
            ...state,
            yearsLoadCnt: state.yearsLoadCnt + 1,
            hugaYears:
               action.mode === 'edit'
                  ? state.hugaYears.map(it => {
                       if (it.seq === action.payload.seq) {
                          return action.payload;
                       }
                       return it;
                    })
                  : state.hugaYears.concat(action.payload),
         };
      case Actions.REMOVE_YEARS:
         return {
            ...state,
            hugaYears: state.hugaYears.filter(it => !action.rows.includes(it.seq)),
            yearsLoadCnt: state.yearsLoadCnt + 1,
         };
      case Actions.UPDATE_EDIT:
         return {
            ...state,
            entities: state.entities.map(entity => {
               if (entity.empno === action.payload.employee.empno) {
                  entity.seq = action.payload.seq;
               }
               return entity;
            }),
            editLoadCnt: state.editLoadCnt + 1,
         };
      default:
         return state;
   }
};

export default vattendReducer;
