import axios from 'axios';
import { pack } from '../../../../../@gc';
import { toastr } from 'react-redux-toastr';
import { entityToEvent } from '../../common';

export const SET_PROJECT_ENTITY = '[TMS] SET PROJECT ENTITY';
export const LOADING_PROJECTS = '[TMS] LOADING PROJECTS';
export const SAVING_PROJECT = '[TMS] SAVING PROJECT';
export const PROJECT_SAVED = '[TMS] PROJECT SAVED';
export const PROJECT_REMOVED = '[TMS] PROJECT REMOVED';
export const PROJECT_EVENTS = '[TMS] PROJECT EVENTS';
export const REFRESH_PROJECT = '[TMS] REFRESH PROJECT';

export function fetchProjects(year, notLoading) {
   return (dispatch, getState) => {
      const { user } = getState().gc;

      if (!notLoading)
         dispatch({
            type: LOADING_PROJECTS,
            flag: true,
         });

      axios
         .get(`/tms/project/events/${user.empno}/${year}`)
         .then(response => {
            const events = response.data.map(entity => entityToEvent(entity));

            dispatch({
               type: PROJECT_EVENTS,
               events,
            });
         })
         .catch(pack.showError)
         .finally(() =>
            dispatch({
               type: LOADING_PROJECTS,
               flag: false,
            }),
         );
   };
}

export function setProjectEntity(entity) {
   return {
      type: SET_PROJECT_ENTITY,
      entity,
   };
}

export function saveProject(model) {
   return (dispatch, getState) => {
      const isUpdate = Boolean(model.id);
      const {
         user,
         common: { strings },
      } = getState().gc;

      dispatch({
         type: SAVING_PROJECT,
         flag: true,
      });

      axios
         .post(`/tms/project/${user.empno}`, JSON.stringify(model))
         .then(res => {
            toastr.success(strings.infoTitle, strings.successMessage);
            dispatch({
               type: PROJECT_SAVED,
               payload: entityToEvent(res.data),
               isUpdate,
            });
         })
         .catch(pack.showError)
         .finally(() =>
            dispatch({
               type: SAVING_PROJECT,
               flag: false,
            }),
         );
   };
}

export function removeProject(id) {
   return (dispatch, getState) => {
      const { strings } = getState().gc.common;

      axios
         .delete(`/tms/project/${id}`)
         .then(res => {
            toastr.success(strings.infoTitle, strings.successMessage);
            dispatch({
               type: PROJECT_REMOVED,
               id,
            });
         })
         .catch(pack.showError);
   };
}
