import React from 'react';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/ko';
import LocalizedStrings from 'react-localization';
import $ from 'jquery';
import pathToRegexp from 'path-to-regexp';
import cx from 'classnames';
import { Icon, message, Tree, TreeSelect } from 'antd';
import { amber, blue, brown, cyan, green, indigo, lime, orange, pink, purple, red, teal, yellow } from '@material-ui/core/colors';
import { GcUtils } from '@gc';
import fetch from 'isomorphic-fetch';
import sideBar7 from '../../assets/utils/images/sidebar/city2.jpg';
import profile from '../../assets/utils/images/avatars/profile.jpg';
import company from '../../assets/utils/images/avatars/company.png';

import documentImage from '../../assets/utils/images/attachments/document.png';
import fileImage from '../../assets/utils/images/attachments/attachment.png';
import soundImage from '../../assets/utils/images/attachments/sound.png';
import videoImage from '../../assets/utils/images/attachments/video.png';
import compressImage from '../../assets/utils/images/attachments/compress.png';
import doneImage from '../../assets/utils/images/check-64.png';

import notifyMailIcon from '../../assets/utils/images/icons/notify_email.png';
import notifySignIcon from '../../assets/utils/images/icons/notify_approve.png';
import BgSound from '../../assets/utils/sound/dingdong.wav';
import TaskIcon from '../../assets/utils/images/trello_alim_512.png';
import { isMobile } from 'react-device-detect';

const holidayKr = require('holiday-kr');

const { TreeNode } = Tree;
const TreeSelectNode = TreeSelect.TreeNode;
const fromCountryCode = code => {
   let lang = null;
   switch (code) {
      case 'KR':
         lang = 'ko';
         break;
      case 'US':
         lang = 'en';
         break;
      default:
         lang = code.toLowerCase();
   }
   return lang === 'ko' ? lang : 'en';
};

const holidayApiKey = '';
let serverprop = null;
let servermsg = null;
let strings = getLocale();
let passwordRule = getPasswordRuleInfo();
// const isDev = process.env.NODE_ENV === 'development';
// const { hostname } = window.location;
// let port = isDev ? '8080' : '';
// let protocol = isDev ? 'http' : 'https';
const availableLangs = ['ko', 'en'];

export const pack = {
   strings,
   pageSizes: [25, 50, 100],
   holidays: [],
   holidaysFromGoogle: true,
   language: getLang(),
   appTitle: 'Workware',
   appTitleSub: '',
   a4size: {
      width: 793.7,
      height: 1122.5,
   },
   sm: 576,
   md: 768,
   lg: 992,
   xl: 1200,
   xxl: 1400,
   mapDefaultPosition: {
      x: 37.497323,
      y: 127.013163,
   },
   smallint: 32767,
   tinyint: 127,
   unsignSmallint: 65535,
   unsignTinyint: 255,
   maxEmailBigfileSize: 2147483648, // 2G (대용량 첨부 한계크기)
   maxEmailNormalSize: 26214400, // 25M (대용량 첨부여부 결정 크기) -> ★★ postfix 의 main.cf 에 message_size_limit 값도 함께 수정 ★★
   invalidEmailFolderNames: ['inbox', 'send', 'temp', 'spam', 'recycle'],
   sidebarImagePath: '../../assets/utils/images/sidebar',
   oauthGoogleId: process.env.REACT_APP_GOOGLE_OAUTH_ID, // 구글개발자섽터 - esmp365@gmail.com > Quickstart 프로젝트
   oauthFacebookId: '250764832255215',
   googleCommonApiKey: process.env.REACT_APP_GOOGLE_COMMON_API_KEY,
   holidayCalendarId: process.env.REACT_APP_GOOGLE_HOLIDAY_CALENDAR_ID,
   defaultTimeout: 360, // 단위 : 분, withTimeout 으로 client 타임아웃 메시지 띄우는 대기시간
   defaultMinHeight: 500,
   defaultIcon: 'account_circle', // 'person_outline',
   defaultLogo: 'assets/images/logos/logo_griblue.png',
   defaultPageSize: 20,
   defaultPageSizeList: [20, 50, 100],
   serverUrl: process.env.REACT_APP_API_URL,
   serverPrefix: '/api',
   profileImage: profile,
   companyImage: company,
   documentImage,
   videoImage,
   compressImage,
   doneImage,
   soundImage,
   fileImage,
   taskIconImage: TaskIcon,
   emailIconImage: notifyMailIcon,
   signIconImage: notifySignIcon,
   showRightPanel: false,
   maxFileSize: 104857600, // 100 MB
   HEIGHT_TOP_BAR: 60,
   HEIGHT_NAV_HEADER: 70,
   weekDesc: {
      ko: ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '요일'],
      en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
   },
   monthDesc: {
      1: 'Jan.',
      2: 'Feb.',
      3: 'Mar.',
      4: 'Apr.',
      5: 'May',
      6: 'Jun.',
      7: 'Jul.',
      8: 'Aug.',
      9: 'Sep.',
      10: 'Oct.',
      11: 'Nov.',
      12: 'Dec.',
   },
   regexpUrl: new RegExp(
      '^(https?:\\/\\/)?' + // protocol
         '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
         '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
         '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
         '(\\?[;&a-z\\d%_.,~+=-]*)?' + // query string
         '(\\#[-a-z\\d_]*)?$',
      'i',
   ),
   isCloseMenu: id => {
      return !id || ['mail', 'contacts', 'approve', 'code', 'pds', 'auth', 'employee', 'adminboard'].includes(id) || id.replace(/[0-9]/g, '') === 'board';
   },
   passwordRules: () => {
      if (!passwordRule) {
         passwordRule = getPasswordRuleInfo();
      }
      return passwordRule.rules;
   },
   passwordPeriod: () => {
      if (!passwordRule) {
         passwordRule = getPasswordRuleInfo();
      }
      return passwordRule.period;
   },
   passwordRuleCheck: (value, strings) => {
      let errorMessage = '';
      if (!value) {
         return errorMessage;
      }
      if (!passwordRule) {
         passwordRule = getPasswordRuleInfo();
      }
      passwordRule.rules.forEach(rule => {
         if (rule.isuse !== '1') {
            return true;
         }
         let ok = true;
         switch (rule.type) {
            case 'mix':
               if (rule.value.includes('U')) ok = value.match(/[A-Z]/g);
               if (ok && rule.value.includes('L')) ok = value.match(/[a-z]/g);
               if (ok && rule.value.includes('E')) ok = value.match(/[a-zA-Z]/g);
               if (ok && rule.value.includes('N')) ok = value.match(/[0-9]/g);
               if (ok && rule.value.includes('S')) ok = value.match(/[^a-z\w\s]/g);
               if (!ok) {
                  let msgs = '';
                  for (let i = 0; i < rule.value.length; i++) {
                     msgs += strings[`pwd${rule.value.charAt(i)}`] + ', ';
                  }
                  msgs = msgs.replace(/(.+),\s*$/, '$1');
                  errorMessage = strings.pwdMixFail.replace(/#1/g, msgs);
                  return false;
               }
               break;
            case 'len':
               ok = value.length >= +rule.value;
               if (!ok) {
                  errorMessage = strings.pwdLenFail.replace(/#1/g, String(rule.value));
                  return false;
               }
               break;
            case 'cont':
               let o,
                  d,
                  p,
                  n = 0;
               let l = +rule.value; //repeat limit
               for (let i = 0; i < value.length; i++) {
                  let c = value.charCodeAt(i);
                  if (i > 0 && (p = o - c) > -2 && p < 2 && (n = p === d ? n + 1 : 0) > l - 3) {
                     ok = false;
                     break;
                  }
                  d = p;
                  o = c;
               }
               if (!ok) {
                  errorMessage = strings.pwdContFail.replace(/#1/g, String(rule.value));
                  return false;
               }
               break;
         }
      });
      return errorMessage;
   },
   roles: {
      admin: ['admin'],
      staff: ['admin', 'staff'],
      seller: ['admin', 'seller'],
      vendor: ['admin', 'vendor'],
      client: ['admin', 'client'],
      member: ['admin', 'member', 'staff', 'seller', 'vendor', 'client'],
      all: ['admin', 'member', 'staff', 'seller', 'vendor', 'client', 'guest'],
      guest: ['guest'],
   },
   randomBgColors: [
      blue[600],
      brown[600],
      red[600],
      green[600],
      orange[600],
      purple[600],
      pink[500],
      amber[600],
      indigo[600],
      yellow[600],
      lime[600],
      cyan[600],
      teal[600],
      red[600],
      blue[600],
      brown[600],
      green[600],
      orange[600],
      purple[600],
      pink[500],
      amber[600],
      indigo[600],
      yellow[600],
      lime[600],
      cyan[600],
      teal[600],
   ],
   browserInfo: (() => {
      const ua = navigator.userAgent;
      let tem;
      let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if (/trident/i.test(M[1])) {
         tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
         return `IE ${tem[1] || ''}`;
      }
      if (M[1] === 'Chrome') {
         tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
         if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
      if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
      return M.join(' ');
   })(),
   stripTag: value => {
      /* https://sensechef.com/957 */
      return value
         .replace(/<[^>]*>/g, '')
         .replace(/&nbsp;/g, ' ')
         .replace(/&copy;/g, '©')
         .replace(/&middot;/g, '·');
   },
   nvl: (value, alternative) => {
      if (!value || value.toLowerCase() === 'null') {
         return alternative || '';
      }
      return value;
   },
   reloadedStrings: () => {
      strings = getLocale(true);
      return strings;
   },
   fromCountryCode: fromCountryCode,
   getCountryCode: val => {
      switch (val) {
         case 'ko':
            return 'KR';
         case 'en':
            return 'US';
      }
      return val.toUpperCase();
   },
   getCountryName: val => {
      switch (val) {
         case 'ko':
            return 'Korea';
         case 'en':
            return 'USA';
      }
      return val.toUpperCase();
   },
   /* ReactTable 의 row 클릭시 클릭대상의 class 에 특정문자가 포함되어 있으면 view 로 이동하지 않기 위한 메소드 */
   isViewable: (target, classNamesToStop = [], isAppend = false) => {
      const clss = target.classList.value;
      const defaults = ['ant-checkbox-input', 'MuiIconButton-root', 'MuiIcon-root', 'svg-inline--fa'];
      // view 로 이동하지 않기 위한 클릭대상(체크박스, 아이콘 등)의 class 를 아래에 추가할 것. 클랙대상의 className 으로 'unreadable' 을 줘도 된다.
      const passClass = classNamesToStop.length > 0 ? (!isAppend ? classNamesToStop : [...defaults, ...classNamesToStop]) : defaults;
      return passClass.filter(cls => clss.includes(cls)).length === 0;
   },
   getStrongPassword: (/*score,*/ strings, errorMsg /*= strings.passwordWeak*/) => (values, value) => {
      // return !value || zxcvbn(value).score >= score ? true : errorMsg;
      const message = pack.passwordRuleCheck(value, strings);
      return !Boolean(message) || errorMsg || message;
   },
   getLoaderType: () => {
      const types = ['ball-rotate', 'ball-pulse', 'ball-grid-pulse', 'square-spin', 'ball-pulse-rise', 'line-scale', 'ball-scale-multiple'];
      return GcUtils.randomize(types);
   },
   keyCodes: {
      comma: 188,
      period: 190, // 마침표
      enter: 13,
      tab: 9,
      backspace: 8,
      esc: 27,
      space: 32,
   },
   isInteger: value => {
      const tester = new RegExp('^[0-9]+$');
      return tester.test(String(value));
   },
   excerptFilename: (filename, length) => {
      if (!filename) return '';
      if (!filename.includes('.')) {
         return pack.excerpt(filename, length);
      }
      const filenameOnly = filename.substring(0, filename.lastIndexOf('.'));
      const ext = filename.substr(filename.lastIndexOf('.') + 1);
      return (filenameOnly.length > length ? filenameOnly.substr(0, length) + '..' : filenameOnly) + `.${ext}`;
   },
   excerpt: (value, length) => {
      if (!value) {
         return '';
      }
      return value.length > length ? value.substr(0, length) : value;
   },
   copyText: (text, successText) => {
      const input = document.createElement('input');
      input.setAttribute('type', 'text');
      input.setAttribute('value', text);
      document.body.appendChild(input);
      input.select();
      document.execCommand('copy');
      input.remove();
      if (successText) toastr.success('Info', successText);
   },
   getLoaderColor: () => {
      const colors = ['#f7b924', '#2196f3', '#9e9e9e'];
      return GcUtils.randomize(colors);
   },
   hideFooter: () => {
      const footer = document.getElementsByClassName('app-footer');
      if (footer.length > 0 && footer[0] instanceof Element) {
         footer[0].style.display = 'none';
      }
   },
   styles: {
      listImage: {
         height: 33,
         width: 44,
         objectFit: 'cover',
         objectPosition: 'center',
         textAlign: 'center',
      },
   },
   fetchCode: async groupName => {
      return await axios.get(`/code/list/${groupName}`);
   },
   treeLoop: (data, searchValue = '', disabledText = '', rootId = '0', isAllLeaf) =>
      data
         .filter(item => Boolean(item.title))
         .map(item => {
            let tit = strings[item.title] || item.title;
            const index = tit.toLowerCase().indexOf(searchValue.toLowerCase());
            const beforeStr = tit.substr(0, index);
            const afterStr = tit.substr(index + searchValue.length);
            const LeafIcon = Boolean(item.avatar) ? (
               <img height={18} width={18} className="rounded-circle cover" src={pack.serverImage(item.avatar)} />
            ) : (
               <Icon type="global" className={cx({ disabled: item.disabled })} /*spin={!item.disabled}*/ />
            );
            const title =
               index > -1 ? (
                  <span>
                     {beforeStr}
                     <span style={{ color: '#f50' }}>{searchValue}</span>
                     {afterStr + (item.disabled === true ? disabledText : '')}
                  </span>
               ) : (
                  <span>{tit + (item.disabled === true ? disabledText : '')}</span>
               );
            if (item.children) {
               return (
                  <TreeNode
                     key={item.id}
                     title={title}
                     // value={tit}
                     icon={item.id === rootId ? <Icon type="home" /> : isAllLeaf ? LeafIcon : null}
                     disableCheckbox={Boolean(item.disableCheckbox)}
                     disabled={item.disabled === true}
                  >
                     {pack.treeLoop(item.children, searchValue, disabledText)}
                  </TreeNode>
               );
            }
            return (
               <TreeNode
                  key={item.id}
                  title={title}
                  // value={tit}
                  icon={item.isLeaf ? LeafIcon : <Icon type="folder" />}
                  disableCheckbox={Boolean(item.disableCheckbox)}
                  disabled={item.disabled === true}
               />
            );
         }),
   treeSelectLoop: (data, searchValue = '', disabledText = '') =>
      data.map(item => {
         let tit = strings[item.title] || item.title;
         const index = tit.indexOf(searchValue);
         const beforeStr = tit.substr(0, index);
         const afterStr = tit.substr(index + searchValue.length);
         const title =
            index > -1 ? (
               <span>
                  {beforeStr}
                  <span style={{ color: '#f50' }}>{searchValue}</span>
                  {afterStr + (item.disabled === true ? disabledText : '')}
               </span>
            ) : (
               <span>{tit + (item.disabled === true ? disabledText : '')}</span>
            );
         if (item.children) {
            return (
               <TreeSelectNode
                  key={item.id}
                  title={title}
                  value={`${item.id}\`${item.title}`}
                  icon={<Icon className="mr-1">folder_open</Icon>}
                  disableCheckbox={Boolean(item.disableCheckbox)}
                  disabled={item.disabled === true}
               >
                  {pack.treeSelectLoop(item.children, searchValue, disabledText)}
               </TreeSelectNode>
            );
         }
         return (
            <TreeSelectNode
               key={item.id}
               title={title}
               value={`${item.id}\`${item.title}`}
               icon={
                  <Icon className="mr-1" color="secondary">
                     local_offer
                  </Icon>
               }
               disableCheckbox={Boolean(item.disableCheckbox)}
               disabled={item.disabled === true}
            />
         );
      }),
   isHexString: value => {
      const re = /[0-9A-Fa-f]{6}/g;
      return re.test(value.replace(/^#/, ''));
   },
   getToPath: ({ path }) => pathToRegexp.compile(path),
   updateLocaleData: (data, lang) => {
      strings = new LocalizedStrings(data);
      strings.setLanguage(lang || sessionStorage.getItem('lang'));
      sessionStorage.setItem('locale', JSON.stringify(strings));
      return strings;
   },
   animateView: ($obj, size) => {
      $obj
         .animate({ marginRight: size, marginLeft: `-${size}` }, 50)
         .animate({ marginRight: `-${size}`, marginLeft: size }, 50)
         .animate({ marginRight: size, marginLeft: `-${size}` }, 50)
         .animate({ marginRight: '', marginLeft: '' }, 100);
   },
   AES_Encode: (value, encKey) => {
      /* 참고 : https://stackoverflow.com/questions/36733132/react-native-aes-encryption-matching-java-decryption-algorithm */
      // const key = CryptoJS.enc.Latin1.parse(pack.serverprop().code['aesKey' + new Date().getDay()]);    // 일 ~ 토 = 0 ~ 6
      /*const key = CryptoJS.enc.Latin1.parse(encKey || pack.serverprop().code[`aesKey${new Date().getDay()}`]); // SessionManager 에서 관리.
      const iv = CryptoJS.enc.Latin1.parse('1588365816553837'); // AESUtil.java 의 decrypt 메소드의 값과 동일하게 유지.
      const encrypted = CryptoJS.AES.encrypt(value, key, {
         iv,
         mode: CryptoJS.mode.CBC,
         padding: CryptoJS.pad.ZeroPadding,
      });
      return encrypted;*/
      return value;
   },
   hasRole: (user, roleName) => {
      return user.isAdmin || user.isSuper || user.roles.includes(roleName);
   },
   getCurrentPath: () => {
      let path = '';
      path = window.location.pathname;
      if (path === '/') {
         path = window.location.hash;
         if (path && path.startsWith('#')) path = path.slice(1);
      }
      if (path === '/') {
         path = window.location.href;
         path = path.replace(/^https?:\/\/[^/]+\/(.+)\?*.*/g, '$1');
         if (path && path.startsWith('#')) path = path.slice(1);
      }
      return path;
   },
   currencyFormat: value => {
      const str = String(value);
      let newStr = '';
      let j = 0;
      for (let i = 1; i <= str.length; i++) {
         newStr = str[str.length - i] + newStr;
         if (++j % 3 === 0) {
            newStr = ',' + newStr;
         }
      }
      return newStr.replace(/^,/, '');
   },
   phoneFormat: value => {
      let value1 = $.trim(value);
      let value2 = '';
      let header = '';
      const headList = [
         '010',
         '011',
         '016',
         '017',
         '018',
         '019',
         '02',
         '051',
         '053',
         '032',
         '062',
         '042',
         '052',
         '044',
         '031',
         '033',
         '043',
         '041',
         '063',
         '061',
         '054',
         '055',
         '064',
         '010',
         '030',
         '050',
         '060',
         '070',
         '080',
      ];
      headList.forEach(head => {
         if (value1.length > head.length && value1.startsWith(head)) {
            value1 = value1.substring(head.length);
            header = head;
            return false;
         }
         return true;
      });

      if (value1 && value1.length >= 7) {
         value2 = value1.substring(value1.length - 4);
         value1 = value1.slice(0, value1.length - 4);
      }

      if (header && value1) {
         header += '-';
      }

      if (value1 && value2) {
         value1 += '-';
      }

      return header + value1 + value2;
   },
   fileSizeFormat: size => {
      size = Number(size);
      if (Math.floor(size / 1024) === 0) {
         return `${size} B`;
      }
      size /= 1024;
      if (Math.floor(size / 1024) === 0) {
         return `${Math.round(size)} KB`;
      }
      size /= 1024;
      if (Math.floor(size / 1024) === 0) {
         return `${Math.round(size)} MB`;
      }
      size /= 1024;
      if (Math.floor(size / 1024) === 0) {
         return `${Math.round(size)} GB`;
      }
      return `${Math.round(size / 1024)} TB`; // ljc 왈 : 이게 실행될 일이 있다면 큰일난거다!!
   },
   /* 단위 없이 숫자만 받을 경우 기본단위를 Byte 로 설정 */
   toRealFilesize: text => {
      if (!text) return 0;
      let num = String(text).replace(/([0-9])[^0-9]*/g, '$1');
      try {
         num = +num;
      } catch (e) {
         toastr.error('Error', 'pack.toRealFilesize : Invalid Number !! (correct param must be like 20MB)');
         return -1;
      }

      let unit = String(text).replace(/([0-9]+)/g, '');
      // unit = unit || 'MB';
      if (unit.length > 1) {
         unit = unit.replace(/[bB]/g, '');
      }
      switch (unit.toUpperCase()) {
         case 'K':
            return num * 1024;
         case 'M':
            return num * 1024 * 1024;
         case 'G':
            return num * 1024 * 1024 * 1024;
         case 'T':
            return num * 1024 * 1024 * 1024 * 1024;
      }
      return num;
   },
   showError: e => {
      console.error(e);
      if (!e.response || !e.response.data) {
         return;
      }
      if (e.response.status === 401 && typeof window !== 'undefined') {
         // toastr.warning(strings.infoTitle, '로그인이 만료되었습니다');
         window.location.hash = '#/login';
      } else {
         const err = e.response.data;
         console.log(err.message); // Exception 의 실제 메세지는 콘솔에 표시
         toastr.warning(strings.errTitle, err.message || err); // 상태코드(500, 404..)별 오류를 토스트로 표시
      }
   },
   servermsg: () => {
      if (!servermsg) {
         try {
            servermsg = JSON.parse(localStorage.getItem('servermsg'));
         } catch (e) {
            $.ajax({
               method: 'get',
               url: `${process.env.REACT_APP_API_URL}/api/locale`,
               contentType: 'application/text;charset=UTF-8', // 서버에서 produces 에도 똑같이 세팅해줘서 한글깨짐 방지
            }).done(res => {
               setMessages(Object.keys(res), false);
               servermsg = JSON.parse(localStorage.getItem('servermsg'));
            });
         }
      }
      return servermsg ? servermsg[pack.language] : {};
   },
   serverprop: () => {
      if (!serverprop) {
         try {
            serverprop = JSON.parse(localStorage.getItem('serverprop'));
         } catch (e) {
            console.log('serverprop not set');
         }
      }
      return (
         serverprop || {
            option: {},
            code: {},
            ext: {},
         }
      );
   },
   timeout: ms => {
      return new Promise(resolve => setTimeout(resolve, ms));
   },
   invertColor: (hex, isBlackOrWhite) => {
      function padZero(str, len) {
         len = len || 2;
         const zeros = new Array(len).join('0');
         return (zeros + str).slice(-len);
      }

      if (hex.indexOf('#') === 0) {
         hex = hex.slice(1);
      }
      // convert 3-digit hex to 6-digits.
      if (hex.length === 3) {
         hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
         throw new Error('Invalid HEX color.');
      }
      let r = parseInt(hex.slice(0, 2), 16);
      let g = parseInt(hex.slice(2, 4), 16);
      let b = parseInt(hex.slice(4, 6), 16);
      if (isBlackOrWhite) {
         // http://stackoverflow.com/a/3943023/112731
         return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
      }
      // invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
      // pad each with zeros and return
      return `#${padZero(r)}${padZero(g)}${padZero(b)}`;
   },
   getExtension: filename => {
      return filename.indexOf('.') === -1 || filename.endsWith('.') ? null : filename.substring(filename.lastIndexOf('.') + 1);
   },
   getPasswordStrengthMsg: score => {
      let msg, className;
      switch (score) {
         case 0:
            msg = 'Too weak';
            className = 'text-danger';
            break;
         case 1:
            msg = 'Weak';
            className = 'text-danger';
            break;
         case 2:
            msg = 'Okay';
            className = 'text-warning';
            break;
         case 3:
            msg = 'Good';
            className = 'text-success';
            break;
         case 4:
            msg = 'Powerful';
            className = 'text-primary';
            break;
         default:
            msg = '';
            className = '';
      }
      return { msg, className };
   },
   getPasswordStrengthMsg2: (value, strings) => {
      const message = pack.passwordRuleCheck(value, strings);
      let msg, className;
      if (!value) {
         msg = '';
         className = '';
      } else if (message) {
         msg = 'Weak';
         className = 'text-danger';
      } else {
         msg = 'Good';
         className = 'text-success';
      }
      return { msg, className };
   },
   getMatchingFunction: type => {
      return type instanceof RegExp
         ? {
              onInput: e => {
                 const obj = e.target;
                 const val = obj.value;
                 if (!type.test(val) && val.length > 1) {
                    const preVal = val.substring(0, val.length - 1);
                    if (!type.test(preVal)) {
                       obj.value = val.substring(0, preVal.length - 1);
                       return;
                    }
                 }
                 if (!type.test(val)) {
                    obj.value = val.substring(0, val.length - 1);
                 }
              },
           }
         : type === 'numPointOnly'
         ? {
              onInput: e => {
                 const obj = e.target;
                 obj.value = obj.value.replace(/[^0-9.]/g, ''); //.replace(/^0.+/g, '');
                 if (Boolean(obj.value) && (obj.value.startsWith('0') || obj.value.startsWith('.')) && obj.value.length >= 2) {
                    obj.value = obj.value.slice(1);
                 }
              },
           }
         : type === 'numOnly'
         ? {
              onInput: e => {
                 const obj = e.target;
                 obj.value = obj.value.replace(/[^0-9]/g, ''); //.replace(/^0.+/g, '');
                 if (Boolean(obj.value) && obj.value.startsWith('0') && obj.value.length >= 2) {
                    obj.value = obj.value.slice(1);
                 }
              },
           }
         : type === 'numStringOnly'
         ? {
              onInput: e => {
                 const obj = e.target;
                 obj.value = obj.value.replace(/[^0-9]/g, ''); //.replace(/^0.+/g, '');
              },
           }
         : typeof type === 'string' && type.startsWith('numOnlyLength(')
         ? {
              onInput: e => {
                 const obj = e.target;
                 const maxlen = +type.replace(/^numOnlyLength\(([0-9]+)\)/gi, '$1');
                 obj.value = obj.value.replace(/[^0-9]/g, ''); //.replace(/^0.+/g, '');
                 if (Boolean(obj.value) && obj.value.startsWith('0') && obj.value.length >= 2) {
                    obj.value = obj.value.slice(1);
                 }
                 if (String(obj.value).length > maxlen) {
                    obj.value = obj.value.substr(0, maxlen);
                 }
              },
           }
         : typeof type === 'string' && type.startsWith('numOnlyMax(')
         ? {
              onInput: e => {
                 const obj = e.target;
                 const maxval = +type.replace(/^numOnlyMax\(([0-9]+)\)/gi, '$1');
                 obj.value = obj.value.replace(/[^0-9]/g, ''); //.replace(/^0.+/g, '');
                 if (Boolean(obj.value) && obj.value.startsWith('0') && obj.value.length >= 2) {
                    obj.value = obj.value.slice(1);
                 }
                 if (Boolean(obj.value) && +obj.value > maxval) {
                    obj.value = maxval;
                 }
              },
           }
         : type === 'idOnly'
         ? {
              // 숫자, _, 영문자만 가능
              onInput: e => {
                 const obj = e.target;
                 const regex = /[^0-9a-zA-Z_-]/g;
                 if (!regex.test(obj.value)) {
                    message.warning(strings.engOnly);
                 }
                 obj.value = obj.value.replace(regex, '').toLowerCase();
              },
           }
         : /^max\(\s*[0-9]+\s*\)/gi.test(type)
         ? {
              onInput: e => {
                 const obj = e.target;
                 const max = Number(type.replace(/^max\(\s*([0-9]+)\s*\)/gi, '$1'));
                 obj.value = obj.value.replace(/[^0-9.]/g, '').replace(/^[^0-9]*([0-9]+\.?[0-9]*)[^0-9]*/g, '$1');
                 if (Number(obj.value || '0') > max) {
                    obj.value = max;
                 }
              },
           }
         : /^maxLength\([0-9]+\)$/gi.test(type)
         ? {
              onInput: e => {
                 const obj = e.target;
                 const maxlen = Number(type.replace(/^maxLength\(([0-9]+)\)/gi, '$1'));
                 const val = obj.value || '';
                 if (val.length > maxlen) {
                    obj.value = val.substr(0, maxlen);
                 }
              },
           }
         : null;
   },
   getUserRole: (user, strings) => {
      if (user.isAdmin) {
         return strings.roleAdmin;
      }
      if (!user.role) {
         return '';
      }
      switch (user.role) {
         case 'employee':
            return strings.roleStaff;
         case 'seller':
            return strings.roleSeller;
         case 'guest':
            return strings.roleGuest;
      }
      return user.role.toUpperCase();
   },
   isValidUrl: str => {
      const pattern = pack.regexpUrl; // fragment locator
      return !!pattern.test(str);
   },
   blobToFile: (theBlob, fileName) => {
      return new File([theBlob], fileName);
   },
   onFileClick: (onChange, onPreviewUpdate, totalMaxSizeInString, multiple, accepts) => {
      if (!accepts) {
         accepts = pack
            .serverprop()
            .ext.all.split(',')
            .map(it => `.${it}`)
            .join(',');
      }
      const input = document.createElement('input');
      input.setAttribute('type', 'file');
      if (Boolean(multiple)) input.setAttribute('multiple', true);
      input.setAttribute('accept', accepts);
      input.style.display = 'none';
      document.body.appendChild(input);
      input.addEventListener('change', e => {
         let files = [...e.target.files];
         if (Boolean(files) && files.length > 0) {
            const filesize = Boolean(multiple) ? files.reduce((acc, cur, idx) => acc + cur.size) : files[0].size;
            if (Boolean(totalMaxSizeInString) && totalMaxSizeInString != '0' && filesize > pack.toRealFilesize(totalMaxSizeInString)) {
               message.warn(`${Boolean(multiple) ? 'Total ' : ''}File size exceeds ${totalMaxSizeInString}`);
               e.target.remove();
               return;
            }

            if (typeof onPreviewUpdate === 'function')
               files.forEach(file => {
                  pack.setFileImageByType(file, null, onPreviewUpdate);
               });

            if (typeof onChange === 'function') {
               onChange(Boolean(multiple) ? _.uniqBy(files, 'name') : files[0]);
            }
         }
         e.target.remove();
      });
      input.click();
   },
   /*
      file : type='file' input 에서 받아온 file / 혹은 db 에서 가져온 file 객체
      두 가지를 구분하는 기준은 savename 이 있냐 없냐다.
    */
   setFileImageByType: (file, saveKeyname, onPreviewUpdate) => {
      const filename = !saveKeyname ? file.name : file[saveKeyname];
      let type = 'etc';
      let previewImage = pack.fileImage;
      if (filename.includes('.')) {
         const ext = filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();
         if (pack.serverprop().ext.image.includes(ext)) {
            type = 'image';
            if (Boolean(saveKeyname)) {
               previewImage = pack.serverImage(file[saveKeyname]);
            } else {
               const reader = new FileReader();
               reader.onloadend = () => {
                  file['previewImage'] = reader.result;
                  if (typeof onPreviewUpdate === 'function') {
                     onPreviewUpdate(file);
                  }
               };
               reader.readAsDataURL(file);
            }
         } else if (pack.serverprop().ext.video.includes(ext)) {
            type = 'video';
            previewImage = pack.videoImage;
         } else if (pack.serverprop().ext.document.includes(ext)) {
            type = 'document';
            previewImage = pack.documentImage;
         } else if (pack.serverprop().ext.compress.includes(ext)) {
            type = 'compress';
            previewImage = pack.compressImage;
         } else if (pack.serverprop().ext.sound.includes(ext)) {
            type = 'sound';
            previewImage = pack.soundImage;
         }
      }
      file['fileType'] = type;
      file['previewImage'] = previewImage;
      /*if (typeof onPreviewUpdate === 'function') {
         onPreviewUpdate(file);
      }*/
   },
   serverImage: (avatar, defaultImage, subDir) => {
      if (!avatar) {
         return defaultImage || pack.profileImage;
      }

      if (pack.browserInfo.startsWith('IE')) {
         return defaultImage || pack.profileImage;
      }
      return `${pack.serverUrl}/image/${Boolean(subDir) ? `${subDir}/` : ''}${avatar}`;
   },
   getLabelByValueFromOptions: (value, options) => {
      return (_.find(options, { value }) || {}).label;
   },
   codes: {
      BOARDS_CATEGORY: 'BRDS_CATE',
   },
   options: {
      validate: {
         required: value => (value ? '' : '필수 입력값입니다'),
         number: value => (value && isNaN(Number(value)) ? '숫자가 아닙니다' : ''),
         email: value => (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? '올바른 주소가 아닙니다' : ''),
      },
      swal: {
         type: 'error', // ["warning","error","success","info","input"]
         title: strings.delete,
         text: strings.sureDelete,
         html: null,
         showCancelButton: true,
         showConfirmButton: true,
         confirmButtonText: strings.confirm,
         cancelButtonText: strings.cancel,
         allowEscapeKey: true,
         backdrop: true,
         showCloseButton: true,
         onConfirm: null,
         onCancel: null,
      },
      notify: {
         title: 'Hello',
         body: 'Some fishy is going on',
         icon: notifyMailIcon,
         tag: 'abcdef',
         timeout: 60000,
      },
   },
   playSound: filename => {
      const soundElem = document.getElementById('bg-sound');
      soundElem.setAttribute('src', `/sound/${filename || 'pop.mp3'}`);
      soundElem.play();
   },
   alarm: (iconSrc, title, parameter, timeout, href) => {
      const soundElem = document.getElementById('bg-sound');
      soundElem.setAttribute('src', BgSound);
      soundElem.play();

      if (href && href.startsWith('http')) {
         href = href.replace(/https?:\/\//, '');
         href = href.substring(href.indexOf('/') + 1);
         if (!href.startsWith('#')) {
            href = `#${href}`;
         }
      }

      let component = null;
      if (typeof parameter === 'object') {
         if (parameter.type === 'cardDone') {
            const { card, updater, location } = parameter;
            component = (
               <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                     <div className="font-weight-bold" style={{ fontSize: 16, color: lime[200] }}>
                        {updater.desc}&nbsp;
                     </div>
                     <div>{card.isdone === '1' ? strings.cardDoneWho : strings.cardUndoneWho}</div>
                  </div>
                  <div className="d-flex align-items-center">
                     <div>{strings.activityName}&nbsp;:&nbsp;</div>
                     <div style={{ fontSize: 16, color: lime[200] }}>{location}</div>
                  </div>
               </div>
            );
         } else if (parameter.type === 'cardMove') {
            const { updater, location } = parameter;
            component = (
               <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                     <div className="font-weight-bold" style={{ fontSize: 16, color: lime[200] }}>
                        {updater.desc}&nbsp;
                     </div>
                     <div>{strings.cardMoveWho}</div>
                  </div>
                  <div style={{ whiteSpace: 'pre-wrap', color: lime[300] }}>{location}</div>
               </div>
            );
         } else if (parameter.type === 'newFollowCard') {
            const { user, updater, location } = parameter;
            component = (
               <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                     <div className="font-weight-bold" style={{ fontSize: 16, color: lime[200] }}>
                        {updater}&nbsp;
                     </div>
                     <div>
                        {strings.followAddWho1}&nbsp;{user}
                        {strings.followAddWho2}
                     </div>
                  </div>
                  <div style={{ whiteSpace: 'pre-wrap', color: lime[300] }}>{location}</div>
                  <div className="w-100 d-flex justify-content-center mt-2">
                     <button className="btn btn-warning px-3" onClick={() => (window.location.hash = href)}>
                        바로가기
                     </button>
                  </div>
               </div>
            );
         } else if (parameter.type === 'newCard') {
            const { location } = parameter;
            component = (
               <div className="d-flex flex-column">
                  <div className="d-flex align-items-center mb-2">
                     {/*<div className="font-weight-bold" style={{fontSize: 16, color: lime[200]}}>{updater.desc}&nbsp;</div>*/}
                     <div>{strings.newCardCreated}</div>
                  </div>
                  <div style={{ whiteSpace: 'pre-wrap', color: lime[300] }}>{location}</div>
               </div>
            );
         }
      } else if (typeof parameter === 'string') {
         component = (
            <div className={href ? 'd-flex flex-column' : ''}>
               <div className="crlf">{parameter}</div>
               {href && (
                  <div className="w-100 d-flex justify-content-center mt-2">
                     <button className="btn btn-warning px-3" onClick={() => (window.location.hash = href)}>
                        바로가기
                     </button>
                  </div>
               )}
            </div>
         );
      } else {
         component = <div>{title}</div>;
      }
      toastr.info(title, {
         timeOut: timeout || 6000,
         position: 'top-right',
         icon: <img src={iconSrc || TaskIcon} className="rounded-circle mb-2" width={50} height={50} />,
         component: component,
      });
   },
   notify: alims => {
      // [ {title, body, icon, timeout, onClick}, {...} ]
      if (!alims || alims.length === 0) {
         return;
      }

      if (Notification && Notification.permission !== 'granted') {
         Notification.requestPermission().then(permission => {
            if (permission === 'granted') {
               pack.notify(alims);
            }
         });
         /*Notification.requestPermission(status => {
            if (Notification.permission !== status) {
               Notification.permission = status;
            }
         });*/
      } else if (Notification && Notification.permission === 'granted') {
         let i = 0;
         const interval = setInterval(() => {
            const alim = alims[i];
            const notification = new Notification(alim.title, {
               body: alim.body,
               icon: alim.icon || notifyMailIcon,
               tag: i % 5, // 같은 태그값의 알림이 떠 있으면 대체한다. (최대 5개의 알림만 띄우기 위한 수단)
            });
            if (typeof alim.onClick === 'function') {
               notification.addEventListener('onclick', alim.onClick);
            }
            if (++i === alims.length) {
               clearInterval(interval);
            }
            setTimeout(notification.close.bind(notification), alim.timeout || 10000);
         }, 200);
      } else if (Notification && Notification.permission !== 'denied') {
         Notification.requestPermission(status => {
            if (status === 'granted') {
               let i = 0;
               const interval = setInterval(() => {
                  const alim = alims[i];
                  const notification = new Notification(alim.title, {
                     body: alim.body,
                     icon: alim.icon || notifyMailIcon,
                     tag: i % 5, // 같은 태그값의 알림이 떠 있으면 대체한다. (최대 5개의 알림만 띄우기 위한 수단)
                  });
                  if (typeof alim.onClick === 'function') {
                     notification.addEventListener('onclick', alim.onClick);
                  }
                  if (++i === alims.length) {
                     clearInterval(interval);
                  }
                  setTimeout(notification.close.bind(notification), alim.timeout || 10000);
               }, 200);
            } /*else {
               if (alims.length > 1) {
                  alert("You have new messages : \n" + alims[0].title + "\n" + alims[0].body + "\n" + "외 " + (alims.length-1) + "건")
               } else {
                  alert("You have a new message : \n" + alims[0].title + "\n" + alims[0].body);
               }
            }*/
         });
      } /* else {
         if (alims.length > 1) {
            alert("You have new messages : \n" + alims[0].title + "\n" + alims[0].body + "\n" + "외 " + (alims.length-1) + "건")
         } else {
            alert("You have a new message : \n" + alims[0].title + "\n" + alims[0].body);
         }
      }*/
   },
   /* uuidv4 의 uuid() 함수로 대체 */
   getUUID: () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
         const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
         return v.toString(16);
      });
   },
   sortArray: (arr, key) => {
      arr.sort((a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0));
   },
   numberToKorean: (value, suffix) => {
      if (!value) return '';
      if (+value === 0) return '영 원';
      const hanA = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구', '십'];
      const danA = ['', '십', '백', '천', '', '십', '백', '천', '', '십', '백', '천', '', '십', '백', '천'];
      let result = '';
      const num = String(value);

      for (let i = 0; i < num.length; i++) {
         let str = '';
         const han = hanA[num.charAt(num.length - (i + 1))];
         if (han) str += han + danA[i];
         if (i === 4) str += '만 ';
         if (i === 8) str += '억 ';
         if (i === 12) str += '조 ';
         result = str + result;
      }
      if (num !== '0') result += suffix || '원';
      return result
         .replace(/(.*조\s*)억(.*)/g, '$1$2')
         .replace(/(.*조\s*)만(.*)/g, '$1$2')
         .replace(/(.*억\s*)만(.*)/g, '$1$2');
   },
   capitalize: value => {
      return value.charAt(0).toUpperCase() + value.slice(1);
   },
   daysBetween: (firstDate, secondDate, withoutHolidy) => {
      if (!withoutHolidy) {
         const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
         return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay)) + 1;
      }
      let date = new Date(firstDate);
      date.setHours(0, 0, 0, 0);
      let cnt = 0;
      do {
         if (!pack.isHoliday(date)) {
            cnt++;
         }
         date = pack.addDays(date, 1);
      } while (date <= secondDate);
      return cnt;
   },
   addDays: (date, days) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
   },
   isSameDay: (date1, date2) => {
      return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
   },
   getWeekOfMonth: date => {
      const selectedDayOfMonth = date.getDate();
      const first = new Date(date.getFullYear() + '/' + (date.getMonth() + 1) + '/01');
      const monthFirstDateDay = first.getDay();
      const weekNum = Math.ceil((selectedDayOfMonth + monthFirstDateDay) / 7);
      return date.getDay() < first.getDay() ? weekNum - 1 : weekNum;
   },
   getWeekNumberOfMonth: date => {
      let count = 0;
      const weekday = date.getDay();
      const idate = new Date(date.getFullYear(), date.getMonth(), 1);
      while (idate.getTime() <= date.getTime()) {
         if (idate.getDay() === weekday) {
            count++;
         }
         idate.setDate(idate.getDate() + 1);
      }
      return count;
   },
   getNthDayOfWeek: (date, dayOfWeek, nthWeek) => {
      const momentDate = moment(date);
      let result = momentDate.startOf('month');
      let cnt = 0;
      while (true) {
         if (result.day() === dayOfWeek) cnt++;
         if (cnt < nthWeek) result.add(1, 'days');
         else break;
      }
      return result.toDate();
   },
   getFirstDayOfWeek: (date, dayOfWeek) => {
      const momentDate = moment(date);
      let result = momentDate.startOf('month');
      while (result.day() !== dayOfWeek) {
         result.add(1, 'days');
      }
      return result.toDate();
   },
   getLastDayOfWeek: (date, dayOfWeek) => {
      const momentDate = moment(date);
      let result = momentDate.endOf('month');
      while (result.day() !== dayOfWeek) {
         result.subtract(1, 'days');
      }
      return result.toDate();
   },
   isWeekend: date => {
      return date.getDay() === 0 || date.getDay() === 6;
   },
   isHoliday: date => {
      // 직접구현은 DateUtil.java 참고해서 만들자. 음력구하기 부분이 Java 에 좀 복잡해질 것이다.
      const dateStr = pack.dateToString(date, 'yyyyMMdd');
      const holiday = _.find(pack.holidays, { dateStr });
      if (holiday) {
         return true;
      }
      return holidayKr.isSolarHoliday(date) || pack.isAlternativeHoliday(date) || pack.isWeekend(date);
   },
   /*getHolidayEvents: () => {
      return pack.holidays.map(item => ({
         ...item,
         allDay: true,
         backgroundColor: '#f44336'
      }));
   },*/
   /** ****************************************************************************
    * Function명 : gfnIsLeapYear
    * 설명       : 윤년여부 확인
    * Params     : sDate : yyyyMMdd형태의 날짜 ( 예 : "20121122" )
    * Return     :
    *               - sDate가 윤년인 경우 = true
    *     - sDate가 윤년이 아닌 경우 = false
    *       - sDate가 입력되지 않은 경우 = false
    ***************************************************************************** */
   isLeapYear: sDate => {
      let ret;
      const nY = parseInt(sDate.substring(0, 4), 10);
      if (nY % 4 === 0) {
         if (nY % 100 !== 0 || nY % 400 === 0) ret = true;
         else ret = false;
      } else ret = false;
      return ret;
   },
   /** ****************************************************************************
    * Function명 : gfnSolar2Lunar
    * 설명       : 양력을 음력으로 변환해주는 함수 (처리가능 기간  1841 - 2043년)
    * Params     : sDate : yyyyMMdd형태의 양력일자 ( 예 : "20121122" )
    * Return     : return값이 8자리가 아니고 9자리임에 주의
    *               - 성공 = Flag(1 Byte) + (yyyyMMdd형태의 음력일자)
    *        ( Flag : 평달 = "0", 윤달 = "1" )
    *       - 실패 = "" ( 1841 ~ 2043 범위 오류시 )
    ***************************************************************************** */
   solar2Lunar: date => {
      const sMd = '31,0,31,30,31,30,31,31,30,31,30,31';
      let aMd = [];
      let aBaseInfo = [];
      const aDt = []; // 매년의 음력일수를 저장할 배열 변수
      let td; // 음력일을 계산하기 위해 양력일과의 차이를 저장할 변수
      let td1; // 1840년까지의 날수
      let td2; // 현재까지의 날수
      let mm; // 임시변수
      let nLy;
      let nLm;
      let nLd; // 계산된 음력 년, 월, 일을 저장할 변수
      let sLyoon; // 현재월이 윤달임을 표시
      let m1;
      let m2;
      let i;
      let sD;
      let sY;
      let sM;
      let j;

      const sDate = pack.dateToString(date, 'yyyyMMdd');

      // eslint-disable-next-line prefer-const
      sY = parseInt(sDate.substr(0, 4), 10);
      // eslint-disable-next-line prefer-const
      sM = parseInt(sDate.substr(4, 2), 10);
      // eslint-disable-next-line prefer-const
      sD = parseInt(sDate.substr(6, 2), 10);
      if (sY < 1841 || sY > 2043) return '';

      // eslint-disable-next-line no-underscore-dangle
      aBaseInfo = pack._SolarBase();
      aMd = sMd.split(',');
      if (pack.isLeapYear(sDate)) aMd[1] = 29;
      else aMd[1] = 28;

      // eslint-disable-next-line prefer-const
      td1 = 672069; // 672069 = 1840 * 365 + 1840/4 - 1840/100 + 1840/400 + 23  //1840년까지 날수

      // 1841년부터 작년까지의 날수
      td2 = (sY - 1) * 365 + +((sY - 1) / 4) - +((sY - 1) / 100) + +((sY - 1) / 400);

      // 전월까지의 날수를 더함
      for (i = 0; i <= sM - 2; i++) td2 += +aMd[i];

      // 현재일까지의 날수를 더함
      td2 += sD;

      // 양력현재일과 음력 1840년까지의 날수의 차이
      td = td2 - td1 + 1;

      // 1841년부터 음력날수를 계산
      for (i = 0; i <= sY - 1841; i++) {
         aDt[i] = 0;
         for (j = 0; j <= 11; j++) {
            switch (+aBaseInfo[i * 12 + j]) {
               case 1:
                  mm = 29;
                  break;
               case 2:
                  mm = 30;
                  break;
               case 3:
                  mm = 58; // 29 + 29
                  break;
               case 4:
                  mm = 59; // 29 + 30
                  break;
               case 5:
                  mm = 59; // 30 + 29
                  break;
               case 6:
                  mm = 60; // 30 + 30
                  break;
               default:
                  break;
            }
            // eslint-disable-next-line operator-assignment
            aDt[i] = aDt[i] + mm;
         }
      }

      // 1840년 이후의 년도를 계산 - 현재까지의 일수에서 위에서 계산된 1841년부터의 매년 음력일수를 빼가면수 년도를 계산
      nLy = 0;
      do {
         td -= aDt[nLy];
         nLy += 1;
      } while (td > aDt[nLy]);

      nLm = 0;
      sLyoon = '0'; // 현재월이 윤달임을 표시할 변수 - 기본값 평달
      do {
         if (+aBaseInfo[nLy * 12 + nLm] <= 2) {
            mm = +aBaseInfo[nLy * 12 + nLm] + 28;
            if (td > mm) {
               td -= mm;
               nLm += 1;
            } else break;
         } else {
            switch (+aBaseInfo[nLy * 12 + nLm]) {
               case 3:
                  m1 = 29;
                  m2 = 29;
                  break;
               case 4:
                  m1 = 29;
                  m2 = 30;
                  break;
               case 5:
                  m1 = 30;
                  m2 = 29;
                  break;
               case 6:
                  m1 = 30;
                  m2 = 30;
                  break;
               default:
                  break;
            }

            if (td > m1) {
               td -= m1;
               if (td > m2) {
                  td -= m2;
                  nLm += 1;
               } else {
                  sLyoon = '1';
               }
            } else {
               break;
            }
         }
      } while (1);

      nLy += 1841;
      nLm += 1;
      // eslint-disable-next-line prefer-const
      nLd = td;

      return sLyoon + nLy + _.padStart(nLm, 2, '0') + _.padStart(nLd, 2, '0');
   },
   isAlternativeHoliday: date => {
      let result = false;
      let d = null;
      d = pack.stringToDate(`${date.getFullYear()}0505`);
      if (pack.isWeekend(d)) {
         d = pack.addDays(d, 1);
      }
      if (pack.isWeekend(d)) {
         d = pack.addDays(d, 1);
      }
      if (pack.isSameDay(date, d)) {
         result = true;
      }

      // let dStr = moment().year(date.getFullYear()).month(date.getMonth()+1).date(date.getDate()).lunar().format('MMDD');
      let dStr = pack.solar2Lunar(date);
      dStr = dStr.slice(dStr.length - 4); // MMdd 만 남김.

      d = new Date(date);
      if (dStr === '0103') {
         d = pack.addDays(d, -1);
         if (d.getDay() === 0) return true;
         d = pack.addDays(d, -1);
         if (d.getDay() === 0) return true;
         d = pack.addDays(d, -1);
         if (d.getDay() === 0) return true;
      }

      d = new Date(date);
      if (dStr === '0817') {
         d = pack.addDays(d, -1);
         if (d.getDay() === 0) return true;
         d = pack.addDays(d, -1);
         if (d.getDay() === 0) return true;
         d = pack.addDays(d, -1);
         if (d.getDay() === 0) return true;
      }
      return result;
   },
   momentToString: mt => {
      return !mt ? mt : mt.year() + _.padStart(mt.month() + 1, 2, '0') + _.padStart(mt.date(), 2, '0');
   },
   stringToDate: (value, defaultDate) => {
      if (!value) {
         return defaultDate;
      }
      value = value.replace(/[^0-9]/g, '');
      if (value.length < 8) {
         if (value.length === 4) {
            return new Date(value);
         } else if (value.length === 6) {
            const y = value.substr(0, 4);
            const m = value.substr(4, 2);
            return new Date(`${y}-${m}`);
         }
         return new Date();
      }
      let date = null;
      const year = value.substr(0, 4);
      const month = value.substr(4, 2);
      const day = value.substr(6, 2);

      if (value.length === 8) {
         date = new Date(`${year}-${month}-${day}`);
      } else if (value.length === 10) {
         const hour = value.substr(8, 2);
         date = new Date(+year, +month - 1, +day, +hour);
      } else if (value.length === 12) {
         const hour = value.substr(8, 2);
         const min = value.substr(10, 2);
         date = new Date(+year, +month - 1, +day, +hour, +min);
      } else if (value.length === 14) {
         const hour = value.substr(8, 2);
         const min = value.substr(10, 2);
         const sec = value.substr(12, 2);
         date = new Date(+year, +month - 1, +day, +hour, +min, +sec);
      }
      return date;
   },
   stringToDateWithTime: (value, defaultDate) => {
      if (!value) {
         return defaultDate;
      }
      value = value.replace(/[^0-9]/g, '');
      if (value.length < 12) {
         console.log('잘못된 날짜형식입니다. 12자리가 아닙니다.', value);
         return null;
      }
      const year = value.substr(0, 4);
      const month = value.substr(4, 2);
      const day = value.substr(6, 2);
      const hour = value.substr(8, 2);
      const min = value.substr(10, 2);

      if (value.length === 14) {
         const sec = value.substr(12, 2);
         return new Date(`${year}-${month}-${day} ${hour}:${min}:${sec}`);
      }
      return new Date(`${year}-${month}-${day} ${hour}:${min}`);
   },
   stringToMoment: (val, defaultDate) => {
      let rt = null;
      if (!val) rt = defaultDate ? moment(defaultDate) : null;
      else rt = moment(pack.dateFormat(val), 'YYYY-MM-DD');
      return rt;
   },
   timestampToDate: (value, defaultDate) => {
      if (!value) return defaultDate;
      return new Date(value);
   },

   /* yyyyMMdd HH:mm:ss */
   timestampToFormattedString: (value, format, useFromNow) => {
      const date = new Date(value);
      if (useFromNow) {
         const now = new Date();
         if (pack.dateDiff(now, date, 'hour') < 6) {
            return moment(date).fromNow();
         }
      }
      return pack.dateToString(date, format);
   },
   timestampToMoment: value => {
      if (!value) return null;
      return moment(new Date(value));
   },
   timestampToString: (value, showTime, showSecondToo, useFromNow, withoutCurrentYear, todayText) => {
      const date = new Date(value);
      if (useFromNow) {
         const now = new Date();
         if (pack.dateDiff(now, date, 'hour') < 6) {
            return moment(date).fromNow();
         }
      }
      let rt = '';
      if (!withoutCurrentYear) {
         rt = `${date.getFullYear()}-${_.padStart(date.getMonth() + 1, 2, '0')}-${_.padStart(date.getDate(), 2, '0')}`;
         if (showTime) {
            rt += ` ${_.padStart(date.getHours(), 2, '0')}:${_.padStart(date.getMinutes(), 2, '0')}`;
         }
         if (showSecondToo) {
            rt += `:${_.padStart(date.getSeconds(), 2, '0')}`;
         }
      } else {
         const now = new Date();
         if (Boolean(todayText) && now.getFullYear() === date.getFullYear() && now.getMonth() === date.getMonth() && now.getDate() === date.getDate()) {
            return todayText;
         }
         const year = `${date.getFullYear() === new Date().getFullYear() ? '' : date.getFullYear() + '년'}`;
         const mon = `${date.getMonth() + 1}월`;
         const day = `${date.getDate()}일`;
         let hour = '',
            min = '',
            sec = '';
         rt = `${year} ${mon} ${day}`;
         if (showTime) {
            hour = date.getHours();
            min = date.getMinutes();
            rt += ` ${hour}:${min}`;
            if (showSecondToo) {
               sec = date.getSeconds();
               rt += `:${sec}`;
            }
         }
      }

      return rt;
      // return moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
      // return moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL)
      // return moment(date).format(moment.HTML5_FMT.DATE)
   },
   /* yyyyMMdd HH:mm:ss */
   dateToString: (value, format) => {
      if (!value) {
         return null;
      }
      if (typeof value === 'string') {
         return pack.dateFormat(value, format);
      }
      const period = value.getFullYear() + _.padStart(value.getMonth() + 1, 2, '0') + _.padStart(value.getDate(), 2, '0');

      if (!format /* || format === 'yyyyMMdd' */) return period;

      const time = _.padStart(value.getHours(), 2, '0') + _.padStart(value.getMinutes(), 2, '0') + _.padStart(value.getSeconds(), 2, '0');
      const yFormat = format.replace(/[^yY]+/g, '');
      const mFormat = format.replace(/[^M]+/g, '');
      const dFormat = format.replace(/[^d]+/g, '');
      const hTime = format.replace(/[^hH]+/g, '');
      const mTime = format.replace(/[^m]+/g, '');
      const sTime = format.replace(/[^sS]+/g, '');

      let year = '';
      let month = '';
      let date = '';
      let hour = '';
      let minute = '';
      let second = '';

      if (yFormat.length > 0) {
         if (yFormat.length === 2) {
            year = period.substr(2, 2);
         } else {
            year = value.getFullYear();
         }
      }
      if (mFormat.length > 0) {
         if (mFormat.length === 2) {
            month = period.substr(4, 2);
         } else {
            month = value.getMonth() + 1;
         }
      }
      if (dFormat.length > 0) {
         if (dFormat.length === 2) {
            date = period.substr(6, 2);
         } else {
            date = value.getDate();
         }
      }
      if (hTime.length > 0) {
         if (hTime.length === 2) {
            hour = time.substr(0, 2);
         } else {
            hour = value.getHours();
         }
      }
      if (mTime.length > 0) {
         if (mTime.length === 2) {
            minute = time.substr(2, 2);
         } else {
            minute = value.getMinutes();
         }
      }
      if (sTime.length > 0) {
         if (sTime.length === 2) {
            second = time.substr(4, 2);
         } else {
            second = value.getSeconds();
         }
      }
      return format
         .replace(/[yY]+/g, year)
         .replace(/[M]+/g, month)
         .replace(/[d]+/g, date)
         .replace(/[hH]+/g, hour)
         .replace(/[m]+/g, minute)
         .replace(/[sS]+/g, second);
   },
   dateToKorean: (date, useHour, useMinute, useSecond) => {
      if (!date) {
         return '';
      }
      let formatted = `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
      if (useHour || useMinute) {
         formatted = `${formatted} ${date.getHours()}시;`;
      }
      if (useMinute || useSecond) {
         formatted = `${formatted} ${date.getMinutes()}분`;
      }
      if (useSecond) {
         formatted = `${formatted} ${date.getSeconds()}초`;
      }
      return formatted;
   },
   dateFormat: (value, cropCurrentYear) => {
      if (!value) return value;

      let formatted = value;
      const nowYear = String(new Date().getFullYear());

      const numVal = value.replace(/[^0-9]/g, '');
      if (numVal.length === 6) formatted = numVal.replace(/([0-9]{4})([0-9]{2})/, '$1-$2');
      else if (numVal.length === 8) formatted = numVal.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3');
      else if (numVal.length === 12) formatted = numVal.replace(/([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})/, '$1-$2-$3 $4:$5');
      else if (numVal.length === 14) formatted = numVal.replace(/([0-9]{4})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})([0-9]{2})/, '$1-$2-$3 $4:$5:$6');
      else formatted = value;

      if (cropCurrentYear && formatted.startsWith(nowYear)) {
         return formatted.substr(5);
      }
      return formatted;
   },
   //== 양수만 반환
   /* type = day, hour, min */
   dateDiff: (_date1, _date2, type = 'day') => {
      let diff1 = _date1 instanceof Date ? _date1 : new Date(_date1);
      let diff2 = _date2 instanceof Date ? _date2 : new Date(_date2);
      diff1 = new Date(diff1.getFullYear(), diff1.getMonth() + 1, diff1.getDate());
      diff2 = new Date(diff2.getFullYear(), diff2.getMonth() + 1, diff2.getDate());
      let diff = Math.abs(diff2.getTime() - diff1.getTime());
      switch (type) {
         case 'day':
            diff = Math.ceil(diff / (1000 * 3600 * 24));
            break;
         case 'hour':
            diff = Math.ceil(diff / (1000 * 3600));
            break;
         case 'min':
            diff = Math.ceil(diff / (1000 * 60));
            break;
      }
      return diff;
   },
   //== 음수도 반환
   dateCompare: (_date1, _date2, type = 'day') => {
      let diff1 = _date1 instanceof Date ? _date1 : new Date(_date1);
      let diff2 = _date2 instanceof Date ? _date2 : new Date(_date2);
      diff1 = new Date(diff1.getFullYear(), diff1.getMonth() + 1, diff1.getDate());
      diff2 = new Date(diff2.getFullYear(), diff2.getMonth() + 1, diff2.getDate());
      let diff = diff2.getTime() - diff1.getTime();
      switch (type) {
         case 'day':
            diff = Math.ceil(diff / (1000 * 3600 * 24));
            break;
         case 'hour':
            diff = Math.ceil(diff / (1000 * 3600));
            break;
         case 'min':
            diff = Math.ceil(diff / (1000 * 60));
            break;
      }
      return diff;
   },
   //== 두 날짜의 차이가 아닌, 두날짜 사이의 휴일이 아닌 일수를 반환 (01-03 ~ 01-04 인 경우 2를 반환)
   datesBetweenWithoutHoliday: (start, end, maxDiff) => {
      let tempStart = new Date(start.getTime());
      let diff = 0;
      while (tempStart <= end) {
         if (!pack.isHoliday(tempStart)) {
            diff++;
         }
         if (maxDiff && maxDiff <= diff) {
            break;
         }
         tempStart.setDate(tempStart.getDate() + 1);
      }
      return diff;
   },
   isDateBetweenStartEnd: (date, start, end) => {
      if (!date || !start || !end) {
         return false;
      }
      const dateStr = pack.dateToString(date, 'dd/MM/yyyy');
      const startStr = pack.dateToString(start, 'dd/MM/yyyy');
      const endStr = pack.dateToString(end, 'dd/MM/yyyy');

      const d = dateStr.split('/');
      const s = startStr.split('/');
      const e = endStr.split('/');

      const from = new Date(s[2], +s[1] - 1, s[0]);
      const to = new Date(e[2], +e[1] - 1, e[0]);
      const check = new Date(d[2], +d[1] - 1, d[0]);
      const isBetween = check > from && check < to;
      return isBetween;
   },
   defaultFilterMethod: (filter, row, column) => {
      const id = filter.pivotId || filter.id;
      return row[id] != null ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true;
   },
   getParentKey: (key, tree) => {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
         const node = tree[i];
         if (node.children) {
            if (node.children.some(item => item.id === key)) {
               parentKey = node.id;
            } else if (pack.getParentKey(key, node.children)) {
               parentKey = pack.getParentKey(key, node.children);
            }
         }
      }
      return parentKey;
   },
   removeTreeSelectEvents: () => {
      const elems = document.getElementsByClassName('ant-select-tree-title');
      const elems2 = document.getElementsByClassName('ant-select-tree-node-content-wrapper');
      for (let i = 0; i < elems.length; i++) {
         const element = elems[i];
         const clone = element.cloneNode();
         while (element.firstChild) {
            clone.appendChild(element.lastChild);
         }
         element.parentNode.replaceChild(clone, element);
      }
      for (let i = 0; i < elems2.length; i++) {
         const element = elems2[i];
         const clone = element.cloneNode();
         while (element.firstChild) {
            clone.appendChild(element.lastChild);
         }
         element.parentNode.replaceChild(clone, element);
      }
   },
   deepClone: value => {
      return JSON.parse(JSON.stringify(value));
   },
   getOtherTabSessionData: () => {
      const sd = localStorage.getItem('user');
      if (sd) {
         return _.merge(JSON.parse(sd), { menus: pack.getMenus() });
      }
      return null;
   },
   getSessionData: () => {
      return JSON.parse(localStorage.getItem('user') || '{}');
   },
   /* lastdate(이전 로그인시간) 등 날짜정보를 포함하여 반환.
      위에 getSessionData 는 서버로 post 시 parameter 에 sessionData 를 담아서 보낼때 사용한다.
      lastdate 등 timestamp 정보가 local 에서 string 으로 저장되는데, 이걸 그대로 서버로 보내면 서버의 java.util.Date 타입과 안맞아서 오류가 난다.
   */
   setSessionData: data => {
      localStorage.setItem('user', JSON.stringify(data));
   },
   setMenus: menus => {
      localStorage.setItem('menus', JSON.stringify(menus));
   },
   getMenus: () => {
      return JSON.parse(localStorage.getItem('menus') || '[]');
   },
   removeSessionData: () => {
      sessionStorage.removeItem('locale');
      sessionStorage.removeItem('lang');
   },
   removeLocalData: () => {
      localStorage.removeItem('user');
      localStorage.removeItem('menus');
   },
   encodeHtml: html => {
      const div = document.createElement('DIV');
      div.innerHTML = html;
      return div;
      /* (html || '')
         .replace(/</g, '&lt;')
         .replace(/>/g, '$gt;')
         .replace(/&/g, '&amp;')
         .replace(/'/g, '&#39;')
         .replace(/"/g, '&quot;'); */
   },
   downloadJson: (json, filename) => {
      if (!json || !filename || typeof json !== 'object') {
         return;
      }
      const jsonStr = JSON.stringify(json, null, 2);
      const blob1 = new Blob([jsonStr], { type: 'text/plain;charset=utf-8' });
      if (pack.browserInfo.includes('IE')) {
         window.navigator.msSaveBlob(blob1, filename);
      } else {
         const url = window.URL || window.webkitURL;
         const link = url.createObjectURL(blob1);
         const a = document.createElement('a');
         a.download = filename;
         a.href = link;
         document.body.appendChild(a);
         a.click();
         document.body.removeChild(a);
      }
   },
   getOsName: () => {
      if (navigator.userAgent.includes('Win')) return 'windows';
      else if (navigator.userAgent.includes('Mac')) return 'mac';
      else if (navigator.userAgent.includes('X11')) return 'unix';
      else if (navigator.userAgent.includes('Linux')) return 'linux';
      return 'unknown';
   },
   downloadPoiGET: (url, defaultFilename) => {
      axios
         .get(url, {
            responseType: 'blob',
         })
         .then(res => {
            let filename = '';
            try {
               filename = res.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
            } catch (e) {
               filename = defaultFilename;
            }
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', pack.getOsName() === 'windows' ? filename.normalize('NFC') : filename.normalize('NFD'));
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
         })
         .catch(pack.showError);
   },
   downloadPoiPOST: (url, data, defaultFilename) => {
      axios
         .post(url, JSON.stringify({ data }), {
            responseType: 'blob',
         })
         .then(res => {
            let filename = '';
            try {
               filename = res.headers['content-disposition'].split('filename=')[1].replace(/"/g, '');
            } catch (e) {
               filename = defaultFilename;
            }
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', pack.getOsName() === 'windows' ? filename.normalize('NFC') : filename.normalize('NFD'));
            link.style.cssText = 'display:none';
            document.body.appendChild(link);
            link.click();
            link.remove();
         })
         .catch(pack.showError);
   },
   downloadFile: ({ filename, savename }, subDir) => {
      axios({
         // url: `/download/${subDir != null ? `${subDir}/` : ''}${savename}?fileName=${filename}`,
         url: `/download/${Boolean(subDir) ? `${subDir.replace(/\//g, '`@`')}/` : ''}${savename || encodeURIComponent(filename)}?fileName=Garbage`,
         method: 'GET',
         responseType: 'blob',
      })
         .then(res => {
            const normalizedName = pack.getOsName() === 'windows' ? filename.normalize('NFC') : filename.normalize('NFD');
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', normalizedName);
            document.body.appendChild(link);
            link.click();
            link.remove();
         })
         .catch(e => {
            pack.showError(e);
            // toastr.warning("Warning", "File not exists");
         });
   },
   /*settingsToEntity: (settings) => {
      return settings;
   },*/
   entityToSettings: json => {
      const realSettings = {};
      const settings = {};

      if (!json) return null;

      settings.showFilter = json.showFilter ? json.showFilter === '1' : true;
      settings.backgroundColor = json.backgroundColor ? json.backgroundColor : '';
      settings.headerBackgroundColor = json.headerBackgroundColor ? json.headerBackgroundColor : '';
      settings.enableMobileMenuSmall = json.enableMobileMenuSmall ? json.enableMobileMenuSmall : '';
      settings.enableBackgroundImage = json.enableBackgroundImage ? json.enableBackgroundImage === '1' : false;
      settings.enableClosedSidebar = json.enableClosedSidebar ? json.enableClosedSidebar === '1' : false;
      settings.enableFixedHeader = json.enableFixedHeader ? json.enableFixedHeader === '1' : true;
      settings.enableHeaderShadow = json.enableHeaderShadow ? json.enableHeaderShadow === '1' : true;
      settings.enableSidebarShadow = json.enableSidebarShadow ? json.enableSidebarShadow === '1' : true;
      settings.enableFixedFooter = json.enableFixedFooter ? json.enableFixedFooter === '1' : true;
      settings.enableFixedSidebar = json.enableFixedSidebar ? json.enableFixedSidebar === '1' : true;
      settings.colorScheme = json.colorScheme ? json.colorScheme : 'white';
      settings.backgroundImage = json.backgroundImage ? json.backgroundImage : sideBar7;
      settings.backgroundImageOpacity = json.backgroundImageOpacity ? json.backgroundImageOpacity : 'opacity-15';
      settings.enablePageTitleIcon = json.enablePageTitleIcon ? json.enablePageTitleIcon === '1' : true;
      settings.enablePageTitleSubheading = json.enablePageTitleSubheading ? json.enablePageTitleSubheading === '1' : true;
      settings.enablePageTabsAlt = json.enablePageTabsAlt ? json.enablePageTabsAlt === '1' : true;

      return settings;
   },
   jsonToJpa: (data, key) => {
      const deep = data[key];
      if (deep)
         Object.keys(deep).forEach(k => {
            data[`${key}.${k}`] = deep[k];
         });
      return _.omit(data, key);
   },
   jsonToJpa2: (data, keyArr) => {
      keyArr.forEach(key => {
         const deep = data[key];
         if (deep)
            Object.keys(deep).forEach(k => {
               data[`${key}.${k}`] = deep[k];
            });
         data = _.omit(data, key);
      });
      return data;
   },
   changeLocale: lang => {
      strings = new LocalizedStrings(strings._props);
      strings.setLanguage(lang);
      sessionStorage.setItem('locale', JSON.stringify(strings));
      sessionStorage.setItem('lang', lang);
      return strings;
   },
   debug: state => {
      const date = new Date();
      console.log(state, `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}.${date.getMilliseconds()}`);
   },
   /** ****************************************************************************
    * Function명 : _SolarBase
    * 설명       : 각 월별 음력 기준 정보를 처리하는 함수(처리가능 기간  1841 - 2043년) 단, 내부에서 사용하는 함수임
    * Params     :  없음
    * Return     :
    *               - 성공 = 음력 기준정보
    ***************************************************************************** */
   _SolarBase: () => {
      let kk;
      // 1841
      kk = '1,2,4,1,1,2,1,2,1,2,2,1,';
      kk += '2,2,1,2,1,1,2,1,2,1,2,1,';
      kk += '2,2,2,1,2,1,4,1,2,1,2,1,';
      kk += '2,2,1,2,1,2,1,2,1,2,1,2,';
      kk += '1,2,1,2,2,1,2,1,2,1,2,1,';
      kk += '2,1,2,1,5,2,1,2,2,1,2,1,';
      kk += '2,1,1,2,1,2,1,2,2,2,1,2,';
      kk += '1,2,1,1,2,1,2,1,2,2,2,1,';
      kk += '2,1,2,3,2,1,2,1,2,1,2,2,';
      kk += '2,1,2,1,1,2,1,1,2,2,1,2,';
      // 1851
      kk += '2,2,1,2,1,1,2,1,2,1,5,2,';
      kk += '2,1,2,2,1,1,2,1,2,1,1,2,';
      kk += '2,1,2,2,1,2,1,2,1,2,1,2,';
      kk += '1,2,1,2,1,2,5,2,1,2,1,2,';
      kk += '1,1,2,1,2,2,1,2,2,1,2,1,';
      kk += '2,1,1,2,1,2,1,2,2,2,1,2,';
      kk += '1,2,1,1,5,2,1,2,1,2,2,2,';
      kk += '1,2,1,1,2,1,1,2,2,1,2,2,';
      kk += '2,1,2,1,1,2,1,1,2,1,2,2,';
      kk += '2,1,6,1,1,2,1,1,2,1,2,2,';
      // 1861
      kk += '1,2,2,1,2,1,2,1,2,1,1,2,';
      kk += '2,1,2,1,2,2,1,2,2,3,1,2,';
      kk += '1,2,2,1,2,1,2,2,1,2,1,2,';
      kk += '1,1,2,1,2,1,2,2,1,2,2,1,';
      kk += '2,1,1,2,4,1,2,2,1,2,2,1,';
      kk += '2,1,1,2,1,1,2,2,1,2,2,2,';
      kk += '1,2,1,1,2,1,1,2,1,2,2,2,';
      kk += '1,2,2,3,2,1,1,2,1,2,2,1,';
      kk += '2,2,2,1,1,2,1,1,2,1,2,1,';
      kk += '2,2,2,1,2,1,2,1,1,5,2,1,';
      // 1871
      kk += '2,2,1,2,2,1,2,1,2,1,1,2,';
      kk += '1,2,1,2,2,1,2,1,2,2,1,2,';
      kk += '1,1,2,1,2,4,2,1,2,2,1,2,';
      kk += '1,1,2,1,2,1,2,1,2,2,2,1,';
      kk += '2,1,1,2,1,1,2,1,2,2,2,1,';
      kk += '2,2,1,1,5,1,2,1,2,2,1,2,';
      kk += '2,2,1,1,2,1,1,2,1,2,1,2,';
      kk += '2,2,1,2,1,2,1,1,2,1,2,1,';
      kk += '2,2,4,2,1,2,1,1,2,1,2,1,';
      kk += '2,1,2,2,1,2,2,1,2,1,1,2,';
      // 1881
      kk += '1,2,1,2,1,2,5,2,2,1,2,1,';
      kk += '1,2,1,2,1,2,1,2,2,1,2,2,';
      kk += '1,1,2,1,1,2,1,2,2,2,1,2,';
      kk += '2,1,1,2,3,2,1,2,2,1,2,2,';
      kk += '2,1,1,2,1,1,2,1,2,1,2,2,';
      kk += '2,1,2,1,2,1,1,2,1,2,1,2,';
      kk += '2,2,1,5,2,1,1,2,1,2,1,2,';
      kk += '2,1,2,2,1,2,1,1,2,1,2,1,';
      kk += '2,1,2,2,1,2,1,2,1,2,1,2,';
      kk += '1,5,2,1,2,2,1,2,1,2,1,2,';
      // 1891
      kk += '1,2,1,2,1,2,1,2,2,1,2,2,';
      kk += '1,1,2,1,1,5,2,2,1,2,2,2,';
      kk += '1,1,2,1,1,2,1,2,1,2,2,2,';
      kk += '1,2,1,2,1,1,2,1,2,1,2,2,';
      kk += '2,1,2,1,5,1,2,1,2,1,2,1,';
      kk += '2,2,2,1,2,1,1,2,1,2,1,2,';
      kk += '1,2,2,1,2,1,2,1,2,1,2,1,';
      kk += '2,1,5,2,2,1,2,1,2,1,2,1,';
      kk += '2,1,2,1,2,1,2,2,1,2,1,2,';
      kk += '1,2,1,1,2,1,2,5,2,2,1,2,';
      // 1901
      kk += '1,2,1,1,2,1,2,1,2,2,2,1,';
      kk += '2,1,2,1,1,2,1,2,1,2,2,2,';
      kk += '1,2,1,2,3,2,1,1,2,2,1,2,';
      kk += '2,2,1,2,1,1,2,1,1,2,2,1,';
      kk += '2,2,1,2,2,1,1,2,1,2,1,2,';
      kk += '1,2,2,4,1,2,1,2,1,2,1,2,';
      kk += '1,2,1,2,1,2,2,1,2,1,2,1,';
      kk += '2,1,1,2,2,1,2,1,2,2,1,2,';
      kk += '1,5,1,2,1,2,1,2,2,2,1,2,';
      kk += '1,2,1,1,2,1,2,1,2,2,2,1,';
      // 1911
      kk += '2,1,2,1,1,5,1,2,2,1,2,2,';
      kk += '2,1,2,1,1,2,1,1,2,2,1,2,';
      kk += '2,2,1,2,1,1,2,1,1,2,1,2,';
      kk += '2,2,1,2,5,1,2,1,2,1,1,2,';
      kk += '2,1,2,2,1,2,1,2,1,2,1,2,';
      kk += '1,2,1,2,1,2,2,1,2,1,2,1,';
      kk += '2,3,2,1,2,2,1,2,2,1,2,1,';
      kk += '2,1,1,2,1,2,1,2,2,2,1,2,';
      kk += '1,2,1,1,2,1,5,2,2,1,2,2,';
      kk += '1,2,1,1,2,1,1,2,2,1,2,2,';
      // 1921
      kk += '2,1,2,1,1,2,1,1,2,1,2,2,';
      kk += '2,1,2,2,3,2,1,1,2,1,2,2,';
      kk += '1,2,2,1,2,1,2,1,2,1,1,2,';
      kk += '2,1,2,1,2,2,1,2,1,2,1,1,';
      kk += '2,1,2,5,2,1,2,2,1,2,1,2,';
      kk += '1,1,2,1,2,1,2,2,1,2,2,1,';
      kk += '2,1,1,2,1,2,1,2,2,1,2,2,';
      kk += '1,5,1,2,1,1,2,2,1,2,2,2,';
      kk += '1,2,1,1,2,1,1,2,1,2,2,2,';
      kk += '1,2,2,1,1,5,1,2,1,2,2,1,';
      // 1931
      kk += '2,2,2,1,1,2,1,1,2,1,2,1,';
      kk += '2,2,2,1,2,1,2,1,1,2,1,2,';
      kk += '1,2,2,1,6,1,2,1,2,1,1,2,';
      kk += '1,2,1,2,2,1,2,2,1,2,1,2,';
      kk += '1,1,2,1,2,1,2,2,1,2,2,1,';
      kk += '2,1,4,1,2,1,2,1,2,2,2,1,';
      kk += '2,1,1,2,1,1,2,1,2,2,2,1,';
      kk += '2,2,1,1,2,1,4,1,2,2,1,2,';
      kk += '2,2,1,1,2,1,1,2,1,2,1,2,';
      kk += '2,2,1,2,1,2,1,1,2,1,2,1,';
      // 1941
      kk += '2,2,1,2,2,4,1,1,2,1,2,1,';
      kk += '2,1,2,2,1,2,2,1,2,1,1,2,';
      kk += '1,2,1,2,1,2,2,1,2,2,1,2,';
      kk += '1,1,2,4,1,2,1,2,2,1,2,2,';
      kk += '1,1,2,1,1,2,1,2,2,2,1,2,';
      kk += '2,1,1,2,1,1,2,1,2,2,1,2,';
      kk += '2,5,1,2,1,1,2,1,2,1,2,2,';
      kk += '2,1,2,1,2,1,1,2,1,2,1,2,';
      kk += '2,2,1,2,1,2,3,2,1,2,1,2,';
      kk += '2,1,2,2,1,2,1,1,2,1,2,1,';
      // 1951
      kk += '2,1,2,2,1,2,1,2,1,2,1,2,';
      kk += '1,2,1,2,4,2,1,2,1,2,1,2,';
      kk += '1,2,1,1,2,2,1,2,2,1,2,2,';
      kk += '1,1,2,1,1,2,1,2,2,1,2,2,';
      kk += '2,1,4,1,1,2,1,2,1,2,2,2,';
      kk += '1,2,1,2,1,1,2,1,2,1,2,2,';
      kk += '2,1,2,1,2,1,1,5,2,1,2,2,';
      kk += '1,2,2,1,2,1,1,2,1,2,1,2,';
      kk += '1,2,2,1,2,1,2,1,2,1,2,1,';
      kk += '2,1,2,1,2,5,2,1,2,1,2,1,';
      // 1961
      kk += '2,1,2,1,2,1,2,2,1,2,1,2,';
      kk += '1,2,1,1,2,1,2,2,1,2,2,1,';
      kk += '2,1,2,3,2,1,2,1,2,2,2,1,';
      kk += '2,1,2,1,1,2,1,2,1,2,2,2,';
      kk += '1,2,1,2,1,1,2,1,1,2,2,1,';
      kk += '2,2,5,2,1,1,2,1,1,2,2,1,';
      kk += '2,2,1,2,2,1,1,2,1,2,1,2,';
      kk += '1,2,2,1,2,1,5,2,1,2,1,2,';
      kk += '1,2,1,2,1,2,2,1,2,1,2,1,';
      kk += '2,1,1,2,2,1,2,1,2,2,1,2,';
      // 1971
      kk += '1,2,1,1,5,2,1,2,2,2,1,2,';
      kk += '1,2,1,1,2,1,2,1,2,2,2,1,';
      kk += '2,1,2,1,1,2,1,1,2,2,2,1,';
      kk += '2,2,1,5,1,2,1,1,2,2,1,2,';
      kk += '2,2,1,2,1,1,2,1,1,2,1,2,';
      kk += '2,2,1,2,1,2,1,5,2,1,1,2,';
      kk += '2,1,2,2,1,2,1,2,1,2,1,1,';
      kk += '2,2,1,2,1,2,2,1,2,1,2,1,';
      kk += '2,1,1,2,1,6,1,2,2,1,2,1,';
      kk += '2,1,1,2,1,2,1,2,2,1,2,2,';
      // 1981
      kk += '1,2,1,1,2,1,1,2,2,1,2,2,';
      kk += '2,1,2,3,2,1,1,2,2,1,2,2,';
      kk += '2,1,2,1,1,2,1,1,2,1,2,2,';
      kk += '2,1,2,2,1,1,2,1,1,5,2,2,';
      kk += '1,2,2,1,2,1,2,1,1,2,1,2,';
      kk += '1,2,2,1,2,2,1,2,1,2,1,1,';
      kk += '2,1,2,2,1,5,2,2,1,2,1,2,';
      kk += '1,1,2,1,2,1,2,2,1,2,2,1,';
      kk += '2,1,1,2,1,2,1,2,2,1,2,2,';
      kk += '1,2,1,1,5,1,2,1,2,2,2,2,';
      // 1991
      kk += '1,2,1,1,2,1,1,2,1,2,2,2,';
      kk += '1,2,2,1,1,2,1,1,2,1,2,2,';
      kk += '1,2,5,2,1,2,1,1,2,1,2,1,';
      kk += '2,2,2,1,2,1,2,1,1,2,1,2,';
      kk += '1,2,2,1,2,2,1,5,2,1,1,2,';
      kk += '1,2,1,2,2,1,2,1,2,2,1,2,';
      kk += '1,1,2,1,2,1,2,2,1,2,2,1,';
      kk += '2,1,1,2,3,2,2,1,2,2,2,1,';
      kk += '2,1,1,2,1,1,2,1,2,2,2,1,';
      kk += '2,2,1,1,2,1,1,2,1,2,2,1,';
      // 2001
      kk += '2,2,2,3,2,1,1,2,1,2,1,2,';
      kk += '2,2,1,2,1,2,1,1,2,1,2,1,';
      kk += '2,2,1,2,2,1,2,1,1,2,1,2,';
      kk += '1,5,2,2,1,2,1,2,2,1,1,2,';
      kk += '1,2,1,2,1,2,2,1,2,2,1,2,';
      kk += '1,1,2,1,2,1,5,2,2,1,2,2,';
      kk += '1,1,2,1,1,2,1,2,2,2,1,2,';
      kk += '2,1,1,2,1,1,2,1,2,2,1,2,';
      kk += '2,2,1,1,5,1,2,1,2,1,2,2,';
      kk += '2,1,2,1,2,1,1,2,1,2,1,2,';
      // 2011
      kk += '2,1,2,2,1,2,1,1,2,1,2,1,';
      kk += '2,1,6,2,1,2,1,1,2,1,2,1,';
      kk += '2,1,2,2,1,2,1,2,1,2,1,2,';
      kk += '1,2,1,2,1,2,1,2,5,2,1,2,';
      kk += '1,2,1,1,2,1,2,2,2,1,2,2,';
      kk += '1,1,2,1,1,2,1,2,2,1,2,2,';
      kk += '2,1,1,2,3,2,1,2,1,2,2,2,';
      kk += '1,2,1,2,1,1,2,1,2,1,2,2,';
      kk += '2,1,2,1,2,1,1,2,1,2,1,2,';
      kk += '2,1,2,5,2,1,1,2,1,2,1,2,';
      // 2021
      kk += '1,2,2,1,2,1,2,1,2,1,2,1,';
      kk += '2,1,2,1,2,2,1,2,1,2,1,2,';
      kk += '1,5,2,1,2,1,2,2,1,2,1,2,';
      kk += '1,2,1,1,2,1,2,2,1,2,2,1,';
      kk += '2,1,2,1,1,5,2,1,2,2,2,1,';
      kk += '2,1,2,1,1,2,1,2,1,2,2,2,';
      kk += '1,2,1,2,1,1,2,1,1,2,2,2,';
      kk += '1,2,2,1,5,1,2,1,1,2,2,1,';
      kk += '2,2,1,2,2,1,1,2,1,1,2,2,';
      kk += '1,2,1,2,2,1,2,1,2,1,2,1,';
      // 2031
      kk += '2,1,5,2,1,2,2,1,2,1,2,1,';
      kk += '2,1,1,2,1,2,2,1,2,2,1,2,';
      kk += '1,2,1,1,2,1,5,2,2,2,1,2,';
      kk += '1,2,1,1,2,1,2,1,2,2,2,1,';
      kk += '2,1,2,1,1,2,1,1,2,2,1,2,';
      kk += '2,2,1,2,1,4,1,1,2,1,2,2,';
      kk += '2,2,1,2,1,1,2,1,1,2,1,2,';
      kk += '2,2,1,2,1,2,1,2,1,1,2,1,';
      kk += '2,2,1,2,5,2,1,2,1,2,1,1,';
      kk += '2,1,2,2,1,2,2,1,2,1,2,1,';
      // 2041
      kk += '2,1,1,2,1,2,2,1,2,2,1,2,';
      kk += '1,5,1,2,1,2,1,2,2,2,1,2,';
      kk += '1,2,1,1,2,1,1,2,2,1,2,2';

      return kk.split(',');
   },
   fetchHolidays: () => {
      if (pack.holidays.length > 0) {
         return;
      }

      // if (pack.holidaysFromGoogle) {
      const API_KEY = pack.googleCommonApiKey;
      const CALENDAR_ID = pack.holidayCalendarId;
      let url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}`;
      fetch(url)
         .then(res => {
            return res.json();
         })
         .then(json => {
            pack.holidays = json.items.map(event => ({
               title: event.summary,
               start: event.start.date, //pack.dateToString(start, 'yyyy-MM-dd'),
               end: event.end.date, //start >= end ? null : pack.dateToString(end, 'yyyy-MM-dd'),
               dateStr:
                  typeof event.start.date.getMonth === 'function'
                     ? pack.dateToString(event.start.date, 'yyyyMMdd')
                     : typeof event.start.date === 'string'
                     ? event.start.date.replace(/[^0-9]/g, '')
                     : '',
               allDay: true,
               backgroundColor: '#f44336',
            }));
         })
         .catch(e => pack.showError(e));
      /*} else {
         axios.get('/common/holidays').then(res => {
            res.data.forEach(root => {
               const { months } = root;
               months.forEach(month => {
                  month.holidays.forEach(holiday => {
                     pack.holidays.push({
                        title: holiday.dateName,
                        start: pack.stringToDate(holiday.locdate),
                        allDay: true,
                        backgroundColor: holiday.isHoliday === 'Y' ? '#f44336' : '#757575',
                        dateStr: holiday.locdate,
                     });
                  });
               });
            });
         });
      }*/
   },
   triggerChange: (element, newValue, type) => {
      const lastValue = element.value;
      element.value = newValue;
      const tracker = element._valueTracker;
      if (tracker) {
         tracker.setValue(lastValue);
      }
      const event = new Event(type, { bubbles: true });
      element.dispatchEvent(event);
   },

   groupingChat: data => {
      const sections = {};
      data
         .filter(it => Boolean(it))
         .forEach(chat => {
            const group = moment(chat.createdAt).format('YYYY-MM-DD');
            if (Array.isArray(sections[group])) {
               sections[group].push(chat);
            } else {
               sections[group] = [chat];
            }
         });
      return sections;
   },
   tinyOptions: {
      plugins: [
         'advlist',
         'autolink',
         'lists',
         'link',
         'image',
         'emoticons',
         'charmap',
         'preview',
         'anchor',
         'searchreplace',
         'visualblocks',
         'code',
         'codesample',
         'fullscreen',
         'insertdatetime',
         'media',
         'table',
         //'wordcount',
         'autoresize',
         // Premium Plugins
         'advtable',
         'advcode',
         'tableofcontents', // 상단에 목차 표시 (유료 플러그인이라 로딩지연 발생, 결제 후에 주석해제)
         'checklist',
         'powerpaste',
         'editimage',
      ],
      toolbar: mode =>
         mode === 'simple'
            ? isMobile
               ? 'bold italic underline strikethrough | image emoticons'
               : 'bold italic underline strikethrough forecolor | fontfamily | image emoticons'
            : 'undo redo | blocks | fontsize fontfamily | bold italic underline strikethrough forecolor backcolor | align lineheight | ' +
              'link image media table | bullist numlist outdent indent | link removeformat | code codesample emoticons | checklist tableofcontents',
      init: o => ({
         auto_focus: o.autoFocus ? o.editorId : undefined,
         height: o.mode === 'simple' ? 50 : o.height || 400,
         min_height: o.mode === 'simple' ? 50 : o.height || 400,
         max_height: o.mode === 'simple' ? 400 : o.maxHeight || 800,
         width: '100%',
         branding: false,
         //theme: 'silver',
         //skin: 'material-outline',
         //icons: 'material',
         plugins: pack.tinyOptions.plugins,
         //invalid_elements: 'br',
         // premium ->
         tableofcontents_class: 'wiki-toc',
         // premium <-
         menubar: false,
         statusbar: false,
         text_patterns: false, // 해시태그(hashtag) 입력후 엔터치면 h1 패턴이 적용되어버리는 문제 방지~!!!
         autoresize_max_height: 800,
         autoresize_bottom_margin: 0,
         inline: o.inline === true,
         // skin: 'dark', // 다크모드
         // content_css: 'dark',
         content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Black+Han+Sans&family=Gugi&family=Hi+Melody&family=Jua&family=Nanum+Gothic:wght@400;700;800&family=Nanum+Myeongjo:wght@400;700;800&family=Nanum+Pen+Script&family=Noto+Sans+KR:wght@400;500;700;900&display=swap'); body { font-family: Noto Sans KR, sans-serif; }",
         toolbar: pack.tinyOptions.toolbar(o.mode),
         // @ts-ignore
         toolbar_location: o.mode === 'simple' ? 'bottom' : 'top',
         toolbar_mode: 'wrap', // default: 'floating', 권장 : 'sliding'
         font_size_formats: '8px 10px 12px 14px 16px 18px 24px 30px 36px',
         font_family_formats:
            '노토산스=Noto Sans KR;나눔고딕=Nanum Gothic;나눔명조=Nanum Myeongjo;한스=Black Han Sans;주아=Jua;멜로디=Hi Melody;구기=Gugi;나눔펜=Nanum Pen Script',
         paste_data_images: true,
         images_upload_handler: (blobInfo, progress) =>
            new Promise(resolve => {
               const formData = new FormData();
               formData.append('file', blobInfo.blob());
               axios
                  .post('/upload', formData, {
                     onUploadProgress: e => {
                        progress(Math.round((100 * e.loaded) / e.total));
                     },
                  })
                  .then(res => {
                     resolve(pack.serverImage(res.data.savename));
                  })
                  .catch(pack.showError);
            }),
      }),
   },
};

function ipLookUp() {
   let locale = localStorage.getItem('default_lang'); // 'ko'
   if (locale) {
      return locale;
   }
   $.ajax({
      url: 'http://ip-api.com/json',
      async: false,
      dataType: 'json',
   })
      .done(result => {
         locale = fromCountryCode(result.countryCode);
      })
      .fail((data, status) => {
         console.log('Request failed.  Returned status of', status);
         locale = 'ko';
      });

   localStorage.setItem('default_lang', locale);

   /*if (locale === 'ko') {
      localStorage.setItem('default_lang', 'ko');
   } else {
      localStorage.setItem('default_lang', 'en');
   }*/
   return locale;
}

/*export const changeLocale = (lang) => {
   const loc = pack.strings;
   loc.setLanguage(lang);
   sessionStorage.setItem('locale', JSON.stringify(loc));
};*/

function getLang() {
   if (!sessionStorage.getItem('lang')) {
      sessionStorage.setItem('lang', ipLookUp());
   }
   return sessionStorage.getItem('lang');
}

function setMessages(langs, async) {
   if (!localStorage.getItem('servermsg')) {
      $.ajax({
         method: 'get',
         url: `${process.env.REACT_APP_API_URL}/api/messages/${langs.join(',')}`,
         dataType: 'json',
         async,
      }).done(res => {
         localStorage.setItem('servermsg', JSON.stringify(res));
      });
   }
}

function getLocale(forceReload) {
   let saved = sessionStorage.getItem('locale');
   if (Boolean(saved) && !forceReload) {
      let savedLocale = JSON.parse(saved);
      const savedLang = sessionStorage.getItem('lang');
      if (savedLang && savedLang !== savedLocale._language) {
         savedLocale = new LocalizedStrings(savedLocale._props);
         savedLocale.setLanguage(savedLang);
         sessionStorage.setItem('locale', JSON.stringify(savedLocale));
      }
      return savedLocale;
   }

   let locale = null;
   $.ajax({
      method: 'get',
      url: `${process.env.REACT_APP_API_URL}/api/locale`,
      contentType: 'application/text;charset=UTF-8', // 서버에서 produces 에도 똑같이 세팅해줘서 한글깨짐 방지
      async: false,
   }).done(res => {
      locale = new LocalizedStrings(JSON.parse(res));
      setMessages(Object.keys(locale._props), true);
      locale.setLanguage(ipLookUp());
      sessionStorage.setItem('locale', JSON.stringify(locale));
   });
   return locale;
}

function getPasswordRuleInfo() {
   let info = { period: 30, rules: [] };
   const saved = sessionStorage.getItem('pwdrule');
   if (!saved) {
      $.ajax({
         url: `${process.env.REACT_APP_API_URL}/api/password/rules`,
         dataType: 'json',
         async: false,
      }).done(res => {
         info = res;
         sessionStorage.setItem('pwdrule', JSON.stringify(info));
      });
   } else {
      info = JSON.parse(saved);
   }
   return info;
}

async function getServerLocale() {
   axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;
   axios.defaults.headers = { 'Content-Type': 'application/json' };
   let res = await axios.get('/locale');
   return new LocalizedStrings(res.data);
}
