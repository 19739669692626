import axios from 'axios';
import {updateStrings} from "../../../../@gc/common/actions/common.action";
import {toastr} from "react-redux-toastr";
import _ from 'lodash';
import {pack} from "../../../../@gc";

const jtf = require('json-form-data');

export const OPEN_EDIT = '[LOCALE] OPEN EDIT';
export const OPEN_VIEW = '[LOCALE] OPEN VIEW';
export const LOAD_LOCALE = '[LOCALE] LOAD LOCALE';
export const SELECT_TAB = '[LOCALE] SELECT TAB';
export const UPDATE_LOCALE = '[LOCALE] UPDATE LOCALE';
export const LOADER_START = '[LOCALE] LOADER START';
export const LOADER_STOP = '[LOCALE] LOADER STOP';
export const SHOW_MERGE_LOADING = '[LOCALE] SHOW MERGE LOADING';

export function loadLocale() {
   return (dispatch) => {
      axios.get('/locale')
         .then(res => {
            dispatch({
               type: LOAD_LOCALE,
               payload: res.data
            })
         });
   }
}

export function startLoader() {
   return {
      type: LOADER_START
   };
}

export function stopLoader() {
   return {
      type: LOADER_STOP
   };
}

export function selectTab(tab) {
   return (dispatch) =>
      dispatch({
         type: SELECT_TAB,
         tab
      });
}

export function updateLocale(data) {
   return (dispatch, getState) => {
      dispatch(startLoader());
      axios({
         method: 'post',
         url: 'locale',
         data: jtf({json: JSON.stringify(data)})
      }).then(res => {
         // const strings = pack.updateLocaleData(data, getState().gc.common.lang);
         // dispatch(changeStrings(strings));
         dispatch(updateStrings(true));
         dispatch({
            type: UPDATE_LOCALE,
            payload: data
         });
         dispatch(stopLoader());
      })

   }
}

export function setMergeLoading(flag) {
   return {
      type: SHOW_MERGE_LOADING,
      flag
   };
}

export function mergeLocale(file, strings) {
   return (dispatch) => {
      dispatch(setMergeLoading(true));
      axios.post(`/locale/merge`, jtf({savename: file.savename}))
         .then(res => {
            if (res.status === 200) {
               const {data: {oldJson, newJson}} = res;
               const newSaveJson = _.merge(newJson, oldJson);
               axios.post('locale', jtf({json: JSON.stringify(newSaveJson)}))
                  .then(res => {
                     if (res.status === 200) {
                        toastr.success(strings.infoTitle, strings.successMessage);
                        dispatch({
                           type: UPDATE_LOCALE,
                           payload: newSaveJson
                        });
                        dispatch(updateStrings(true));
                     } else {
                        toastr.error(strings.errTitle, strings.errMessage);
                     }
                     dispatch(setMergeLoading(false));
                  }).catch(e => {
                     pack.showError(e);
                     dispatch(setMergeLoading(false));
                  });
            }
         }).catch(e => {
            pack.showError(e);
            dispatch(setMergeLoading(false));
         });
   };
}
