import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import ResizeDetector from 'react-resize-detector';
import AppMain from '../../Layout/AppMain';
import ReduxToastr from 'react-redux-toastr';
import GcSweetAlert from '../common/components/swal/GcSweetAlert';
import GcNotify from '../common/components/notify/GcNotify';
import { pack } from '@gc';
import SockJsClient from 'react-stomp';
import GlobalStyles from './GlobalStyles';
import { GEO_DENIED, useStore } from '../../store/globalStore';
import { logoutUser } from '../../@gc/auth/actions/user.action';
import { onConfirmMail, onReceiveNewMail, onReceiveRemovedMail, onReceiveSeenMail } from '../activity/mailbox/actions';
import { updateRefSignCount } from '../../@gc/common/actions/common.action';
import { updateMenuSignCount } from '../../@gc/navigation/actions';
import { onReceiveNewApprove } from '../activity/approve/actions';
import { cardUpdated, commentAdded, fetchBoards } from '../activity/mytask/actions/trello.actions';

function Main(props) {
   const {
      user,
      user: {
         settings: { colorScheme, enableFixedHeader, enableMobileMenu, /*enableFixedFooter,*/ enableFixedSidebar, enableClosedSidebar, enablePageTabsAlt },
      },
      common: { currentItem: curMenu, themeOpen },
   } = useSelector(state => state.gc);
   const [closedSmallerSidebar, setClosedSmallerSidebar] = useState(false);
   const [menuClose, setMenuClose] = useState(enableClosedSidebar);
   const { logout, geoStatus, setConnectInfo, connectInfo } = useStore();
   const dispatch = useDispatch();
   const history = useHistory();
   const { hash, pathname } = useLocation();

   useEffect(() => {
      if (!connectInfo) {
         setConnectInfo();
      }
   }, []);

   useEffect(() => {
      if (geoStatus === GEO_DENIED) {
         history.push('/denied');
      }
   }, [geoStatus]);

   useEffect(() => {
      if (logout) {
         dispatch(logoutUser());
      }
   }, [logout]);

   useEffect(() => {
      if (pathname !== '/denied') setConnectInfo();
   }, [pathname]);

   useEffect(() => {
      setMenuClose(enableClosedSidebar);
   }, [enableClosedSidebar]);

   useEffect(() => {
      setMenuClose(pack.isCloseMenu(curMenu.id));
   }, [curMenu]);

   return (
      <ResizeDetector
         handleWidth
         handleHeight
         render={({ width }) => (
            <div
               className={cx(
                  'app-container app-theme-' + colorScheme,
                  { 'hidden-overflow': themeOpen },
                  { 'fixed-header': enableFixedHeader },
                  { 'fixed-sidebar': enableFixedSidebar || width < 1250 },
                  // {'fixed-footer': enableFixedFooter},
                  {
                     'closed-sidebar': /*menuClose || */ enableClosedSidebar || width < 1250,
                  },
                  { 'closed-sidebar-mobile': closedSmallerSidebar || width < 1250 },
                  { 'sidebar-mobile-open': enableMobileMenu },
                  { 'body-tabs-shadow-btn': enablePageTabsAlt },
               )}
            >
               <GlobalStyles />
               <AppMain />
               <ReduxToastr
                  timeOut={5000}
                  newestOnTop={true}
                  preventDuplicates
                  position="top-center"
                  // getState={(state) => state.toastr} // This is the default
                  transitionIn="fadeIn"
                  transitionOut="fadeOut"
                  progressBar={false}
                  closeOnToastrClick={true}
                  showCloseButton={true}
               />
               <GcSweetAlert />
               <GcNotify />
               {Boolean(user.empno) && (
                  <SockJsClient
                     url={`${pack.serverUrl}/sock`}
                     topics={[
                        `/sub/mail/new/${user.empno}`,
                        `/sub/mail/removed/${user.empno}`,
                        `/sub/mail/seen/${user.empno}`,
                        `/sub/mail/confirm/${user.empno}`,
                        // `/sub/approve/count/${user.empno}`,
                        `/sub/approve/new/${user.empno}`,
                        `/sub/approve/newref/${user.empno}`,
                        `/sub/trello/newcard`,
                        '/sub/trello/delcard',
                        '/sub/trello/chkcomplete',
                        '/sub/trello/cardcomplete',
                        '/sub/trello/newcomment',
                        `/sub/trello/addfollower/${user.empno}`,
                        '/sub/jandi/newmsg',
                     ]}
                     onMessage={(msg, topic) => {
                        if (topic.startsWith('/sub/mail/new')) {
                           dispatch(onReceiveNewMail(msg, props));
                        } else if (topic.startsWith('/sub/mail/removed')) {
                           dispatch(onReceiveRemovedMail(msg));
                        } else if (topic.startsWith('/sub/mail/seen')) {
                           dispatch(onReceiveSeenMail(msg));
                        } else if (topic.startsWith('/sub/mail/confirm')) {
                           dispatch(onConfirmMail(msg));
                        } else if (topic === `/sub/approve/new/${user.empno}`) {
                           setTimeout(() => {
                              dispatch(updateMenuSignCount());
                              if (msg.seq && !msg.ignorePush) dispatch(onReceiveNewApprove(msg, props));
                           }, 3000);
                        } else if (topic === `/sub/approve/newref/${user.empno}`) {
                           dispatch(updateRefSignCount(msg));
                        } else if (topic.startsWith('/sub/trello/newcard')) {
                           dispatch(fetchBoards(null, msg));
                        } else if (topic.startsWith('/sub/trello/addcard')) {
                           dispatch(fetchBoards(null, [msg.card], msg.empno));
                        } else if (topic === '/sub/trello/newcomment') {
                           dispatch(commentAdded(msg));
                        } else if (topic === '/sub/trello/cardcomplete') {
                           dispatch(cardUpdated(msg));
                        } else if (topic.startsWith('/sub/trello/addfollower')) {
                           msg.type = 'newFollowCard';
                           dispatch(fetchBoards(null, [msg]));
                        } else if (topic === '/sub/jandi/newmsg') {
                           pack.alarm(pack.emailIconImage, `${msg.writerName} 님의 메세지`, msg.text.replace(/^\/[^\s]*\s+/, ''), 7000);
                        }
                     }}
                     onConnect={() => console.log('Connected to sol WebSocket Server')}
                     onDisconnect={() => console.log('Disconnected from sol WebSocket Server')}
                  />
               )}
            </div>
         )}
      />
   );
}

export default withRouter(Main);
