import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { pack } from '../../@gc';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import axios from 'axios';
import { useSelector } from 'react-redux';
import SockJsClient from 'react-stomp';
import cx from 'classnames';
import Loader from 'react-loaders';
import { amber, blue } from '@material-ui/core/colors';
import { useForm } from 'react-hook-form';
import Scrollbars from 'react-custom-scrollbars-2';
import useTinymce from '../../@gc/common/hooks/useTinymce';
import Chat from './Chat';

const ChatWrap = styled.div`
   width: 100%;
   min-height: fit-content;
   height: 100%;
   border-top: 1px solid #bcbcbc;
   border-bottom: 1px solid #bcbcbc;
   position: relative;
`;

const ChatContainer = styled.div`
   height: calc(100vh - 125px);
   width: 100%;
   display: flex;
   flex-direction: column;
`;

const ChatNextLoading = styled.div`
   position: absolute;
   top: 8px;
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   z-index: 5010;
   & > span {
      font-size: 12px;
      border-radius: 4px;
      background: ${amber[500]};
      color: #fff;
   }
`;

const ChatLoading = styled.div`
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
`;

const ChatGroupTitle = styled.div`
   display: flex;
   justify-content: center;
   width: 100%;
   position: sticky;
   top: 0;
   z-index: 10;
   & > button {
      font-size: 12px;
      color: #757575;
      border: 1px solid #bcbcbc;
      padding: 4px 16px;
      z-index: 40;
      border-radius: 6px;
      background: rbga(0, 200, 83, 0.8);
      outline: none;
   }
`;

const defaultPageSize = 40;

const ChatList = () => {
   const client = useQueryClient();
   const { user } = useSelector(stt => stt.gc);
   const [mutateCount, setMutateCount] = useState(0);
   const [chatSections, setChatSections] = useState({});
   const refs = useMemo(() => Array.from({ length: 3 }, () => createRef()), []);
   const scrollRef = refs[0];
   const inputRef = refs[1];
   const { control, handleSubmit } = useForm({
      mode: 'onChange',
   });
   const { TinyEditor, editorRef, isReady, setEditorValue } = useTinymce({
      editorId: 'gchat',
      name: 'content',
      mode: 'simple',
      autoFocus: true,
      rules: {
         required: true,
      },
      control,
      onKeyDown: e => {
         if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (!e.isComposing) {
               handleSubmit(onSubmit)();
            }
         }
      },
   });

   /**
    *  받기
    **/
   const {
      data: chatData,
      isLoading,
      hasNextPage,
      fetchNextPage,
      isFetchingNextPage,
      refetch,
   } = useInfiniteQuery(
      ['gchat'],
      ({ pageParam = 0 }) =>
         axios
            .get(`/gchat/${pageParam}/${defaultPageSize}`)
            .then(res => res.data)
            .catch(pack.showError),
      {
         getNextPageParam: (lastPage, pages) => {
            const { skip, chats, total } = lastPage;
            return skip + chats.length < total ? pages.length : undefined;
         },
      },
   );

   /*
      낙관적 업데이트를 위해 send 후에 setQueryData 로 chatData 변경하고,
      이 때 mutateCount 를 변경하여 useEffect 가 호출되도록 유도함.
    */
   useEffect(() => {
      const data = chatData && chatData.pages ? chatData.pages.flatMap(page => page && page.chats) : null;
      /*
         chatSections 변경시 rerender 를 위해 useMemo 가 아닌 useState 로 정의.
      */
      setChatSections(pack.groupingChat(data ? [].concat(...data).reverse() : []));
      const position = scrollRef.current.getClientHeight() + scrollRef.current.getScrollTop() + 200;
      if (scrollRef.current.getScrollHeight() < position) {
         setTimeout(() => scrollRef.current.scrollToBottom(), 100);
      }
   }, [chatData, mutateCount]);

   const onMessage = useCallback(msg => {
      pack.playSound('pop.mp3');
      refetch();
   }, []);
   /* 받기 */

   /**
    *  보내기
    **/
   const { mutate: sendChat } = useMutation(data => axios.post('/gchat', JSON.stringify(data)), {
      onSuccess: () => {
         console.log('chat send success');
      },
      onError: pack.showError,
   });

   const onSubmit = useCallback(
      model => {
         const data = {
            ...model,
            empno: user.empno,
         };
         sendChat(data);
         setEditorValue('');
         client.setQueryData(['gchat'], prev => {
            prev.pages[0].chats.unshift({ ...data, id: 0, employee: { empno: user.empno, name: user.name, avatar: user.avatar } });
            prev.pages[0].total += 1;
            return prev;
         });
         /*
            mutateCount 변경시 useEffect 에서 chatSections 를 업데이트
         */
         setMutateCount(prev => prev + 1);
      },
      [user, inputRef],
   );
   /* 보내기 */

   useEffect(() => {
      if (isReady) {
         setTimeout(() => scrollRef.current.scrollToBottom(), 300);
         setTimeout(() => scrollRef.current.scrollToBottom(), 800);
      }
   }, [isReady]);

   useEffect(() => {
      setTimeout(focusChatBox, 300);
   }, []);

   const focusChatBox = useCallback(() => {
      if (isReady) editorRef.current.editor.focus();
   }, [isReady, editorRef]);

   const onScroll = useCallback(
      positionValue => {
         const { current } = scrollRef;
         if (positionValue.scrollTop === 0 && hasNextPage) {
            fetchNextPage().then(_ => setTimeout(() => current.scrollTop(current.getScrollHeight() - positionValue.scrollHeight), 300));
         }
      },
      [hasNextPage],
   );

   const onLinkFound = useCallback(() => {
      const position = scrollRef.current.getClientHeight() + scrollRef.current.getScrollTop() + 200;
      if (scrollRef.current.getScrollHeight() < position) {
         setTimeout(() => scrollRef.current.scrollToBottom(), 10);
      }
   }, [scrollRef]);

   return (
      <ChatContainer>
         <SockJsClient
            url={`${pack.serverUrl}/sock`}
            topics={['/sub/gchat/new']}
            onMessage={(msg, topic) => {
               if (topic === '/sub/gchat/new') {
                  onMessage(msg);
               }
            }}
            onConnect={() => console.log('Connected to sol WebSocket Server')}
            onDisconnect={() => console.log('Disconnected from sol WebSocket Server')}
         />
         <ChatWrap className="flex-1 p-2">
            {isFetchingNextPage && (
               <ChatNextLoading>
                  <Loader color={pack.getLoaderColor()} active type={pack.getLoaderType()} />
                  {/*<span className="text-black-50 mt-4 py-1 px-3">Loading more...</span>*/}
               </ChatNextLoading>
            )}
            {isLoading && (
               <ChatLoading>
                  <Loader color={pack.getLoaderColor()} active type={pack.getLoaderType()} />
               </ChatLoading>
            )}
            <Scrollbars universal autoHide ref={scrollRef} onScrollFrame={onScroll} style={{ minHeight: 'fit-content', height: '100%' }}>
               {Object.entries(chatSections).map(([date, chats]) => (
                  <section key={date} className="mb-3 d-flex flex-column align-items-stretch w-100">
                     <ChatGroupTitle>
                        <button className="shadow-1">{date}</button>
                     </ChatGroupTitle>
                     {chats.map(chat => (
                        <Chat chat={chat} onLinkFound={onLinkFound} />
                     ))}
                  </section>
               ))}
            </Scrollbars>
         </ChatWrap>
         <form className="w-100">{TinyEditor}</form>
      </ChatContainer>
   );
};

export default ChatList;
