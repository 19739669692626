// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { HashRouter } from 'react-router-dom';

import 'react-complex-tree/lib/style-modern.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'antd/dist/antd.css';
import './assets/base.scss';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';
// import '@fullcalendar/list/main.css';
import Main from '@griffin/main/Main';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import GcAuth from '@gc/auth/GcAuth';
import { pack } from '@gc';
import axios from 'axios';
import history from './history';
import NotSupported from './@griffin/common/components/NotSupported';
import { QueryClient, QueryClientProvider } from 'react-query';

const store = configureStore();
const rootElement = document.getElementById('root');

axios.defaults.timeout = 0;
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}${pack.serverPrefix}`;
axios.defaults.headers = { 'Content-Type': 'application/json' };
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
   config => {
      let token = null;
      try {
         token = localStorage.getItem('imhappy');
      } catch (e) {
         console.log('행복한 토큰 가져오는중 오류 발생');
         console.error(e);
      }
      if (token) {
         config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
   },
   error => {
      console.error('Request Error: ' + error);
      return Promise.reject(error);
   },
);
/*axios.interceptors.response.use(
  (response) => {
    console.log("in interceptor, response=", response);
    if (response.data.token) {
      localStorage.setItem("happy", JSON.stringify(response.data));
    }
    return response;
  },
  (error) => {
    console.error("Response Error: " + error);
    return Promise.reject(error);
  }
);*/

const unSupported = pack.browserInfo.startsWith('IE');

const queryClient = new QueryClient({
   defaultOptions: {
      queries: {
         retry: 0,
         useErrorBoundary: false,
         staleTime: 0,
      },
      mutations: {
         retry: 0,
         useErrorBoundary: false,
      },
   },
});

const renderApp = Component => {
   unSupported
      ? ReactDOM.render(<NotSupported />, rootElement)
      : ReactDOM.render(
           <Provider store={store}>
              <div>
                 <HashRouter history={history}>
                    <GcAuth>
                       <QueryClientProvider client={queryClient}>
                          <Component />
                       </QueryClientProvider>
                    </GcAuth>
                 </HashRouter>
              </div>
           </Provider>,
           rootElement,
        );
};

renderApp(Main);

serviceWorker.unregister();
