import * as Actions from "../actions/sysmail.actions";

const initState = {
   domains: [],
   domain: {
      id: '',
      name: ''
   },
   groups: [],
   openGroup: false,
   targetTree: [],
   targetList: [],
   loaderActive: false
};

const sysmailReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_DOMAINS:
         return {
            ...state,
            domains: action.payload
         };
      case Actions.FETCH_GROUPS:
         return {
            ...state,
            groups: action.payload
         };
      case Actions.FETCH_TARGET_TREE:
         return {
            ...state,
            targetTree: action.tree,
            targetList: action.list
         };
      case Actions.TOGGLE_GROUP_MODAL:
         return {
            ...state,
            openGroup: action.flag
         };
      case Actions.TOGGLE_LOADING:
         return {
            ...state,
            loaderActive: action.flag
         };
      default:
         return state;
   }
};

export default sysmailReducer;