import { pack } from './global';
import {
   MAILBOX_IVTYPE_READ,
   MAILBOX_IVTYPE_UNREAD,
   MAILBOX_TYPE_ALL,
   MAILBOX_TYPE_ETC,
   MAILBOX_TYPE_FILE,
   MAILBOX_TYPE_IMPORTANT,
   MAILBOX_TYPE_INBOX,
   MAILBOX_TYPE_RECYCLE,
   MAILBOX_TYPE_SEND,
   MAILBOX_TYPE_SPAM,
   MAILBOX_TYPE_STAR,
   MAILBOX_TYPE_TAG,
   MAILBOX_TYPE_TEMP,
   MAILBOX_TYPE_TOME,
   MAILBOX_TYPE_TOTAL,
   MAILBOX_TYPE_UNREAD,
} from '../../@griffin/activity/mailbox/actions';

class GcUtils {
   static filterArrayByString(mainArr, searchText) {
      if (searchText === '') {
         return mainArr;
      }

      searchText = searchText.toLowerCase();

      return mainArr.filter(itemObj => {
         return this.searchInObj(itemObj, searchText);
      });
   }

   static searchInObj(itemObj, searchText) {
      for (const prop in itemObj) {
         if (!itemObj.hasOwnProperty(prop)) {
            continue;
         }

         const value = itemObj[prop];

         if (typeof value === 'string') {
            if (this.searchInString(value, searchText)) {
               return true;
            }
         } else if (Array.isArray(value)) {
            if (this.searchInArray(value, searchText)) {
               return true;
            }
         }

         if (typeof value === 'object') {
            if (this.searchInObj(value, searchText)) {
               return true;
            }
         }
      }
   }

   static searchInArray(arr, searchText) {
      for (const value of arr) {
         if (typeof value === 'string') {
            if (this.searchInString(value, searchText)) {
               return true;
            }
         }

         if (typeof value === 'object') {
            if (this.searchInObj(value, searchText)) {
               return true;
            }
         }
      }
   }

   static searchInString(value, searchText) {
      return value.toLowerCase().includes(searchText);
   }

   static randomize(myArray) {
      return myArray[Math.floor(Math.random() * myArray.length)];
   }

   static reorder(list, startIndex, endIndex) {
      const result = Array.from(list);
      const removed = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed[0]);
      return result;
   }

   static getTopN(array, topN, sortByFunc, isDesc) {
      if (array.length < 2) {
         return array;
      }
      let sorted = array.sort(sortByFunc);
      if (isDesc) {
         sorted = sorted.reverse();
      }
      return sorted.slice(0, Math.min(topN, sorted.length));
   }

   static findById(o, id) {
      //Early return
      if (o.id === id) {
         return o;
      }
      let result, p;
      for (p in o) {
         if (o.hasOwnProperty(p) && o[p] != null && typeof o[p] === 'object') {
            // 수정 by ljc : o[p] != null 조건 추가.
            result = this.findById(o[p], id);
            if (result) {
               return result;
            }
         }
      }
      return result;
   }

   static getFlatNavigation(navigationItems, flatNavigation, strings) {
      flatNavigation = flatNavigation ? flatNavigation : [];

      for (const navItem of navigationItems) {
         if (navItem.type === 'subheader') {
            continue;
         }

         if (navItem.type === 'item') {
            flatNavigation.push({
               ...navItem,
               // id   : navItem.id,
               // label: strings[navItem.label],
               // type : navItem.type,
               // icon : navItem.icon || false,
               // to  : navItem.to
            });

            continue;
         }

         if (navItem.type === 'collapse' || navItem.type === 'group') {
            if (navItem.content) {
               this.getFlatNavigation(navItem.content, flatNavigation, strings);
            }
         }
      }

      return flatNavigation;
   }

   static getEmailsBySearch(entities, condition) {
      let arr = entities;
      const { sender, startdate, enddate, to, content } = condition;
      if (Boolean(sender)) {
         arr = arr.filter(mail => mail.fromName.toLowerCase().includes(sender.toLowerCase()) || mail.fromAddress.toLowerCase().includes(sender.toLowerCase()));
      }
      if (Boolean(startdate)) {
         arr = arr.filter(mail => {
            if (mail.folderName === 'send' || mail.istemp === '1') {
               return +pack.timestampToFormattedString(mail.date, 'yyyyMMdd') >= +startdate.replace(/[^0-9]/g, '');
            } else {
               return +pack.timestampToFormattedString(mail.receivedDate, 'yyyyMMdd') >= +startdate.replace(/[^0-9]/g, '');
            }
         });
      }
      if (Boolean(enddate)) {
         arr = arr.filter(mail => {
            if (mail.folderName === 'send' || mail.istemp === '1') {
               return +pack.timestampToFormattedString(mail.date, 'yyyyMMdd') <= +enddate.replace(/[^0-9]/g, '');
            } else {
               return +pack.timestampToFormattedString(mail.receivedDate, 'yyyyMMdd') <= +enddate.replace(/[^0-9]/g, '');
            }
         });
      }
      if (Boolean(to.value)) {
         switch (to.type) {
            case 'to':
               arr = arr.filter(mail => mail.tos.some(it => Boolean(it.address) && it.address.toLowerCase().includes(to.value.toLowerCase())));
               break;
            case 'cc':
               arr = arr.filter(mail => mail.ccs.some(cc => Boolean(cc.address) && cc.address.toLowerCase().includes(to.value.toLowerCase())));
               break;
            case 'tocc':
               arr = arr.filter(
                  mail =>
                     mail.tos.some(it => Boolean(it.address) && it.address.toLowerCase().includes(to.value.toLowerCase())) ||
                     mail.ccs.some(cc => Boolean(cc.address) && cc.address.toLowerCase().includes(to.value.toLowerCase())),
               );
               break;
         }
      }
      if (Boolean(content.value)) {
         switch (content.type) {
            case 'subject':
               arr = arr.filter(mail => mail.subject.toLowerCase().includes(content.value.toLowerCase()));
               break;
            case 'text':
               arr = arr.filter(mail => mail.text.toLowerCase().includes(content.value.toLowerCase()));
            case 'subtext':
               arr = arr.filter(mail => mail.subject.toLowerCase().includes(content.value.toLowerCase()) || mail.text.toLowerCase().includes(content.value.toLowerCase()));
               break;
            case 'filename':
               arr = arr.filter(mail => mail.files.some(file => file.filename.toLowerCase().includes(content.value.toLowerCase())));
               break;
            case 'subfile':
               arr = arr.filter(mail => mail.subject.toLowerCase().includes(content.value.toLowerCase()) || mail.files.some(file => file.filename.toLowerCase().includes(content.value.toLowerCase())));
               break;
         }
      }
      return arr;
   }

   static getEmailsByBoxtype({ entities, searchText, boxType, folderName, inboxViewType, tag, user }) {
      let arr = entities;
      const someTag = { ...tag };

      arr = !searchText
         ? arr
         : arr.filter(mail => {
              const search = searchText.toLowerCase();
              return (
                 mail.text.toLowerCase().indexOf(search) > -1 ||
                 mail.subject.toLowerCase().indexOf(search) > -1 ||
                 mail.fromName.toLowerCase().indexOf(search) > -1 ||
                 mail.fromAddress.toLowerCase().indexOf(search) > -1
              );
           });

      switch (inboxViewType) {
         case MAILBOX_IVTYPE_READ:
            arr = arr.filter(mail => mail.isread === '1');
            break;
         case MAILBOX_IVTYPE_UNREAD:
            arr = arr.filter(mail => mail.isread === '0');
            break;
      }

      switch (boxType) {
         case MAILBOX_TYPE_TOTAL:
            return arr;
         case MAILBOX_TYPE_ALL:
            return arr.filter(mail => mail.folderName !== 'spam' && mail.folderName !== 'recycle' && mail.folderName !== 'send');
         case MAILBOX_TYPE_INBOX:
            return arr.filter(mail => mail.folderName === 'inbox');
         case MAILBOX_TYPE_SEND:
            return arr.filter(mail => mail.folderName === 'send');
         case MAILBOX_TYPE_TOME:
            return arr.filter(mail => mail.folderName === 'send' && mail.tos.map(to => to.address).includes(user.email));
         case MAILBOX_TYPE_TEMP:
            return arr.filter(mail => mail.folderName === 'temp');
         case MAILBOX_TYPE_STAR:
            return arr.filter(mail => mail.starred === '1');
         case MAILBOX_TYPE_IMPORTANT:
            return arr.filter(mail => mail.important === '1');
         case MAILBOX_TYPE_SPAM:
            return arr.filter(mail => mail.folderName === 'spam');
         case MAILBOX_TYPE_RECYCLE:
            return arr.filter(mail => mail.folderName === 'recycle');
         case MAILBOX_TYPE_TAG:
            return arr.filter(mail => mail.tags.some(item => item.seq === someTag.seq));
         case MAILBOX_TYPE_FILE:
            return arr.filter(mail => mail.files.length > 0);
         case MAILBOX_TYPE_ETC:
            return arr.filter(mail => mail.folderName === folderName);
         case MAILBOX_TYPE_UNREAD:
            return arr.filter(mail => mail.isread === '0' && mail.folderName !== 'send' && mail.folderName !== 'recycle' && mail.folderName !== 'spam');
         default:
            return arr;
      }
   }

   static getRepeatDesc(repeatOption, lang) {
      let repeatDesc = '';
      if (Boolean(repeatOption) && repeatOption !== 'null') {
         const json = JSON.parse(repeatOption);
         if (typeof json === 'object')
            switch (json.value) {
               case 'none':
                  break;
               case 'daily':
                  if (lang === 'ko') repeatDesc = '매일';
                  else repeatDesc = 'Everyday';
                  break;
               case 'weekly':
                  if (lang === 'ko') repeatDesc = `매주 ${pack.weekDesc[lang][+json.data]}`;
                  else repeatDesc = `Every ${pack.weekDesc[lang][+json.data]}`;
                  break;
               case 'monthly':
                  const { isFirst, isLast, weekNum, day } = json.data;
                  if (lang === 'ko') {
                     repeatDesc = `매월 ${isFirst === '1' ? '첫번째' : isLast === '1' ? '마지막' : `${weekNum}번째`} ${pack.weekDesc[lang][+day]}`;
                  } else {
                     repeatDesc = `${isFirst === '1' ? 'First' : isLast === '1' ? 'Last' : `${weekNum}th`} ${pack.weekDesc[lang][+day]} of every month`;
                  }
                  break;
               case 'yearly':
                  const { month, date } = json.data;
                  if (lang === 'ko') {
                     repeatDesc = `매년 ${month}월 ${date}일`;
                  } else {
                     repeatDesc = `${pack.monthDesc[month]} ${date} every year`;
                  }
                  break;
            }
      }
      return repeatDesc;
   }
}

export default GcUtils;
