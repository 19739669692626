import * as Actions from "../actions";
import _ from 'lodash';

const initState = {
   entities: [],
   firmList: [],
   firmTree: [],
   treeLoadCnt: 0,
   entity: {
      isapprover: '1',
      islogin: '1',
      isdelete: '0',
      isretire: '0',
      member: {},
      firm: {},
      note: ''
   },
   firm: {}
};

const sellerReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_FIRMS:
         return {
            ...state,
            firmList: action.list,
            firmTree: action.tree,
            treeLoadCnt: state.treeLoadCnt + 1
         };
      case Actions.FETCH_SELLERS:
         return {
            ...state,
            entities: action.payload.map(item => ({
               ...item,
               member: item.member || {},
               firm: item.firm || {}
            }))
         };
      case Actions.FETCH_SELLER:
         return {
            ...state,
            entity: _.find(state.entities, {seq: +(action.seq)})
         };
      case Actions.SELECT_FIRM:
         return {
            ...state,
            firm: action.payload || initState.firm
         };
      case Actions.OPEN_ADD:
         return {
            ...state,
            entity: initState.entity
         };
      case Actions.OPEN_VIEW:
      case Actions.OPEN_EDIT:
         return {
            ...state,
            entity: action.data
         };
      default:
         return state;
   }
};

export default sellerReducer;