import _ from 'lodash';
import * as Actions from '../actions';
import { pack } from '../../../../@gc';

const initState = {
   entities: [],
   entity: {
      seq: '',
      startdate: '',
      enddate: '',
      amount: 0,
      type: '',
      regDate: '',
      employee: {
         member: {},
      },
      approve: {},
   },
   treeOpen: false,
   year: new Date().getFullYear(),
   total: 0,
   wolcha: 0,
   usedWolcha: 0,
   withIngUsedWolcha: 0,
   useableWolcha: 0,
   yunchaLoaded: false,
   joinEnddate: '19700101',
   amounts: 0, // 신청연차 중 승인된 갯수
   withIngAmounts: 0, // 신청연차 중 (신청중인것 + 승인된것) 갯수
   yuncha: {},
   amountPerType: {},
   employee: {
      member: {},
   },
   types: [],
   entityLoadCnt: 0,
   targetList: [],
   targetTree: [],
};

const vacationReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_TARGET_LIST:
         return {
            ...state,
            targetTree: action.tree,
            targetList: action.list,
         };
      case Actions.FETCH_EMPLOYEE:
         return {
            ...state,
            employee: action.payload,
            entity: { ...initState.entity },
         };
      case Actions.FETCH_LIST:
         return {
            ...state,
            entities: action.payload,
         };
      case Actions.FETCH_AMOUNTS:
         return {
            ...state,
            amounts: action.payload,
         };
      case Actions.FETCH_ING_AMOUNTS:
         return {
            ...state,
            withIngAmounts: action.payload,
         };
      case Actions.FETCH_TOTAL:
         const yuncha = action.payload;
         let prevWolcha = yuncha.wolchaList
            .filter(it => it.islast)
            .map(it => it.count)
            .reduce((acc, cur) => acc + cur, 0);
         let wolcha = yuncha.wolchaList
            .filter(it => !it.islast)
            .map(it => it.count)
            .reduce((acc, cur) => acc + cur, 0);

         const theStart = new Date(new Date().getFullYear() + '-01-01');
         const today = new Date(pack.dateToString(new Date(), 'yyyy-MM-dd'));
         const joinDate = new Date(pack.dateFormat(yuncha.joinDate));
         const joinEnd = new Date(joinDate.getTime());
         joinEnd.setFullYear(joinEnd.getFullYear() + 1);
         prevWolcha = Math.min(pack.datesBetweenWithoutHoliday(theStart, joinEnd, prevWolcha), prevWolcha);
         wolcha = Math.min(pack.datesBetweenWithoutHoliday(today, joinEnd, wolcha), wolcha);

         return {
            ...state,
            total: Math.floor((yuncha.realCount + /*(prevWolcha - yuncha.prevUsedCount + yuncha.prevAmount) +*/ wolcha) * 100) / 100,
            wolcha: /*Math.max(prevWolcha - yuncha.prevUsedCount, 0) +*/ wolcha,
            usedWolcha: yuncha.curUsedWolcha,
            withIngUsedWolcha: yuncha.curUsedTotalWolcha,
            useableWolcha:
               Math.max(prevWolcha - yuncha.prevUsedCount + yuncha.prevAmount /* 이월된 월차 - 전년도 승인된 휴가수 */, 0) +
               (wolcha - yuncha.curUsedTotalWolcha) /* 올해 월차 - 올해 [승인된 or 요청중인] 월차수 */,
            yuncha,
            joinEnddate: pack.dateToString(joinEnd),
            yunchaLoaded: true,
         };
      case Actions.TOGGLE_YUNCHA_LOADED:
         return {
            ...state,
            yunchaLoaded: action.flag,
         };
      case Actions.FETCH_HUGA_TYPES:
         return {
            ...state,
            types: action.payload,
         };
      case Actions.FETCH_AMOUNT_PER_TYPE:
         return {
            ...state,
            amountPerType: _.keyBy(action.payload, 'key'),
         };
      case Actions.TOGGLE_TREE:
         return {
            ...state,
            treeOpen: action.open,
         };
      case Actions.SELECT_ENTIITY:
         return {
            ...state,
            entity: action.entity,
            entityLoadCnt: state.entityLoadCnt + 1,
         };
      case Actions.UPDATE_YEAR:
         return {
            ...state,
            year: action.year,
            entity: {
               ...initState.entity,
            },
         };
      default:
         return state;
   }
};

export default vacationReducer;
