import * as Actions from '../actions/user.action';
import { pack } from '@gc';
import _ from 'lodash';

const initState = {
   role: 'guest', // admin, staff, user, guest
   name: 'Guest',
   empno: '',
   deptcode: '',
   firmname: '',
   firmcode: '',
   deptname: '',
   position: '',
   positionDesc: '',
   desc: '',
   avatar: null,
   emails: ['guest@example.com'],
   currentEmail: '',
   happy: '',
   isAdmin: false,
   isSuper: false,
   ispublic: '0',
   menus: [], // 메뉴권한
   roles: [], // 기능권한(CRUD + @)
   deptcodes: [], // 부서권한
   point: 0,
   shortcuts: [],
   settings: {},
   lastip: '',
   lastdate: null,
   userLoadCnt: 0,
   trelloShowMsg: true,
   trelloBgtype: null,
   trelloBgname: null,
   firstMenu: 'mail',
   firstUrl: '/activity/mail',
   menuTree: [],
   menuList: [],
};

const initialState = pack.getOtherTabSessionData() || initState;

const user = function (state = { ...initialState, ...pack.getSessionData() }, action) {
   switch (action.type) {
      case Actions.UPDATE_USER_POINT:
         return {
            ...state,
            point: action.payload,
         };
      case Actions.UPDATE_USER_ROLES:
         return {
            ...state,
            roles: action.payload,
         };
      case Actions.INIT_USER:
         return {
            ...initialState,
         };
      case Actions.UPDATE_USER_DATA:
         const reState = {
            ...state,
            ...action.data,
            userLoadCnt: state.userLoadCnt + 1,
         };

         pack.setSessionData(_.omit(reState, ['menus']));
         return reState;
      case Actions.SET_USER_DATA:
         const user = _.omit(action.payload, ['mySettings']);
         const settings = action.payload.settings || state.settings;
         const shortcuts = user.shortcuts.map(it => {
            if (typeof it === 'object') return it.id;
            else return it;
         });

         const newState = {
            ...state,
            ...user,
            avatar: user.avatar,
            shortcuts,
            settings,
         };

         pack.setSessionData(_.omit(newState, ['menus']));
         pack.setMenus(newState.menus);

         return newState;
      case Actions.SET_USER_FUNC_MAP:
         return {
            ...state,
            roleMap: action.payload,
         };
      case Actions.REMOVE_USER_DATA:
         pack.removeLocalData();
         return {
            ...initState,
         };
      case Actions.USER_LOGGED_OUT:
         pack.removeSessionData();
         pack.removeLocalData();
         return {
            ...initState,
         };
      case Actions.UPDATE_TRELLO_SHOWAGAIN:
         return {
            ...state,
            trelloShowMsg: false,
         };
      case Actions.UPDATE_FIRST_MENU:
         const furl = (_.find(state.menuList, { id: action.payload }) || {}).to;
         return {
            ...state,
            firstMenu: action.payload,
            firstUrl: (furl || '/activity/mail').replace(/^#/, ''),
         };
      case Actions.UPDATE_USER_MENU_TREE:
         const ffurl = (_.find(action.list, { id: state.firstMenu }) || {}).to;
         return {
            ...state,
            menuTree: action.tree,
            menuList: action.list,
            firstUrl: (ffurl || '/activity/mail').replace(/^#/g, ''),
         };
      default: {
         return state;
      }
   }
};

export default user;
