import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Col, FormText, Label } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import cx from 'classnames';
import axios from 'axios';
import { pack } from '@gc';
import { Tag } from 'antd';
import chroma from 'chroma-js';

class CodeSelectFormsy extends Component {
   constructor(props) {
      super(props);
      this.state = {
         touched: false,
         codes: [],
      };
   }

   componentDidMount() {
      axios.get(`/code/list/${this.props.groupName}`).then(res => {
         this.setState({
            codes: res.data,
         });
      });
      this.animatedComponents = makeAnimated();
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.viewmode !== this.props.viewmode) {
         this.setState({
            touched: false,
         });
      }
   }

   changeValue = selectedOption => {
      const value = this.props.isMulti ? selectedOption.map(item => item.value) : selectedOption.value;
      if (this.props.maxOptions && this.props.isMulti && value.length > this.props.maxOptions) {
         return;
      }
      this.props.setValue(value);
      if (!this.state.touched) {
         this.setState({
            touched: true,
         });
      }
      if (typeof this.props.onChange === 'function') {
         this.props.onChange(selectedOption);
      }
   };

   render() {
      const { touched } = this.state;
      let importedProps = _.pick(this.props, ['name', 'isMulti', 'children']);

      importedProps.options = (Boolean(this.props.selectNoneText) ? [{ label: this.props.selectNoneText, value: '', color: '' }] : []).concat(
         this.state.codes.map(code => ({
            label: code.codeDesc,
            value: code.codeValue,
            color: Boolean(code.color) ? `#${code.color}` : '',
         })),
      );

      // 참고 : https://thewebdev.info/2021/11/20/how-to-change-zindex-of-the-items-in-a-react-select-drop-down/
      if (Boolean(this.props.targetParent)) {
         importedProps.menuPortalTarget = this.props.targetParent;
      }

      importedProps.styles = {
         control: styles => ({ ...styles, backgroundColor: 'white', height: 30, minHeight: 30 }),
         option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            if (!data.color) return styles;

            const color = chroma(data.color);
            return {
               ...styles,
               backgroundColor: isDisabled ? null : isSelected ? data.color : isFocused ? color.alpha(0.1).css() : null,
               color: isDisabled ? '#ccc' : isSelected ? (chroma.contrast(color, 'white') > 2 ? 'white' : 'black') : data.color,
               cursor: isDisabled ? 'not-allowed' : 'default',
               ':active': {
                  ...styles[':active'],
                  backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
               },
            };
         },

         /* 드랍다운 영역을 최상위로 */
         menuPortal: styles => ({ ...styles, zIndex: 9999 }),
         menu: styles => ({ ...styles, zIndex: 9999 }),

         input: styles => ({
            ...styles,
            margin: 0,
         }),
         valueContainer: styles => ({ ...styles, padding: '0 6px', height: 30 }),
         indicatorsContainer: styles => ({
            ...styles,
            height: 30,
         }),
         multiValue: (styles, { data }) => {
            if (!data.color) return styles;

            const color = chroma(data.color);
            return {
               ...styles,
               backgroundColor: color.alpha(0.1).css(),
            };
         },
         multiValueLabel: (styles, { data }) => {
            if (!data.color) return styles;
            return {
               ...styles,
               color: data.color,
            };
         },
         multiValueRemove: (styles, { data }) => {
            if (!data.color) return styles;
            return {
               ...styles,
               color: data.color,
               ':hover': {
                  backgroundColor: data.color,
                  color: 'white',
               },
            };
         },
      };

      let error = false;
      let errorMessage = this.props.getErrorMessage() || this.props.errorTrigger;
      const value = this.props.getValue();

      /*if (!_.isEqual(value, this.props.value)) {
         this.props.setValue(this.props.value);
      }*/

      let valueObj = this.props.isMulti ? [] : {};

      if (!this.props.isMulti && (Boolean(value) || Boolean(this.props.selectNoneText))) {
         valueObj = _.find(importedProps.options, { value });
      }
      if (this.props.isMulti && value && value.length > 0) {
         valueObj = importedProps.options.filter(option => value.includes(option.value));
      }

      if (touched && !Boolean(value) && !errorMessage && this.props.required) {
         errorMessage = this.props.requiredMsg || '';
         error = true;
      }

      const labelWidth = this.props.labelWidth || { sm: 2 };
      const colWidth = this.props.colWidth || { sm: 4 };
      const id = this.props.id || this.props.name;
      importedProps.id = id;

      return (
         <>
            {Boolean(this.props.label) && (
               <Label {...labelWidth} for={id} className={cx({ required: !this.props.viewmode && this.props.required })}>
                  {this.props.label}
               </Label>
            )}
            <Col {...colWidth} className={cx('d-flex align-items-center', this.props.colClassName || '')}>
               {!this.props.viewmode ? (
                  <>
                     <div className={cx('d-flex flex-column', this.props.rightComponent ? 'w-auto' : 'w-100')}>
                        <Select
                           {...importedProps}
                           value={valueObj}
                           onChange={this.changeValue}
                           className={Boolean(errorMessage) || error ? 'react-select-invalid' : ''}
                           components={this.animatedComponents}
                        />
                        {Boolean(errorMessage) && <FormText color="danger">{errorMessage}</FormText>}
                     </div>
                     {this.props.rightComponent != null && <div className="pane-right">{this.props.rightComponent}</div>}
                  </>
               ) : this.props.isMulti ? (
                  valueObj.map((opt, idx) =>
                     pack.isHexString(opt.value) ? (
                        <Tag color={`#${opt.value.replace(/^#/, '')}`} className="mr-2" key={idx}>
                           {opt.label}
                        </Tag>
                     ) : (
                        <Label className="view" key={idx}>{`${idx > 0 ? ', ' : ''}${opt.label}`}</Label>
                     ),
                  )
               ) : (
                  <Label className="view">{valueObj != null ? valueObj.label : ''}</Label>
               )}
            </Col>
         </>
      );
   }
}

CodeSelectFormsy.propTypes = {
   id: PropTypes.string,
   name: PropTypes.string.isRequired,
   groupName: PropTypes.string.isRequired,
   isMulti: PropTypes.bool,
   value: PropTypes.any,
   label: PropTypes.string,
   required: PropTypes.bool,
   viewmode: PropTypes.bool,
   onChange: PropTypes.func,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   rightComponent: PropTypes.object,
   colClassName: PropTypes.string,
   maxOptions: PropTypes.number,
   noUpdate: PropTypes.bool,
   targetParent: PropTypes.any,
   selectNoneText: PropTypes.string,
};

export default withFormsy(CodeSelectFormsy);
