import axios from 'axios';
import {toastr} from "react-redux-toastr";
import {pack} from '@gc';

const jtf = require('json-form-data');

export const FETCH_RULES = '[PWDRULE] FETCH RULES';

export function fetchRules(onSuccess) {
   return (dispatch, getState) =>
      axios.get('/password/rules')
         .then(res => {
            if (res.status === 200) {
               dispatch({
                  type: FETCH_RULES,
                  payload: res.data
               });

               if(typeof onSuccess === 'function')
                  onSuccess(res.data);
            } else {
               const {strings} = getState().gc.common;
               toastr(strings.errTitle, strings.pwdFetchErr);
            }
         }).catch(e => pack.showError(e));
}

export function updateRules(data, afterUpdate) {
   return (dispatch, getState) => {
      axios.put('/password', jtf(data))
         .then(res => {
            if (res.status === 200) {
               dispatch(fetchRules());
               afterUpdate(true);
            } else {
               const {strings} = getState().gc.common;
               toastr.error(strings.errTitle, strings.errorMessage);
               afterUpdate(false);
            }
         }).catch(e => {
            pack.showError(e);
            afterUpdate(false);
         });
   };
}