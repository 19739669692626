import axios from 'axios';
import _ from 'lodash';

const jtf = require('json-form-data');

export const GET_EVENTS = '[CALENDAR APP] GET EVENTS';
export const GET_GOOGLE_EVENTS = '[CALENDAR APP] GET GOOGLE EVENTS';
export const OPEN_NEW_EVENT_DIALOG = '[CALENDAR APP] OPEN NEW EVENT DIALOG';
export const CLOSE_NEW_EVENT_DIALOG = '[CALENDAR APP] CLOSE NEW EVENT DIALOG';
export const OPEN_EDIT_EVENT_DIALOG = '[CALENDAR APP] OPEN EDIT EVENT DIALOG';
export const CLOSE_EDIT_EVENT_DIALOG = '[CALENDAR APP] CLOSE EDIT EVENT DIALOG';
export const ADD_EVENT = '[CALENDAR APP] ADD EVENT';
export const UPDATE_EVENT = '[CALENDAR APP] UPDATE EVENT';
export const REMOVE_EVENT = '[CALENDAR APP] REMOVE EVENT';

// const CALENDAR_ID = 'qansohiecib58ga9k1bmppvt5oi65b1q@import.calendar.google.com';
// const API_KEY = 'AIzaSyCfLwB5WfVZbUCET7pyD5DdQPWZNVbtAps';
const API_KEY = 'AIzaSyBuW4pWWQ5jRgvXqQFTtt_uJ1LIn44zA6Q';
const CALENDAR_ID = 'ko.south_korea%23holiday@group.v.calendar.google.com';
let url = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${API_KEY}`;

export function getEvents(year, month) {
   return (dispatch, getState) =>
      axios.get(`/mytask/events/${getState().gc.user.id}/${year}/${_.padStart(month, 2, '0')}`).then(response => {
         dispatch({
            type: GET_EVENTS,
            payload: response.data,
         });
         // dispatch(getGoogleEvents());
      });
}

export function getGoogleEvents() {
   return dispatch => {
      axios.get(url).then(res => {
         dispatch({
            type: GET_GOOGLE_EVENTS,
            payload: res.data.items,
         });
      });
   };
}

export function openNewEventDialog(data) {
   return {
      type: OPEN_NEW_EVENT_DIALOG,
      data,
   };
}

export function closeNewEventDialog() {
   return {
      type: CLOSE_NEW_EVENT_DIALOG,
   };
}

export function openEditEventDialog(data) {
   return {
      type: OPEN_EDIT_EVENT_DIALOG,
      data,
   };
}

export function closeEditEventDialog() {
   return {
      type: CLOSE_EDIT_EVENT_DIALOG,
   };
}

export function addEvent(newEvent) {
   return (dispatch, getState) => {
      const request = axios.post('/api/calendar-app/add-event', {
         newEvent,
      });

      return request.then(response =>
         Promise.all([
            dispatch({
               type: ADD_EVENT,
            }),
         ]).then(() => dispatch(getEvents())),
      );
   };
}

export function updateEvent(event) {
   return (dispatch, getState) => {
      const request = axios.post('/api/calendar-app/update-event', {
         event,
      });

      return request.then(response =>
         Promise.all([
            dispatch({
               type: UPDATE_EVENT,
            }),
         ]).then(() => dispatch(getEvents())),
      );
   };
}

export function removeEvent(eventId) {
   return (dispatch, getState) => {
      const request = axios.post('/api/calendar-app/remove-event', {
         eventId,
      });

      return request.then(response =>
         Promise.all([
            dispatch({
               type: REMOVE_EVENT,
            }),
         ]).then(() => dispatch(getEvents())),
      );
   };
}
