import axios from 'axios';
import {startLoading, stopLoading} from "../../../../@gc/common/actions/common.action";
import {pack} from "@gc";

const jtf = require('json-form-data');

export const FETCH_SELLERS = '[SELLER] FETCH SELLERS';
export const FETCH_SELLER = '[SELLER] FETCH SELLER';
export const FETCH_FIRMS = '[SELLER] FETCH FIRMS';
export const OPEN_ADD = '[SELLER] OPEN ADD';
export const OPEN_EDIT = '[SELLER] OPEN EDIT';
export const OPEN_VIEW = '[SELLER] OPEN VIEW';
export const SELECT_FIRM = '[SELLER] SELECT_FIRM';


export function fetchFirms() {
   return (dispatch, getState) => {
      const request = axios.get(`/firm?lang=${getState().gc.common.lang}`);
      request.then(res => {
         dispatch({
            type: FETCH_FIRMS,
            tree: res.data.tree,
            list: res.data.list
         })
      })
   }
}

export function fetchSellers(showLoading, props, firmSeq) {
   return (dispatch, getState) => {
      if (showLoading)
         dispatch(startLoading());
      const request = axios.get(`/seller/${firmSeq || '0'}`);

      request.then(res => {
         dispatch(stopLoading());
         dispatch({
            type: FETCH_SELLERS,
            payload: res.data
         });
         if (props && Boolean(props.match.params.seq)) {
            dispatch(fetchSeller(props.match.params.seq));
         }
      });
   };
}

export function fetchSeller(seq) {
   return {
      type: FETCH_SELLER,
      seq
   };
}

export function openAdd({match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_ADD
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'add',
         seq: null
      }));
   };
}

export function openView(seq, {match, history}) {
   return (dispatch) => {
      dispatch(fetchSeller(seq));
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         seq
      }));
   };
}

export function removeEntity(seq, {match, history}) {
   return (dispatch, getState) =>
      axios.delete(`/seller/${seq}`)
         .then(res => {
            dispatch(fetchSellers(true, null, (getState().griffin.manage.seller.firm || {}).seq));
            history.push(pack.getToPath(match)({
               ...match.params,
               mode: 'list',
               seq: null
            }));
         });
}

export function createEntity(data, {match, history}) {
   data = pack.jsonToJpa(data, 'member');
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: `/seller${match.params.mode === 'edit' ? '/update' : ''}`,
         data: jtf({...data, myId: getState().gc.user.id})
      }).then(res => {
         dispatch(fetchSellers());
         history.push(pack.getToPath(match)({
            ...match.params,
            mode: 'list'
         }));
      });
}

export function selectTreeNode(id) {
   return (dispatch) => {
      axios.get(`/firm/${id || '0'}`)
         .then(res => {
            dispatch({
               type: SELECT_FIRM,
               payload: res.data
            });
            dispatch(fetchSellers(true, null, res.data.seq));
         });
   };
}