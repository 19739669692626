import * as Actions from '../actions';
import _ from 'lodash';
import { pack } from '../../../../@gc';
import { SELECT_MONK_AND_MONKERS } from '../actions';

const initState = {
   entities: [],
   entity: {
      seq: '',
      suggests: [],
      estimates: [],
      ispublic: '0',
      openType: 'PUB',
      islast: '1',
      isdelete: '0',
      products: [],
      employees: [],
      outsources: [],
      viewers: [],
      costs: [],
      etcs: [],
   },
   events: [],
   eventLoadCnt: 0,
   pmoGridType: Actions.PMO_GRID_TYPE_LIST,
   companyList: [],
   clientList: [],
   sellerList: [],
   monkerList: [],
   productList: [], // 전체 제품
   // totalEmployees: [],  // 전체 직원
   // employeeList: [],    // 선택한 투입인력
   // stakeList: [],    // 선택한 이해관계자
   sellerTargetTree: [],
   sellerTargetList: [],
   empTargetTree: [],
   empTargetList: [],
   openClientDw: false,
   openSellerDw: false,
   openMonkerDw: false,
   openEmployeeDw: false,
   openStakeDw: false,
   company: {},
   firm: {},
   monk: {},
   entityLoadCnt: 0,
   listTab: '1',
   zoom: 'day',
};

const projectReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_PROJECTS:
         return {
            ...state,
            entities: action.payload,
         };
      case Actions.FETCH_PROJECT:
         const entity = _.find(state.entities, { seq: +action.seq });
         return {
            ...state,
            entity,
            entityLoadCnt: state.entityLoadCnt + 1,
            company: entity.company || {},
            firm: entity.firm || {},
            monk: entity.monk || {},
            clientList: entity.clients.map(item => item.client) || [],
            sellerList: entity.sellers.map(item => item.seller) || [],
            monkerList: entity.monkers.map(item => item.monker) || [],
            // employeeList: entity.employees.map(item => item.employee) || [],
            // stakeList: entity.viewers.map(item => item.employee) || []
         };
      case Actions.FETCH_EVENTS:
         const events = (action.payload || []).map(event => ({
            ...event,
            start: pack.dateFormat(event.start),
            end: pack.dateFormat(event.end),
         }));
         return {
            ...state,
            events,
            eventLoadCnt: state.eventLoadCnt + 1,
         };
      case Actions.FETCH_COMPANYS:
         return {
            ...state,
            companyList: action.payload,
         };
      case Actions.FETCH_SELLERS:
         return {
            ...state,
            sellerTargetTree: action.tree,
            sellerTargetList: action.list,
         };
      case Actions.FETCH_PRODUCTS:
         return {
            ...state,
            productList: action.payload,
         };
      case Actions.TOGGLE_LIST_TAB:
         return {
            ...state,
            listTab: action.tab,
         };
      case Actions.TOGGLE_PMO_GRID_TYPE:
         return {
            ...state,
            pmoGridType: action.gridType,
         };
      case Actions.TOGGLE_WBS_ZOOM:
         return {
            ...state,
            zoom: action.zoom,
         };
      case Actions.TOGGLE_CLIENT_RIGHT_BAR:
         return {
            ...state,
            openClientDw: action.open,
         };
      case Actions.TOGGLE_SELLER_RIGHT_BAR:
         return {
            ...state,
            openSellerDw: action.open,
         };
      case Actions.TOGGLE_MONKER_RIGHT_BAR:
         return {
            ...state,
            openMonkerDw: action.open,
         };
      case Actions.TOGGLE_EMPLOYEE_RIGHT_BAR:
         return {
            ...state,
            openEmployeeDw: action.open,
         };
      case Actions.TOGGLE_STAKE_RIGHT_BAR:
         return {
            ...state,
            openStakeDw: action.open,
         };
      case Actions.OPEN_ADD:
         return {
            ...state,
            entity: initState.entity,
            company: initState.company,
            firm: initState.firm,
            monk: initState.monk,
            clientList: initState.clientList,
            sellerList: initState.sellerList,
            monkerList: initState.monkerList,
            employeeList: initState.employeeList,
            stakeList: initState.stakeList,
         };
      /*case Actions.OPEN_VIEW:
      case Actions.OPEN_EDIT:
         const entity = action.data;
         return {
            ...state,
            entity,
            entityLoadCnt: state.entityLoadCnt + 1,
            company: entity.company || {},
            firm: entity.firm || {},
            clientList: entity.clients.map(item => item.client) || [],
            sellerList: entity.sellers.map(item => item.seller) || [],
            employeeList: entity.employees.map(item => item.employee) || [],
            stakeList: entity.viewers.map(item => item.employee) || []
         };*/
      case Actions.SELECT_COMPANY:
         return {
            ...state,
            company: action.value,
         };
      case Actions.SELECT_CLIENTS:
         return {
            ...state,
            clientList: (state.company.clients || []).filter(client => action.seqs.includes(client.seq)),
         };
      /*case Actions.SELECT_EMPLOYEES:
         return {
            ...state,
            employeeList: state.totalEmployees.filter(emp => action.selected.includes(emp.empno))
         };
      case Actions.SELECT_STAKES:
         return {
            ...state,
            stakeList: state.totalEmployees.filter(emp => action.selected.includes(emp.empno))
         };*/
      case Actions.SELECT_FIRM_AND_SELLERS:
         return {
            ...state,
            firm: action.firm,
            sellerList: action.sellers,
         };
      case Actions.SELECT_MONK_AND_MONKERS:
         return {
            ...state,
            monk: action.firm,
            monkerList: action.sellers,
         };
      case Actions.CREATE_TASK:
         const saved = action.payload;
         const idx = _.findIndex(state.entity.tasks, { seq: saved.seq });
         state.entity.tasks.splice(idx, 1, saved);

         return {
            ...state,
         };
      case Actions.CREATE_LINK:
         const savedLink = action.payload;
         let link = _.find(state.entity.links, { seq: savedLink.seq });
         if (!link) {
            state.entity.links.push(savedLink);
         } else {
            link = savedLink;
         }
         return {
            ...state,
         };
      case Actions.REMOVE_TASK:
         state.entity.tasks.forEach((item, idx) => {
            if (item.seq === action.seq) {
               state.entity.tasks.splice(idx, 1);
               return false;
            }
         });
         return {
            ...state,
         };
      case Actions.REMOVE_LINK:
         state.entity.links.forEach((item, idx) => {
            if (item.seq === action.seq) {
               state.entity.links.splice(idx, 1);
               return false;
            }
         });
         return {
            ...state,
         };
      default:
         return state;
   }
};

export default projectReducer;
