import axios from 'axios';
import {pack} from "@gc";

const jtf = require('json-form-data');

export const FETCH_FIRMS = '[FIRM] FETCH FIRMS';
export const FETCH_FIRM = '[FIRM] FETCH FIRM';
export const OPEN_ADD = '[FIRM] OPEN ADD';
export const OPEN_EDIT = '[FIRM] OPEN EDIT';
export const OPEN_VIEW = '[FIRM] OPEN VIEW';
export const SELECT_FIRM = '[FIRM] SELECT FIRM';
export const SAVE_ENTITY = '[FIRM] SAVE ENTITY';
export const REMOVE_ENTITY = '[FIRM] REMOVE ENTITY';

export function fetchFirms(props, seq) {
   return (dispatch, getState) => {
      const request = axios.get(`/firm?lang=${getState().gc.common.lang}`);
      request.then(res => {
         dispatch({
            type: FETCH_FIRMS,
            tree: res.data.tree,
            list: res.data.list,
         });
         if(!seq && props && Boolean(props.match.params.seq)) {
            dispatch(fetchFirm(props.match.params.seq));
         } else if (Boolean(seq)) {
            dispatch(fetchFirm(seq));
         }
      });
   };
}

export function fetchFirm(seq) {
   return {
      type: FETCH_FIRM,
      seq
   };
}

export function openAdd({match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_ADD
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'add'
      }));
   };
}

export function openEdit(data, {match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_EDIT,
         data
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'edit'
      }));
   };
}

export function openView(seq, {match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_VIEW,
         seq
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         seq
      }));
   };
}

export function removeEntity(seq, {match, history}) {
   return (dispatch, getState) =>
      axios.delete(`/firm/${seq || getState().griffin.manage.firm.entity.seq}`)
         .then(res => {
            dispatch(fetchFirms());
            dispatch({
               type: REMOVE_ENTITY
            });
            history.push(pack.getToPath(match)({
               ...match.params,
               mode: 'view',
               seq: null
            }));
         });
}

export function createEntity(data, {match, history}, mode, reload) {
   return (dispatch, getState) =>
      axios({
         method: 'post',
         url: '/firm',
         data: jtf({...data, id: getState().gc.user.id})
      }).then(res => {
         dispatch({
            type: SAVE_ENTITY,
            payload: res.data
         });

         if (reload)
            dispatch(fetchFirms());

         history.push(pack.getToPath(match)({
            ...match.params,
            mode,
            seq: res.data.seq
         }));
      });
}

export function moveTreeIn(dropKey, dragKey) {
   return (dispatch) => {
      axios.put(`/firm/tree-in/${dropKey}/${dragKey}`)
         .then(res =>
            dispatch(fetchFirms())
         );
   }
}

export function moveTreeAfter(dropKey, dragKey) {
   return (dispatch) =>
      axios.put(`/firm/tree-after/${dropKey}/${dragKey}`)
         .then(res =>
            dispatch(fetchFirms())
         );
}

export function moveTreeBefore(dropKey, dragKey) {
   return (dispatch) =>
      axios.put(`/firm/tree-before/${dropKey}/${dragKey}`)
         .then(res =>
            dispatch(fetchFirms())
         );
}
