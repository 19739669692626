import React, { Component } from 'react';
import { logoutUser } from '../auth/actions/user.action';
import { connect } from 'react-redux';
import { swal } from '../../@griffin/common/components/swal/actions';
import { pack } from '@gc';
import BgSound from '../../assets/utils/sound/timeout.mp3';
import LogoutSound from '../../assets/utils/sound/bird.wav';

const withTimeout = time => WrappedComponent => {
   class AutoLogout extends Component {
      constructor(props) {
         super(props);
         this.state = {
            warningTime: 1000 * 60 * time - 1000 * 60,
            signoutTime: 1000 * 60 * time,
            strings: props.strings,
            timeoutSoundInterval: null,
         };
         this.warn = this.warn.bind(this);
         this.startTimeMessage = this.startTimeMessage.bind(this);
         this.run = this.run.bind(this);
      }

      componentDidUpdate(prevProps) {
         if (prevProps.lang !== this.props.lang) {
            this.setState({
               strings: this.props.strings,
            });
         }
         if (prevProps.user.role !== this.props.user.role) {
            if (this.props.role !== 'guest') {
               this.run();
            } else {
               this.clearTimeoutFunc();
            }
         }

         if (prevProps.timeoutEnable !== this.props.timeoutEnable) {
            if (this.props.timeoutEnable) {
               this.run();
            } else {
               this.clearTimeoutFunc();
            }
         }
      }

      run() {
         if (this.props.user.role !== 'guest' && this.props.timeoutEnable) {
            this.setTimeout();
         }
      }

      componentDidMount() {
         this.events = [
            'load',
            // 'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress',
         ];

         for (let i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
         }

         this.run();
      }

      clearTimeoutFunc = () => {
         if (this.warnTimeout) clearTimeout(this.warnTimeout);
         if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
         if (this.remainTimeout) clearInterval(this.remainTimeout);
         if (this.state.timeoutSoundInterval) clearInterval(this.state.timeoutSoundInterval);
         this.setState({
            timeoutSoundInterval: null,
         });
      };

      setTimeout = () => {
         this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
         this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
      };

      resetTimeout = () => {
         this.clearTimeoutFunc();
         this.run();
      };

      warn = () => {
         const { strings } = this.state;
         const time = this.startTimeMessage();
         const soundElem = document.getElementById('bg-sound');
         soundElem.setAttribute('src', BgSound);
         soundElem.play();

         if (!this.state.timeoutSoundInterval) {
            this.setState({
               timeoutSoundInterval: setInterval(() => soundElem.play(), 1000),
            });
         }

         const opt = {
            ...pack.options.swal,
            type: 'warning', // ["warning","error","success","info","input"]
            title: strings.timeoutTitle,
            text: strings.timeoutMsg.replace(/#1/, time),
            confirmButtonText: strings.timeoutExtend,
            cancelButtonText: strings.logout,
            closeOnConfirm: true,
            timer: time * 1000,
            onConfirm: e => this.resetTimeout(),
            onCancel: e => {
               this.clearTimeoutFunc();
               this.logout();
            },
         };
         this.props.swal(opt);
      };

      startTimeMessage() {
         const { strings } = this.state;
         let time = (this.state.signoutTime - this.state.warningTime) / 1000;
         this.remainTimeout = setInterval(() => {
            const elem = document.getElementById('swal2-content');
            if (time > 0) {
               time -= 1;
               if (elem instanceof Element) elem.innerHTML = strings.timeoutMsg.replace(/#1/, time);
            }
         }, 1000);
         return time;
      }

      logout = () => {
         const soundElem = document.getElementById('bg-sound');
         soundElem.setAttribute('src', LogoutSound);
         soundElem.play();
         this.props.logoutUser();
      };

      render() {
         return <WrappedComponent {...this.props} />;
      }
   }

   function mapSttToProps({ gc }) {
      return {
         strings: gc.common.strings,
         user: gc.user,
         timeoutEnable: gc.common.timeoutEnable,
      };
   }

   return connect(mapSttToProps, { swal, logoutUser })(AutoLogout);
};

export default withTimeout;
