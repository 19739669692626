import axios from 'axios';
import { pack } from '../../../../../@gc';
import { toastr } from 'react-redux-toastr';
import { entityToEvent } from '../../common';

export const SET_TASK_ENTITY = '[TMS] SET TASK ENTITY';
export const LOADING_TASKS = '[TMS] LOADING TASKS';
export const SAVING_TASK = '[TMS] SAVING TASK';
export const REFRESH_TASK = '[TMS] REFRESH TASK';
export const FETCH_SIMPLE_ACTIVITIES = '[TMS] FETCH SIMPLE ACTIVITIES';
export const FETCH_TASKS = '[TMS] FETCH TASKS';
export const TAKE_TASK = '[TMS] TAKE TASK';
export const UNTAKE_TASK = '[TMS] UNTAKE TASK';
export const TASK_SAVED = '[TMS] TASK SAVED';
export const TASK_REMOVED = '[TMS] TASK REMOVED';

export function fetchSimpleActivities() {
   return (dispatch, getState) => {
      const { user } = getState().gc;

      axios
         .get(`/tms/simple/activities/${user.empno}`)
         .then(res =>
            dispatch({
               type: FETCH_SIMPLE_ACTIVITIES,
               payload: res.data,
            }),
         )
         .catch(pack.showError);
   };
}

export function fetchTasks(year, notLoading) {
   return (dispatch, getState) => {
      const { user } = getState().gc;

      if (!notLoading)
         dispatch({
            type: LOADING_TASKS,
            flag: true,
         });

      axios
         .get(`/tms/task/events/${user.empno}/${year}`)
         .then(response => {
            const events = response.data.map(entity => entityToEvent(entity));
            dispatch({
               type: FETCH_TASKS,
               payload: events,
            });
         })
         .catch(pack.showError)
         .finally(() =>
            dispatch({
               type: LOADING_TASKS,
               flag: false,
            }),
         );
   };
}

export function setTaskEntity(entity) {
   return {
      type: SET_TASK_ENTITY,
      entity,
   };
}

export function saveTask(model) {
   return (dispatch, getState) => {
      const isUpdate = Boolean(model.id);
      const {
         user,
         common: { strings },
      } = getState().gc;

      dispatch({
         type: SAVING_TASK,
         flag: true,
      });

      axios
         .post(`/tms/task/${user.empno}`, JSON.stringify(model))
         .then(res => {
            toastr.success(strings.infoTitle, strings.successMessage);
            dispatch({
               type: TASK_SAVED,
               payload: entityToEvent(res.data),
               isUpdate,
            });
         })
         .catch(pack.showError)
         .finally(() =>
            dispatch({
               type: SAVING_TASK,
               flag: false,
            }),
         );
   };
}

export function removeTask(id) {
   return (dispatch, getState) => {
      const { strings } = getState().gc.common;

      axios
         .delete(`/tms/task/${id}`)
         .then(res => {
            toastr.success(strings.infoTitle, strings.successMessage);
            dispatch({
               type: TASK_REMOVED,
               id,
            });
         })
         .catch(pack.showError);
   };
}
