import {setUserData} from "../../../@gc/auth/actions/user.action";
import _ from 'lodash';
import axios from 'axios';

const jtf = require('json-form-data');

export const SET_ENABLE_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_ENABLE_BACKGROUND_IMAGE';

export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU';
export const SET_ENABLE_MOBILE_MENU_SMALL = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU_SMALL';

export const SET_ENABLE_FIXED_HEADER = 'THEME_OPTIONS/SET_ENABLE_FIXED_HEADER';
export const SET_ENABLE_HEADER_SHADOW = 'THEME_OPTIONS/SET_ENABLE_HEADER_SHADOW';
export const SET_ENABLE_SIDEBAR_SHADOW = 'THEME_OPTIONS/SET_ENABLE_SIDEBAR_SHADOW';
export const SET_ENABLE_FIXED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_FIXED_SIDEBAR';
export const SET_ENABLE_CLOSED_SIDEBAR = 'THEME_OPTIONS/SET_ENABLE_CLOSED_SIDEBAR';
export const SET_ENABLE_FIXED_FOOTER = 'THEME_OPTIONS/SET_ENABLE_FIXED_FOOTER';

export const SET_ENABLE_PAGETITLE_ICON = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_ICON';
export const SET_ENABLE_PAGETITLE_SUBHEADING = 'THEME_OPTIONS/SET_ENABLE_PAGETITLE_SUBHEADING';
export const SET_ENABLE_PAGE_TABS_ALT = 'THEME_OPTIONS/SET_ENABLE_PAGE_TABS_ALT';

export const SET_BACKGROUND_IMAGE = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE';
export const SET_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_BACKGROUND_COLOR';
export const SET_COLOR_SCHEME = 'THEME_OPTIONS/SET_COLOR_SCHEME';
export const SET_BACKGROUND_IMAGE_OPACITY = 'THEME_OPTIONS/SET_BACKGROUND_IMAGE_OPACITY';

export const SET_HEADER_BACKGROUND_COLOR = 'THEME_OPTIONS/SET_HEADER_BACKGROUND_COLOR';


const saveSettings = (id, settings) =>
   axios({
      method: 'post',
      url: `/setting/${id}`,
      data: jtf(settings)
   });

export const setEnableBackgroundImage = enableBackgroundImage => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enableBackgroundImage}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setEnableFixedHeader = enableFixedHeader => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enableFixedHeader}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setShowFilter = showFilter => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, showFilter}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   }
}

export const setEnableHeaderShadow = enableHeaderShadow => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enableHeaderShadow}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setEnableSidebarShadow = enableSidebarShadow => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enableSidebarShadow}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setEnablePageTitleIcon = enablePageTitleIcon => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enablePageTitleIcon}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setEnablePageTitleSubheading = enablePageTitleSubheading => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enablePageTitleSubheading}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setEnablePageTabsAlt = enablePageTabsAlt => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enablePageTabsAlt}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setEnableFixedSidebar = enableFixedSidebar => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enableFixedSidebar}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setEnableClosedSidebar = enableClosedSidebar => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enableClosedSidebar}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setEnableMobileMenu = enableMobileMenu => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enableMobileMenu}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setEnableMobileMenuSmall = enableMobileMenuSmall => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enableMobileMenuSmall}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setEnableFixedFooter = enableFixedFooter => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, enableFixedFooter}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setBackgroundColor = backgroundColor => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, backgroundColor}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setHeaderBackgroundColor = headerBackgroundColor => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, headerBackgroundColor}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setColorScheme = colorScheme => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, colorScheme}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setBackgroundImageOpacity = backgroundImageOpacity => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, backgroundImageOpacity}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};

export const setBackgroundImage = backgroundImage => {
   return (dispatch, getState) => {
      const oldUser = getState().gc.user;
      const user = _.merge({}, oldUser, {settings: {...oldUser.settings, backgroundImage}});
      saveSettings(user.id, user.settings);
      dispatch(setUserData(user));
   };
};
