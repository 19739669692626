import * as Actions from '../actions';
import { pack } from '@gc';

const initState = {
   entities: [],
   holidays: [],
   taskplan: {
      seq: '',
      title: '',
   },
   treeOpen: false,
   entity: {
      seq: '',
      date: '',
      startTime: '',
      finishTime: '',
      startIp: '',
      finishIp: '',
      employee: {
         member: {},
      },
   },
   myEntity: {
      employee: {
         member: {},
      },
   },
   employee: {
      member: {},
   },
   baseDay: pack.dateToString(new Date(), 'yyyyMMdd'),
   stampable: true,
   targetList: [],
   targetTree: [],
};

const commuteReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_COMMUTES:
         const list = action.payload
            .filter(event => Boolean(event.start))
            .map(event => {
               let time = event.title.replace(/[^0-9]/g, '');
               if (time.length === 4) {
                  time = time.substring(0, 2) + ':' + time.substring(2);
               }
               const day = pack.stringToDate(event.start).getDay();
               const isStamped = event.title.indexOf('#start#') > -1 || event.title.indexOf('#finish#') > -1;
               return {
                  ...event,
                  commute: true,
                  checked: isStamped,
                  start: (day !== 6 && day !== 0) || isStamped ? new Date(pack.dateFormat(event.start)) : event.start,
                  end: (day !== 6 && day !== 0) || isStamped ? new Date(pack.dateFormat(event.end)) : event.end,
                  title:
                     event.title
                        .replace(/#start#/g, pack.strings.cmmStarting + '  ')
                        .replace(/#finish#/g, pack.strings.cmmFinishing + '  ')
                        .replace(/#nostart#/g, day === 0 || day === 6 ? '' : pack.strings.cmmNotStart)
                        .replace(/#nofinish#/g, pack.strings.cmmNotFinish)
                        .replace(/[0-9]/g, '') + time,
               };
            });
         return {
            ...state,
            entities: list,
         };
      case Actions.GET_GOOGLE_EVENTS:
         const arr = action.payload.map(event => ({
            title: event.summary,
            start: event.start.date, //pack.dateToString(start, 'yyyy-MM-dd'),
            end: event.end.date, //start >= end ? null : pack.dateToString(end, 'yyyy-MM-dd'),
            allDay: true,
            commute: false,
            checked: false,
            backgroundColor: '#f44336',
         }));
         return {
            ...state,
            holidays: arr,
            entities: [...state.entities, ...arr],
            eventLoadCnt: state.eventLoadCnt + 1,
         };
      case Actions.FETCH_COMMUTE:
         const stampable = +action.day <= +pack.dateToString(new Date(), 'yyyyMMdd');
         return {
            ...state,
            entity: action.payload || initState.entity,
            baseDay: action.day,
            myEntity: action.myEntity || state.myEntity,
            stampable,
         };
      case Actions.FETCH_EMPLOYEE:
         return {
            ...state,
            employee: action.payload,
         };
      case Actions.START_WORK:
         return {
            ...state,
            myEntity: action.payload,
            entity: action.payload,
         };
      case Actions.FINISH_WORK:
         return {
            ...state,
            myEntity: action.payload,
            entity: action.payload,
         };
      case Actions.TOGGLE_TREE:
         return {
            ...state,
            treeOpen: action.open,
         };
      case Actions.FETCH_TARGET_LIST:
         return {
            ...state,
            targetTree: action.tree,
            targetList: action.list,
         };
      default:
         return state;
   }
};

export default commuteReducer;
