import * as Actions from "../actions";
import _ from 'lodash';

const initState = {
   entities: [],
   folderTree: [],
   folderList: [],
   targetTree: [],
   targetList: [],
   folder: {
      seq: '',
      name: '',
      pname: '',
      operator: 'or',
      positions: [],
      employees: []
   },
   positions: [],
   checkedTargetKeys: [],
   folderLoadCnt: 0,
   foldersLoadCnt: 0,
   fileDialogOpen: false,
   file: {
      seq: '',
      filename: '',
      savename: '',
      filesize: 0,
      candown: '1',
      employee: {},
      downloads: []
   },
};

const pdsReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_FOLDERS:
         return {
            ...state,
            folderTree: action.tree,
            folderList: action.list,
            foldersLoadCnt: state.foldersLoadCnt + 1
         };
      case Actions.FETCH_TARGET_LIST:
         return {
            ...state,
            targetTree: action.tree,
            targetList: action.list
         };
      case Actions.FETCH_FOLDER:
         return {
            ...state,
            folder: { ...initState.folder, ...action.payload },
            folderLoadCnt: state.folderLoadCnt + 1,
            file: initState.file
         };
      case Actions.FETCH_FILES:
         return {
            ...state,
            entities: action.payload
         };
      case Actions.FETCH_POSITIONS:
         return {
            ...state,
            positions: action.payload
         };
      case Actions.ON_TARGET_CHECKED:
         return {
            ...state,
            checkedTargetKeys: action.checkedKeys
         };
      case Actions.OPEN_FILE_ADD:
         return {
            ...state,
            fileDialogOpen: true
         };
      case Actions.CLOSE_FILE_DIALOG:
         return {
            ...state,
            fileDialogOpen: false
         };
      case Actions.SELECT_FILE:
         return {
            ...state,
            file: action.file
         };
      case Actions.UPDATE_FILE:
         const idx = _.findIndex(state.entities, {seq: action.file.seq});
         state.entities.splice(idx, 1, action.file);

         return {
            ...state,
            file: action.file,
         };
      case Actions.UPDATE_FOLDER_TREE:
         return {
            ...state,
            folderTree: action.tree
         };
      case Actions.REMOVE_FILE:
         return {
            ...state,
            file: initState.file
         };
      case Actions.UPDATE_CHECKED_TARGETS:
         return {
            ...state,
            checkedTargetKeys: action.checkedKeys
         };
      default:
         return state;
   }
};

export default pdsReducer;