import * as Actions from '../actions';
import _ from 'lodash';
import { REMOVE_CODE } from '../actions';

const initState = {
   openCodeDialog: false,
   treeSource: [],
   treeMap: null,
   codes: [],
   groups: [],
   code: {
      seq: 0,
      codeGroup: '',
      codeValue: '',
      codeDesc: '',
      sort: 0,
      menus: [],
   },
   menu: {
      // 트리에서 선택한 노드
      seq: null,
      id: '',
      title: '',
      pid: '',
      isLeaf: false,
      menus: [],
   },
   menuSelectTree: [],
   listLoadCnt: 0,
};

const codeReducer = (state = initState, action) => {
   let code = null;
   switch (action.type) {
      case Actions.FETCH_CODE_TREE:
         return {
            ...state,
            treeSource: action.tree,
            treeMap: _.keyBy(action.list, 'id'),
            groups: action.list.filter(item => item.isLeaf),
         };
      case Actions.SELECT_CODE:
         code = action.payload;
         return {
            ...state,
            menu: {
               ...code,
               id: code.codeValue,
               title: code.codeDesc,
            },
         };
      case Actions.FETCH_CODES:
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
            codes: action.payload,
         };
      case Actions.FETCH_MENUSELECT_TREE:
         return {
            ...state,
            menuSelectTree: action.payload,
         };
      case Actions.UPDATE_CODE_SORT:
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
            codes: action.payload,
         };
      case Actions.OPEN_CODE_DIALOG:
         return {
            ...state,
            openCodeDialog: true,
         };
      case Actions.CLOSE_CODE_DIALOG:
         return {
            ...state,
            openCodeDialog: false,
         };
      case Actions.CREATE_CODE:
         return {
            ...state,
            expanded: action.payload,
         };
      case Actions.UPDATE_CODE:
         code = action.payload;
         return {
            ...state,
            codes: {
               ...state.codes,
               [code.seq]: code,
            },
            listLoadCnt: state.listLoadCnt + 1,
            expanded: action.expanded,
         };
      case REMOVE_CODE:
         if (action.isGroup) {
            return {
               ...state,
               menu: initState.menu,
               codes: initState.codes,
               listLoadCnt: state.listLoadCnt + 1,
            };
         } else {
            _.remove(state.codes, it => it.seq === action.seq);
            return {
               ...state,
            };
         }
      default:
         return state;
   }
};

export default codeReducer;
