import * as Actions from '../actions';

const initState = {
   rules: [],
   period: 0
};

const pwdruleReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_RULES:
         return {
            ...state,
            rules: action.payload.rules,
            period: action.payload.period
         };
      default:
         return state;
   }
};

export default pwdruleReducer;