import * as Actions from '../actions';
import _ from 'lodash';
import { pack } from '@gc';

const defaultPagingParam = {
   page: 0,
   size: pack.defaultPageSize,
   sorted: [{ id: 'seq', desc: true }],
   filtered: [],
};

const initState = {
   entities: {},
   list: [],
   pages: 0,
   searchText: '',
   selectedSeqs: [],
   // mode: 'list',
   listType: Actions.TOGGLE_BOARDS_LIST_ALL,
   entity: {
      boardsId: {},
      title: '',
      category: '',
      categoryDesc: '',
      content: '',
      employee: {
         member: {},
      },
      uploads: [],
      filenames: [],
      savenames: [],
      likeType: null,
      pointScore: 0,
      goodCnt: 0,
      badCnt: 0,
      isnotice: '0',
   },
   category: {
      id: '',
      pid: '0',
      title: pack.servermsg()['board.category.root'],
      ptitle: '',
   },
   viewCategory: {
      id: '',
      pid: '0',
      title: pack.servermsg()['board.category.root'],
      ptitle: '',
   },
   listLoadCnt: 0,
   treeLoadCnt: 0,
   treeSource: [],
   treeMap: {},
   treeSelect: [],
   newEntity: {},
   comments: [],
   defaultPagingParam,
   pagingParam: _.cloneDeep(defaultPagingParam),
};

let entity = null;

const boardsReducer = function (state = initState, action) {
   switch (action.type) {
      case Actions.FETCH_BOARDS:
         return {
            ...state,
            entities: _.keyBy(action.payload.list, 'boardsId.seq'),
            list: action.payload.list,
            pages: action.payload.pages,
         };
      case Actions.FETCH_BOARD:
         return {
            ...state,
            entity: action.payload,
         };
      case Actions.UPDATE_PAGING_PARAM:
         return {
            ...state,
            pagingParam: action.param,
         };
      case Actions.TOGGLE_BOARDS_LIST:
         return {
            ...state,
            listType: action.listType,
         };
      case Actions.FETCH_CATEGORY_TREE:
         return {
            ...state,
            treeSource: action.tree,
            treeMap: _.keyBy(action.list, 'id'),
            treeSelect: (action.select || []).map(item => ({
               ...item,
               key: item.id,
               value: item.id,
            })),
            treeLoadCnt: state.treeLoadCnt + 1,
         };
      case Actions.REMOVE_BOARDS:
         delete state.entities[action.seq];
         return {
            ...state,
         };
      case Actions.UPDATE_CATEGORY:
         return {
            ...state,
            category: action.payload,
         };
      case Actions.SELECT_CATEGORY:
         return {
            ...state,
            category: action.payload || { ...initState.category },
            viewCategory: action.payload || state.viewCategory,
            mode: state.mode === 'view' ? 'list' : state.mode,
         };
      case Actions.OPEN_BOARDS_ADD:
         return {
            ...state,
            entity: _.cloneDeep(initState.entity),
            viewCategory: state.category,
         };
      case Actions.OPEN_BOARDS_VIEW:
         return {
            ...state,
            entity: action.payload || state.entity,
            viewCategory: action.category || state.viewCategory,
         };
      case Actions.BOARDS_UPDATE_VIEW:
         return {
            ...state,
            entity: {
               ...state.entity,
               likeType: action.payload.likeType,
            },
         };
      case Actions.SELECT_ALL_POSTS:
         const arr = Object.keys(state.entities).map(k => state.entities[k]);

         const selectedSeqs = arr.map(post => post.boardsId.seq);

         return {
            ...state,
            selectedSeqs: selectedSeqs,
         };
      case Actions.DESELECT_ALL_POSTS:
         return {
            ...state,
            selectedSeqs: [],
         };
      case Actions.TOGGLE_BOARDS_STARRED:
         const { seq, newStarred } = action;
         const data = state.entities[seq];
         if (data) {
            data.starred = newStarred;
         }
         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.TOGGLE_BOARDS_STARRED_LIST:
         action.seqs.forEach(seq => {
            const data = state.entities[seq];
            if (data) {
               data.starred = action.starred;
            }
         });

         return {
            ...state,
            listLoadCnt: state.listLoadCnt + 1,
         };
      case Actions.TOGGLE_IN_SELECTED_POSTS:
         const postSeq = action.postSeq;
         // let data = state[postSeq];
         // if (data) {
         //    data.starred = data.starred === '1' ? '0' : '1';
         // }
         let seqs = [...state.selectedSeqs];

         if (seqs.find(id => id === postSeq) !== undefined) {
            seqs = seqs.filter(id => id !== postSeq);
         } else {
            seqs = [...seqs, postSeq];
         }

         return {
            ...state,
            selectedSeqs: seqs,
         };
      case Actions.CONFIRM_BOARDS_POINT:
         return {
            ...state,
            entity: {
               ...state.entity,
               pointSignStatus: '1',
            },
         };
      case Actions.UPDATE_COMMENT:
         entity = action.payload;
         return {
            ...state,
            entity,
            entities: {
               ...state.entities,
               [entity.boardsId.seq]: entity,
            },
         };
      default:
         return state;
   }
};

export default boardsReducer;
