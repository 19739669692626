import { combineReducers } from "redux";
import activity from "../activity/reducers";
import admin from "../admin/reducers";
import manage from "../manage/reducers";
import login from "../login/reducers";
import common from "../common/reducers";
import { USER_LOGGED_OUT } from "../../@gc/auth/actions/user.action";

const griffinTempReducer = combineReducers({
  activity,
  admin,
  manage,
  login,
  common,
});

// 로그아웃 시 "griffin" reducer 데이터 초기화 처리
const griffinReducer = (state, action) => {
  if (action.type === USER_LOGGED_OUT) {
    return griffinTempReducer(undefined, action);
  }
  return griffinTempReducer(state, action);
};

export default griffinReducer;
