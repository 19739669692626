import axios from 'axios';
import {pack} from '@gc';
import {startLoading, stopLoading} from "../../../../@gc/common/actions/common.action";

const jtf = require('json-form-data');

export const FETCH_OUTSOURCES = '[OUTSOURCE] FETCH OUTSOURCES';
export const FETCH_OUTSOURCE = '[OUTSOURCE] FETCH OUTSOURCE';
export const OPEN_ADD = '[OUTSOURCE] OPEN ADD';
export const REMOVE_ENTITY = '[OUTSOURCE] REMOVE ENTITY';

export function fetchOutsources(loading, props) {
   return (dispatch, getState) => {
      if (loading) {
         dispatch(startLoading());
      }

      axios.get(`/outsource/list`)
         .then(res => {
            dispatch({
               type: FETCH_OUTSOURCES,
               payload: res.data
            });

            dispatch(stopLoading());

            if (props && Boolean(props.match.params.seq)) {
               dispatch(fetchEntity(props.match.params.seq));
            }
         });
   };
}

export function fetchEntity(seq) {
   return {
      type: FETCH_OUTSOURCE,
      seq
   };
}

export function openAdd({match, history}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_ADD
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'add',
         seq: null
      }));
   };
}

export function openView(seq, {match, history}) {
   return (dispatch) => {
      dispatch(fetchEntity(seq));
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         seq
      }));
   };
}

export function createEntity(data, {match, history}, onError) {
   return (dispatch, getState) => {
      axios.post('/outsource', jtf({
         ...data,
         id: getState().gc.user.id
      })).then(res => {
         if (res.status === 200) {
            dispatch(fetchOutsources());
            history.goBack();
         } else {
            onError(res);
         }
      }).catch(e => onError(e));
   };
}

export function removeEntity(seq, {match, history}, onError) {
   return (dispatch, getState) => {
      axios.delete(`/outsource/${seq}/${getState().gc.user.id}`)
         .then(res => {
            if (res.status === 200) {
               dispatch({
                  type: REMOVE_ENTITY,
                  seq
               });
               history.push(pack.getToPath(match)({
                  ...match.params,
                  mode: 'list',
                  seq: null
               }));
            } else {
               onError(res);
            }
         }).catch(e => onError(e));
   };
}