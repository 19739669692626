import _ from 'lodash';
import { LOADING_PROJECTS, PROJECT_EVENTS, PROJECT_REMOVED, PROJECT_SAVED, REFRESH_PROJECT, SAVING_PROJECT, SET_PROJECT_ENTITY } from '../actions';

const initState = {
   projects: [],
   loadingProject: false,
   savingProject: false,
   refreshProject: false, // Refresh Calendar Events
   project: {
      startdate: null,
      enddate: null,
      activities: [],
      files: [],
      refs: [],
   },
};

const tmsprojectReducer = (state = initState, action) => {
   switch (action.type) {
      case PROJECT_EVENTS:
         return {
            ...state,
            projects: action.events,
         };
      case REFRESH_PROJECT:
         return {
            ...state,
            refreshProject: action.flag,
         };
      case SET_PROJECT_ENTITY:
         return {
            ...state,
            project: action.entity || _.cloneDeep(initState.project),
         };
      case LOADING_PROJECTS:
         return {
            ...state,
            loadingProject: action.flag,
         };
      case SAVING_PROJECT:
         return {
            ...state,
            savingProject: action.flag,
         };
      case PROJECT_SAVED:
         return {
            ...state,
            projects: action.isUpdate
               ? state.projects.map(prj => {
                    if (prj.id === action.payload.id) {
                       return action.payload;
                    }
                    return prj;
                 })
               : state.projects.concat(action.payload),
            refreshProject: true,
         };
      case PROJECT_REMOVED:
         return {
            ...state,
            projects: state.projects.filter(prj => prj.id !== action.id),
            refreshProject: true,
         };
      default:
         return state;
   }
};

export default tmsprojectReducer;
