import axios from 'axios';
import { UPDATE_INFOS } from './index';

export const OPEN_PROFILE_DIALOG = '[PROFILE] OPEN DIALOG';
export const CLOSE_PROFILE_DIALOG = '[PROFILE] CLOSE DIALOG';
export const FETCH_ENTITY = '[PROFILE] FETCH ENTITY';
export const UPDATE_PROFILE_INFOS = '[PROFILE] UPDATE PROFILE INFOS';

export function openProfileDialog() {
   return (dispatch, getState) => {
      dispatch(fetchEntity(getState().gc.user.empno));
      dispatch({
         type: OPEN_PROFILE_DIALOG,
      });
   };
}

export function fetchEntity(empno) {
   return (dispatch, getState) => {
      axios.get(`/emp/${empno}`).then(res =>
         dispatch({
            type: FETCH_ENTITY,
            payload: res.data,
         }),
      );
   };
}

export function closeProfileDialog() {
   return {
      type: CLOSE_PROFILE_DIALOG,
   };
}

export function updateProfileInfos(key, infos, save) {
   return dispatch => {
      dispatch({
         type: UPDATE_PROFILE_INFOS,
         key,
         save,
         payload: infos.map(info => {
            if (typeof info.seq === 'string') {
               info.seq = null;
            }
            return info;
         }),
      });
   };
}
