import axios from 'axios';
import {startLoading, stopLoading} from "@gc/common/actions/common.action";
import {pack} from "@gc";
import _ from 'lodash';

const jtf = require('json-form-data');

export const FETCH_TASKPLAN = '[TASK] FETCH TASKPLAN';
export const FETCH_TASK = '[TASK] FETCH TASK';
export const FETCH_TARGET_LIST = '[TASK] FETCH TARGET LIST';
export const REMOVE_TASKPLAN = '[TASK] REMOVE TASKPLAN';
export const OPEN_TASK_LIST = '[TASK] OPEN TASK LIST';
export const OPEN_TASK_ADD = '[TASK] OPEN TASK ADD';
export const OPEN_TASK_EDIT = '[TASK] OPEN TASK EDIT';
export const TASK_PLUS_YEAR = '[TASK] TASK PLUS YEAR';
export const TASK_MINUS_YEAR = '[TASK] TASK MINUS YEAR';
export const TASK_TOGGLE_VIEW = '[TASK] TASK TOGGLE VIEW';
export const UPDATE_TASK = '[TASK] UPDATE TASK';

export function fetchTasks(year, showLoading, props) {
   return (dispatch, getState) => {
      if(!showLoading)
         dispatch(stopLoading());
      else
         dispatch(startLoading());

      const request = axios.get(`/task/${year}`);

      return request.then(res => {
         dispatch(stopLoading());
         dispatch({
            type: FETCH_TASKPLAN,
            payload: res.data
         });
         if (props && Boolean(props.match.params.seq)) {
            dispatch(fetchTask(props.match.params.seq));
         }
      })
   };
}

export function fetchTask(seq) {
   return {
      type: FETCH_TASK,
      seq
   };
}

export function fetchTargetList() {
   return (dispatch, getState) => {
      axios.get(`/department/emp/tree?lang=${getState().gc.common.lang}`)
         .then(res =>
            dispatch({
               type: FETCH_TARGET_LIST,
               tree: res.data.tree,
               list: res.data.list
            })
         );
   }
}

export function removeTask(seq, {history, match}) {
   return (dispatch, getState) => {
      const request = axios.delete(`/task/${seq}`);

      return request.then(res => {
         dispatch({
            type: REMOVE_TASKPLAN,
            seq
         });
         // dispatch(fetchTasks(getState().griffin.activity.task.year));
         // dispatch(fetchTasks(getState().griffin.activity.task.year, false, {match}));
         history.push(pack.getToPath(match)({
            ...match.params,
            mode: 'list',
            seq: null
         }));
      })
   };
}

export function openTaskList() {
   return {
      type: OPEN_TASK_LIST
   };
}

export function openTaskAdd({history, match}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_TASK_ADD
      });
      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'add'
      }));
   };
}

export function openTaskView(data, {history, match}) {
   return (dispatch) => {
      dispatch({
         type: OPEN_TASK_EDIT,
         payload: data
      });

      history.push(pack.getToPath(match)({
         ...match.params,
         mode: 'view',
         seq: data.seq
      }));
   }
}

export function createTask(data, isUpdate, {history, match}) {
   const formdata = _.omit(data, 'employee', 'taskDateList', 'taskTodos', 'targetEmpnos', 'targetDepts', 'taskUsers', 'regDate', 'taskTypes');

   return (dispatch, getState) => {
      const request = axios({
         method: 'post',
         url: `/task${isUpdate ? '/update' : ''}`,
         data: jtf(_.merge(
            formdata,
            {
               todos: JSON.stringify(data.taskTodos),
               dates: JSON.stringify(data.taskDateList),
               users: JSON.stringify(data.targetEmpnos),
               depts: JSON.stringify(data.targetDepts),
               types: JSON.stringify(data.typeList),
               empno: getState().gc.user.empno
            },
         ))
      });

      return request.then(res => {
         // dispatch(fetchTasks(getState().griffin.activity.task.year, true, {match}));
         dispatch({
            type: UPDATE_TASK,
            oldSeq: data.seq,
            payload: res.data
         });
         history.push(pack.getToPath(match)({
            ...match.params,
            mode: 'list',
            seq: null
         }));
      });
   };
}

export function plusYear() {
   return {
      type: TASK_PLUS_YEAR
   };
}

export function minusYear() {
   return {
      type: TASK_MINUS_YEAR
   };
}



