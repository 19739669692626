import _ from 'lodash';
import axios from 'axios';
import { pack } from '@gc';
import { offTimeout, resetConnectInfo } from '../../common/actions/common.action';
import { toastr } from 'react-redux-toastr';
import { refreshList } from '../../../@griffin/activity/mailbox/actions';
import { useStore } from '../../../store/globalStore';

const jtf = require('json-form-data');

export const SET_USER_DATA = '[USER] SET DATA';
export const UPDATE_USER_DATA = '[USER] UPDATE DATA';
export const REMOVE_USER_DATA = '[USER] REMOVE DATA';
export const USER_LOGGED_OUT = '[USER] LOGGED OUT';
export const SET_USER_FUNC_MAP = '[USER] SET USER FUNC MAP';
export const UPDATE_USER_POINT = '[USER] UPDATE USER POINT';
export const UPDATE_USER_ROLES = '[USER] UPDATE USER ROLES';
export const INIT_USER = '[USER] INIT USER';
export const UPDATE_TRELLO_SHOWAGAIN = '[USER] UPDATE_TRELLO_SHOWAGAIN';
export const UPDATE_FIRST_MENU = '[USER] UPDATE_FIRST_MENU';
export const UPDATE_USER_MENU_TREE = '[USER] UPDATE USER MENU TREE';

export function setUserData(user) {
   return dispatch => {
      if (user.mySettings) {
         user.settings = pack.entityToSettings(user.mySettings);
      }
      // dispatch(toggleShowFilter(null, user.settings.showFilter));
      user.currentEmail = user.primaryEmail;
      dispatch({
         type: SET_USER_DATA,
         payload: user,
      });
      // dispatch(setCurrentEmail(user.primaryEmail));
   };
}

export function updateUserData(data) {
   return (dispatch, getState) => {
      const { currentEmail } = getState().gc.user;
      dispatch({
         type: UPDATE_USER_DATA,
         data,
      });
      if (Boolean(data.currentEmail) && data.currentEmail !== currentEmail) {
         setTimeout(() => {
            dispatch(refreshList());
         }, 100);
      }
   };
}

export function updateUserRoles(roles) {
   return {
      type: UPDATE_USER_ROLES,
      payload: roles,
   };
}

export function initUser() {
   return {
      type: INIT_USER,
   };
}

export function updateUserSettings(settings) {
   return (dispatch, getState) => {
      const oldUser = getState().auth.user;
      const user = _.merge({}, oldUser, { settings });
      axios({
         method: 'post',
         url: `/setting/${user.id}`,
         data: jtf(pack.settingsToEntity(user.settings)),
      });

      return dispatch(setUserData(user));
   };
}

export function removeUserData() {
   return {
      type: REMOVE_USER_DATA,
   };
}

export function logoutUser() {
   return (dispatch, getState) => {
      axios.get(`/logout`);
      useStore.getState().resetConnectInfo();
      // dispatch(resetConnectInfo());
      dispatch(offTimeout());
      dispatch({
         type: USER_LOGGED_OUT,
      });
   };
}

export function updateUserPoint() {
   return (dispatch, getState) => {
      const user = getState().gc.user;
      axios
         .get(`/point/score/${user.id}`)
         .then(res => {
            dispatch({
               type: UPDATE_USER_POINT,
               payload: res.data,
            });
         })
         .catch(e => pack.showError(e));
   };
}

/**
 * Update User Shortcuts
 */
export function updateUserShortcuts(shortcuts) {
   return (dispatch, getState) => {
      const user = getState().gc.user;
      const newUser = {
         ...user,
         shortcuts,
      };
      dispatch(setUserData(newUser));

      const request = axios({
         method: 'post',
         url: '/member-shortcuts',
         data: jtf({ id: user.id, shortcutJoin: shortcuts.join(',') }),
      });

      request.then(res => {
         if (res.status !== 200) {
            console.log('shortcut 저장 오류. response=', res);
         }
      });
   };
}

export function saveTrelloNotShowAgain() {
   return (dispatch, getState) => {
      axios.put('/employee/trello/showagain', jtf({ empno: getState().gc.user.empno })).then(res =>
         dispatch({
            type: UPDATE_TRELLO_SHOWAGAIN,
         }),
      );
   };
}

export function saveFirstMenu(menuId) {
   return (dispatch, getState) => {
      axios.post('/member/first-menu', jtf({ menuId, memberId: getState().gc.user.id })).then(res => {
         if (res.status === 200) {
            dispatch({
               type: UPDATE_FIRST_MENU,
               payload: menuId,
            });
         } else {
            const { strings } = getState().gc.common;
            toastr.error(strings.errTitle, strings.errorMessage);
         }
      });
   };
}

export function setUserMenuTree(dataFromNavigation) {
   return dispatch => {
      if (Boolean(dataFromNavigation) && dataFromNavigation.length > 0) {
         const list = [];
         dataFromNavigation.forEach(it => {
            mapTreeRecursive(it, list);
         });
         dispatch({
            type: UPDATE_USER_MENU_TREE,
            tree: dataFromNavigation,
            list,
         });
      }
   };
}

function mapTreeRecursive(item, list) {
   item.children = item.content;
   item.title = item.label;
   list.push(item);
   if (Boolean(item.content) && item.content.length > 0) {
      item.content.forEach(menu => {
         mapTreeRecursive(menu, list);
      });
   }
}
