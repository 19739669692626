import _ from 'lodash';
import {
   FETCH_SIMPLE_ACTIVITIES,
   FETCH_SIMPLE_PROJECTS,
   FETCH_TASKS,
   LOADING_ACTIVITIES,
   LOADING_PROJECTS,
   LOADING_TASKS,
   REFRESH_ACTIVITY,
   REFRESH_PROJECT,
   REFRESH_TASK,
   SAVING_ACTIVITY,
   SAVING_PROJECT,
   SAVING_TASK,
   SET_ACTIVITY_ENTITY,
   SET_PROJECT_ENTITY,
   SET_TASK_ENTITY,
   TAKE_TASK,
   TASK_REMOVED,
   TASK_SAVED,
   UNTAKE_TASK,
} from '../actions';
import { ACTIVITY_REMOVED, ACTIVITY_SAVED } from '../../activity/actions';

const initState = {
   activityInfos: [],
   tasks: [],
   loadingTask: false,
   savingTask: false,
   refreshTask: false, // Refresh Calendar Events
   task: {
      activity: {
         project: {},
      },
      files: [],
   },
};

const tmstaskReducer = (state = initState, action) => {
   switch (action.type) {
      case REFRESH_TASK:
         return {
            ...state,
            refreshTask: action.flag,
         };
      case SET_TASK_ENTITY:
         return {
            ...state,
            task: action.entity || _.cloneDeep(initState.task),
         };
      case LOADING_TASKS:
         return {
            ...state,
            loadingTask: action.flag,
         };
      case FETCH_SIMPLE_ACTIVITIES:
         return {
            ...state,
            activityInfos: action.payload,
         };
      case FETCH_TASKS:
         return {
            ...state,
            tasks: action.payload,
         };
      case SAVING_TASK:
         return {
            ...state,
            savingTask: action.flag,
         };
      case TASK_SAVED:
         return {
            ...state,
            tasks: action.isUpdate
               ? state.tasks.map(task => {
                    if (task.id === action.payload.id) {
                       return action.payload;
                    }
                    return task;
                 })
               : state.tasks.concat(action.payload),
            refreshTask: true,
         };
      case TASK_REMOVED:
         return {
            ...state,
            tasks: state.tasks.filter(task => task.id !== action.id),
            refreshTask: true,
         };
      case TAKE_TASK:
         return {
            ...state,
            tasks: state.tasks.map(t => {
               if (t.id === action.id) {
                  return { ...t, accept: '1' };
               }
               return t;
            }),
         };
      case UNTAKE_TASK:
         return {
            ...state,
            tasks: state.tasks.map(t => {
               if (t.id === action.id) {
                  return { ...t, accept: '0' };
               }
               return t;
            }),
         };
      default:
         return state;
   }
};

export default tmstaskReducer;
