import * as Actions from '../actions/setting.actions';

const initState = {
   filters: [],
   blocks: [],
   backupEntity: {},
   backups: [],
   backupFetched: false,
   backupIng: false,
   showUploadRate: false,
   uploadRate: 0,
   uploadMessage: '',
   uploadActive: false,
   folderList: [],
};

const mailsettingReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_FOLDER_LIST:
         return {
            ...state,
            folderList: action.payload,
         };
      case Actions.FETCH_FILTERS:
         return {
            ...state,
            filters: action.payload,
         };
      case Actions.TOGGLE_LOADING:
         return {
            ...state,
            loaderActive: action.flag,
         };
      case Actions.FETCH_BLOCK_LIST:
         return {
            ...state,
            blocks: action.payload,
         };
      case Actions.BACKUP_IS_ING:
         return {
            ...state,
            backupEntity: action.payload,
         };
      case Actions.FETCH_BACKUPS:
         return {
            ...state,
            backups: action.payload,
            backupFetched: true,
         };
      case Actions.TOGGLE_BACKUP_ING:
         return {
            ...state,
            backupIng: action.flag,
         };
      case Actions.SET_UPLOAD_PROGRESS_RATE:
         return {
            ...state,
            showUploadRate: Boolean(action.total) && +action.total > 0,
            uploadRate: action.rate,
            uploadMessage: action.msg,
         };
      case Actions.TOGGLE_UPLOAD_ACTIVE:
         return {
            ...state,
            uploadActive: action.flag,
            showUploadRate: !action.flag ? false : state.showUploadRate,
            uploadRate: !action.flag ? 0 : state.uploadRate,
            uploadMessage: !action.flag ? '' : state.uploadMessage,
         };
      default:
         return state;
   }
};

export default mailsettingReducer;
