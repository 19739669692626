import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Col, FormText, Label } from 'reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import cx from 'classnames';

class SelectFormsy extends Component {
   constructor(props) {
      super(props);
      this.state = {
         touched: false,
      };
   }

   componentDidMount() {
      this.animatedComponents = makeAnimated();
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.viewmode !== this.props.viewmode) {
         this.props.setValue(this.props.value);
         this.setState({
            touched: false,
         });
      }
   }

   changeValue = selectedOption => {
      const value = this.props.isMulti ? selectedOption.map(item => item.value) : selectedOption.value;
      if (this.props.maxOptions && this.props.isMulti && value.length > this.props.maxOptions) {
         return;
      }
      this.props.setValue(value);
      if (!this.state.touched) {
         this.setState({
            touched: true,
         });
      }
      if (typeof this.props.onChange === 'function') {
         this.props.onChange(selectedOption);
      }
   };

   render() {
      const { touched } = this.state;
      let importedProps = _.pick(this.props, ['name', 'isMulti', 'children']);
      let error = false;
      let errorMessage = this.props.getErrorMessage() || this.props.errorTrigger;
      const value = this.props.getValue();

      importedProps.options = Boolean(this.props.selectNoneText)
         ? [{ label: this.props.selectNoneText, value: '' }].concat(this.props.options)
         : this.props.options;

      let valueObj = this.props.isMulti ? [] : {};
      if (!this.props.isMulti && (Boolean(value) || Boolean(this.props.selectNoneText))) {
         valueObj = _.find(importedProps.options, { value });
      }
      if (this.props.isMulti && value && value.length > 0) {
         valueObj = importedProps.options.filter(option => value.includes(option.value));
      }

      if (touched && !Boolean(value) && !errorMessage && this.props.required) {
         errorMessage = this.props.requiredMsg || '';
         error = true;
      }

      const labelWidth = this.props.labelWidth || { sm: 2 };
      const colWidth = this.props.colWidth || { sm: 4 };
      const id = this.props.id || this.props.name;
      importedProps.id = id;

      importedProps.styles = {
         control: (provided, state) => ({
            ...provided,
            backgroundColor: '#fff',
            height: 30,
            minHeight: 30,
         }),

         /* 드랍다운 영역을 최상위로 */
         menuPortal: styles => ({ ...styles, zIndex: 9999 }),
         menu: styles => ({ ...styles, zIndex: 9999 }),

         input: styles => ({
            ...styles,
            margin: 0,
         }),
         indicatorsContainer: styles => ({
            ...styles,
            height: 30,
         }),
      };

      return (
         <>
            {Boolean(this.props.label) && (
               <Label {...labelWidth} for={id} className={cx({ required: !this.props.viewmode && this.props.required })}>
                  {this.props.label}
               </Label>
            )}
            <Col {...colWidth} className={cx('d-flex align-items-center', this.props.colClassName || '')}>
               {!this.props.viewmode ? (
                  <>
                     <div className={cx('d-flex flex-column', this.props.rightComponent ? 'w-min-50' : 'w-100')}>
                        <Select
                           {...importedProps}
                           value={valueObj}
                           onChange={this.changeValue}
                           className={cx('gc-form-select', Boolean(errorMessage) || error ? 'react-select-invalid' : '')}
                           components={this.animatedComponents}
                        />
                        {Boolean(errorMessage) && <FormText color="danger">{errorMessage}</FormText>}
                     </div>
                     {this.props.rightComponent != null && <div className="pane-right">{this.props.rightComponent}</div>}
                  </>
               ) : this.props.isMulti ? (
                  valueObj.map((opt, idx) => <Label className="view" key={idx}>{`${idx > 0 ? ', ' : ''}${opt.label}`}</Label>)
               ) : (
                  <Label className="view">{valueObj != null ? valueObj.label : ''}</Label>
               )}
            </Col>
         </>
      );
   }
}

SelectFormsy.propTypes = {
   id: PropTypes.string,
   name: PropTypes.string.isRequired,
   options: PropTypes.array.isRequired,
   isMulti: PropTypes.bool,
   value: PropTypes.any,
   label: PropTypes.string,
   required: PropTypes.bool,
   viewmode: PropTypes.bool,
   onChange: PropTypes.func,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   rightComponent: PropTypes.object,
   maxOptions: PropTypes.number,
   updateNow: PropTypes.bool,
   colClassName: PropTypes.string,
   selectNoneText: PropTypes.string,
};

export default withFormsy(SelectFormsy);
