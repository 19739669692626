import * as Actions from '../actions';

const initState = {
   suggestions: [],
   open: false,
   newChecked: [],
   targetTree: [],
   targetList: [],
   initChecked: [],
   loadingSuggest: false,
   loadingTree: false,
   openId: '',
   totalLoaded: false,
};

const empselectReducer = (state = initState, action) => {
   switch (action.type) {
      case Actions.FETCH_SUGGESTIONS:
         return {
            ...state,
            suggestions: action.payload.map(emp => ({
               id: emp.empno,
               text: `${emp.name} (${emp.department.deptname})`,
               data: emp,
            })),
            totalLoaded: !state.loadingTree,
         };
      case Actions.FETCH_TREE:
         return {
            ...state,
            targetTree: action.tree,
            targetList: action.list,
            totalLoaded: !state.loadingSuggest,
         };
      case Actions.TOGGLE_TREE:
         return {
            ...state,
            open: action.flag,
            openId: action.id,
            initChecked: action.initKeys || [],
         };
      case Actions.LOADING_SUGGEST:
         return {
            ...state,
            loadingSuggest: action.flag,
         };
      case Actions.LOADING_TREE:
         return {
            ...state,
            loadingTree: action.flag,
         };
      default:
         return state;
   }
};

export default empselectReducer;
