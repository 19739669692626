import * as Actions from '../actions';
import navigation from '../../../Layout/AppNav/NavItems' ;
import _ from 'lodash';

// const initialState = sessionStorage.getItem("navigation") ? JSON.parse(sessionStorage.getItem("nativation")) : navigation;
const initialState = navigation;

const controlRecursive = (menu, strings) => {
   menu['label'] = strings[menu.label];
   if (menu.isLeaf || menu.content == null) {
      delete menu['content'];
   } else {
      menu.content.forEach(item => {
         controlRecursive(item, strings);
      });
   }
};

const navigationReducer = function (state = initialState, action) {
   switch ( action.type )
   {
      case Actions.GET_NAVIGATION:
         return state;
      case Actions.LOAD_NAVIGATION:
         const menus = action.payload;
         const strings = action.strings;
         if (!menus) {
            return [...JSON.parse(sessionStorage.getItem("navigation"))];
         } else {
            menus.forEach(item => {
               controlRecursive(item, strings);
            });
            sessionStorage.setItem("navigation", JSON.stringify(menus));
            return [...menus];
         }
      case Actions.UPDATE_MENU_MAIL_COUNT:
         const activity = _.find(state, {id: 'activity'});
         if(activity != null) {
            const mail = _.find(activity.content, {id: 'mail'});
            if(mail != null) {
               if (action.count) {
                  mail.badge = {
                     'title': String(action.count),
                     'bg': '#f44336',
                     'fg': '#ffffff'
                  };
               } else {
                  mail.badge = undefined;
               }
            }
            sessionStorage.setItem("navigation", JSON.stringify(state));
         }
         return [
            ...state
         ];
      case Actions.UPDATE_MENU_SIGN_COUNT:
         const activity2 = _.find(state, {id: 'activity'});
         if (activity2 != null) {
            const approve = _.find(activity2.content, {id: 'approve'});
            if(approve != null) {
               if (action.payload) {
                  approve.badge = {
                     'title': String(action.payload),
                     'bg': '#722ed1',
                     'fg': '#ffffff'
                  };
               } else {
                  approve.badge = undefined;
               }
            }
            sessionStorage.setItem("navigation", JSON.stringify(state));
         }
         return [
            ...state
         ];
      default:
         return state;
   }
};

export default navigationReducer;