import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import React, { lazy, Suspense, useEffect } from 'react';
import Loader from 'react-loaders';
import withTimeout from '../../@gc/common/withTimeout';
import { pack } from '@gc';
import { connect } from 'react-redux';
import GoogleLoading from '../../@griffin/common/components/GoogleLoading';
import NotFound from '../../@griffin/common/components/NotFound';
// import {refreshList} from "../../@griffin/activity/mailbox/actions";
import { setReloadable } from '../../@gc/common/actions/common.action';
import { useStore } from '../../store/globalStore';
import { updateMenuMailCount, updateMenuSignCount } from '../../@gc/navigation/actions';
import { logoutUser, updateUserPoint } from '../../@gc/auth/actions/user.action';

const Admin = lazy(() => import('@griffin/admin'));
const LoginPage = lazy(() => import('@griffin/login/LoginPage'));
const Activity = lazy(() => import('@griffin/activity'));
const Manage = lazy(() => import('@griffin/manage'));
const DeniedVideo = lazy(() => import('@griffin/login/DeniedVideo'));

const AppMain = props => {
   let signCounter = null;
   const { resetConnectInfo } = useStore();
   // const dispatch = useDispatch();
   const {
      user,
      reloadable,
      location: { pathname },
   } = props;

   /* 첫 화면로딩 후 한번 실행 */
   useEffect(() => {
      pack.fetchHolidays();

      /*if (Boolean(user.empno)) {
      axios.get(`/start-listen/${user.empno}`);
    }*/

      if (!signCounter) {
         signCounter = getSignListener(Boolean(user.empno));
      }

      // if (!isDev) {
      if (process.env.NODE_ENV !== 'development')
         window.addEventListener('beforeunload', function (ev) {
            ev.preventDefault();
            resetConnectInfo();
            const silentMode = Boolean(sessionStorage.getItem('silent'));
            if (!silentMode) {
               pack.removeLocalData();
               pack.removeSessionData();
            }
            sessionStorage.removeItem('silent');
            /*if (!silentMode) {
               return ev.returnValue = 'Are you sure you want to close?';
            }*/
         });

      document.body.addEventListener('keydown', e => {
         if (e.key === 'F5' || (e.metaKey && e.key === 'R')) {
            // F5 를 누를경우 마우스클릭 처리
            sessionStorage.setItem('silent', '1');
         }
      });
      // }

      /* 화면 언로드시 타이머 모두 삭제 */
      return () => {
         if (signCounter != null) clearInterval(signCounter);
         signCounter = null;
      };
   }, []);

   /* 사용자 Role 변경시 마다 실행 */
   useEffect(() => {
      if (signCounter != null) {
         clearInterval(signCounter);
      }

      signCounter = getSignListener();

      if (reloadable) {
         props.setReloadable(false);
         sessionStorage.setItem('silent', '1');
         setTimeout(() => {
            window.location.reload();
         }, 300);
      }
   }, [user.role, reloadable]);

   /*useEffect(() => {
    if (Boolean(user.empno)) {
      axios.get(`/start-listen/${user.empno}`);
    }
  }, [user.empno]);*/

   const getSignListener = isEmp =>
      setInterval(() => {
         if (Boolean(user.empmo)) {
            props.updateMenuSignCount();
            props.updateUserPoint();
         }
      }, 1000 * 30);

   return (
      <>
         {/*Administration*/}
         <Suspense
            fallback={
               <div className="loader-container">
                  <div className="loader-container-inner">
                     <div className="text-center">
                        {pathname === '/login' ? <GoogleLoading type="mesh" modal="white" /> : <Loader type={pack.getLoaderType()} active />}
                     </div>
                     {pathname !== '/login' && (
                        <h6 className="mt-5">
                           Please wait while we load all Components
                           <small>It will take a few seconds...</small>
                        </h6>
                     )}
                  </div>
               </div>
            }
         >
            <Switch>
               <Route path="/denied" component={DeniedVideo} />
               <Route path="/login" component={LoginPage} />
               <Route path="/admin" component={Admin} />
               <Route path="/activity" component={Activity} />
               <Route path="/manage" component={Manage} />
               <Route exact path="/" render={() => <Redirect to={user.firstUrl} />} />
               <Route component={NotFound} />
            </Switch>
         </Suspense>
         {/*<ToastContainer/>*/}
      </>
   );
};

function mapStateToProps({ gc, griffin }) {
   return {
      user: gc.user,
      lang: gc.common.lang,
      strings: gc.common.strings,
      reloadable: gc.common.reloadable,
   };
}

export default withTimeout(pack.defaultTimeout)(
   withRouter(
      connect(mapStateToProps, {
         updateMenuMailCount,
         updateMenuSignCount,
         updateUserPoint,
         logoutUser,
         setReloadable,
      })(AppMain),
   ),
);
// export default withTimeout(pack.defaultTimeout)(withRouter(AppMain));
