import { combineReducers } from 'redux';
import menu from '../menu/reducers';
import code from '../code/reducers';
import employee from '../employee/reducers';
import auth from '../auth/reducers';
import point from '../point/reducers';
import boardmgr from '../boardmgr/reducers';
import profile from '../employee/reducers/profile.reducers';
import locale from '../locale/reducers';
import pwdrule from '../pwdrule/reducers';
import vattend from '../vattend/reducers';
import adminboard from '../adminboard/reducers';
import sign from '../sign/reducers';

const adminAppReducer = combineReducers({
   menu,
   code,
   employee,
   profile,
   auth,
   point,
   boardmgr,
   locale,
   pwdrule,
   vattend,
   adminboard,
   sign,
});

export default adminAppReducer;
