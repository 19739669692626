import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  .fc-toolbar-chunk > div {
    display: inline-flex;
    align-items: center;
    & > button {
      margin: 0 8px !important;
    }
    &.fc-button-group > button {
      margin: 0 1px !important;
    }
  }
  .fc {
    & a {
      color: #757575 !important;
    }
    & .fc-day-sun a {
      color: tomato !important;
    }
    & .fc-day-sat a {
      color: tomato !important;
    }
    & td.fc-day a.fc-event {
      transition: all .3s;
      &:hover {
        z-index: 105;
        transform: scale(130%);
      }
    }
  }
  .redux-toastr .toastr.rrt-warning {background-color:#ffc107 !important; color: #424242 !important;}
  .redux-toastr .toastr.rrt-info {background-color:#3f51b5 !important;}
  
  .app-container {
    max-height: 100vh;
    overflow-y: auto;
    &.hidden-overflow {
      overflow-y: hidden !important;
    }
  }
  
  .small-loader > div {
    width: 24px !important;
    height: 24px !important;
  }
  
  .tox-textarea-wrap {
    height: 100%!important;
    textarea {
      height: 100%!important;
    }
  }
`;

export default GlobalStyles;
