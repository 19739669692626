import axios from 'axios';
import { pack } from '@gc';
import { updateMailCnt, updateSignCnt } from '../../common/actions/common.action';
import { addEvent, updateSignList } from '../../common/actions/common.action';
import { userMenuLoaded } from '../../common/actions/common.action';
import { setUserMenuTree } from '../../auth/actions/user.action';
import { fetchList, APPROVE_TYPE_GET } from '../../../@griffin/activity/approve/actions';
import { toastr } from 'react-redux-toastr';
import { notify } from '../../../@griffin/common/components/notify/actions';
import _ from 'lodash';

const jtf = require('json-form-data');

export const GET_NAVIGATION = '[NAVIGATION] GET NAVIGATION';
export const SET_NAVIGATION = '[NAVIGATION] SET NAVIGATION';
export const RESET_NAVIGATION = '[NAVIGATION] RESET NAVIGATION';
export const LOAD_NAVIGATION = '[NAVIGATION] LOAD_NAVIGATION';
export const UPDATE_MENU_MAIL_COUNT = '[NAVIGATION] UPDATE MENU MAIL COUNT';
export const UPDATE_MENU_SIGN_COUNT = '[NAVIGATION] UPDATE MENU SIGN COUNT';

export function getNavigation() {
   return {
      type: GET_NAVIGATION,
   };
}

export function setNavigation(navigation) {
   return {
      type: SET_NAVIGATION,
      navigation,
   };
}

export function resetNavigation() {
   return {
      type: RESET_NAVIGATION,
   };
}

function removEmptyRecursive(pMenu, contents) {
   if (pMenu.isLeaf) {
      return true;
   }
   if (!pMenu.content || pMenu.content.length === 0) {
      return false;
   }
   for (let i = pMenu.content.length - 1; i >= 0; i--) {
      if (!removEmptyRecursive(pMenu.content[i])) {
         pMenu.content.splice(i, 1);
      }
   }
   return Boolean(pMenu.content) && pMenu.content.length > 0;
}

export function loadNavigation(forceReload) {
   return (dispatch, getState) => {
      if (forceReload || !Boolean(sessionStorage.getItem('navigation'))) {
         const param = getState().gc.user.isAdmin || getState().gc.user.isSuper ? 'isuse=1' : `isuse=1&id=${getState().gc.user.id}`;
         axios.get(`/menu/navtree?${param}`).then(res => {
            for (let i = res.data.length - 1; i >= 0; i--) {
               if (!removEmptyRecursive(res.data[i])) {
                  res.data.splice(i, 1);
               }
            }
            dispatch({
               type: LOAD_NAVIGATION,
               payload: res.data,
               strings: getState().gc.common.strings,
            });
            dispatch(setUserMenuTree(res.data));
            dispatch(userMenuLoaded());
            dispatch(updateMenuMailCount());
            dispatch(updateMenuSignCount());
         });
      } else {
         dispatch({
            type: LOAD_NAVIGATION,
         });
      }
   };
}

export function updateMenuMailCount(newCount) {
   return (dispatch, getState) => {
      const { currentEmail } = getState().gc.user;
      if (newCount != null) {
         dispatch({
            type: UPDATE_MENU_MAIL_COUNT,
            count: newCount,
         });
         dispatch(updateMailCnt(newCount));
      } else if (Boolean(currentEmail)) {
         axios(`/mail/count/${currentEmail}`).then(res => {
            dispatch({
               type: UPDATE_MENU_MAIL_COUNT,
               count: res.data,
            });
            dispatch(updateMailCnt(res.data));
         });
      }
   };
}

export function updateMenuSignCount(count) {
   return (dispatch, getState) => {
      const { listType } = getState().griffin.activity.approve;
      if (typeof count === 'number') {
         const oldCount = getState().gc.common.signCount;
         if (oldCount !== count) {
            dispatch({
               type: UPDATE_MENU_SIGN_COUNT,
               payload: count,
            });
            dispatch(updateSignCnt(count));
            dispatch(fetchList(listType));
         }
      } else if (Boolean(getState().gc.user.empno)) {
         axios.get(`/approve/count/${getState().gc.user.empno}`).then(res => {
            dispatch(updateSignList(res.data.list));
            dispatch({
               type: UPDATE_MENU_SIGN_COUNT,
               payload: res.data.count,
            });
            dispatch(updateSignCnt(res.data.count));
            dispatch(fetchList(listType));
         });
      }
   };
}
/* @gbiz */
