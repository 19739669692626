import {withFormsy} from 'formsy-react';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Switch from 'react-switch';
import {Col, Label} from "reactstrap";
import cx from 'classnames';

class SwitchFormsy extends Component {

   constructor(props) {
      super(props);
      this.state = {
         touched: false,
         checked: props.checked
      };
   }

   handleChange = (checked) => {
      this.props.setValue(checked ? '1' : '0');
      this.setState({
         checked
      });
      if (typeof this.props.onChange === 'function') {
         this.props.onChange(checked);
      }
   };

   componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.checked !== this.props.checked) {
         this.setState({
            checked: this.props.checked,
            touched: false
         });
      }
      const value = this.state.checked ? '1' : '0';
      if (this.props.getValue() !== value) {
         this.props.setValue(value);
      }
   }

   render() {
      let importedProps = _.pick(this.props, [
         "name",
         "id",
         "className"
      ]);
      let checked = this.state.checked;
      const value = checked ? '1' : '0';

      /*if (this.props.getValue() !== value) {
         this.props.setValue(value);
      }*/

      /*if (this.state.touched) {
         checked = this.state.checked;
      } else {
         this.setState({
            touched: true,
            checked
         });
      }*/

      const labelWidth = this.props.labelWidth || {sm: 2};
      const colWidth = this.props.colWidth || {sm: 4};

      return (
         <>
            {Boolean(this.props.label) &&
            <Label
               {...labelWidth}
               for={this.props.id || ''}
               className={!this.props.viewmode && this.props.required ? 'text-danger' : ''}
            >
               {this.props.label}
            </Label>
            }
            <Col {...colWidth} className={cx("d-flex align-items-center", this.props.colClassName || '')}>
               { this.props.viewmode && this.props.onText && this.props.offText ?
                  <Label className="view">{this.state.checked ? this.props.onText : this.props.offText}</Label>
                  :
                  <>
                     <div className={cx("d-flex", (this.props.rightComponent ? "w-auto" : "w-100"))}>
                        <Switch
                           {...importedProps}
                           checked={checked}
                           value={value}
                           onChange={this.handleChange}
                           disabled={this.props.viewmode}
                           handleDiameter={18}
                           height={20}
                           width={48}
                           onColor="#1e88e5"
                           // onColor="#86d3ff"
                           onHandleColor="#fff"
                           // onHandleColor="#2693e6"
                           boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                           activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        />
                     {
                        this.props.onText && this.props.offText && (
                           <Label className="view ml-2">{this.state.checked ? this.props.onText : this.props.offText}</Label>
                        )
                     }
                     </div>
                     {this.props.rightComponent != null &&
                        <div className="pane-right">
                           {this.props.rightComponent}
                        </div>
                     }
                  </>
               }
            </Col>
         </>
      );
   }
}

SwitchFormsy.propTypes = {
   id: PropTypes.string,
   name: PropTypes.string.isRequired,
   label: PropTypes.string,
   value: PropTypes.oneOf(['0', '1']),
   checked: PropTypes.bool,
   onChange: PropTypes.func,
   onText: PropTypes.string,
   offText: PropTypes.string,
   viewmode: PropTypes.bool,
   labelWidth: PropTypes.object,
   colWidth: PropTypes.object,
   rightComponent: PropTypes.object,
   colClassName: PropTypes.string,
   disabled: PropTypes.bool
};

export default withFormsy(SwitchFormsy)