import * as Actions from "../actions";

const initState = {
   loadCnt: 0,
   currentTab: '1',
   data: {},
   loaderActive: false,
   mergeLoading: false,
};

const localeReducer = function(state = initState, action) {
   switch(action.type) {
      case Actions.LOAD_LOCALE:
         return {
            ...state,
            data: action.payload,
            loadCnt: state.loadCnt + 1
         };
      case Actions.LOADER_START:
         return {
            ...state,
            loaderActive: true
         };
      case Actions.LOADER_STOP:
         return {
            ...state,
            loaderActive: false
         };
      case Actions.SELECT_TAB:
         return {
            ...state,
            currentTab: action.tab
         };
      case Actions.UPDATE_LOCALE:
         return {
            ...state,
            data: action.payload,
            currentTab: '1',
            loadCnt: state.loadCnt + 1
         };
      case Actions.SHOW_MERGE_LOADING:
         return {
            ...state,
            mergeLoading: action.flag
         };
      default:
         return state;
   }
};

export default localeReducer;