import axios from 'axios';
import {pack} from "@gc";
import {startLoading, stopLoading} from "../../../../@gc/common/actions/common.action";
import {selectFolder} from "../../contacts/actions";

const jtf = require('json-form-data');

export const FETCH_FOLDERS = '[PDS] FETCH FOLDERS';
export const FETCH_FOLDER = '[PDS] FETCH FOLDER';
export const FETCH_FILES = '[PDS] FETCH FILES';
export const FETCH_TARGET_LIST = '[PDS] FETCH TARGET LIST';
export const FETCH_POSITIONS = '[POS] FETCH POSITIONS';
export const ON_TARGET_CHECKED = '[PDS] ON TARGET CHECKED';
export const OPEN_FILE_ADD = '[PDS] OPEN FILE ADD';
export const CLOSE_FILE_DIALOG = '[PDS] CLOSE FILE DIALOG';
export const SELECT_FILE = '[PDS] SELECT FILE';
export const UPDATE_FILE = '[PDS] UPDATE FILE';
export const REMOVE_FILE = '[PDS] REMOVE FILE';
export const UPDATE_CHECKED_TARGETS = '[PDS] UPDATE CHECKED TARGETS';
export const UPDATE_FOLDER_TREE = '[PDS] UPDATE FOLDER TREE';

export function fetchPositions(groupName) {
   return (dispatch) =>
      axios.get(`/code/list/${groupName}`)
         .then(res =>
            dispatch({
               type: FETCH_POSITIONS,
               payload: res.data
            })
         );
}

export function fetchTargetList() {
   return (dispatch, getState) => {
      axios.get(`/department/emp/tree?lang=${getState().gc.common.lang}`)
         .then(res =>
            dispatch({
               type: FETCH_TARGET_LIST,
               tree: res.data.tree,
               list: res.data.list
            })
         );
   }
}

export function fetchFolders(folderSeq) {
   return (dispatch, getState) => {
      let url = `/pds/folders?lang=${getState().gc.common.lang}`;
      const user = getState().gc.user;

      if(!pack.hasRole(user, 'manage'))
         url = `/pds/folders/${user.empno}?lang=${getState().gc.common.lang}`;

      const request = axios.get(url);

      request.then(res => {
         dispatch({
            type: FETCH_FOLDERS,
            tree: res.data.tree,
            list: res.data.list
         });
         if(folderSeq != null) {
            dispatch(selectTreeNode(folderSeq));
         }
      });
   };
}

export function fetchFolder(seq) {
   return (dispatch, getState) => {
      axios.get(`/pds/folder/${seq || 0}`)
         .then(res => {
            dispatch({
               type: FETCH_FOLDER,
               payload: res.data
            });
            const folder = res.data;
            const user = getState().gc.user;
            if(folder.ispublic === '1'
               || pack.hasRole(user, 'manage')
               || folder.employees.map(auth => auth.employee.empno).includes(user.empno)
               || folder.positions.map(item => item.position).includes(user.position)
            )
               dispatch(fetchFiles(folder.seq, true));
            else
               dispatch(fetchFiles(null, true));
         });
   };
}

export function fetchFiles(folderSeq, showLoading) {
   return (dispatch) => {
      if (!showLoading) {
         dispatch(stopLoading());
      } else {
         dispatch(startLoading());
      }
      axios.get(`/pds/files/${folderSeq || 0}`)
         .then(res => {
            dispatch(stopLoading());
            dispatch({
               type: FETCH_FILES,
               payload: res.data
            })
         })
   };
}

export function selectTreeNode(folderSeq) {
   return (dispatch, getState) => {
      if(folderSeq === '0') {
         dispatch({
            type: FETCH_FOLDER,
            payload: {
               seq: 0,
               name: 'ROOT'
            }
         });
         dispatch(fetchFiles(null, true));
      } else {
         dispatch(fetchFolder(folderSeq));
      }

   };
}

export function removeFolder(seq) {
   return (dispatch) =>
      axios.delete(`/pds/folder/${seq}`)
         .then(res => {
            dispatch(fetchFolders());
            dispatch(selectTreeNode('0'))
         })
}

export function updateFolder(data) {
   return (dispatch) =>
      axios({
         method: 'post',
         url: '/pds',
         data: jtf(data)
      }).then(res => {
         dispatch(fetchFolders());
         dispatch(fetchFolder(res.data.seq));
      });
}

export function updateCheckedTargets(checkedKeys) {
   return {
      type: UPDATE_CHECKED_TARGETS,
      checkedKeys
   };
}

export function onTargetChecked(checkedKeys) {
   return {
      type: ON_TARGET_CHECKED,
      checkedKeys
   };
}

export function openFileAdd() {
   return {
      type: OPEN_FILE_ADD
   };
}

export function closeFileDialog() {
   return {
      type: CLOSE_FILE_DIALOG
   };
}

export function saveFiles(data) {
   return (dispatch, getState) => {
      axios({
         method: 'post',
         url: '/pds/files',
         data: jtf({...data, empno: getState().gc.user.empno})
      }).then(res => {
         dispatch(selectTreeNode(data.folderSeq));
         // dispatch(fetchFiles(data.folderSeq, true))
      });
   };
}

export function downloadFile(seq) {
   return (dispatch, getState) =>
      axios.get(`/pds/download/${seq}/${getState().gc.user.empno}`)
         .then(res => {
            pack.downloadFile(res.data);
            dispatch({
               type: SELECT_FILE,
               file: res.data
            });
            dispatch(fetchFiles(getState().griffin.activity.pds.folder.seq, false));
         });
}

export function selectFile(file) {
   return {
      type: SELECT_FILE,
      file
   };
}

export function removeFile(seq) {
   return (dispatch, getState) =>
      axios.delete(`/pds/file/${seq}`)
         .then(res => {
            dispatch({
               type: REMOVE_FILE
            });
            dispatch({
               type: FETCH_FILES,
               payload: res.data
            });
         });
}

export function toggleCandown(seq, value) {
   return (dispatch, getState) =>
      axios.get(`/pds/candown/${seq}/${value}`)
         .then(res =>
            dispatch({
               type: UPDATE_FILE,
               file: res.data
            })
         );
}

export function updateFolderTree(tree) {
   return {
      type: UPDATE_FOLDER_TREE,
      tree
   };
}

export function moveTree(pid, dragid, children) {
   return (dispatch) =>
      axios.put(`/pds/folder/drag/${pid}/${dragid}`, jtf({children}))
         .then(res =>
            dispatch(fetchFolders())
         );
}