import _ from 'lodash';
import {pack} from "@gc";
import * as Actions from "../actions";

const initState = {
   entities: [],
   entity: {
      /* 속성들 */
      seq: '',
      name: '',
      folder: {}
   },
   newEntity: null,
   mode: 'list',   // list, add, edit, view
   listLoadCnt: 0,
   deptTreeSource: [],
   deptTreeMap: {},
   sharedTreeSource: [],
   sharedTreeMap: {},
   folderTreeSource: [],
   folderTreeMap: {},
   folderTreeSelect: [],
   treeLoadCnt: 0,
   selectedFolder: {
      id: '',
      pid: '',
      ispublic: '0'
   },
   shareDialog: {
      open: false,
      type: '',
      seq: ''
   },
   targetTree: [],   // 계층형 리스트
   targetList: [],    // 1차원 리스트
   targetChecked: []  // 공유된 직원목록
};

const contactsReducer = function(state = initState, action) {
   switch(action.type) {
      case Actions.FETCH_DEPT_TREE:
         return {
            ...state,
            deptTreeSource: action.deptTree,
            deptTreeMap: _.keyBy(action.deptList, 'id'),
            treeLoadCnt: state.treeLoadCnt + 1
         };
      case Actions.FETCH_FOLDER_TREE:
         const {folderTree, folderList} = action;
         let folderSelect = [];
         if (folderTree.length > 0 && folderTree[0].children && folderTree[0].children.length > 0) {
            folderSelect = folderTree[0].children;
         }
         return {
            ...state,
            folderTreeSource: folderTree,
            folderTreeMap: _.keyBy(folderList, 'id'),
            folderTreeSelect: folderSelect.map(item => ({
               ...item,
               key: item.id,
               value: item.id
            })),
            treeLoadCnt: state.treeLoadCnt + 1
         };
      case Actions.FETCH_SHARED_LIST:
         return {
            ...state,
            sharedTreeSource: action.sharedTree,
            sharedTreeMap: _.keyBy(action.sharedList, 'id'),
            treeLoadCnt: state.treeLoadCnt + 1
         };
      case Actions.FETCH_LIST:
         return {
            ...state,
            entities: action.payload,
            listLoadCnt: state.listLoadCnt + 1
         };
      case Actions.SAVE_CONTACTS:
         return {
            ...state,
            entity: action.payload
         };
      case Actions.OPEN_LIST:
         return {
            ...state,
         };
      case Actions.OPEN_EDIT:
         return {
            ...state,
            entity: action.entity
         };
      case Actions.OPEN_ADD:
         return {
            ...state,
            entity: initState.entity
         };
      case Actions.REMOVE_ENTITY:
         state.entities.forEach((it, idx) => {
            if(it.seq === action.seq) {
               state.entities.splice(idx, 1);
               return false;
            }
         });
         return {
            ...state
         };
      case Actions.FETCH_EMPLOYEES:
         return {
            ...state,
            entities: action.payload,
            listLoadCnt: state.listLoadCnt + 1
         };
      case Actions.FETCH_CONTACTS:
         return {
            ...state,
            entities: action.payload,
            listLoadCnt: state.listLoadCnt + 1
         };
      case Actions.FETCH_CONTACT:
         return {
            ...state,
            entity: _.find(state.entities, {seq: +(action.seq)}) || state.entity
         };
      case Actions.SELECT_FOLDER:
         const id = action.payload;
         return {
            ...state,
            selectedFolder: state.deptTreeMap[id] || state.folderTreeMap[id] || { id, pid: ''}
         };
      case Actions.OPEN_SHARE_DIALOG:
         return {
            ...state,
            targetTree: action.tree,
            targetList: action.list,
            targetChecked: action.checked,
            shareDialog: {
               ...state.shareDialog,
               open: true,
               type: action.kind,
               seq: action.seq
            }
         };
      case Actions.CLOSE_SHARE_DIALOG:
         return {
            ...state,
            shareDialog: initState.shareDialog
         };
      default:
         return state;
   }
};

export default contactsReducer;