import React, {Component} from 'react';
import SweetAlert from "react-sweetalert2";
import {connect} from 'react-redux';
import _ from 'lodash';
import {closeSwal} from "./actions";

class GcSweetAlert extends Component {

   shouldComponentUpdate(nextProps, nextState, nextContext) {
      return this.props.show !== nextProps.show;
   }

   handleConfirm = (result) => {
      this.props.closeSwal();
      if (result.value === true && typeof this.props.onConfirm === 'function') {
         this.props.onConfirm();
      } else if (result.dismiss === 'cancel' && typeof this.props.onCancel === 'function') {
         this.props.onCancel();
      }
   };

   render() {
      const importedProps = _.omit(this.props, ['onConfirm', 'onCancel', 'dispatch', 'swalType', 'closeSwal']);
      return (
         <SweetAlert
            {...importedProps}
            customClass="swal-small"
            onConfirm={this.handleConfirm}
         />
      )
   }

}

function mapStateToProps({swal}) {
   return {
      ...swal
   }
}

export default connect(mapStateToProps, {closeSwal})(GcSweetAlert);